import { useTranslation } from "react-i18next"
import { ColourPicker, HtmlEditor, Label, ModalPopup, TextInput } from "../../common";
import { useEffect, useRef, useState } from "react";
import { colourHelper } from "../../../helpers";
import { ValidationResult } from "../../../types/forms";
import { ClientFormDto } from "../../../types/dtos/admin/ClientFormDto";
import { ClientFormDefaults } from "../../../types/dtos/admin/ClientFormDefaults";
import { useNavigate } from "react-router-dom";
import { SendNowUserDto } from "../../../types/dtos/admin/SendNowUserDto";
import SectionSelection from "./SectionSelector/SectionSelection";
import WarningAlert from "../../alerts/WarningAlert";
import AudienceSelection from "./AudienceSelection/AudienceSelection";
import AudienceTabType from "../../../types/admin/AudienceTabType";
import FullScreenLoader from "../../loaders/FullScreenLoader";
import SendNowUserApiResponseDto from "../../../types/dtos/admin/SendNowUserApiResponseDto";
import SmallLoader from "../../loaders/SmallLoader";
import AppRoutes from "../../../routes/AppRoutes";
import DottedLoader from "../../loaders/DottedLoader";
import PeoplePickerUserDto from "../../../types/dtos/generic/PeoplePickerUserDto";

interface SendNowFormProps {
  details: ClientFormDefaults;
  availableSections: ClientFormDto[];
  selectedSections: ClientFormDto[];
  onUpdateAvailableSections(newState: ClientFormDto[] | undefined): void;
  onUpdateSelectedSections(newState: ClientFormDto[] | undefined): void;
}

function SendNowForm({
  details,
  availableSections,
  selectedSections,
  onUpdateAvailableSections,
  onUpdateSelectedSections
}: SendNowFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const docTitleRef = useRef(null), audienceSelectionRef = useRef(null), introMessageRef = useRef(null),
    themeColourRef = useRef(null), selectSectionRef = useRef(null);
  const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false);
  const [showPageLoadingSpinner, setShowPageLoadingSpinner] = useState(false);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState<string>("");
  const [isLoadingUserCount, setIsLoadingUserCount] = useState(false);
  const [confirmationModalUserCount, setConfirmationModalUserCount] = useState<number>(0);
  const [confirmationModalUsers, setConfirmationModalUsers] = useState<SendNowUserDto[]>();
  const [isConfirmationModalUsersLoading, setIsConfirmationModalUsersLoading] = useState(false);

  // Doc Title
  const docTitleInput = "doc-title-input";
  const [docTitle, setDocTitle] = useState<string>("");
  const [docTitleValidation, setDocTitleValidation] = useState<ValidationResult | null>(null);

  // Who Send Too
  const [selectedAudienceTab, setSelectedAudienceTab] = useState<AudienceTabType>();
  const [audienceAppraisalLevelTreeChecked, setAudienceAppraisalLevelTreeChecked] = useState<string[]>([]);
  const [audienceAppraisalLevelTreeExpanded, setAudienceAppraisalLevelTreeExpanded] = useState<string[]>([]);
  const [audienceLocationTreeChecked, setAudienceLocationTreeChecked] = useState<string[]>([]);
  const [audienceLocationTreeExpanded, setAudienceLocationTreeExpanded] = useState<string[]>([]);
  const [audienceSpecificUsers, setAudienceSpecificUsers] = useState<PeoplePickerUserDto[]>([]);
  const [audienceUserIdSwitch, setAudienceUserIdSwitch] = useState<boolean>(false);
  const [audienceUserIds, setAudienceUserIds] = useState<string>("");
  const [audienceSelectionValidation, setAudienceSelectionValidation] = useState<ValidationResult | null>(null);

  // Select Section
  const [selectedSectionValidation, setSelectedSectionValidation] = useState<ValidationResult | null>(null);
  const [completedInPastNumOfDays, setCompletedInPastNumOfDays] = useState<number>(30);

  // Intro Message
  const introMessageInputId = "intro-mesage-editor";
  const maxWordCount = 500;
  const [showIntroWarningAlert, setShowIntroWarningAlert] = useState<boolean>(false);
  const [introMessage, setIntroMessage] = useState<string>(details.introMessage);
  const [introMessageWordCount, setIntroMessageWordCount] = useState<number>(details.introMessage.split(' ').length);
  const [introMessageValidation, setIntroMessageValidation] = useState<ValidationResult | null>(null)

  // Theme Colour
  const themeColourInputId = "theme-colour-selector";
  const contrastCheckerFontHexCode = colourHelper.getDefaultTextColour();
  const [themeColour, setThemeColour] = useState<string>(details.themeColour);
  const [themeColourValidation, setThemeColourValidation] = useState<ValidationResult | null>(null);

  //  |--------------- HOOKS (BEGIN) ---------------|
  useEffect(() => {
    if (showValidationErrors) {
      validateDocTitle();
    }
  }, [docTitle]);

  useEffect(() => {
    if (showValidationErrors) {
      validateAudienceSelection();
    }
  }, [selectedAudienceTab, audienceAppraisalLevelTreeChecked, audienceLocationTreeChecked, audienceSpecificUsers, audienceUserIdSwitch, audienceUserIds]);

  useEffect(() => {
    if (showValidationErrors) {
      validateSelectedSections();
    }

    if (selectedSections.length > 1) {
      setShowIntroWarningAlert(true);
    } else {
      setShowIntroWarningAlert(false);
    }
  }, [selectedSections]);

  useEffect(() => {
    if (showValidationErrors) {
      validateIntroMessage();
    }
  }, [introMessage]);

  useEffect(() => {
    if (showValidationErrors) {
      validateThemeColour();
    }
  }, [themeColour]);
  //  |--------------- HOOKS (END) ---------------|

  //  |--------------- EVENTS (BEGIN) ---------------|
  const onSendNowClickHandler = () => {
    if (isFormValid()) {
      console.log("Save - Validation Success");
      setConfirmationModalIsOpen(true);
      setIsLoadingUserCount(true);

      if (selectedSections.length === 1) {
        setConfirmationModalMessage(t("Pages.Admin.Common.YouAreAboutToSend").replace("%SECTION_NAME%", t(selectedSections[0].translationKey)));
      } else {
        setConfirmationModalMessage(t("Pages.Admin.Common.YouAreAboutToSendTheFollowing"));
      }

      //SY-TODO - Will need to pass in a list of ids too based on the audience tab
      fetch(`/api/admin/get-user-count/${selectedAudienceTab}`)
        .then((res) => res.json())
        .then((json: number) => {
          setConfirmationModalUserCount(json);
          setIsLoadingUserCount(false);
        })
        .catch((e) => {
          console.error(e.message);
          // TODO: Show some form of error message
        });

      setShowValidationErrors(false);
    } else {
      console.log("Save - Validation Failed");
      setShowValidationErrors(true); // Show errors
    }
  }

  const onConfirmationSendClick = () => {
    setConfirmationModalIsOpen(false);

    // TODO: API call to send, and toggle below whilst its sending.
    //setShowPageLoadingSpinner(true);
    navigate(AppRoutes.admin.pathAfterSendNowComplete);
    console.log("Sending...");
  };

  const onConfirmationCancelClick = () => {
    setConfirmationModalIsOpen(false);
    setConfirmationModalUsers(undefined);
    setConfirmationModalMessage("");
    setIsConfirmationModalUsersLoading(false);
    console.log("Cancel");
  };

  const onConfirmationModalLoadUsers = () => {
    if (confirmationModalUsers === undefined) {
      setIsConfirmationModalUsersLoading(true);

      //SY-TODO - Will need to pass in a list of ids too based on the audience tab
      fetch(`/api/admin/get-users/${selectedAudienceTab}`)
        .then((res) => res.json())
        .then((json: SendNowUserApiResponseDto) => {
          setConfirmationModalUsers(json.users);
          setIsConfirmationModalUsersLoading(false);
          console.log("json.users", json.users);
        })
        .catch((e) => {
          console.error(e.message);
          // TODO: Show some form of error message
        });
    }
  }

  const handleCompletedInPastNumOfDaysChange = (selectedValue: number) => {
    if (selectedValue !== null) {
      setCompletedInPastNumOfDays(selectedValue);
    }
  };
  //  |--------------- EVENTS (END) ---------------|

  //  |------------- VALIDATION (BEGIN) -------------|
  const isFormValid = (): boolean => {
    const docTitleValidationResult = validateDocTitle();
    const audienceSelectionValidationResult = validateAudienceSelection();
    const selectedSectionValidationResult = validateSelectedSections();
    const introMessageValidationResult = validateIntroMessage();
    const themeColourValidationResult = validateThemeColour();

    // Scroll to the first element that has an invalid message
    if (!docTitleValidationResult.isValid) {
      // @ts-ignore
      docTitleRef.current.scrollIntoView();
    } else if (!audienceSelectionValidationResult.isValid) {
      // @ts-ignore
      audienceSelectionRef.current.scrollIntoView();
    } else if (!selectedSectionValidationResult.isValid) {
      // @ts-ignore
      selectSectionRef.current.scrollIntoView();
    } else if (!introMessageValidationResult.isValid) {
      // @ts-ignore
      introMessageRef.current.scrollIntoView();
    } else if (!themeColourValidationResult.isValid) {
      // @ts-ignore
      themeColourRef.current.scrollIntoView();
    }

    return docTitleValidationResult.isValid
      && audienceSelectionValidationResult.isValid
      && selectedSectionValidationResult.isValid
      && introMessageValidationResult.isValid
      && themeColourValidationResult.isValid;
  }

  const validateDocTitle = (): ValidationResult => {
    let validationResult: ValidationResult;

    if (docTitle.length === 0) {
      validationResult = new ValidationResult(false, [{ errorType: "REQUIRED" }]);
    } else {
      validationResult = new ValidationResult(true);
    }

    setDocTitleValidation(validationResult);
    return validationResult;
  };

  const validateAudienceSelection = (): ValidationResult => {
    let validationResult: ValidationResult;

    if (selectedAudienceTab === undefined) {
      validationResult = new ValidationResult(false, [{ errorType: "REQUIRED" }])
    } else if (selectedAudienceTab === "APPRAISAL-LEVEL" && audienceAppraisalLevelTreeChecked.length === 0) {
      validationResult = new ValidationResult(false, [{ errorType: "MAX", min: 1 }])
    } else if (selectedAudienceTab === "LOCATION" && audienceLocationTreeChecked.length === 0) {
      validationResult = new ValidationResult(false, [{ errorType: "MAX", min: 1 }])    
    } else if (selectedAudienceTab === "SPECIFIC-USER" && audienceSpecificUsers.length === 0) {
      validationResult = new ValidationResult(false, [{ errorType: "MAX", min: 1 }])    
    } else if (selectedAudienceTab === "USER-IDS") {
      var userIds = audienceUserIds.split(/\n/);

      if (userIds.length === 1 && userIds[0] === "") {
        validationResult = new ValidationResult(false, [{ errorType: "MAX", min: 1 }])
      } else {
        // If System User Ids is selected, we need to ensure all given userIds are numerical
        // @ts-ignore
        if (!audienceUserIdSwitch && userIds.some(isNaN)) {
          validationResult = new ValidationResult(false, [{ errorType: "ILLEGAL-CHARACTER" }])
        } else { // Employee Number (External Reference) doesn't need the same validation as system ids, as possible they can contain numbers
          validationResult = new ValidationResult(true);
        }
      }
    }
    else {
      validationResult = new ValidationResult(true);
    }

    setAudienceSelectionValidation(validationResult);
    return validationResult;
  };

  const validateSelectedSections = (): ValidationResult => {
    let validationResult: ValidationResult;

    if (selectedSections.length === 0) {
      validationResult = new ValidationResult(false, [{ errorType: "ATLEAST-ONE-SECTION" }]);
    } else {
      validationResult = new ValidationResult(true);
    }

    setSelectedSectionValidation(validationResult);
    return validationResult;
  };

  const validateIntroMessage = (): ValidationResult => {
    let validationResult: ValidationResult;

    if (introMessageWordCount === 0) {
      validationResult = new ValidationResult(false, [{ errorType: "REQUIRED" }]);
    } else if (introMessageWordCount > maxWordCount) {
      validationResult = new ValidationResult(false, [{ errorType: "TOO-LONG", max: maxWordCount, current: introMessageWordCount }]);
    } else {
      validationResult = new ValidationResult(true);
    }

    setIntroMessageValidation(validationResult);
    return validationResult;
  };

  const validateThemeColour = (): ValidationResult => {
    let validationResult: ValidationResult;

    if (themeColour === "#" || themeColour.length === 0) {
      validationResult = new ValidationResult(false, [{ errorType: "REQUIRED" }]);
    } else if (!colourHelper.isValidHex(themeColour)) {
      validationResult = new ValidationResult(false, [{ errorType: "INVALID-HEX" }]);
    } else if (!colourHelper.doesColourPassContrastCheck(contrastCheckerFontHexCode, themeColour)) {
      validationResult = new ValidationResult(false, [{ errorType: "ACCESSIBILITY-ISSUE" }]);
    } else {
      validationResult = new ValidationResult(true);
    }

    setThemeColourValidation(validationResult);
    return validationResult;
  };
  //  |------------- VALIDATION (END) -------------|

  return (
    <>
      {showPageLoadingSpinner && (
        <FullScreenLoader />
      )}
      {!showPageLoadingSpinner && (
        <>
          <div>
            <div ref={docTitleRef} className="mb-2 pt-4">
              <Label
                htmlFor={docTitleInput}
                text={t("Pages.SendNow.Fields.DocTitle")} />
              <TextInput
                inputId={docTitleInput}
                value={docTitle}
                onChange={setDocTitle}
                placeholder={t("Pages.SendNow.Placeholders.DocTitle")}
                className="w-full"
                maxLength={50}
                showValidationErrors={showValidationErrors}
                validationResult={docTitleValidation}
              />
            </div>
            <div ref={audienceSelectionRef} className="mb-2 pt-4">
              <Label
                text={t("Pages.SendNow.Fields.WhoDoYouWantToSend")}
              />
              <AudienceSelection
                onAudienceChange={setSelectedAudienceTab}
                appraisalLevelTreeChecked={audienceAppraisalLevelTreeChecked}
                appraisalLevelTreeExpanded={audienceAppraisalLevelTreeExpanded}
                onAppraisalLevelTreeChecked={setAudienceAppraisalLevelTreeChecked}
                onAppraisalLevelTreeExpanded={setAudienceAppraisalLevelTreeExpanded}
                locationTreeChecked={audienceLocationTreeChecked}
                locationTreeExpanded={audienceLocationTreeExpanded}
                onLocationTreeChecked={setAudienceLocationTreeChecked}
                onLocationTreeExpanded={setAudienceLocationTreeExpanded}
                onSpecificUserChange={setAudienceSpecificUsers}
                specificUserSelectedUsers={audienceSpecificUsers}
                userIdsTextAreaValue={audienceUserIds}
                userIdSwitch={audienceUserIdSwitch}
                onUserIdsTextAreaValueChange={setAudienceUserIds}
                onUserIdsSwitchChange={setAudienceUserIdSwitch}
                showValidationErrors={showValidationErrors}
                validationResult={audienceSelectionValidation} />
            </div>
            <div ref={selectSectionRef} className="mb-2 pt-4">
              <div className="pb-2">
                <Label
                  text={t("Pages.SendNow.Fields.WhichSections")}
                />
              </div>
              <SectionSelection
                availableSections={availableSections}
                selectedSections={selectedSections}
                completedInPastNumOfDays={completedInPastNumOfDays}
                onUpdateAvailableSections={onUpdateAvailableSections}
                onUpdateSelectedSections={onUpdateSelectedSections}
                onNumOfDaysChange={handleCompletedInPastNumOfDaysChange}
                showValidationErrors={showValidationErrors}
                validationResult={selectedSectionValidation} />
            </div>
            <div ref={introMessageRef} className="mb-2 pt-4">
              <Label
                htmlFor={introMessageInputId}
                text={t("Pages.SendNow.Fields.IntroMessage")}
              />
              {showIntroWarningAlert && (
                <WarningAlert
                  prefix=""
                  message={t("Common.Validation.Warning.ReviewAndAmendIntroMessage")}
                />
              )}
              <HtmlEditor
                inputId={introMessageInputId}
                toolbarMode="ADVANCED"
                currentValue={introMessage}
                onValueChange={setIntroMessage}
                showValidationErrors={showValidationErrors}
                validationResult={introMessageValidation}
                currentWordCount={introMessageWordCount}
                maxWordCount={maxWordCount}
                onWordCountChange={setIntroMessageWordCount}
              />
            </div>
            <div ref={themeColourRef} className="mb-2">
              <Label
                htmlFor={themeColourInputId}
                text={t("Pages.Admin.Common.ThemeColour")}
              />
              <ColourPicker
                inputId={themeColourInputId}
                value={themeColour}
                fontColour={contrastCheckerFontHexCode}
                onChange={setThemeColour}
                showValidationErrors={showValidationErrors}
                validationResult={themeColourValidation} />
            </div>
            <div className="flex flex-row py-3">
              <div className="grow">
                <button className="btn-primary" onClick={onSendNowClickHandler}>{t("Pages.SendNow.Buttons.SendNow")}</button>
              </div>
            </div>
          </div>

          <ModalPopup
            isOpen={confirmationModalIsOpen}
            onOpenChange={setConfirmationModalIsOpen}
            onPrimaryButtonClick={onConfirmationSendClick}
            onSecondaryButtonClick={onConfirmationCancelClick}
            primaryButtonText={t("Common.YesSend")}
            secondaryButtonText={t("Common.NoCancel")}
            title={t("Pages.Admin.Common.AreYouSure")}
          >
            <p>
              {confirmationModalMessage}
              {isLoadingUserCount && (<span className="inline-block pl-1"><DottedLoader /></span>)}
              {!isLoadingUserCount && (
                <span className="underline font-bold cursor-pointer pl-1" onClick={() => onConfirmationModalLoadUsers()}>{confirmationModalUserCount} {(confirmationModalUserCount === 1 ? t("Common.User_LowerCase") : t("Common.Users_LowerCase"))}</span>
              )}
            </p>
            {selectedSections.length > 1 && (
              <ul>
                {selectedSections.map((ss) => {
                  return (
                    <li key={ss.id}>- {t(ss.translationKey)}</li>
                  );
                })}
              </ul>
            )}
            {isConfirmationModalUsersLoading && (
              <div className="pt-2">
                <SmallLoader />
                <p className="text-center pt-1 text-[#959595] text-sm">{t("Common.LoadingUsers")}...</p>
              </div>
            )}
            {!isConfirmationModalUsersLoading && confirmationModalUsers !== undefined && (
              <div
                className="snap-y overflow-scroll overflow-x-hidden mb-4 max-h-60 border border-gray-200"
              >
                {confirmationModalUsers.map((user, ix) => (
                  <div className="pl-10 py-1.5 border-b border-b-gray-200 flex flex-row">
                    <p className="w-2/4">{user.name}</p>
                    <p className="w-2/4 text-sm text-right pr-6">({t(user.jobTitle)})</p>
                  </div>
                ))}
              </div>

            )}
            <p>{t("Pages.Admin.Common.AreYouSureProceed")}</p>
          </ModalPopup></>
      )}
    </>
  )
}

export default SendNowForm;
