import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

interface AreaChartProps {
  data?: null | undefined | any[];
}

const CustomLabel = ({ x, y, stroke, value }: any) => {
  return (
    <text
      x={x - 0}
      y={y - 6}
      dy={-4}
      fill={stroke}
      fontSize={10}
      textAnchor="middle"
    >
      {value}
    </text>
  );
};

function AreaChartComponent({ data }: AreaChartProps) {
  if (!data || data === null) {
    return null;
  } else {
    return (
      <ResponsiveContainer className="-mt-16" width="100%" height="100%">
        <AreaChart
          width={800}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 5,
            left: 5,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#62DBC6" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#62DBC6" stopOpacity={0} />
            </linearGradient>
            <filter id="whiteOutlineEffect" color-interpolation-filters="sRGB">
              <feMorphology
                in="SourceAlpha"
                result="MORPH"
                operator="dilate"
                radius="2"
              />
              <feColorMatrix
                in="MORPH"
                result="WHITENED"
                type="matrix"
                values="-1 0 0 0 1, 0 -1 0 0 1, 0 0 -1 0 1, 0 0 0 1 0"
              />
              <feMerge>
                <feMergeNode in="WHITENED" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
          <CartesianGrid vertical={false} horizontal={false} />
          <XAxis dataKey={"name"} hide interval={"preserveStartEnd"} />
          <Tooltip />

          <Area
            type="monotone"
            dataKey="Score"
            stroke="#62DBC6"
            fillOpacity={1}
            fill="url(#color)"
            dot={{ stroke: "#62DBC6", strokeWidth: 2, fill: "#62DBC6" }}
            label={({
              cx,
              cy,
              x,
              y,
              midAngle,
              innerRadius,
              outerRadius,
              value,
              index,
            }: any) => {
              return (
                <text
                  x={x}
                  y={y - 16}
                  fill="#777777"
                  fontSize="0.9em"
                  textAnchor={
                    index === 0
                      ? "start"
                      : x > cx
                      ? "end"
                      : index === data.length - 1
                      ? "end"
                      : "middle"
                  }
                  dominantBaseline="central"
                  filter="url(#whiteOutlineEffect)"
                >
                  {data[index].name}
                </text>
              );
            }}
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }
}

export default AreaChartComponent;
