import { FormQuestion, FormType } from "../types/forms";

export type GoalReviewFormat =
  | "STATUS-ONLY"
  | "STATUS-AND-ADDITIONAL-QUESTIONS";

export const goalReviewHelper = {
  /** Return whether this should be a simple goal review status list,
   * or a more advanced form with additional questions displayed per goal */
  getReviewFormat(
    formType: FormType,
    question: FormQuestion
  ): GoalReviewFormat {
    const hasGoalsToReview =
      question.goalReviewOptions?.goals &&
      question.goalReviewOptions?.goals.length > 0;

    const hasAdditionalQuestions =
      question.goalReviewOptions?.additionalQuestions &&
      question.goalReviewOptions.additionalQuestions !== null &&
      question.goalReviewOptions.additionalQuestions.length > 0;

    if (
      formType === "JOURNEY" ||
      (formType === "COLLAB-DOC" &&
        !hasAdditionalQuestions &&
        hasGoalsToReview &&
        question.goalReviewOptions!.goals.length > 1)
    ) {
      return "STATUS-ONLY";
    }

    return "STATUS-AND-ADDITIONAL-QUESTIONS";
  },
};

export default goalReviewHelper;
