import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus, faInfoCircle, faRadar
} from "@fortawesome/pro-solid-svg-icons";
import { ClientFormDto } from "../../../../types/dtos/admin/ClientFormDto";
import { ValidationResult } from "../../../../types/forms";
import ValidationWarning from "../../../common/ValidationWarning";
import DraggableList from "react-draggable-list";
import SectionItem from "./SectionItem";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { AlertPopup, NumberInput } from "../../../common";

interface SectionSelectionProps {
  availableSections: ClientFormDto[];
  selectedSections: ClientFormDto[];
  completedInPastNumOfDays: number;
  onUpdateAvailableSections(newState: ClientFormDto[] | undefined): void;
  onUpdateSelectedSections(newState: ClientFormDto[] | undefined): void;
  onNumOfDaysChange(newValue: number): void;
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
}

/** A text input field, with common styling already applied
 */
const SectionSelection = ({
  availableSections,
  selectedSections,
  completedInPastNumOfDays = 30,
  onUpdateAvailableSections,
  onUpdateSelectedSections,
  onNumOfDaysChange,
  showValidationErrors = false,
  validationResult = null,
}: SectionSelectionProps) => {
  const [showSelectedSectionsTooltip, setShowSelectedSectionsTooltip] = useState(false);
  const [showCompletedInPastNumOfDaysTooltip, setShowCompletedInPastNumOfDaysTooltip] = useState(false);
  const [selectedSectionsWithRelevantOverrides, setselectedSectionsWithRelevantOverrides] = useState<ClientFormDto[]>();
  const [automatedModePostSuffix, setAutomatedModePostSuffix] = useState<string>(t("Pages.SendNow.Warnings.WithinXDays"));

  const _onAddToSelectedSection = (item: ClientFormDto) => {
    // Copy and then remove item from Available.
    let newAvailableState = [...availableSections];
    let index = newAvailableState.findIndex(x => x.id == item.id);
    newAvailableState.splice(index, 1);

    // Copy and push item to Selected.
    let newSelectedState = [...selectedSections];
    newSelectedState.push(item);

    onUpdateAvailableSections(newAvailableState);
    onUpdateSelectedSections(newSelectedState);
  }

  const _onListChange = (newList: ClientFormDto[]) => {
    onUpdateSelectedSections(newList);
  }

  const _onDelete = (item: ClientFormDto) => {
    var newSelectedState = selectedSections.filter(function (ss) {
      return ss !== item
    });

    let newAvailableState = [...availableSections];
    newAvailableState.push(item);

    onUpdateSelectedSections(newSelectedState);
    onUpdateAvailableSections(newAvailableState);
  }

  useEffect(() => {
    // Retrieve any selected sections that have overrides.
    var selectedSectionsWithRelevantOverrides = selectedSections.filter(function (ss) {
      return ss.overrides.length > 0;
    });

    // Loop over and remove any non-relevant overrides e.g. manual only
    selectedSectionsWithRelevantOverrides.forEach(function (ss) {
      var overrides = ss.overrides.filter(function (override) {
        return override.mode !== "MANUAL-ONLY";
      });
      ss.overrides = overrides;
    });

    setselectedSectionsWithRelevantOverrides(selectedSectionsWithRelevantOverrides);
  }, [selectedSections]);

  useEffect(() => {
    let postSuffix = t("Pages.SendNow.Warnings.WithinXDays");
    postSuffix = postSuffix.replace("%NUM_DAYS%", completedInPastNumOfDays.toString());

    setAutomatedModePostSuffix(postSuffix);
  }, [completedInPastNumOfDays]);

  return (
    <>
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      <div className="grid grid-cols-1 md:grid-cols-12 gap-10">
        <div className="col-span-1 md:col-span-6">
          <h4>{t("Pages.SendNow.Other.AvailableSections")}:</h4>
          <div className="mt-1">
            {availableSections.length > 0 && availableSections.map((as) => {
              return (
                <div key={as.id} className="p-2 border shadow-md mb-3 grid grid-cols-12">
                  <p className="col-span-11 pl-3">{t(as.translationKey)}</p>
                  <div>
                    <FontAwesomeIcon icon={faPlus} onClick={() => _onAddToSelectedSection(as)} className="text-green-700 cursor-pointer col-span-1"></FontAwesomeIcon>
                  </div>
                </div>
              )
            })}
            {availableSections.length === 0 && (
              <div className="p-2 border-t border-t-gray-500 border-b border-b-gray-500 text-center text-sm">
                <span>
                  {t("Pages.SendNow.Other.NoAvailableSections")}
                  <FontAwesomeIcon icon={faRadar} className="pl-2"></FontAwesomeIcon>
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="col-span-1 md:col-span-6">
          <h4>
            {t("Pages.SendNow.Other.SelectedSections")}:
            <FontAwesomeIcon onClick={() => setShowSelectedSectionsTooltip(true)} icon={faInfoCircle} size="sm" className="text-gray-600 pl-1" />
          </h4>
          <AlertPopup
            isOpen={showSelectedSectionsTooltip}
            onOpenChange={setShowSelectedSectionsTooltip}
            onPrimaryButtonClick={() => console.log("Ok")}
            primaryButtonText={t("Pages.SendNow.Tooltips.Ok")}
            bodyText={t("Pages.SendNow.Tooltips.SelectedSections")}
            title={t("Pages.SendNow.Tooltips.Information")}
          />
          <div className="mt-1">
            {selectedSections.length > 0 && (
              <DraggableList
                list={selectedSections}
                itemKey="id"
                template={SectionItem}
                onMoveEnd={(newList: any) => _onListChange(newList)}
                commonProps={_onDelete}
              />
            )}
            {selectedSections.length === 0 && (
              <div className="p-2 border-t border-t-gray-500 border-b border-b-gray-500 text-center text-sm">
                <span>
                  {t("Pages.SendNow.Other.NoSelectedSections")}
                  <FontAwesomeIcon icon={faRadar} className="pl-2"></FontAwesomeIcon>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12">
        <div className="col-span-1 md:col-span-7 pt-2">
          <h4>{t("Pages.SendNow.Fields.HaventCompletedInThePastXDays")}</h4>
        </div>
        <div className="col-span-1 md:col-span-2">
          <NumberInput
            className="w-full text-base"
            onChange={onNumOfDaysChange}
            value={completedInPastNumOfDays}
            minimum={1}
            maximum={365}
            step={1}
            inputId="number-input-id"
          />
        </div>
        <div className="col-span-1 md:col-span-3 pt-2 md:pl-3">
          <h4>
            {t("Common.Days_LowerCase")}
            <FontAwesomeIcon onClick={() => setShowCompletedInPastNumOfDaysTooltip(true)} icon={faInfoCircle} size="sm" className="text-gray-600 pl-1" />
          </h4>
          <AlertPopup
            isOpen={showCompletedInPastNumOfDaysTooltip}
            onOpenChange={setShowCompletedInPastNumOfDaysTooltip}
            onPrimaryButtonClick={() => console.log("Ok")}
            primaryButtonText={t("Pages.SendNow.Tooltips.Ok")}
            bodyText={t("Pages.SendNow.Tooltips.CompletedInPastNumOfDays")}
            title={t("Pages.SendNow.Tooltips.Information")}
          />
        </div>
      </div>
      {selectedSectionsWithRelevantOverrides && selectedSectionsWithRelevantOverrides.length > 0 && (
        <div className="bg-gray-50 py-1 px-4 mb-2 text-gray-700 text-sm rounded-sm border-l-2 border-l-gray-800 mt-4">
          <div>
            <div className="fa-layers fa-fw mr-0.5">
              <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
            </div>
            {t("Pages.SendNow.Warnings.DueToOverrides")}:
            <div>
              {selectedSectionsWithRelevantOverrides.map((section) => (
                <div key={section.id}>
                  <div className="pt-1">
                    <span className="font-bold">{t(section.translationKey)}</span> {t("Pages.SendNow.Warnings.WontBeSent")}:
                  </div>
                  <div>
                    <ul>
                      {section.overrides.map((override) => (
                        <li key={`${section.id}_${override.overrideId}`}>
                          - {t(override.appraisalLevelName)} {override.mode === "AUTOMATED" && (<span>{automatedModePostSuffix}</span>)}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SectionSelection;
