import * as AvatarPrimitive from "@radix-ui/react-avatar";
import cx from "classnames";
import Tooltip from "./Tooltip";

interface AvatarProps {
  /** Set a value here to display a status indicator badge (or set to `DO-NOT-SHOW` to not display one, which is the default setting) */
  activeStatus?: "ACTIVE" | "INACTIVE" | "DO-NOT-SHOW" | "UNKNOWN";
  /** The url to the avatar image */
  imageUrl: string | null | undefined;
  /** The name to display in a tooltip */
  userFullName: string;
  /** The initials to display if there is no image */
  userInitials: string;
  /** If you want to override the default size, specify a number and this will apply size classes like `h-10` and `w-10` (which are the defaults) */
  size?: number;
  /** If you increase the size of the avatar, you'll probably want to increase the size of the status badge, if not set to `DO-NOT-SHOW` */
  statusBadgeSize?: number;
  /** If you increase the size of the avatar, and need bigger text for the initials, supply a class name like `text-xl` */
  initialsTextClassName?: string;
}

const Avatar = ({
  activeStatus = "DO-NOT-SHOW",
  statusBadgeSize = 3,
  imageUrl,
  userFullName,
  userInitials,
  initialsTextClassName = "",
  size = 10,
}: AvatarProps) => {
  const showStatusBadge = activeStatus !== "DO-NOT-SHOW";
  let statusBadgeClassName = "";
  switch (activeStatus) {
    case "ACTIVE":
      statusBadgeClassName = "bg-green-600";
      break;
    case "INACTIVE":
      statusBadgeClassName = "bg-red-600";
      break;
    case "UNKNOWN":
      statusBadgeClassName = "bg-gray-300";
      break;
    default:
      break;
  }

  return (
    <Tooltip
      content={userFullName}
      triggerElement={
        <AvatarPrimitive.Root>
          <div className={`relative inline-block w-${size}`}>
            <AvatarPrimitive.Image
              className={cx(
                "inline object-cover rounded-full",
                `w-${size} h-${size}`
              )}
              src={imageUrl ? imageUrl : undefined}
              alt={userFullName}
            />
            <AvatarPrimitive.Fallback
              className={cx(
                "rounded-full text-center bg-gray-200 shadow-lg flex items-center justify-center font-semibold select-none",
                `w-${size} h-${size}`,
                initialsTextClassName
              )}
            >
              {userInitials}
            </AvatarPrimitive.Fallback>
            {showStatusBadge && (
              <span
                className={cx(
                  `absolute bottom-0 right-0 inline-block shadow-lg w-${statusBadgeSize} h-${statusBadgeSize} border-2 border-white rounded-full`,
                  statusBadgeClassName
                )}
              ></span>
            )}
          </div>
        </AvatarPrimitive.Root>
      }
    />
  );
};

export default Avatar;
