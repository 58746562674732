import { useEffect, useState } from "react";
import { t } from "i18next";
import { EditableAction } from "../../types/tasks/EditableTasks";
import { DateInput, Label, TextArea, TextInput } from "../common";
import { isDate } from "lodash";
import { ValidationResult } from "../../types/forms";

const titleInputId = "new-action-title";
const datePickerInputId = "new-action-target-date";
const field1InputId = "new-action-field1";

interface EditActionFormProps {
  editObject: EditableAction | null;
  showValidationErrors: boolean;
  onChange(newState: EditableAction): void;
  isReadOnly?: boolean;
}

function EditActionForm({
  editObject,
  showValidationErrors,
  onChange,
  isReadOnly = false,
}: EditActionFormProps) {
  // State
  const [taskTitle, setTaskTitle] = useState<string>("");
  const [taskTargetDate, setTaskTargetDate] = useState<Date | null>(null);
  const [taskField1, setTaskField1] = useState<string>("");

  // Lifecycle
  const loadEditDetails = () => {
    setTaskTitle(editObject && editObject.title ? editObject.title : "");
    setTaskTargetDate(editObject ? editObject.targetDate : null);
    setTaskField1(editObject && editObject.field1 ? editObject.field1 : "");
  };

  // Initial mount
  useEffect(() => {
    // Load the values to edit, if any were supplied
    loadEditDetails();
  }, []);

  useEffect(() => {
    // When the task to edit changes
    loadEditDetails();
  }, [editObject]);

  // Private functions
  const updateParentEditObjectState = (
    title = taskTitle,
    targetDate = taskTargetDate,
    field1 = taskField1
  ) => {
    // State for the individual fields is managed locally. But we need to sync the changes to the edit
    // object passed from the parent state
    const updatedObject = new EditableAction(
      editObject && editObject.toDoId ? "UPDATED" : "NEW",
      editObject ? editObject.toDoId : null,
      title,
      targetDate,
      field1
    );

    onChange(updatedObject);
  };

  // Events
  const handleTitleChange = (newValue: string) => {
    setTaskTitle(newValue);
    updateParentEditObjectState(newValue);
  };

  const handleTargetDateChange = (newValue: Date | null) => {
    setTaskTargetDate(newValue);
    updateParentEditObjectState(undefined, newValue);
  };

  const handleField1Change = (newValue: string) => {
    setTaskField1(newValue);
    updateParentEditObjectState(undefined, undefined, newValue);
  };

  // Validation
  const titleIsValid = taskTitle && taskTitle.trim().length > 0;
  const targetDateIsValid = taskTargetDate && isDate(taskTargetDate);
  const field1IsValid = taskField1 && taskField1.trim().length > 0;
  const validationResults = {
    valid: new ValidationResult(true, []),
    invalid: new ValidationResult(false, [{ errorType: "REQUIRED" }]),
  };

  return (
    <>
      <div className="mb-2">
        <Label htmlFor={titleInputId} text={t("Tasks.Forms.Action.Title")} />
        <TextInput
          onChange={handleTitleChange}
          inputId={field1InputId}
          className="block mt-2 p-2 w-full bg-gray-100 border-0"
          value={taskTitle}
          showValidationErrors={showValidationErrors}
          validationResult={
            titleIsValid ? validationResults.valid : validationResults.invalid
          }
          isReadOnly={isReadOnly}
        />
      </div>
      <div className="mb-2">
        <Label
          htmlFor={datePickerInputId}
          text={t("Tasks.Common.TargetDate")}
        />
        <DateInput
          onChange={handleTargetDateChange}
          inputId={datePickerInputId}
          showValidationErrors={showValidationErrors}
          validationResult={
            targetDateIsValid
              ? validationResults.valid
              : validationResults.invalid
          }
          value={taskTargetDate}
          isReadOnly={isReadOnly}
        />
      </div>
      <div>
        <Label htmlFor={field1InputId} text={t("Tasks.Forms.Action.Field1")} />
        <TextArea
          onChange={handleField1Change}
          inputId={field1InputId}
          className="block mt-2 p-2 w-full bg-gray-100 border-0"
          minRows={3}
          maxRows={6}
          value={taskField1}
          showValidationErrors={showValidationErrors}
          validationResult={
            field1IsValid ? validationResults.valid : validationResults.invalid
          }
          isReadOnly={isReadOnly}
        />
      </div>
    </>
  );
}

export default EditActionForm;
