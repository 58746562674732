import { useState } from "react";
import Autosuggest from "react-autosuggest";
import { AutoCompleteSelectedValue, KeyValuePair } from "../../types/generic";

interface AutoCompleteProps {
  /** Optional css class names for the text input */
  className?: string;
  /** The id attribute for the text input. Useful when pairing this control with a label */
  inputId?: string;
  /** Optional placeholder attribute to render inside the text input */
  placeholder?: string | undefined;
  selectedValue: string | undefined;
  /** Whether or not to raise the valueSelected event for custom typed values (i.e. not just ones in the suggestion list) */
  allowCustomTypedValues: boolean;
  /** The list of suggestions to pick from */
  valuesList: KeyValuePair<string, string>[];
  onValueSelected(newValue: AutoCompleteSelectedValue | null): void;
  /** Whether to display the key or the value of the selected item in the text input field once a selection has been made */
  suggestionPropertyToDisplay?: "KEY" | "VALUE";
  /** An optional custom method for the HTML to render for each suggestion */
  customSuggestionRender?(
    suggestion: KeyValuePair<string, string>
  ): React.ReactNode;
}

interface AutoCompleteValueChangeEventProps {
  newValue: string;
}

interface AutoCompleteSearchEventProps {
  value: string;
}

/** An autocomplete/search text field */
const AutoComplete = ({
  inputId = "",
  selectedValue = "",
  placeholder,
  valuesList,
  onValueSelected,
  className = "",
  allowCustomTypedValues = false,
  suggestionPropertyToDisplay = "VALUE",
  customSuggestionRender,
}: AutoCompleteProps) => {
  const [displayText, setDisplayText] = useState<string>("");
  const [suggestions, setSuggestions] = useState<
    KeyValuePair<string, string>[]
  >([]);

  // Get the suggestions for the search value
  const getSuggestions = (value: string) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : valuesList.filter(
          (kvp) => kvp.value.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({
    value,
  }: AutoCompleteSearchEventProps) => {
    setSuggestions(getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onInputValueChange = (
    event: any,
    { newValue }: AutoCompleteValueChangeEventProps
  ) => {
    setDisplayText(newValue);

    if (allowCustomTypedValues) {
      onValueSelected({
        displayText: newValue,
        isCustomValue: true,
        key: null,
      });
    } else {
      // Only raise the onChange event if there's a selected item
      let kvp: KeyValuePair<string, string> | undefined = undefined;
      if (suggestionPropertyToDisplay === "VALUE") {
        kvp = valuesList.find((x) => x.value === newValue);
      } else {
        kvp = valuesList.find((x) => x.key === newValue);
      }
      if (kvp) {
        onValueSelected({
          displayText: kvp.value,
          isCustomValue: false,
          key: kvp.key,
        });
      }
    }
  };

  const inputProps = {
    id: inputId,
    autocomplete: "off",
    className: className,
    placeholder: placeholder,
    value: displayText,
    onChange: onInputValueChange,
    type: "search",
  };

  const defaultSuggestionRender = (
    suggestion: KeyValuePair<string, string>
  ) => (
    <div className="z-50 origin-top-right cursor-pointer text-gray-500 absolute px-4 py-2 m-2 mt-2 w-7/8  md:w-2/3 hover:bg-gray-50 bg-white rounded-md shadow-lg focus:outline-none">
      {suggestion.value}
    </div>
  );

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={(suggestion) =>
        suggestionPropertyToDisplay === "VALUE"
          ? suggestion.value
          : suggestion.key
      }
      renderSuggestion={
        customSuggestionRender
          ? customSuggestionRender
          : defaultSuggestionRender
      }
      inputProps={inputProps}
    />
  );
};

export default AutoComplete;
