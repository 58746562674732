import { faLock, faPencil } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { dateHelper } from "../../helpers";
import { FormSummaryFormDataDto } from "../../types/dtos/form-summaries/FormSummaryFormDataDto";
import { FormSummaryGoalDataDto } from "../../types/dtos/form-summaries/FormSummaryGoalDataDto";
import FormattedDate from "../common/FormattedDate";
import FormSummaryLoadingCard from "./FormSummaryLoadingCard";

interface GoalFormSummaryContentProps {
  goalData: FormSummaryGoalDataDto;
}

const GoalFormSummaryContent = ({ goalData }: GoalFormSummaryContentProps) => {
  const { t } = useTranslation();
  const currentDate = dateHelper.getCurrentDateUtc();
  return (
    <div>
      <div className="flex flex-col">
        <div className="flex flex-row uppercase primary-text justify-between px-4 mt-4 bg-gray-100 rounded-t-md">
          <span className="text-xs uppercase w-80 py-2">
            {t("Tasks.Common.TaskName")}
          </span>
          <span className="text-xs uppercase py-2">
            {t("Tasks.Common.Status")}
          </span>
          <span className="text-xs uppercase w-36 py-2 text-right">
            {t("Tasks.Common.TargetDate")}
            <span className="text-sm py-2 text-right w-4 px-2 invisible ml-2 text-gray-500">
              <FontAwesomeIcon icon={faPencil} size="1x"></FontAwesomeIcon>
            </span>
          </span>
        </div>
      </div>
      {goalData.goals.map((g) => {
        const targetDate = dateHelper.convertDateFromStringToUtc(g.targetDate);
        return (
          <div>
            <div className="flex flex-row text-gray-600 font-medium justify-between px-4 bg-gray-50 rounded-b-md">
              <span className="text-sm w-80 py-3">
                {t(g.titleTranslationKey)}
              </span>
              {g.dateCompleted ? (
                <span className=" text-sm h-3 w-3 rounded-full bg-emerald-400 self-center"></span>
              ) : currentDate > targetDate ? (
                <span className=" text-sm h-3 w-3 rounded-full bg-red-400 self-center"></span>
              ) : (
                <span className=" text-sm h-3 w-3 rounded-full bg-amber-400 self-center"></span>
              )}

              <span className="text-sm py-2 w-36 text-right">
                <FormattedDate date={g.targetDate} displayMode="DATE-ONLY" />
                {goalData.windowOpen ? (
                  <span className="text-sm py-2 text-right w-4 px-2 ml-2 text-gray-400">
                    <FontAwesomeIcon
                      icon={faPencil}
                      size="1x"
                    ></FontAwesomeIcon>
                  </span>
                ) : (
                  <span className="text-sm py-2 text-right w-4 px-2 ml-2 text-gray-400">
                    <FontAwesomeIcon icon={faLock} size="1x"></FontAwesomeIcon>
                  </span>
                )}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default GoalFormSummaryContent;
