import { JourneyType } from "../../types/journeys/JourneyType";
import { Link, useNavigate } from "react-router-dom";
import AppRoutes from "../../routes/AppRoutes";
import SuccessIcon from "../common/SuccessIcon";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

interface JourneySubmittedProps {
  journeyType: JourneyType;
  answerSetUniqueId: string;
  forceRedirectToCollabDoc: boolean;
}

function JourneySubmitted({
  journeyType,
  answerSetUniqueId,
  forceRedirectToCollabDoc,
}: JourneySubmittedProps) {
  const collabDocLink = AppRoutes.collaborativeDocument.generateRoute(
    answerSetUniqueId,
    forceRedirectToCollabDoc
  );
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Force redirect to the collab doc on load, if necessary
  useEffect(() => {
    if (forceRedirectToCollabDoc) {
      navigate(collabDocLink);
    }
  }, []);

  return (
    <div className="text-center">
      <SuccessIcon />
      <h3 className="light-heading">{t("Journey.Submit.Heading")}</h3>
      <div className="text-white/70">
        <p>{t("Journey.Submit.Message")}</p>
        {journeyType === "COLLAB-DOC" && (
          <div>
            {t("Journey.Submit.LinkWithCollabDoc")}
            <div className="flex flex-row justify-center pt-4">
              <Link to={collabDocLink}>
                <button className="journey-btn-primary">
                  {t("Journey.Submit.ViewResponsesButton")}
                </button>
              </Link>
              <button className="journey-btn-primary">
                {t("Journey.Submit.RequestCatchUp")}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default JourneySubmitted;
