import { t } from "i18next";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import cx from "classnames";
import { isEqual } from "lodash";
import { CollabDocQuestionInput, CommentsList, NewCommentForm } from ".";
import multipleChoiceQuestionHelper from "../../helpers/multipleChoiceQuestionHelper";
import { CollabDocStatus, NewCollabDocComment } from "../../types/collab-docs";
import { CollabDocCommentDto } from "../../types/dtos/collab-docs";
import CollabDocFlaggedChangeDto from "../../types/dtos/collab-docs/CollabDocFlaggedChangeDto";
import {
  FormQuestion,
  MultipleChoiceOption,
  QuestionAnswer,
  QuestionAnswerValue,
  QuestionNewTasks,
  ValidationResult,
} from "../../types/forms";
import { Badge, Label, ModalPopup, SafeRenderHtml } from "../common";
import CommentIndicator from "./CommentIndicator";
import { newCommentFieldElementId } from "./NewCommentForm";
import CollabDocFlaggedChange from "./CollabDocFlaggedChange";
import UserContext from "../../state/UserContext";
import {
  BaseUserDetailsDto,
  UserBasicDetailsDto,
} from "../../types/dtos/generic";
import { questionTextHelper, userDetailsHelper } from "../../helpers";
import QuestionReadonlyBadge from "../forms/QuestionReadonlyBadge";
import BehaviourMoreInfo from "../forms/advanced/behaviours/BehaviourMoreInfo";
interface CollabDocQuestionProps {
  isReadOnly: boolean;
  formApprovalStatus: CollabDocStatus;
  comments: Array<CollabDocCommentDto>;
  participants: Array<UserBasicDetailsDto>;
  question: FormQuestion;
  currentAnswer: QuestionAnswer | null;
  newTasks: QuestionNewTasks | null;
  isActiveQuestion: boolean;
  showValidationErrors: boolean;
  subjectUser: BaseUserDetailsDto;
  showChangeDetails: boolean;
  formColor: string;
  /** The date/time the form data was loaded (must be UTC) */
  dateFormLoaded: Date;
  /** Recent form answer change to highlight to the user, if there is one */
  flaggedChange: CollabDocFlaggedChangeDto | null;
  toggleShowChangeDetails(showChangeDetails: boolean): void;
  /** Handle the value changing for this question (in the state in parent components) */
  onValueChange(questionId: string, newValue: QuestionAnswerValue): void;
  /** The active question determines the state of the comments sidebar */
  onQuestionFocus(questionId: string): void;
  /** A method to call to mark the comments as seen */
  onCommentsSeen(questionId: string): void;
  /** A method to call to insert a new comment */
  onCommentAdd(newComment: NewCollabDocComment): void;
  /** A method to call to delete a comment */
  onCommentDelete(commentId: string): void;
  /** When a use adds/edits/deletes a new task */
  onChangeQuestionNewTasks(questionTasks: QuestionNewTasks): void;
}
/** We need to use React.memo as questions are rendered as a list,
 * and with lists, when the parent state updates, every child component re-renders
 * But in complex forms, that can lead to a slow UI where lots of components re-render unncessarily.
 * This is equivalent to the old "shouldComponentUpdate" effectively.
 * https://staleclosures.dev/preventing-list-rerenders/
 */
const collabDocComponentPropsAreEqual = (
  prevProps: Readonly<CollabDocQuestionProps>,
  nextProps: Readonly<CollabDocQuestionProps>
): boolean => {
  if (prevProps.isActiveQuestion !== nextProps.isActiveQuestion) return false;
  if (prevProps.showValidationErrors !== nextProps.showValidationErrors)
    return false;
  if (!isEqual(prevProps.currentAnswer, nextProps.currentAnswer)) return false;
  if (!isEqual(prevProps.newTasks, nextProps.newTasks)) return false;
  if (!isEqual(prevProps.comments, nextProps.comments)) return false;
  return true;
};
/** This is the component which renders the correct question type based on the details passed in.
 * State is managed at the CollabDoc level.
 */
const CollabDocQuestion = memo(
  ({
    question,
    currentAnswer,
    comments,
    newTasks,
    participants,
    isActiveQuestion,
    isReadOnly,
    formApprovalStatus,
    showValidationErrors,
    flaggedChange,
    dateFormLoaded,
    subjectUser,
    showChangeDetails,
    formColor,
    toggleShowChangeDetails,
    onValueChange,
    onQuestionFocus,
    onCommentsSeen,
    onCommentAdd,
    onCommentDelete,
    onChangeQuestionNewTasks,
  }: CollabDocQuestionProps) => {
    // Context
    const userContext = useContext(UserContext);
    // Refs
    const commentFormInputRef = useRef<HTMLTextAreaElement>(null);
    // State
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [replyToCommentId, setReplyToCommentId] = useState<string | null>(
      null
    );
    const [validationResult, setValidationResult] =
      useState<ValidationResult | null>(null);
    const [showAddCommentSuggestion, setShowAddCommentSuggestion] =
      useState<boolean>(false);
    // Calculated values and private variables
    const unseenCommentCount = comments.filter((x) => !x.seen).length;
    let multipleChoiceQuestionOptions: MultipleChoiceOption[] = [];
    // Update the multiple choice options when the question changes
    useEffect(() => {
      multipleChoiceQuestionOptions =
        multipleChoiceQuestionHelper.initialiseMultipleChoiceOptions(
          question,
          currentAnswer ? currentAnswer.answer : null,
          true
        );
    }, [question]); // eslint-disable-line react-hooks/exhaustive-deps

    // Update the validation errors when the answer changes
    useEffect(() => {
      const answerToValidate =
        currentAnswer !== null ? currentAnswer.answer : null;
      const tasksToValidate = newTasks !== null ? newTasks.tasks : null;
      setValidationResult(
        question.validate(
          answerToValidate,
          tasksToValidate,
          userContext.id,
          subjectUser.userId
        )
      );
    }, [question, currentAnswer, newTasks]);
    // If the answer changes, or comments are added, calculate whether or not to display the
    // "Suggest you add a comment for context" line
    useEffect(() => {
      if (!currentAnswer) {
        setShowAddCommentSuggestion(false);
      } else {
        const hasBeenAnsweredInThisSession =
          currentAnswer &&
          currentAnswer.userId === userContext.id &&
          currentAnswer.timestamp > dateFormLoaded;
        const hasBeenCommentedInThisSession =
          commentsForQuestion.filter(
            (x) => x.authorId === userContext.id && x.timestamp > dateFormLoaded
          ).length > 0;
        const doShowSuggestion =
          (formApprovalStatus === "RETURNED-BY-OTHER" ||
            formApprovalStatus === "APPROVED-BY-OTHER") &&
          hasBeenAnsweredInThisSession &&
          !hasBeenCommentedInThisSession;
        setShowAddCommentSuggestion(doShowSuggestion);
      }
    }, [question, currentAnswer, comments]);
    // Events
    const onDisplayComments = (mode: "MODAL" | "SIDEBAR") => {
      switch (mode) {
        case "MODAL":
          setModalIsOpen(true);
          // Mark the comments as seen
          if (unseenCommentCount > 0) {
            onCommentsSeen(question.questionId);
          }
          break;
        case "SIDEBAR":
          onQuestionFocus(question.questionId);
          // If the user has clicked to show comments for a question
          // that has no comments, we can assume they want to add a comment
          if (comments.length === 0) {
            // Ideally we'd use the ref for this, but it was difficult (always null at this point in the code)
            // so we've resorted to getting the input by id
            const newCommentTextArea = document.getElementById(
              newCommentFieldElementId
            );
            if (newCommentTextArea) {
              newCommentTextArea.focus();
            }
          }
          // Comments are marked as seen when the sidebar loads
          break;
      }
    };
    /** Get the selected option id(s) and update the state on the parent */
    const onMultipleChoiceQuestionValueChange = (
      updatedMultiChoiceOptions: MultipleChoiceOption[]
    ) => {
      const returnValue =
        multipleChoiceQuestionHelper.getAnswersFromMultiChoiceOptions(
          updatedMultiChoiceOptions,
          question
        );
      onValueChange(question.questionId, returnValue);
    };
    /** Get the questionId and call `onValueChange` */
    const handleAnswerChange = useCallback(
      (newValue: QuestionAnswerValue) => {
        onValueChange(question.questionId, newValue);
      },
      [question.questionId]
    );
    // Rendered elements
    const inputId = `question_input_${question.questionId}`;
    multipleChoiceQuestionOptions =
      multipleChoiceQuestionHelper.initialiseMultipleChoiceOptions(
        question,
        currentAnswer ? currentAnswer.answer : null,
        true
      );

    // Check the actor restrictions, and lock the question if it can't be answered by the current user
    const questionIsLockedForCurrentUser = !question.userCanAnswer(
      userContext.id,
      subjectUser.userId
    );

    // Get the name to display in the read-only/locked question badge
    const otherUserDisplayName = userDetailsHelper.getDisplayName(
      subjectUser.userId,
      participants
    );

    const questionComponent = (
      <CollabDocQuestionInput
        question={question}
        isReadOnly={isReadOnly}
        inputId={inputId}
        currentAnswer={currentAnswer}
        multipleChoiceQuestionOptions={multipleChoiceQuestionOptions}
        showValidationErrors={showValidationErrors}
        subjectUser={subjectUser}
        validationResult={validationResult}
        newTasks={newTasks}
        onChangeQuestionNewTasks={onChangeQuestionNewTasks}
        onMultipleChoiceQuestionValueChange={
          onMultipleChoiceQuestionValueChange
        }
        onValueChange={handleAnswerChange}
        isLockedForCurrentUser={questionIsLockedForCurrentUser}
        formColor={formColor}
        participants={participants}
      />
    );
    // Get any comments that went with the flagged change
    const commentsForQuestion = comments.filter(
      (x) => x.questionId === question.questionId
    );
    let flaggedChangeComments: Array<CollabDocCommentDto> | null = null;
    if (flaggedChange !== null && commentsForQuestion.length > 0) {
      flaggedChangeComments = commentsForQuestion.filter(
        (x) =>
          x.timestamp >= flaggedChange.timestamp &&
          x.authorId === flaggedChange.authorId
      );
    }

    const containerClassName = isActiveQuestion
      ? "bg-gray-100/70 border-blue-400"
      : "border-transparent";
    const questionDisplayText = questionTextHelper.getQuestionText(
      question,
      subjectUser,
      userContext,
      "COLLAB-DOC"
    );
    // If it's a readonly question, just show the content
    if (question.questionType === "READONLY") {
      return (
        <div className="px-2 mt-2 mb-4">
          <SafeRenderHtml
            htmlText={questionDisplayText}
            containerClassName="readonly-question"
          />
        </div>
      );
    }
    return (
      <>
        <div
          className={cx(
            "px-4 py-4 border-l-2 hover:bg-gray-100/70 group",
            containerClassName
          )}
          onClick={() => onQuestionFocus(question.questionId)}
        >
          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-start">
              <Label htmlFor={inputId} text={questionDisplayText} />
              {question.behaviourOptions?.behaviour.key && (
                <BehaviourMoreInfo
                  behaviour={question.behaviourOptions!.behaviour}
                  infoTooltipContent={
                    question.behaviourOptions!.infoTooltipContent
                  }
                  classNames="text-gray-400"
                />
              )}

              {!question.validation.required && (
                <Badge
                  text={t("Common.Optional")}
                  backgroundColourClassName="bg-gray-200/75"
                  textColourClassName="text-gray-400"
                />
              )}

              {questionIsLockedForCurrentUser && (
                <QuestionReadonlyBadge otherUserName={otherUserDisplayName} />
              )}
            </div>
            <div className="flex-end -mt-2">
              {/* Mobile indicator: */}
              <CommentIndicator
                allowAddComment={!isReadOnly}
                handleClick={() => onDisplayComments("MODAL")}
                totalComments={comments.length}
                unseenComments={unseenCommentCount}
                className="md:hidden"
              />
              {/* Desktop indicator: */}
              <CommentIndicator
                allowAddComment={!isReadOnly}
                handleClick={() => onDisplayComments("SIDEBAR")}
                totalComments={comments.length}
                unseenComments={unseenCommentCount}
                className="hidden md:inline-block"
              />
            </div>
          </div>
          {flaggedChange && (
            <CollabDocFlaggedChange
              change={flaggedChange}
              participants={participants}
              relatedComments={flaggedChangeComments}
              toggleShowChangeDetails={toggleShowChangeDetails}
              showChangeDetails={showChangeDetails}
            />
          )}
          <div>{questionComponent}</div>
          {showAddCommentSuggestion && isActiveQuestion && (
            <div className="py-1 px-2 text-sm text-gray-400">
              {t(
                "Pages.CollaborativeDocument.Controls.AddCommentSuggestionPrefix"
              )}{" "}
              <button
                className="hidden md:inline-block underline"
                onClick={() => onDisplayComments("SIDEBAR")}
              >
                {t(
                  "Pages.CollaborativeDocument.Controls.AddCommentSuggestionLink"
                )}
              </button>
              <button
                className="md:hidden underline"
                onClick={() => onDisplayComments("MODAL")}
              >
                {t(
                  "Pages.CollaborativeDocument.Controls.AddCommentSuggestionLink"
                )}
              </button>{" "}
              {t(
                "Pages.CollaborativeDocument.Controls.AddCommentSuggestionSuffix"
              )}
            </div>
          )}
        </div>
        {/* Modal (mobile): */}
        <ModalPopup
          isOpen={modalIsOpen}
          onOpenChange={setModalIsOpen}
          onPrimaryButtonClick={() => setModalIsOpen(false)}
          primaryButtonText={t("Common.Close")}
          title={t("Pages.CollaborativeDocument.Common.Comments")}
        >
          <CommentsList
            isReadOnly={isReadOnly}
            comments={comments}
            participants={participants}
            onDeleteComment={onCommentDelete}
            onTargetCommentForReply={setReplyToCommentId}
          />
          {!isReadOnly && (
            <NewCommentForm
              questionId={question.questionId}
              replyToCommentId={replyToCommentId}
              onSubmit={onCommentAdd}
              inputRef={commentFormInputRef}
            />
          )}
        </ModalPopup>
      </>
    );
  },
  collabDocComponentPropsAreEqual
);
export default CollabDocQuestion;
