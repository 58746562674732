import AppRoutes from "./AppRoutes";

export const NonPrimaryNavItems = [
  { text: "Nav.Profile.Profile", href: AppRoutes.missingFromDemo },
  { text: "Nav.Profile.ChangePassword", href: AppRoutes.missingFromDemo },
  { text: "Nav.Profile.YourGoals", href: AppRoutes.missingFromDemo },
  {
    text: "Nav.Profile.NotificationPreferences",
    href: AppRoutes.missingFromDemo,
  },
  { text: "Nav.Profile.Logout", href: AppRoutes.missingFromDemo },
];

export default NonPrimaryNavItems;
