import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { taskTypeHelper } from "../../helpers";
import UserContext from "../../state/UserContext";
import { ValidationResult } from "../../types/forms";
import { TaskType } from "../../types/tasks";
import {
  EditableAction,
  EditableGoal,
  EditableLearningAction,
  EditableTask,
} from "../../types/tasks/EditableTasks";
import { GenericDropDownList, Label, ModalPopup } from "../common";
import EditActionForm from "./EditActionForm";
import EditGoalForm from "./EditGoalForm";
import EditLearningActionForm from "./EditLearningActionForm";

const taskTypeInputId = "task-type-select";

/** Get the correct translation key identifier for the task type and popup mode (add/edit) */
const getModalTitle = (
  taskType: TaskType | undefined,
  popupMode: TaskPopupMode,
  isReadOnly: boolean
) => {
  let suffix = popupMode === "ADD" ? "Add" : "Edit";
  if (isReadOnly) {
    suffix = "View";
  }
  let titleTranslationKey = "Tasks.Popup.Titles.";

  if (taskType) {
    switch (taskType) {
      case "ACTION":
        titleTranslationKey += "Action";
        break;
      case "GOAL":
        titleTranslationKey += "Goal";
        break;
      case "LEARNING":
        titleTranslationKey += "Learning";
        break;
      default:
        titleTranslationKey += "Task";
        break;
    }
  } else {
    titleTranslationKey += "Task";
  }

  return t(titleTranslationKey + suffix);
};

export type TaskPopupMode = "ADD" | "EDIT";

interface ManageTaskPopupProps {
  /** Whether or not the modal popup is open */
  isOpen: boolean;
  /** Handle modal closing in the parent state */
  onOpenChange(open: boolean): void;
  /** If not specified, the user will be able to choose which task type to add */
  taskType?: TaskType | undefined;
  /** Whether to show in "add" mode or "edit" mode */
  mode: TaskPopupMode;
  /** What to do when the user saves the modal popup */
  onSaveClick(task: EditableTask): void;
  /** The task to edit */
  editObject?: EditableTask | null;
  isReadOnly: boolean;
}

function ManageTaskPopup({
  isOpen,
  onOpenChange,
  taskType,
  mode,
  editObject,
  onSaveClick,
  isReadOnly,
}: ManageTaskPopupProps) {
  // Context
  const userContext = useContext(UserContext);

  // State
  const [modalTitle, setModalTitle] = useState<string>(
    getModalTitle(taskType, mode, isReadOnly)
  );
  const [selectedTaskType, setSelectedTaskType] = useState<
    TaskType | undefined
  >(taskType);
  const [taskObject, setTaskObject] = useState<EditableTask | null>(null);
  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false);

  // Lifecycle

  // Initial mount
  const loadEditDetails = () => {
    setTaskObject(editObject ? editObject : null);
    setSelectedTaskType(editObject ? editObject.taskType : undefined);
  };

  useEffect(() => {
    // Set the task details to edit, if a task was supplied
    loadEditDetails();
  }, []);

  useEffect(() => {
    // When the task to edit changes
    loadEditDetails();
  }, [editObject]);

  // Reset the modal when it closes
  useEffect(() => {
    // If no task type was specified, clear the selection
    if (!isOpen && taskType === undefined) {
      setSelectedTaskType(undefined);
    } else if (isOpen && taskType) {
      setSelectedTaskType(taskType);
    }
    setShowValidationErrors(false);
  }, [isOpen]);

  // When the selectedTaskType changes
  useEffect(() => {
    setModalTitle(getModalTitle(selectedTaskType, mode, isReadOnly));
    setShowValidationErrors(false);
  }, [selectedTaskType, mode, isReadOnly]);

  // Events

  const handleTaskTypeChange = (selectedValue: string | null) => {
    const newTaskType =
      selectedValue !== null ? (selectedValue as TaskType) : undefined;
    setSelectedTaskType(newTaskType);
  };

  const handlePrimaryButtonClick = () => {
    const taskIsValid = taskObject && taskObject.isValid();

    if (selectedTaskType === undefined || !taskIsValid) {
      setShowValidationErrors(true);
      return;
    }

    // Validate the goal category field, if one was displayed
    if (
      selectedTaskType === "GOAL" &&
      !(taskObject as EditableGoal).goalCategoryIsValid(
        userContext.appLvl.goalCategories
      )
    ) {
      setShowValidationErrors(true);
      return;
    }

    // Reset to not display any validation issues
    setShowValidationErrors(false);

    // Add the new task to the list
    onSaveClick(taskObject);

    // Reset the form
    setTaskObject(null);

    // Close the modal
    onOpenChange(false);
  };

  // Display values

  let formComponent = null;
  if (selectedTaskType) {
    switch (selectedTaskType) {
      case "ACTION":
        formComponent = (
          <EditActionForm
            editObject={taskObject as EditableAction}
            onChange={setTaskObject}
            showValidationErrors={showValidationErrors}
            isReadOnly={isReadOnly}
          />
        );
        break;
      case "GOAL":
        formComponent = (
          <EditGoalForm
            editObject={taskObject as EditableGoal}
            onChange={setTaskObject}
            showValidationErrors={showValidationErrors}
            isReadOnly={isReadOnly}
          />
        );
        break;
      case "LEARNING":
        formComponent = (
          <EditLearningActionForm
            editObject={taskObject as EditableLearningAction}
            onChange={setTaskObject}
            showValidationErrors={showValidationErrors}
            isReadOnly={isReadOnly}
          />
        );
        break;
    }
  }

  // The options to display in the task type dropdown
  const taskTypeDropDownItems = taskTypeHelper.getTaskTypesAsKeyValuePairs();

  const taskTypeValidationResult = selectedTaskType
    ? null
    : new ValidationResult(false, [{ errorType: "REQUIRED" }]);

  return (
    <ModalPopup
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      onPrimaryButtonClick={handlePrimaryButtonClick}
      primaryButtonText={isReadOnly ? t("Common.Close") : t("Common.Save")}
      title={modalTitle}
      onSecondaryButtonClick={
        isReadOnly ? undefined : () => onOpenChange(false)
      }
      secondaryButtonText={isReadOnly ? undefined : t("Common.Cancel")}
    >
      <>
        {!taskType && (
          <>
            <div className="mb-2">
              <Label
                htmlFor={taskTypeInputId}
                text={t("Tasks.Common.TaskType")}
              />
              <GenericDropDownList
                currentValue={selectedTaskType ? selectedTaskType : null}
                items={taskTypeDropDownItems}
                onChange={handleTaskTypeChange}
                className="block w-full"
                bgColorClassName="bg-gray-100"
                inputId={taskTypeInputId}
                showValidationErrors={showValidationErrors}
                validationResult={taskTypeValidationResult}
                isReadOnly={isReadOnly}
              />
            </div>
          </>
        )}
        {formComponent}
      </>
    </ModalPopup>
  );
}

export default ManageTaskPopup;
