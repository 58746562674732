import { useTranslation } from "react-i18next";
import { Avatar, FormattedDate, InfoBanner } from "../common";
import { userDetailsHelper } from "../../helpers";
import { UserBasicDetailsDto } from "../../types/dtos/generic";

interface CollabDocHeaderProps {
  participants: UserBasicDetailsDto[];
  lastUpdated: Date;
  lastUpdatedByUserId: number;
  isReadOnly: boolean;
  formBackgroundColour: string;
}

function CollabDocHeader({
  participants,
  lastUpdated,
  lastUpdatedByUserId,
  isReadOnly,
  formBackgroundColour,
}: CollabDocHeaderProps) {
  const { t } = useTranslation();

  // Show the name of the user who last updated the form
  // (Take the firstname only if the names are unique, otherwise show the full name)
  let userLastUpdated = userDetailsHelper.getDisplayName(
    lastUpdatedByUserId,
    participants
  );

  return (
    <div
      className="p-4 lg:px-8 pb-28 lg:pt-1"
      style={{ backgroundColor: `#${formBackgroundColour}` }}
    >
      <div className="flex flex-col-reverse md:flex-row">
        <div className=" hidden lg:block md:basis-1/2 text-sm text-white/70 leading-8 text-center md:text-left">
          {t("Common.UpdatedBySentence.LastUpdated")}{" "}
          <FormattedDate date={lastUpdated} displayMode="FROM-NOW" />{" "}
          {t("Common.UpdatedBySentence.UpdatedBy")} {userLastUpdated}
        </div>
        <div className="md:basis-1/2 text-center md:text-right mb-2 hidden lg:block">
          <span className="text-sm text-white/70 mr-1">
            {t("Pages.CollaborativeDocument.Heading.Participants")}:
          </span>
          <div className="-space-x-1 inline">
            {participants.map((participant, pix) => (
              <Avatar
                key={`p${pix}`}
                userFullName={participant.fullName}
                userInitials={participant.initials}
                size={8}
                imageUrl={
                  participant.profileImageUrl
                    ? participant.profileImageUrl
                    : undefined
                }
              />
            ))}{" "}
          </div>
        </div>
        {isReadOnly && (
          <InfoBanner
            text={t("Pages.CollaborativeDocument.Heading.ReadOnly")}
          />
        )}
      </div>
    </div>
  );
}

export default CollabDocHeader;
