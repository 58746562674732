import { t } from "i18next";
import produce from "immer";
import { useContext, useState } from "react";
import { dateHelper, questionTextHelper } from "../../../../helpers";
import UserContext from "../../../../state/UserContext";
import {
  BaseUserDetailsDto,
  UserBasicDetailsDto,
} from "../../../../types/dtos/generic";
import {
  FormQuestion,
  GoalReviewQuestionAnswerValue,
  ValidationResult,
} from "../../../../types/forms";
import { QuestionBasicAnswer } from "../../../../types/forms/QuestionAnswer";
import { BasicQuestionAnswerValue } from "../../../../types/forms/QuestionAnswerValue";
import { KeyValuePair } from "../../../../types/generic";
import { EditableGoal } from "../../../../types/tasks/EditableTasks";
import { GoalTask } from "../../../../types/tasks/Task";
import { Label } from "../../../common";
import ValidationWarning from "../../../common/ValidationWarning";
import { ManageTaskPopup } from "../../../tasks";
import BasicSubQuestion from "../BasicSubQuestion";
import GoalReviewStatusDropDown from "./GoalReviewStatusDropDown";

interface ReviewableGoalWithQuestionsProps {
  question: FormQuestion;
  goal: GoalTask;
  currentValue: GoalReviewQuestionAnswerValue | null;
  goalStatusOptions: KeyValuePair<number, string>[];
  isReadOnly: boolean;
  formColor: string;
  participants: Array<UserBasicDetailsDto>;
  /** Who the form is regarding. Used to determine attribute text, e.g. "How is Chris doing?" before a slider */
  subjectUser: BaseUserDetailsDto;
  /** Whether or not to display the validation warnings */
  showValidationErrors: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
  onGoalStatusChange(toDoId: number, selectedOptionId: number | null): void;
  /** Notify the parent component(s) about a changed answer for a sub question within this goal */
  onValueChange(newState: GoalReviewQuestionAnswerValue): void;
}

/** The goal review form, repeating out the goals, and showing a status question alongside other questions (e.g. textbox) for each goal */
function ReviewableGoalWithQuestions({
  question,
  goal,
  isReadOnly,
  goalStatusOptions,
  currentValue,
  subjectUser,
  showValidationErrors,
  validationResult,
  formColor,
  participants,
  onGoalStatusChange,
  onValueChange,
}: ReviewableGoalWithQuestionsProps) {
  // Context
  const userContext = useContext(UserContext);

  // State
  const [showGoalModal, setShowGoalModal] = useState<boolean>(false);
  const [goalToShowInModal, setGoalToShowInModal] =
    useState<EditableGoal | null>();

  // Events
  const onShowGoalDetails = () => {
    // Convert to an EditableGoal as that's what the modal needs as a prop
    const goalToDisplay = new EditableGoal(
      "ORIGINAL",
      goal.toDoId,
      goal.title,
      goal.targetDate,
      goal.field1,
      goal.field2,
      goal.categoryId
    );
    setGoalToShowInModal(goalToDisplay);
    setShowGoalModal(true);
  };

  const onHandleSubQuestionValueChange = (
    questionId: string,
    newValue: BasicQuestionAnswerValue
  ) => {
    // In case the current value for this goal review question is null,
    // use an empty answer as the base for the new state
    const previousGoalAnswerState: GoalReviewQuestionAnswerValue = currentValue
      ? currentValue
      : { toDoId: goal.toDoId!, goalStatusOptionId: null, otherAnswers: [] };

    const newState = produce(previousGoalAnswerState, (draft) => {
      // Set the other answers to be an empty array, if it is null
      if (!draft.otherAnswers) {
        draft.otherAnswers = [];
      }

      // Look for a match and update it, or insert a new answer
      const match = draft.otherAnswers.find((x) => x.questionId === questionId);
      const answerTimestamp = dateHelper.getCurrentDateUtc();
      if (match !== undefined) {
        match.id = null; // TODO: Is this the right thing to do? Or should we remove the answer, and add a new one instead?
        match.answer = newValue;
        match.timestamp = answerTimestamp;
        match.userId = userContext.id;
      } else {
        draft.otherAnswers.push({
          id: null,
          questionId: questionId,
          answer: newValue,
          timestamp: answerTimestamp,
          userId: userContext.id,
        });
      }
    });

    onValueChange(newState);
  };

  // Display values and helper functions

  const selectedGoalStatusOptionId =
    currentValue === null ? null : currentValue.goalStatusOptionId;

  const statusDropdownIdAttr = "goal-status-dropdown-" + goal.toDoId;

  const getSubQuestionAnswer = (
    questionId: string
  ): QuestionBasicAnswer | null => {
    if (
      currentValue === null ||
      currentValue.otherAnswers === null ||
      currentValue.otherAnswers.length === 0
    ) {
      return null;
    }

    const match = currentValue.otherAnswers.find(
      (x) => x.questionId === questionId
    );
    return match ? match : null;
  };

  // Any validation errors without a `relatesTo` value are for the goal status dropdown
  const goalStatusValidationErrors = validationResult?.errors.filter(
    (x) => x.relatesTo === undefined
  );

  return (
    <div>
      <div className="mb-2">
        <button
          onClick={onShowGoalDetails}
          className="text-gray-500 text-sm italic underline"
        >
          {t("Forms.Goals.Review.ViewGoalDetailsButtonText")}
        </button>
      </div>
      <div>
        <Label
          htmlFor={statusDropdownIdAttr}
          text={t(
            questionTextHelper.getActorText(
              question.questionText,
              subjectUser,
              userContext
            )
          )}
        />
        {showValidationErrors &&
          validationResult &&
          goalStatusValidationErrors && (
            <ValidationWarning
              isValid={validationResult.isValid}
              errors={goalStatusValidationErrors}
            />
          )}
        <GoalReviewStatusDropDown
          toDoId={goal.toDoId!}
          currentValue={selectedGoalStatusOptionId}
          isReadOnly={isReadOnly}
          dropDownItems={goalStatusOptions}
          onChange={onGoalStatusChange}
          inputId={statusDropdownIdAttr}
        />
      </div>
      {question.goalReviewOptions &&
        question.goalReviewOptions.additionalQuestions && (
          <>
            {question.goalReviewOptions.additionalQuestions.map(
              (subQuestion, subQIx) => {
                const subFormQuestion = new FormQuestion(subQuestion);
                const subFormQuestionAnswer = getSubQuestionAnswer(
                  subQuestion.questionId
                );

                // Create a validation error object out of the errors which relate to this subquestion in the parent question validation error list
                const subQuestionValidationErrors =
                  validationResult?.errors.filter(
                    (x) => x.relatesTo === subQuestion.questionId
                  );

                const subQuestionValidationResult: ValidationResult = {
                  errors: subQuestionValidationErrors
                    ? subQuestionValidationErrors
                    : [],
                  isValid:
                    !subQuestionValidationErrors ||
                    subQuestionValidationErrors.length === 0,
                };

                return (
                  <BasicSubQuestion
                    key={`goalrvw_subq_${goal.toDoId}_${subQIx}`}
                    question={subFormQuestion}
                    isReadOnly={isReadOnly}
                    onValueChange={onHandleSubQuestionValueChange}
                    subjectUser={subjectUser}
                    showValidationErrors={showValidationErrors}
                    validationResult={subQuestionValidationResult}
                    currentAnswer={subFormQuestionAnswer}
                    formColor={formColor}
                    participants={participants}
                  />
                );
              }
            )}
          </>
        )}
      <ManageTaskPopup
        mode={"EDIT"}
        isOpen={showGoalModal}
        onOpenChange={setShowGoalModal}
        editObject={goalToShowInModal}
        onSaveClick={() => undefined}
        isReadOnly={true}
        taskType={"GOAL"}
      />
    </div>
  );
}

export default ReviewableGoalWithQuestions;
