import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { collabDocQuestionSequenceHelper } from "../../helpers/questionSequenceHelpers";
import { CollabDocStatus, NewCollabDocComment } from "../../types/collab-docs";
import {
  CollabDocCommentDto,
  CollabDocFormDto,
} from "../../types/dtos/collab-docs";
import { FormQuestionDto } from "../../types/dtos/forms";
import {
  BaseUserDetailsDto,
  UserBasicDetailsDto,
} from "../../types/dtos/generic";
import {
  QuestionAnswer,
  QuestionAnswerValue,
  QuestionNewTasks,
} from "../../types/forms";
import FormQuestion from "../../types/forms/FormQuestion";
import CollabDocQuestion from "./CollabDocQuestion";

interface CollabDocFormProps {
  form: CollabDocFormDto;
  /** A hex background colour, without the hash */
  backgroundColour: string;
  approvalStatus: CollabDocStatus;
  subjectUser: BaseUserDetailsDto;
  isReadOnly: boolean;
  comments: Array<CollabDocCommentDto>;
  participants: Array<UserBasicDetailsDto>;
  answers: Array<QuestionAnswer>;
  newTasks: Array<QuestionNewTasks>;
  activeQuestionId: string | null;
  showValidationErrors: boolean;
  showChangeDetails: boolean;
  /** The date/time the form data was loaded (must be UTC) */
  dateLoaded: Date;
  /***/
  toggleShowChangeDetails(showChangeDetails: boolean): void;
  /** A method to call to update the answer state */
  onValueChange(questionId: string, newValue: QuestionAnswerValue): void;
  /** The active question determines the state of the comments sidebar */
  onChangeActiveQuestion(activeQuestionId: string): void;
  /** A method to call to mark the comments as seen */
  onCommentsSeen(questionId: string): void;
  /** A method to call to insert a new comment */
  onCommentAdd(newComment: NewCollabDocComment): void;
  /** A method to call to delete a comment */
  onCommentDelete(commentId: string): void;
  /** When a use adds/edits/deletes a new task */
  onChangeQuestionNewTasks(questionTasks: QuestionNewTasks): void;
}

const CollabDocForm = ({
  form,
  backgroundColour,
  approvalStatus,
  isReadOnly,
  comments,
  newTasks,
  participants,
  answers,
  activeQuestionId,
  showValidationErrors,
  dateLoaded,
  subjectUser,
  showChangeDetails,
  toggleShowChangeDetails,
  onValueChange,
  onChangeActiveQuestion,
  onCommentsSeen,
  onCommentAdd,
  onCommentDelete,
  onChangeQuestionNewTasks,
}: CollabDocFormProps) => {
  const { t } = useTranslation();
  const [questions, setQuestions] = useState<FormQuestionDto[]>([]);

  /** Get the questions to display, in the correct sequence,
   * including taking into account conditional questions and whether
   * their parents have answers already or not
   */
  const getQuestionsToDisplayForForm = (): Array<FormQuestionDto> => {
    let output: Array<FormQuestionDto> = [];

    const firstQuestion = new FormQuestion(form.questions[0]);
    output.push(firstQuestion);

    const firstQuestionAnswer = getAnswerForQuestion(firstQuestion.questionId);
    let nextQuestionId =
      collabDocQuestionSequenceHelper.getNextQuestionToDisplay(
        firstQuestion,
        firstQuestionAnswer,
        form.questions
      );

    let loopCounter = 0;
    const loopCounterSafeLimit = 1000;
    while (nextQuestionId != null && loopCounter < loopCounterSafeLimit) {
      const dtoMatch = form.questions.find(
        (x) => x.questionId === nextQuestionId // eslint-disable-line no-loop-func
      );
      if (!dtoMatch) {
        // Unable to match question. This shouldn't happen. Break the loop.
        nextQuestionId = null;
      } else {
        // Add the matching question to the output, and find the next question after that
        const nextQuestion = new FormQuestion(dtoMatch);
        output.push(nextQuestion);

        // Now attempt to calculate the next question
        const loopQuestionAnswer = getAnswerForQuestion(
          nextQuestion.questionId
        );
        nextQuestionId =
          collabDocQuestionSequenceHelper.getNextQuestionToDisplay(
            nextQuestion,
            loopQuestionAnswer,
            form.questions
          );
      }
    }

    return output;
  };

  const getAnswerForQuestion = (questionId: string): QuestionAnswerValue => {
    const match = answers.find((x) => x.questionId === questionId);
    return match ? match.answer : null;
  };

  useEffect(() => {
    const nextQuestionState = getQuestionsToDisplayForForm();
    setQuestions(nextQuestionState);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const nextQuestionState = getQuestionsToDisplayForForm();
    setQuestions(nextQuestionState);
  }, [answers]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="px-4 pt-4 lg:px-6 lg:pt-8">
      <div className="shadow-xl rounded-lg mb-6 bg-white">
        <h3 className=" px-4 py-3 lg:px-8 text-left bg-gray-50 border-b border-gray-200 rounded-t-lg font-semibold">
          {t(form.title)}
        </h3>
        <div className="pb-2">
          {questions.map((q, qix) => {
            // Get the comments for this question
            const questionComments = comments.filter(
              (x) => x.questionId === q.questionId
            );
            // Get the flagged changes for this question
            const questionFlaggedChanges = form.flaggedChanges
              ? form.flaggedChanges.filter((x) => x.questionId === q.questionId)
              : null;

            // In theory, there should only be one flagged change per question to display, so get the first (if there are any for this question)
            const flaggedChangeToDisplay =
              questionFlaggedChanges !== null &&
              questionFlaggedChanges.length > 0
                ? questionFlaggedChanges[0]
                : null;

            const formQuestion = new FormQuestion(q);

            const currentAnswer =
              answers.find((x) => x.questionId === formQuestion.questionId) ||
              null;

            const questionNewTasks = newTasks.find(
              (x) => x.questionId === formQuestion.questionId
            );
            return (
              <>
                <CollabDocQuestion
                  key={`question_${q.questionId}`}
                  question={formQuestion}
                  currentAnswer={currentAnswer}
                  onValueChange={onValueChange}
                  isReadOnly={isReadOnly}
                  isActiveQuestion={
                    activeQuestionId !== null &&
                    activeQuestionId === q.questionId
                  }
                  comments={questionComments}
                  flaggedChange={flaggedChangeToDisplay}
                  participants={participants}
                  formApprovalStatus={approvalStatus}
                  showValidationErrors={showValidationErrors}
                  dateFormLoaded={dateLoaded}
                  subjectUser={subjectUser}
                  newTasks={questionNewTasks ? questionNewTasks : null}
                  onQuestionFocus={onChangeActiveQuestion}
                  onCommentAdd={onCommentAdd}
                  onCommentDelete={onCommentDelete}
                  onCommentsSeen={onCommentsSeen}
                  formColor={backgroundColour}
                  showChangeDetails={showChangeDetails}
                  toggleShowChangeDetails={toggleShowChangeDetails}
                  onChangeQuestionNewTasks={onChangeQuestionNewTasks}
                />
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CollabDocForm;
