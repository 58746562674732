import dayjs from "dayjs";
import TimelineItemDetailDto from "../../types/dtos/timeline/TimelineItemDetailDto";

const now = dayjs();

export const CreateMockTimelineDataSet = (
  numPast: number,
  numFuture: number,
  oneForToday: boolean
): TimelineItemDetailDto[] => {
  const data: TimelineItemDetailDto[] = [];
  if (numPast > 0) {
    for (var iPast = -numPast - 1; iPast < 0; iPast++) {
      data.push({
        titleTranslationKey: "Past catch up with Steve",
        dueDate: now.add(iPast, "day").toDate(),
        type: "CATCH-UP",
      });
    }
  }

  if (oneForToday) {
    data.push({
      titleTranslationKey: "Complete management program",
      dueDate: now.toDate(),
      type: "TASK",
    });
  }

  if (numFuture > 0) {
    for (var iFuture = 1; iFuture < numFuture + 1; iFuture++) {
      data.push({
        titleTranslationKey: "Future catch up with Steve",
        dueDate: now.add(iFuture, "day").toDate(),
        type: "CATCH-UP",
      });
    }
  }

  return data;
};

export default CreateMockTimelineDataSet;
