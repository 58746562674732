import { useTranslation } from "react-i18next";
import { userDetailsHelper } from "../../helpers";
import { CollabDocCommentDto } from "../../types/dtos/collab-docs";
import CollabDocFlaggedChangeDto from "../../types/dtos/collab-docs/CollabDocFlaggedChangeDto";
import { UserBasicDetailsDto } from "../../types/dtos/generic";
import { FormattedDate } from "../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/pro-regular-svg-icons";
import React from "react";

interface CollabDocFlaggedChangeProps {
  change: CollabDocFlaggedChangeDto;
  participants: Array<UserBasicDetailsDto>;
  relatedComments: Array<CollabDocCommentDto> | null;
  showChangeDetails: boolean;
  toggleShowChangeDetails(showChangeDetails: boolean): void;
}

function CollabDocFlaggedChange({
  change,
  participants,
  relatedComments,
  showChangeDetails,
  toggleShowChangeDetails,
}: CollabDocFlaggedChangeProps) {
  const { t } = useTranslation();
  const authorName = userDetailsHelper.getDisplayName(
    change.authorId,
    participants
  );

  const hasComments = relatedComments !== null && relatedComments.length > 0;

  return (
    <div className="px-8 py-0 bg-blue-50 my-1 rounded-md">
      <div className="mb-2 text-xs text-blue-400 inline-block">
        {t("Common.UpdatedBySentence.LastUpdated")}{" "}
        <FormattedDate date={change.timestamp} displayMode="FROM-NOW" />{" "}
        {t("Common.UpdatedBySentence.UpdatedBy")} {authorName}
      </div>
      <button
        className="text-xs text-blue-500 font-semibold ml-2 inline-block"
        onClick={() => toggleShowChangeDetails(showChangeDetails)}
      >
        {showChangeDetails ? (
          <>
            {t("Pages.CollaborativeDocument.Controls.HideChangeDetails")}
            <FontAwesomeIcon icon={faMinus} size={"xs"} className="ml-1" />
          </>
        ) : (
          <>
            {t("Pages.CollaborativeDocument.Controls.ShowChangeDetails")}
            <FontAwesomeIcon icon={faPlus} size={"xs"} className="ml-1" />
          </>
        )}
      </button>

      {showChangeDetails && (
        <>
          <div className=" text-xs">
            <div>
              <span className="text-gray-400 inline">
                {t(
                  "Pages.CollaborativeDocument.Controls.FlaggedChangePreviousAnswer"
                )}
              </span>
              :
              <div className="italic text-gray-600 text-xs inline ml-1">
                {change.previousValue}
              </div>
            </div>
            <div>
              <div className="text-gray-400 mt-1 inline">
                {t("Pages.CollaborativeDocument.Common.Comments")}:
              </div>

              {hasComments && (
                <>
                  {relatedComments.map((comment, ix) => (
                    <div
                      className="italic text-gray-600 text-xs inline ml-1"
                      key={`cmt_${ix}`}
                    >
                      {comment.commentText}
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CollabDocFlaggedChange;
