import { useTranslation } from "react-i18next";
import { userDetailsHelper } from "../../helpers";
import AppRoutes from "../../routes/AppRoutes";
import ManagerDashboardPersonDto from "../../types/dtos/dashboards/ManagerDashboardPersonDto";
import ManagerDashboardUpdateDto from "../../types/dtos/dashboards/ManagerDashboardUpdateDto";

interface UpdatesWidgetProps {
  users: ManagerDashboardPersonDto[];
  updates: ManagerDashboardUpdateDto[];
  isLoading: boolean;
}

function UpdatesWidget({ users, updates, isLoading }: UpdatesWidgetProps) {
  const { t } = useTranslation();

  return (
    <>
      {isLoading && (
        <div className="p-3 mt-2 bg-[#F7F8FA] rounded-md text-sm">
          <p>{t("Common.Loading")}...</p>
        </div>
      )}
      {!isLoading && (
        <div className="mt-3">
          {updates.map((u) => {
            const displayName = userDetailsHelper.getDisplayName(
              u.userId,
              users
            );
            return (
              <div
                key={`user_${u.userId}` + `_form_${u.clientFormTitle}`}
                className="p-3 mt-2 bg-[#F7F8FA] rounded-md text-sm primary-colour"
              >
                <p>
                  {displayName} has updated their {t(u.clientFormTitle)} |{" "}
                  <a
                    href={AppRoutes.collaborativeDocument.generateRoute(
                      u.answerSetUniqueId,
                      false
                    )}
                    className="underline"
                  >
                    View
                  </a>
                </p>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default UpdatesWidget;
