import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { faPencilAlt, faTrashAlt, faHistory, faPalette, faPlus } from "@fortawesome/pro-solid-svg-icons";
import cx from "classnames";
import { Tooltip } from ".";

interface IconButtonProps {
  buttonType: "HISTORY" | "ADD" | "EDIT" | "DELETE" | "PALETTE" | "NEW-OVERRIDE";
  displayMode: "ICON-ONLY" | "WITH-TEXT";
  onClick(): void;
  buttonClassName?: string | undefined;
  iconClassName?: string | undefined;
  iconSize?: SizeProp | undefined;
  isReadOnly?: boolean;
}

function IconButton({
  buttonType,
  displayMode,
  onClick,
  buttonClassName = "",
  iconClassName = "text-gray-600",
  iconSize = "xs",
  isReadOnly = false
}: IconButtonProps) {
  let buttonText: string;
  let icon: IconProp;
  switch (buttonType) {
    case "HISTORY":
      icon = faHistory;
      buttonText = t("Common.History");
      iconSize = "sm";
      break;
    case "ADD":
        icon = faPlus;
        buttonText = t("Common.Add");
        break;
    case "EDIT":
      icon = faPencilAlt;
      buttonText = t("Common.Edit");
      break;
    case "DELETE":
      icon = faTrashAlt;
      buttonText = t("Common.Delete");
      break;
    case "PALETTE":
      icon = faPalette;
      buttonText = t("Common.SelectColour") + "...";
      buttonClassName = "p-1 pl-2"
      iconSize = "lg"
      break;
    case "NEW-OVERRIDE":
      icon = faPlus;
      buttonText = t("Common.CreateNew");
      buttonClassName = "btn-primary-alternative"
      iconSize = "sm"
      break;
  }

  const iconComponent = (
    <FontAwesomeIcon icon={icon} size={iconSize} className={iconClassName} />
  );

  let buttonComponent;
  if (displayMode === "ICON-ONLY") {
    buttonComponent = (
      <button className={buttonClassName} onClick={onClick} disabled={isReadOnly}>
        {iconComponent}
      </button>
    );
  } else {
    // displayMode === "WITH-TEXT"
    buttonComponent = (
      <button
        className={cx(
          buttonClassName,
          "rounded-md px-2 py-1 bg-gray-400 text-gray-800 text-sm"
        )}
        onClick={onClick}
        disabled={isReadOnly}
      >
        {iconComponent}
        <span className="ml-1 text-gray-800">{buttonText}</span>
      </button>
    );
  }

  // Return a tooltip'd button if icon only, otherwise display just the button (with text)
  return displayMode === "ICON-ONLY" ? (
    <Tooltip content={buttonText} triggerElement={buttonComponent} />
  ) : (
    buttonComponent
  );
}

export default IconButton;
