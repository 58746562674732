import { useTranslation } from "react-i18next";
import windowStatus from "../../types/generic/WindowStatus";

interface JourneyWindowStatusProps {
  value: windowStatus;
}

const JourneyWindowStatusDisplay = ({ value }: JourneyWindowStatusProps) => {
  const { t } = useTranslation();

  switch (value) {
    case "OPEN":
      return <span>{t("Journey.Window.Open")}</span>;
    case "SHUT":
      return <span>{t("Journey.Window.Shut")}</span>;    
    case "UNKNOWN":
    default:
      return <span>{t("Journey.Mode.Unknown")}</span>;
  }
};

export default JourneyWindowStatusDisplay;
