import { useContext } from "react";
import DatePicker from "react-datepicker";
import cx from "classnames";
import { isDate } from "lodash";
import { ValidationResult } from "../../types/forms";
import ValidationWarning from "./ValidationWarning";
import "react-datepicker/dist/react-datepicker.css";
import UserContext from "../../state/UserContext";
import { dateHelper } from "../../helpers";

interface DateInputProps {
  /** The onChange event, for handling state changes */
  onChange(newValue: Date | null): void;
  /** The current value for the input */
  value?: Date | null;
  /** The Id attribute for the input element, to match the Label */
  inputId?: string;
  /** The css class names to apply */
  className?: string;
  /** The Placeholder attribute value for the form element */
  placeholder?: string | undefined;
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
  /** Whether or not the field can be cleared */
  isClearable?: boolean;
  isReadOnly?: boolean;
}

/** A date input field, with common styling already applied
 */
const DateInput = ({
  onChange,
  showValidationErrors = false,
  validationResult = null,
  value = undefined,
  inputId = "",
  className = "block w-full border-0 bg-gray-100 rounded-md focus:outline-0 focus:ring-0",
  placeholder = undefined,
  isClearable = false,
  isReadOnly = false,
}: DateInputProps) => {
  const userContext = useContext(UserContext);

  const onValueChange = (newDate: Date | null) => {
    if (newDate && isDate(newDate)) {
      // Convert the date to UTC so we can take timezones out of the equation
      const utcDate = dateHelper.convertDateToUtc(newDate);
      onChange(utcDate);
    } else {
      onChange(null);
    }
  };

  return (
    <div className="datepicker-container rounded-md">
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      <DatePicker
        id={inputId}
        className={cx(className, isReadOnly ? "cursor-not-allowed" : "")}
        calendarClassName="shadow-lg border-0"
        selected={value}
        placeholderText={placeholder}
        onChange={(date: Date) => onValueChange(date)}
        dateFormat={userContext.i18n.dateFormat}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        calendarStartDay={1}
        isClearable={isClearable}
        disabled={isReadOnly}
      />
    </div>
  );
};

export default DateInput;
