import cx from "classnames";
import { t } from "i18next";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import ModalPopup from "../../../common/ModalPopup";
import parse from "html-react-parser";
import KeyValuePair from "../../../../types/generic/KeyValuePair";

interface BehaviourMoreInfoProps {
  /** The id and title (translation key identifier) of the behaviour */
  behaviour: KeyValuePair<number, string>;
  /** Optional "More info" content to display to the user. Context for the behaviour */
  infoTooltipContent?: string | null;
  /** Class name for the more info label*/
  classNames?: string | null;
}

function BehaviourMoreInfo({
  behaviour,
  infoTooltipContent,
  classNames = "",
}: BehaviourMoreInfoProps) {
  const [infoModalIsOpen, setInfoModalIsOpen] = useState<boolean>(false);

  return (
    <>
      {infoTooltipContent && (
        <div>
          <button
            className={cx(
              classNames,
              "px-2 py-0.5 text-sm leading-tight cursor-pointer select-none"
            )}
            onClick={() => setInfoModalIsOpen(true)}
          >
            {t("Forms.Behaviours.MoreBehaviourInfoButtonText")}
            <FontAwesomeIcon icon={faInfoCircle} size="1x" className="ml-1" />
          </button>
          <ModalPopup
            isOpen={infoModalIsOpen}
            onOpenChange={setInfoModalIsOpen}
            onPrimaryButtonClick={() => setInfoModalIsOpen(false)}
            primaryButtonText={t("Common.Close")}
            title={t(behaviour.value)}
          >
            <div className="text-gray-700 competency-info-popup">
              {parse(t(infoTooltipContent))}
            </div>
          </ModalPopup>
        </div>
      )}
    </>
  );
}

export default BehaviourMoreInfo;
