import { FormSummaryFormDataDto } from "../../types/dtos/form-summaries/FormSummaryFormDataDto";

interface CollaborativFormSummaryContentProps {
  formData: FormSummaryFormDataDto[];
}

const CollaborativFormSummaryContent = ({
  formData,
}: CollaborativFormSummaryContentProps) => {
  return (
    <div className="bg-gray-100 rounded-md mt-4">
      {formData.map((fd) => (
        <div className="flex flex-col">
          <div className="flex flex-col p-4">
            <span className="uppercase text-xs text-gray-500 pb-1">
              {fd.titleTranslationKey}
            </span>
            <div className="flex flex-row gap-2 mt-2">
              {fd.selectedOption &&
                Array.isArray(fd.selectedOption) &&
                fd.selectedOption.map((so) => (
                  <div className="border rounded-md py-1 px-6 bg-white">
                    {so}
                  </div>
                ))}
            </div>
            <span className="font-medium text-gray-700 pl-4">{fd.value}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CollaborativFormSummaryContent;
