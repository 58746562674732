type OverallStatisticsGridProps = {
  children: React.ReactNode;
};

function OverallStatisticsGrid({ children }: OverallStatisticsGridProps) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      {children}
    </div>
  );
}

export default OverallStatisticsGrid;
