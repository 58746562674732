import { useTranslation } from "react-i18next";
import smartTruncate from "smart-truncate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { TimelineItemType } from "../../types/dtos/timeline/TimelineItemDetailDto";
import { FormattedDate } from "../common";

interface TimelineItemProps {
  titleTranslationKey: string;
  dueDate: Date;
  type: TimelineItemType;
  isActive: boolean;
}

const TimelineItem = ({
  titleTranslationKey,
  dueDate,
  type,
  isActive,
}: TimelineItemProps) => {
  const { t } = useTranslation();
  const titleMaxLength: number = 20;
  const truncatedTitle = smartTruncate(t(titleTranslationKey), titleMaxLength);
  let typeTranslationKey: string = "";

  switch (type) {
    case "TASK":
      typeTranslationKey = "Timeline.Type.Task";
      break;
    case "JOURNEY":
      typeTranslationKey = "Timeline.Type.Journey";
      break;
    case "CATCH-UP":
      typeTranslationKey = "Timeline.Type.CatchUp";
      break;
    default:
      typeTranslationKey = "Timeline.Type.Task";
      break;
  }
  const translatedType = t(typeTranslationKey);

  return (
    <div className="flex bg-white rounded-md mb-8 px-3 py-5">
      <div className="flex flex-col grow">
        <div className="primary-text text-md flex flex-row">
          {isActive && <div className="w-1 h-20rounded drop-shadow-lg"></div>}
          <div className="flex flex-col pl-2">
            {truncatedTitle}
            <div className="text-slate-400 font-normal text-xs">
              {translatedType}
            </div>
            <p className="text-slate-400 font-normal text-xs">
              <FormattedDate date={dueDate} displayMode="FROM-NOW" />{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-start px-3">
        <div className="justify-self-end">
          {/* This is missing a link/button for the click event, right? This should be set up in either this component, or the parent */}
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      </div>
    </div>
  );
};

export default TimelineItem;
