import { useTranslation } from "react-i18next";
import { FormSummaryBehaviourDataDto } from "../../types/dtos/form-summaries/FormSummaryBehaviourDataDto";
import AreaChartComponent from "./AreaChartComponent";

interface BehaviourFormSummaryContentProps {
  behaviourData: FormSummaryBehaviourDataDto;
}

const BehaviourFormSummaryContent = ({
  behaviourData,
}: BehaviourFormSummaryContentProps) => {
  const { t } = useTranslation();
  const behaviourChartData =
    behaviourData &&
    behaviourData.behaviours &&
    behaviourData.behaviours.map((b) => {
      return {
        name: t(b.titleTranslationKey),
        Score: b.score,
        pv: b.score,
        amt: b.score,
      };
    });
  return (
    <div>
      <div className="flex flex-row justify-between p-4 divide-x">
        <div className="flex flex-col flex-1">
          <span className="uppercase font-medium text-xs text-gray-500 mb-2">
            {t("Summaries.Behaviours.AverageBehaviourScore")}
          </span>
          <div className="text-5xl text-gray-500 font-bold ">
            {behaviourData.averageBehaviourScore}
          </div>
        </div>
        <div className="flex flex-col flex-1 text-right">
          <div>
            <div className="uppercase font-medium text-xs text-gray-500 mb-2">
              {t("Summaries.Behaviours.HighestScoringBehaviour")}
            </div>
            <div className="text-2xl font-bold text-gray-500">
              {t(behaviourData.highestScoringBehaviour)}
            </div>
          </div>

          <div className="mt-4">
            <div className="uppercase font-medium text-xs text-gray-500 mb-2">
              {t("Summaries.Behaviours.LowestScoringBehaviour")}
            </div>
            <div className="text-2xl font-bold  text-gray-500">
              {t(behaviourData.lowestScoringBehaviour)}
            </div>
          </div>
        </div>
      </div>
      <div className="h-36">
        <AreaChartComponent data={behaviourChartData} />
      </div>
    </div>
  );
};

export default BehaviourFormSummaryContent;
