import { useTranslation } from "react-i18next";

interface JourneyKeyboardPagerProps {
  enableNextButton: boolean;
  showSubmitBtn: boolean;
  onNextPageClick(): void;
}

function JourneyKeyboardPager({
  enableNextButton,
  showSubmitBtn,
  onNextPageClick,
}: JourneyKeyboardPagerProps) {
  const { t } = useTranslation();

  return (
    <div className="mt-2 mb-5">
      {!showSubmitBtn && (
        <div>
          <button
            className="keyboard-pager"
            onClick={onNextPageClick}
            disabled={!enableNextButton}
          >
            <span className="journey-btn-primary text-sm">
              {t("Journey.Button.Ok")}
            </span>
            <span className="journey-btn-label text-xs text-white">
              {t("Journey.Button.OrPressEnter")}
            </span>
          </button>
        </div>
      )}
    </div>
  );
}

export default JourneyKeyboardPager;
