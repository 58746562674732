import { QuestionType } from "../types/forms";

const _advancedQuestionTypes: QuestionType[] = [
  "BEHAVIOUR",
  "GOAL-SETTING",
  "GOAL-REVIEW",
  "LEARNING-AND-DEVELOPMENT",
];

export const questionTypeHelper = {
  /** Returns true if the question type is "advanced", i.e. not simple, like a goal review question */
  isAdvancedQuestionType: function (questionType: QuestionType): boolean {
    return _advancedQuestionTypes.indexOf(questionType) >= 0;
  },
};

export default questionTypeHelper;
