import { useTranslation } from "react-i18next";
import ManagerDashboardPersonDto from "../../../types/dtos/dashboards/ManagerDashboardPersonDto";
import { Avatar } from "../../common";
import { faUser, faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PeopleHeaderLoadingCard from "./PeopleHeaderLoadingCard";

interface PeopleCardHeaderProps {
  user: ManagerDashboardPersonDto | null;
}

function PeopleHeader({ user }: PeopleCardHeaderProps) {
  const { t } = useTranslation();
  const appraisalLevelName = user ? t(user.appraisalLevel) : "";

  return (
    <>
      {!user && <PeopleHeaderLoadingCard />}
      {user && (
        <div className="flex gap-4">
          <div className="pt-1">
            <Avatar
              userInitials={user.initials}
              userFullName={user.fullName}
              activeStatus="DO-NOT-SHOW"
              imageUrl={user.profileImageUrl}
            />
          </div>
          <div className="flex-col">
            <div>
              <h3 className="coloured-heading !mb-0 !mt-0">{user.fullName}</h3>
            </div>
            <div>
              <div className="flex gap-5 text-sm ml-3">
                <p>
                  <FontAwesomeIcon
                    icon={faUser}
                    size="1x"
                    className="text-[#C4C4C4]"
                  />
                  <span className="pl-1">{appraisalLevelName}</span>
                </p>
                <p>
                  <FontAwesomeIcon
                    icon={faMapMarkerAlt}
                    size="1x"
                    className="text-[#C4C4C4]"
                  />
                  <span className="pl-1">{user.locationName}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PeopleHeader;
