import { t } from "i18next";
import produce from "immer";
import { useContext, useEffect, useState } from "react";
import {
  ActorQuestionText,
  BaseSelectableItem,
  QuestionNewTasks,
  ValidationResult,
} from "../../../types/forms";
import ScaleOption from "../../../types/forms/ScaleOption";
import { KeyValuePair, SliderScoreDisplayType } from "../../../types/generic";
import { Label, Slider } from "../../common";
import BehaviourQuestionAnswerValue from "../../../types/forms/BehaviourQuestionAnswerValue";
import { mathsHelper, questionTextHelper } from "../../../helpers";
import UserContext from "../../../state/UserContext";
import { BaseUserDetailsDto } from "../../../types/dtos/generic";
import AddTaskControl from "./AddTaskControl";

interface BehaviourQuestionProps {
  questionId: string;
  /** The id and title (translation key identifier) of the behaviour */
  behaviour: KeyValuePair<number, string>;
  /** The scale points for rating this behaviour */
  scalePoints: ScaleOption[];
  /** The label to display for the slider when there aren't any attributes */
  singleScaleLabel: ActorQuestionText | null;
  /** The attributes to rate. There should always be at least one */
  attributes: KeyValuePair<number, ActorQuestionText>[] | null;
  /** Whether or not to show the Add Task button */
  showAddTaskButton: boolean;
  /** Optional "More info" content to display to the user. Context for the behaviour */
  infoTooltipContent?: string | null;
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** The current value(s) for attributes or for the single behaviour slider */
  currentValues: BehaviourQuestionAnswerValue[] | undefined;
  /** Notify the parent component(s) about a changed answer within this behaviour */
  onValueChange(newState: BehaviourQuestionAnswerValue[]): void;
  /** Specify a CSS class name for the track to the left hand side of the drag handle */
  selectedTrackBgColourClassName: string;
  /**The hex of the form colour used in collaborative docs */
  formBackgroundColorStyle?: string | null;
  /** The class name for the add task button which might changed depending in if it's in the collaborative documents or the journey widget */
  addTaskButtonClassName?: string;
  /** The colour of the track to the right hand side of the drag handle */
  emptyTrackBgColourClassName: string;
  /** Who the form is regarding. Used to determine attribute text, e.g. "How is Chris doing?" before a slider */
  subjectUser: BaseUserDetailsDto;
  /** Whether to show the numeric scale selected on the slider or words */
  sliderScoreDisplayType: SliderScoreDisplayType;
  isReadOnly: boolean;
  newTasks: QuestionNewTasks | null;
  /** When a use adds/edits/deletes a new task */
  onChangeQuestionNewTasks(questionTasks: QuestionNewTasks): void;
}

interface BehaviourSlider {
  attributeId: number | null;
  scaleOptions: ScaleOption[];
  displayText: ActorQuestionText;
}

function BehaviourQuestion({
  questionId,
  behaviour,
  scalePoints,
  singleScaleLabel,
  attributes,
  showValidationErrors,
  currentValues,
  onValueChange,
  selectedTrackBgColourClassName,
  formBackgroundColorStyle,
  addTaskButtonClassName = "btn-primary",
  emptyTrackBgColourClassName,
  showAddTaskButton,
  subjectUser,
  sliderScoreDisplayType,
  isReadOnly,
  newTasks,
  onChangeQuestionNewTasks,
}: BehaviourQuestionProps) {
  // Context
  const userContext = useContext(UserContext);

  // State
  const [sliders, setSliders] = useState<BehaviourSlider[]>([]);
  const [averageAttributeScore, setAverageAttributeScore] = useState<
    number | null
  >(null);
  const hasAttributes = attributes && attributes.length > 0;

  /** Create a copy of the scalePoints array with the scalePoint matching the answer value being selected */
  const getScalePointsCopyWithSelectedValue = (
    selectedValue: number | null
  ) => {
    const output = produce(scalePoints, (draft) => {
      draft.forEach((scalePoint) => {
        scalePoint.isSelected = scalePoint.value === selectedValue;

        // Translate the scale point display text, for the slider
        if (scalePoint.text && scalePoint.text.length > 0) {
          scalePoint.text = t(scalePoint.text);
        }
      });
    });

    return output;
  };

  /** Use the answers, the scale points etc to create the state necessary to power the slider questions */
  const intialiseSliders = () => {
    const newState: BehaviourSlider[] = [];

    if (hasAttributes) {
      attributes.forEach((attr) => {
        const attributeAnswer = currentValues
          ? currentValues.find((x) => x.attributeId === attr.key)
          : null;
        const attributeSliderValue =
          attributeAnswer && attributeAnswer !== null
            ? attributeAnswer.selectedScaleValue
            : null;

        newState.push({
          attributeId: attr.key,
          scaleOptions:
            getScalePointsCopyWithSelectedValue(attributeSliderValue),
          displayText: attr.value,
        });
      });
    } else {
      const singleSliderValue =
        currentValues && currentValues.length > 0
          ? currentValues[0].selectedScaleValue
          : null;
      newState.push({
        attributeId: null,
        scaleOptions: getScalePointsCopyWithSelectedValue(singleSliderValue),
        displayText: singleScaleLabel!,
      });
    }

    setSliders(newState);
  };

  // Initial mount
  useEffect(() => {
    intialiseSliders();
  }, []);

  // On answer change
  useEffect(() => {
    intialiseSliders();
  }, [currentValues, attributes, scalePoints]);

  const calculateAttributesAverageScore = (
    values: BehaviourQuestionAnswerValue[]
  ): number | null => {
    if (values.filter((x) => x.selectedScaleValue === null).length > 0) {
      return null;
    }

    const selectedValues = values.map((x) => x.selectedScaleValue as number);
    const sum = selectedValues.reduce(
      (a, b) => parseFloat(a.toString()) + parseFloat(b.toString()),
      0
    );
    const average = sum / selectedValues.length || 0;
    return average;
  };

  /** Handle the behaviour slider value change, updating the answer state in the parent component */
  const handleSliderChange = (
    attributeId: number | null,
    scaleOptions: ScaleOption[]
  ) => {
    const nextSliderState = produce(sliders, (draft) => {
      const match = draft.find((x) => x.attributeId === attributeId);
      if (match !== undefined) {
        match.scaleOptions = scaleOptions;
      }
    });

    // Convert the slider values into the expected answer type array
    const newAnswerState = getAnswersFromLocalSliderState(nextSliderState);

    // Call the onValueChange prop to store the answers in the form answerState
    onValueChange(newAnswerState);

    // Update the average score, if it is displayed
    if (sliderScoreDisplayType === "NUMERIC") {
      const avg = calculateAttributesAverageScore(newAnswerState);
      setAverageAttributeScore(avg);
    }
  };

  /** Convert the slider values into the expected answer type array */
  const getAnswersFromLocalSliderState = (
    sliders: BehaviourSlider[]
  ): BehaviourQuestionAnswerValue[] => {
    return sliders.map((slider) => {
      const selectedScaleItem = slider.scaleOptions.find((x) => x.isSelected);
      return {
        behaviourId: behaviour.key,
        attributeId: slider.attributeId,
        selectedScaleValue: selectedScaleItem
          ? parseInt(selectedScaleItem.value.toString())
          : null,
      };
    });
  };

  /** Validate the slider. Must have a value (i.e. not null) */
  const validateSlider = (slider: BehaviourSlider): ValidationResult => {
    const selectedValue = slider.scaleOptions.find((x) => x.isSelected);
    const isValid = selectedValue !== undefined;
    return new ValidationResult(isValid, [{ errorType: "REQUIRED" }]);
  };

  return (
    <>
      <div className="pt-2">
        {sliders.map((attr, ix) => {
          const sliderValidationResult = validateSlider(attr);
          const attributeDisplayText = questionTextHelper.getActorText(
            attr.displayText,
            subjectUser,
            userContext
          );

          return (
            <div className="mb-2 px-4" key={`bhv${behaviour.key}_attr${ix}`}>
              <Label
                text={attributeDisplayText}
                className="text-md font-normal"
              />

              <div className="px-4">
                <Slider
                  key={`attr_` + ix}
                  scaleOptions={attr.scaleOptions}
                  onChange={(newState: BaseSelectableItem[]) =>
                    handleSliderChange(attr.attributeId, newState)
                  }
                  showValidationErrors={showValidationErrors}
                  validationResult={sliderValidationResult}
                  selectedTrackBgColourClassName={
                    selectedTrackBgColourClassName
                  }
                  formBackgroundColorStyle={formBackgroundColorStyle}
                  emptyTrackBgColourClassName={emptyTrackBgColourClassName}
                  topMarginClassName="mt-2"
                  selectedValueDisplayMode={sliderScoreDisplayType}
                  isReadOnly={isReadOnly}
                />
              </div>
            </div>
          );
        })}
        {sliderScoreDisplayType === "NUMERIC" &&
          sliders.length > 1 &&
          averageAttributeScore !== null && (
            <div className="text-center italic text-sm mt-2 select-none opacity-50">
              {`${t("Common.Average")}: ${mathsHelper.roundForDisplay(
                averageAttributeScore,
                2
              )}`}
            </div>
          )}
        {showAddTaskButton && (
          <AddTaskControl
            questionId={questionId}
            questionTasks={newTasks}
            onChange={onChangeQuestionNewTasks}
            isReadOnly={isReadOnly}
            classNames={addTaskButtonClassName}
          />
        )}
      </div>
    </>
  );
}

export default BehaviourQuestion;
