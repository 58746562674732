import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";

interface SuccessAlertProps {
  prefix: String | null;
  message: String | null;
}

function SuccessAlert({ prefix, message }: SuccessAlertProps) {
  return (
    <div className="bg-green-50 py-1 px-4 mb-2 text-green-700 text-sm rounded-sm border-l-2 border-l-green-800">
      <div>
        <div className="fa-layers fa-fw mr-0.5">
          <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
        </div>
        <span className="text-green-800 font-medium">{prefix}</span>
        {" "}
        {message}
      </div>
    </div>
  );
}

export default SuccessAlert;
