import React, { useEffect, useReducer, Reducer } from "react";
import { useTranslation } from "react-i18next";
import { MainContainer } from "../components/layout";
import AppContext from "../state/AppContext";
import UserContext from "../state/UserContext";
import { useNavigate } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import AdminHomeApiResponseDto from "../types/dtos/admin/AdminHomeApiResponseDto";
import ClientFormsGrid from "../components/admin/Home/ClientFormsGrid";
import { AdminJourneyFormDto } from "../types/dtos/admin/AdminJourneyFormDto";
import SuccessAlert from "../components/alerts/SuccessAlert";

interface AdminHomeState {
  waitingForApiResult: boolean;
  clientForms: AdminJourneyFormDto[];
}

function AdminHome() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const appContext = React.useContext(AppContext);
  const userContext = React.useContext(UserContext);
  const hasUserComeFromSendNowComplete = (window.location.href).includes("send-now-complete");

  console.log("window.location.href", window.location.href);

  const [state, setState] = useReducer<
    Reducer<AdminHomeState, Partial<AdminHomeState>>
  >((state, newState) => ({ ...state, ...newState }), {
    waitingForApiResult: true,
    clientForms: []
  });

  useEffect(() => {
    if (!userContext.isAdmin) {
      navigate(AppRoutes.myDashboard);
    }

    // Call the API to load the necessary state
    fetch("/api/admin/home")
      .then((res) => res.json())
      .then((json: AdminHomeApiResponseDto) => {
        setState({
          waitingForApiResult: false,
          clientForms: json.clientForms
        });
      })
      .catch((e) => {
        console.error(e.message);
        // TODO: Show some form of error message
      });

    appContext.setPageTitle(t("Pages.Admin.PageTitle.Home"));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainContainer>
      <div className="flex flex-col py-4">
        <div className="flex flex-row-reverse">
          <button className="btn-primary block" onClick={() => navigate(AppRoutes.adminPeople)}>
            <span>{t("Pages.Admin.Buttons.AdminPeople")}</span>
          </button>
        </div>
      </div>
      {hasUserComeFromSendNowComplete && (
        <div>
          <SuccessAlert
            prefix={t("Common.Success")}
            message={t("Common.Validation.Successful.UsersWillReceiveShortly")}
          />
        </div>
      )}

      <ClientFormsGrid clientForms={state.clientForms} isLoading={state.waitingForApiResult} />
    </MainContainer>
  );
}

export default AdminHome;
