import cx from "classnames";
import { MultipleChoiceOption, ValidationResult } from "../../types/forms";
import produce from "immer";
import ValidationWarning from "./ValidationWarning";

export interface RadioButtonGroupProps {
  /** Used for the `name` attribute for the radio buttons, and as a prefix for `id` attributes, so make this unique */
  uniqueFieldName: string;
  /** The question text for this radio button group */
  fieldLabel?: string;
  /** The options to show in the list */
  values: MultipleChoiceOption[];
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
  /** The onChange event, returning the new state of the options */
  onChange(updatedValues: MultipleChoiceOption[]): void;
  isReadOnly?: boolean;
}

/** A form field containing multiple radio buttons */
const RadioButtonGroup = ({
  uniqueFieldName,
  fieldLabel,
  values,
  showValidationErrors = false,
  validationResult = null,
  onChange,
  isReadOnly = false,
}: RadioButtonGroupProps) => {
  /** Ensure that only one selected value exists in the state */
  const handleValueChange = (optionId: number) => {
    const nextState = produce(values, (draft) => {
      draft.forEach((option) => {
        option.isSelected = option.optionId === optionId;
      });
    });
    onChange(nextState);
  };

  return (
    <>
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      <fieldset>
        {fieldLabel && (
          <legend className="text-sm font-medium leading-4 text-gray-800">
            {fieldLabel}
          </legend>
        )}
        <div className="mt-3 space-y-3">
          {values.map(({ value, text, isSelected, optionId }) => (
            <div key={value} className="flex items-center">
              <input
                id={`radio_${uniqueFieldName}_${optionId}`}
                name={uniqueFieldName}
                type="radio"
                data-option-id={optionId}
                checked={isSelected}
                onChange={() => handleValueChange(optionId)}
                value={value}
                disabled={isReadOnly}
                className={cx(
                  // Setting the background in dark properly requires a workaround (see css/tailwind.css)
                  "h-4 w-4 border border-transparent bg-gray-100 text-green-600",
                  "focus:outline-none focus:ring-0 focus:ring-offset-0 focus-visible:ring focus-visible:ring-green-500 focus-visible:ring-opacity-75 focus-visible:ring-offset-2",
                  isReadOnly ? "cursor-not-allowed" : ""
                )}
              />
              <label
                htmlFor={`radio_${uniqueFieldName}_${optionId}`}
                className="ml-2 block text-sm font-medium text-gray-700"
              >
                {text}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </>
  );
};

export default RadioButtonGroup;
