import React, { useEffect, useReducer, Reducer } from "react";
import { useTranslation } from "react-i18next";
import { MainContainer } from "../components/layout";
import AppContext from "../state/AppContext";
import UserContext from "../state/UserContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import SendNowApiResponseDto from "../types/dtos/admin/SendNowApiResponseDto";
import { ClientFormDto } from "../types/dtos/admin/ClientFormDto";
import SendNowForm from "../components/admin/SendNow/SendNowForm";
import { ClientFormDefaults } from "../types/dtos/admin/ClientFormDefaults";
import CheckboxTreeNodeDto from "../types/dtos/generic/CheckboxTreeNodeDto";

interface SendNowState {
  waitingForApiResult: boolean;
  details?: ClientFormDefaults;
  availableSections: ClientFormDto[];
  selectedSections: ClientFormDto[];
}

function SendNow() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clientFormId } = useParams();
  const appContext = React.useContext(AppContext);
  const userContext = React.useContext(UserContext);

  const [state, setState] = useReducer<
    Reducer<SendNowState, Partial<SendNowState>>
  >((state, newState) => ({ ...state, ...newState }), {
    waitingForApiResult: true,
    details: undefined,
    availableSections: [],
    selectedSections: []
  });

  useEffect(() => {
    if (!userContext.isAdmin) {
      navigate(AppRoutes.myDashboard);
    }

    // Call the API to load the necessary state
    fetch(`/api/admin/send-now/${clientFormId}`)
      .then((res) => res.json())
      .then((json: SendNowApiResponseDto) => {
        setState({
          waitingForApiResult: false,
          details: json.details,
          availableSections: json.availableSections,
          selectedSections: json.selectedSections
        });
      })
      .catch((e) => {
        console.error(e.message);
        // TODO: Show some form of error message
      });

    appContext.setPageTitle(t("Pages.Admin.PageTitle.SendNow"));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onUpdateAvailableSections = (newState: any) => {
    setState({ availableSections: newState });
  }

  const onUpdateSelectedSections = (newState: any) => {
    setState({ selectedSections: newState });
  }

  return (
    <MainContainer>
      <div className="pt-2">
        <Link to={AppRoutes.admin.path} className="btn-primary">
          {t("Common.Back")}
        </Link>
      </div>
      {state.details && (
        <div className="grid grid-cols-1 md:grid-cols-12 gap-0.5">
          <div className="mt-4 col-span-1 md:col-span-10 md:col-start-2">
            <SendNowForm
              details={state.details}
              availableSections={state.availableSections}
              selectedSections={state.selectedSections}
              onUpdateAvailableSections={onUpdateAvailableSections}
              onUpdateSelectedSections={onUpdateSelectedSections} />
          </div>
        </div>
      )}
    </MainContainer>
  );
}

export default SendNow;
