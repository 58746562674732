import { useEffect, useMemo, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// @ts-ignore
import Countable from "countable";
import cx from "classnames";
import { ValidationResult } from "../../types/forms";
import ValidationWarning from "./ValidationWarning";

interface HtmlEditorProps {
  toolbarMode: "SIMPLE" | "ADVANCED";
  currentValue: string | null;
  onValueChange(newValue: string): void;
  heightPx?: number;
  inputId?: string | undefined;
  className?: string;
  isReadOnly?: boolean;
  placeholder?: string | undefined;

  /** Providing the currentWordCount and Maxwordcount will display a word counter underneath the editor */
  currentWordCount?: number;
  maxWordCount?: number;
  onWordCountChange?(counter: number): void;

  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
}

function HtmlEditor({
  toolbarMode,
  currentValue,
  onValueChange,
  inputId = undefined,
  heightPx = 200,
  className = "",
  placeholder = undefined,
  isReadOnly = false,
  currentWordCount,
  maxWordCount,
  showValidationErrors = false,
  validationResult = null,
  onWordCountChange,
}: HtmlEditorProps) {
  const falseEmptyState = "<p><br></p>";
  const displayValue = currentValue ? currentValue : "";
  const counterInputRef = useRef<ReactQuill>(null);

  useEffect(() => {
    // @ts-ignore
    Countable.on(counterInputRef.current?.editingArea, (counter) => onWordCountChange && onWordCountChange(counter.words));
  });

  /** Specify the buttons to display in the toolbar */
  const getToolbarOptions = () => {
    let toolbar;
    if (toolbarMode === "SIMPLE") {
      toolbar = [
        ["bold", "italic", "underline"], // toggled buttons
        [{ header: [1, 2, 3, false] }], // headers
        ["clean"], // remove formatting button
      ];
    } else {
      toolbar = [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        //["blockquote", "code-block"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        //[{ script: "sub" }, { script: "super" }], // superscript/subscript
        //[{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        //[{ direction: "rtl" }], // text direction

        //[{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        //[{ color: [] }, { background: [] }], // dropdown with defaults from theme
        //[{ font: [] }],
        [{ align: [] }],

        ["clean"], // remove formatting button
      ];
    }

    return {
      toolbar: toolbar,
    };
  };

  // Save re-rendering the text editor every time the value prop changes by using Memo
  const modulesProp = useMemo(() => getToolbarOptions(), [toolbarMode]);

  // The height "snow" theme doesn't take into account the toolbar as well, so the container height
  // needs to be text area height + toolbar height
  const toolbarHeight = 50;

  const handleValueChange = (newValue: string): void => {
    if (newValue === falseEmptyState) {
      onValueChange("");
    } else {
      onValueChange(newValue);
    }
  }

  return (
    <>
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      <div style={{ height: `${heightPx + toolbarHeight}px` }}>
        <ReactQuill
          id={inputId}
          ref={counterInputRef}
          theme="snow"
          value={displayValue}
          onChange={handleValueChange}
          readOnly={isReadOnly}
          placeholder={placeholder}
          modules={modulesProp}
          className={className}
          style={{ height: `${heightPx}px` }}
        />
      </div>
      {currentWordCount !== undefined && maxWordCount !== undefined && (
          <div className="text-right pr-1 pt-1">
            <span className="p-1 px-3 border rounded-full border-[#6b7280] text-sm">
              <span className={cx(
                currentWordCount > maxWordCount ? "text-rose-500 font-bold" : "text-black font-normal"
              )}>
                {currentWordCount}
              </span> / {maxWordCount}
            </span>
          </div>
        )}
    </>
  );
}

export default HtmlEditor;
