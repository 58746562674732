import { t } from "i18next";
import { taskTypeHelper } from "../../helpers";
import { EditableTask } from "../../types/tasks/EditableTasks";
import { FormattedDate, IconButton } from "../common";
import TaskTypeIcon from "./TaskTypeIcon";

interface TasksListProps {
  /** The tasks to manage */
  tasks: Array<EditableTask>;
  /** Whether the items can be edited/deleted */
  isReadOnly: boolean;
  onDelete(taskIndex: number, editTask: EditableTask): void;
  onEdit(taskIndex: number, editTask: EditableTask): void;
}

function TasksList({ tasks, isReadOnly, onDelete, onEdit }: TasksListProps) {
  if (tasks.length === 0) return null;

  return (
    <div className="mt-2">
      <div className="hidden lg:flex flex-row text-xs uppercase px-2">
        <div className="flex-initial w-6"></div>
        <div className="flex-grow px-1">{t("Tasks.Common.TaskName")}</div>
        <div className="flex-none px-1 text-center w-28">
          {t("Tasks.Common.TargetDate")}
        </div>
        <div className="flex-initial px-1 w-20"></div>
      </div>
      {tasks.map((task, taskIndex) => {
        // Don't render deleted items
        if (task.modifyStatus === "DELETED") return null;

        return (
          <div
            className="my-1 py-1 w-full px-2 lg:flex lg:flex-row bg-white/30 text-white rounded-md"
            key={`task_${taskIndex}`}
          >
            {/* Large screens */}
            <div className="hidden lg:block lg:flex-initial lg:w-6 text-center">
              <TaskTypeIcon
                taskType={task.taskType}
                colourClassName="text-white"
                iconSize="sm"
              />
            </div>
            <div className="hidden lg:block lg:flex-grow lg:px-1 truncate">
              <button
                className="xl:hidden cursor-pointer truncate"
                onClick={() => onEdit(taskIndex, task)}
              >
                {task.title}
              </button>
              <button
                className="hidden xl:inline-block cursor-pointer truncate"
                onClick={() => onEdit(taskIndex, task)}
              >
                {task.title}
              </button>
            </div>
            <div className="hidden lg:block lg:flex-none lg:px-1 lg:text-center lg:w-28">
              <FormattedDate date={task.targetDate!} displayMode="DATE-ONLY" />
            </div>
            {/* Small screens */}
            <div className="lg:hidden">
              <div className="mb-1 font-semibold truncate">
                <button
                  onClick={() => onEdit(taskIndex, task)}
                  className="truncate"
                >
                  {task.title}
                </button>
              </div>
              <div className="mb-1">
                <span className="text-cs italic mr-1">
                  {t("Tasks.Common.TaskType")}:
                </span>
                <TaskTypeIcon
                  taskType={task.taskType}
                  colourClassName="text-white"
                  iconSize="sm"
                />
                <span className="ml-1">
                  {t(
                    taskTypeHelper.getTranslationKeyForTaskType(task.taskType)
                  )}
                </span>
              </div>
              <div>
                <span className="text-cs italic mr-1">
                  {t("Tasks.Common.TargetDate")}:
                </span>
                <FormattedDate
                  date={task.targetDate!}
                  displayMode="DATE-ONLY"
                />
              </div>
            </div>
            {!isReadOnly && (
              <>
                {/* Large screens */}
                <div className="hidden lg:block lg:flex-initial lg:w-20 lg:text-right lg:px-1">
                  <IconButton
                    buttonType="EDIT"
                    displayMode="ICON-ONLY"
                    buttonClassName="px-1 mr-2"
                    iconClassName="text-white"
                    onClick={() => onEdit(taskIndex, task)}
                  />
                  <IconButton
                    buttonType="DELETE"
                    displayMode="ICON-ONLY"
                    buttonClassName="px-1"
                    iconClassName="text-white hover:text-red-600"
                    onClick={() => onDelete(taskIndex, task)}
                  />
                </div>
                {/* Small screens */}
                <div className="lg:hidden mt-2 mb-1">
                  <div className="flex flex-row">
                    <div className="basis-1/2 pr-1">
                      <IconButton
                        buttonType="EDIT"
                        displayMode="WITH-TEXT"
                        buttonClassName="px-1 w-full"
                        iconClassName="text-gray-800"
                        onClick={() => onEdit(taskIndex, task)}
                      />
                    </div>
                    <div className="basis-1/2 pl-1">
                      <IconButton
                        buttonType="DELETE"
                        displayMode="WITH-TEXT"
                        buttonClassName="px-1 w-full"
                        iconClassName="text-gray-800"
                        onClick={() => onDelete(taskIndex, task)}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default TasksList;
