import { useTranslation } from "react-i18next";
import cx from "classnames"
import FrequencyDisplay from "../../common/FrequencyDisplay";
import JourneyModeDisplay from "../../common/JourneyModeDisplay";
import JourneyWindowStatusDisplay from "../../common/JourneyWindowStatusDisplay";
import { FormattedDate } from "../../common";
import ClientFormLoadingCard from "./ClientFormLoadingCard";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../../routes/AppRoutes";
import { AdminJourneyFormDto } from "../../../types/dtos/admin/AdminJourneyFormDto";

interface ClientFormCardProps {
  clientForm: AdminJourneyFormDto | null;
}

function ClientFormCard({
  clientForm
}: ClientFormCardProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let configRoute = clientForm?.clientFormId
    ? AppRoutes.adminConfiguration.generateRoute(clientForm?.clientFormId.toString())
    : "";
  let sendNowRoute = clientForm?.clientFormId
    ? AppRoutes.sendNow.generateRoute(clientForm?.clientFormId.toString())
    : "";

  return (
    <div className="basis-full md:basis-1/2">
      <div className="px-4 border-4 border-[#F7F8FA] rounded p-2 bg-white">
        {!clientForm && (
          <ClientFormLoadingCard />
        )}

        {clientForm && (
          <>
            <div className="flex flex-col pt-2">
              <div className="flex flex-row">
                <div className={cx("flex flex-col", clientForm.mode === "HIDDEN-ON-DASHBOARD" ? "w-full" : "w-3/4")}>
                  <h4 className="coloured-heading">{t(clientForm.clientFormTitle)}</h4>
                  <hr />
                  <div className="flex flex-col gap-1 pt-2">
                    <div>
                      <label className="font-semibold">{t("Pages.Admin.Common.Mode")}: </label>
                      <JourneyModeDisplay value={clientForm.mode} />
                    </div>
                    {clientForm.mode === "AUTOMATED" &&
                      clientForm.frequencyValue && clientForm.frequencyType && (
                        <div>
                          <label className="font-semibold">{t("Pages.Admin.Common.DefaultFrequency")}: </label>
                          <FrequencyDisplay value={clientForm.frequencyValue} type={clientForm.frequencyType} />
                        </div>
                      )}
                    {clientForm.mode === "WINDOWED" &&
                      clientForm.windowStatus && clientForm.nextWindowStartDate && clientForm.nextWindowEndDate && (
                        <>
                          <div>
                            <label className="font-semibold">{t("Pages.Admin.Common.WindowStatus")}: </label>
                            <span>{clientForm.windowStatus === "OPEN" ? "" : ""}</span>
                            <JourneyWindowStatusDisplay value={clientForm.windowStatus} />
                          </div>
                          <div>
                            <label className="font-semibold">{t("Pages.Admin.Common.NextWindow")}: </label>
                            <span>
                              <FormattedDate displayMode="DATE-ONLY" date={clientForm.nextWindowStartDate} /> - <FormattedDate displayMode="DATE-ONLY" date={clientForm.nextWindowEndDate} />
                            </span>
                          </div>
                        </>
                      )}
                    {clientForm.mode === "INDUCTION" && (
                      <div>
                        <label className="font-semibold">{t("Pages.Admin.Common.Between")}: </label>
                        <span>{clientForm.daysFrom}</span> {t("Common.And_LowerCase")} <span>{clientForm.daysTo}</span> {t("Common.Days")}
                      </div>
                    )}
                  </div>
                </div>
                {clientForm.mode !== "HIDDEN-ON-DASHBOARD" && (
                  <div className="w-1/4">
                    <div className="flex flex-col text-center">
                      <div className="border rounded-lg rounded-bl-none rounded-br-none">
                        <h3 className="coloured-heading !mb-0">{clientForm.completed}</h3>
                        <h4 className="coloured-heading !text-sm !mt-0">{t("Pages.Admin.Common.Updates_Lower")}</h4>
                      </div>
                      <div className="border rounded-lg border-t-0 rounded-tl-none rounded-tr-none">
                        <p className="text-xs">{t("Pages.Admin.Common.InTheLast30Days_Lower")}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="grid grid-cols-5 pt-4 pb-2">
                <button className="btn-primary-alternative block text-sm col-start-1 col-end-3" onClick={() => navigate(configRoute)}>
                  <span>{t("Pages.Admin.Buttons.EditConfiguration")}</span>
                </button>
                {clientForm.mode !== "WINDOWED" &&
                  clientForm.mode !== "HIDDEN-ON-DASHBOARD" &&
                  clientForm.mode !== "INDUCTION" &&
                  clientForm.mode !== "EXIT" && (
                    <button className="btn-primary block text-sm col-end-6 col-span-2" onClick={() => navigate(sendNowRoute)}>
                      <span>{t("Pages.Admin.Buttons.SendNow")}</span>
                    </button>
                  )}
              </div>
            </div>
          </>
        )}
      </div>
    </div >
  )
}

export default ClientFormCard;
