import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "../common";

interface QuestionReadonlyBadgeProps {
  otherUserName: string;
}

/** A "Locked" badge to display when the current user can't edit a question due to actor restrictions */
export const QuestionReadonlyBadge = ({
  otherUserName,
}: QuestionReadonlyBadgeProps) => {
  let tooltipContent = t(
    "Pages.CollaborativeDocument.Common.QuestionLockedBadgeTooltip"
  );
  tooltipContent = tooltipContent.replace("#OTHER_USER_NAME#", otherUserName);
  return (
    <Tooltip
      triggerElement={
        <span className="bg-gray-400 text-white px-2 py-1 text-xs rounded-full select-none ml-1 whitespace-nowrap">
          <FontAwesomeIcon icon={faLock} size="sm" className="mr-1" />
          <small className="text-xs">
            {t("Pages.CollaborativeDocument.Common.QuestionLockedBadgeText")}
          </small>
        </span>
      }
      content={tooltipContent}
    />
  );
};

export default QuestionReadonlyBadge;
