import { useTranslation } from "react-i18next";
import FormSummary from "./FormSummary";
import FormSummariesGrid from "./FormSummariesGrid";
import { DashboardSummaryTabDto } from "../../types/dtos/dashboards/DashboardSummaryTabDto";
import Tabs, { TabDetails } from "../common/Tabs";

interface MyFormSummariesProps {
  summaries: DashboardSummaryTabDto[];
  isLoading: boolean;
  /** A function to call when the user has selected a different journey to complete from within a summary */
  onChangeJourney(clientFormId: number): void;
}

const MyFormSummaries = ({
  summaries,
  isLoading,
  onChangeJourney,
}: MyFormSummariesProps) => {
  const { t } = useTranslation();
  const formSummaryTabs: Array<TabDetails> = [];

  summaries.map((s) => {
    const tabName = t(s.clientFormTitle);
    formSummaryTabs.push({
      title: tabName,
      content: (
        <FormSummary
          key={s.clientFormId}
          summaryTabData={s}
          onTriggerSectionUpdate={onChangeJourney}
        />
      ),
      displayUpdateIcon: s.tabHasUpdates,
    });
  });

  return (
    <>
      {isLoading && (
        <FormSummariesGrid>
          <FormSummary />
          <FormSummary />
          <FormSummary />
          <FormSummary />
        </FormSummariesGrid>
      )}
      {!isLoading && summaries && summaries.length > 0 && (
        <div className="my-8">
          <Tabs
            tabs={formSummaryTabs}
            selectedTabClassNames="radix-state-inactive: border-0 radix-state-active:border-b-2 hover:!border-b-gray-200 rounded-0"
            maxTabsOnSmallScreens={2}
          />
        </div>
      )}
    </>
  );
};

export default MyFormSummaries;
