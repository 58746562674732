import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const dateHelper = {
  /** Get the current UTC date (to take out timezones from times) */
  getCurrentDateUtc: function (): Date {
    return dayjs().utc().toDate();
  },
  /** Gets the current UTC date minus or plus the days from given */
  getDaysFromCurrentDateUtcs: function (daysFrom: number): Date {
    return dayjs.utc().add(daysFrom, "day").toDate();
  },
  /** Gets the current UTC date minus or plus the duration given */
  getFromCurrentDateUtcs: function (daysFrom: number, duration: string): Date {
    return dayjs.utc().add(daysFrom, duration).toDate();
  },
  /** Converts a date object to UTC (so we can take timezones out of the equation) */
  convertDateToUtc: function (localDate: Date): Date {
    return dayjs(localDate).utc().toDate();
  },
   /** Converts a string to UTC*/
   convertDateFromStringToUtc: function (inputDate: string | Date): Date {
    return dayjs(inputDate).utc().toDate();
  },
  /** Converts a UTC date to a local date */
  convertUtcDateToLocal: function (utcDate: Date): Date {
    const dateObj = dayjs.utc(utcDate);
    if (dateObj.isUTC()) {
      return dateObj.local().toDate();
    }
    return dateObj.toDate();
  },
};

export default dateHelper;
