import { multipleChoiceQuestionHelper } from "../../helpers";
import {
  BaseUserDetailsDto,
  UserBasicDetailsDto,
} from "../../types/dtos/generic";
import {
  CheckBoxListDisplayMode,
  FormQuestion,
  GoalReviewQuestionAnswerValue,
  MultipleChoiceOption,
  QuestionAnswer,
  QuestionAnswerValue,
  QuestionNewTasks,
  ValidationResult,
  BehaviourQuestionAnswerValue,
  DevelopmentQuestionAnswerValue,
} from "../../types/forms";
import {
  CheckBoxList,
  FormDropDownList,
  RadioButtonGroup,
  Slider,
  TextArea,
  TextInput,
} from "../common";
import {
  BehaviourQuestion,
  DevelopmentQuestion,
  GoalReviewQuestion,
  GoalSettingQuestion,
} from "../forms/advanced";

interface CollabDocQuestionInputProps {
  question: FormQuestion;
  isReadOnly: boolean;
  inputId: string;
  currentAnswer: QuestionAnswer | null;
  multipleChoiceQuestionOptions: MultipleChoiceOption[];
  showValidationErrors: boolean;
  subjectUser: BaseUserDetailsDto;
  formColor: string;
  participants: Array<UserBasicDetailsDto>;

  /** If this question is locked to only employee/manager, and the user's role doesn't match, disable the field */
  isLockedForCurrentUser: boolean;

  /** If validation has been run, this is the validity plus any errors */
  validationResult: ValidationResult | null;
  /** Any new tasks added against this question */
  newTasks: QuestionNewTasks | null;
  /** When a use adds/edits/deletes a new task */
  onChangeQuestionNewTasks(questionTasks: QuestionNewTasks): void;

  onMultipleChoiceQuestionValueChange(
    updatedMultiChoiceOptions: MultipleChoiceOption[]
  ): void;
  /** Handle the value changing for this question (in the state in parent components) */
  onValueChange(newValue: QuestionAnswerValue): void;
}

/** A wrapper for just the input component, i.e. not the label, the comment indicator etc */
function CollabDocQuestionInput({
  question,
  isReadOnly,
  inputId,
  currentAnswer,
  multipleChoiceQuestionOptions,
  subjectUser,
  newTasks,
  showValidationErrors,
  validationResult,
  isLockedForCurrentUser,
  formColor,
  participants,
  onChangeQuestionNewTasks,
  onMultipleChoiceQuestionValueChange,
  onValueChange,
}: CollabDocQuestionInputProps) {
  const disableQuestion = isReadOnly || isLockedForCurrentUser;

  switch (question.questionType) {
    case "SLIDER":
      const sliderScoreDisplayMode =
        multipleChoiceQuestionHelper.getSliderScoreDisplayMode(
          multipleChoiceQuestionOptions
        );
      return (
        <Slider
          scaleOptions={multipleChoiceQuestionOptions}
          onChange={onMultipleChoiceQuestionValueChange}
          showValidationErrors={showValidationErrors}
          validationResult={validationResult}
          selectedValueDisplayMode={sliderScoreDisplayMode}
          isReadOnly={disableQuestion}
          formBackgroundColorStyle={formColor}
        />
      );
    case "RADIOLIST":
      return (
        <RadioButtonGroup
          uniqueFieldName={`radio_q_${question.questionId}`}
          values={multipleChoiceQuestionOptions}
          onChange={onMultipleChoiceQuestionValueChange}
          showValidationErrors={showValidationErrors}
          validationResult={validationResult}
          isReadOnly={disableQuestion}
        />
      );
    case "CHECKLIST":
      const checkboxCount = question.multiChoiceOptions!.length;
      // Change the display mode based on how many checkboxes there are to show
      let displayMode: CheckBoxListDisplayMode = "horizontal";
      if (checkboxCount > 10) {
        displayMode = "grid";
      } else if (checkboxCount > 2) {
        displayMode = "vertical";
      }

      return (
        <CheckBoxList
          uniqueFieldName={`radio_q_${question.questionId}`}
          values={multipleChoiceQuestionOptions}
          onChange={onMultipleChoiceQuestionValueChange}
          displayMode={displayMode}
          showValidationErrors={showValidationErrors}
          validationResult={validationResult}
          isReadOnly={disableQuestion}
          selectMinCount={question.validation.min}
          selectMaxCount={question.validation.max}
        />
      );
    case "LONGTEXT":
      return (
        <TextArea
          inputId={inputId}
          value={currentAnswer ? (currentAnswer.answer as string) : undefined}
          onChange={onValueChange}
          minRows={1}
          maxRows={6}
          className="mt-2 p-2 w-full rounded-md lg:max-w-2xl border border-gray-400"
          showValidationErrors={showValidationErrors}
          validationResult={validationResult}
          isReadOnly={disableQuestion}
        />
      );
    case "SHORTTEXT":
      return (
        <TextInput
          inputId={inputId}
          value={currentAnswer ? (currentAnswer.answer as string) : undefined}
          onChange={onValueChange}
          className="mt-2 p-2 w-full rounded-md lg:max-w-2xl border border-gray-400"
          showValidationErrors={showValidationErrors}
          validationResult={validationResult}
          isReadOnly={disableQuestion}
        />
      );
    case "DROPDOWNLIST":
      return (
        <FormDropDownList
          inputId={inputId}
          values={multipleChoiceQuestionOptions}
          onChange={onMultipleChoiceQuestionValueChange}
          className="block my-3 w-full rounded-md border-gray-400"
          showValidationErrors={showValidationErrors}
          validationResult={validationResult}
          isReadOnly={disableQuestion}
        />
      );
    case "BEHAVIOUR":
      return (
        <BehaviourQuestion
          questionId={question.questionId}
          attributes={question.behaviourOptions!.attributes}
          behaviour={question.behaviourOptions!.behaviour}
          scalePoints={question.behaviourOptions!.scale}
          singleScaleLabel={question.behaviourOptions!.singleScaleLabel}
          infoTooltipContent={question.behaviourOptions!.infoTooltipContent}
          showAddTaskButton={question.behaviourOptions!.showAddTaskButton}
          showValidationErrors={showValidationErrors}
          onValueChange={onValueChange}
          currentValues={
            currentAnswer
              ? (currentAnswer.answer as BehaviourQuestionAnswerValue[])
              : undefined
          }
          selectedTrackBgColourClassName="bg-green-300"
          emptyTrackBgColourClassName="bg-gray-300"
          formBackgroundColorStyle={formColor}
          subjectUser={subjectUser}
          sliderScoreDisplayType={
            question.behaviourOptions!.sliderScoreDisplayType
          }
          isReadOnly={disableQuestion}
          newTasks={newTasks}
          onChangeQuestionNewTasks={onChangeQuestionNewTasks}
        />
      );
      break;
    case "LEARNING-AND-DEVELOPMENT":
      return (
        <DevelopmentQuestion
          formType="COLLAB-DOC"
          question={question}
          isReadOnly={disableQuestion}
          newTasks={newTasks}
          showValidationErrors={showValidationErrors}
          onChangeQuestionNewTasks={onChangeQuestionNewTasks}
          onValueChange={onValueChange}
          currentValues={
            currentAnswer
              ? (currentAnswer.answer as DevelopmentQuestionAnswerValue[])
              : undefined
          }
          validationResult={validationResult}
        />
      );
    case "GOAL-SETTING":
      return (
        <GoalSettingQuestion
          question={question}
          isReadOnly={disableQuestion}
          showValidationErrors={showValidationErrors}
          newTasks={newTasks}
          onChangeQuestionNewTasks={onChangeQuestionNewTasks}
          validationResult={validationResult}
        />
      );
    case "GOAL-REVIEW":
      return (
        <GoalReviewQuestion
          formType="COLLAB-DOC"
          question={question}
          currentValues={
            currentAnswer
              ? (currentAnswer.answer as GoalReviewQuestionAnswerValue[])
              : null
          }
          isReadOnly={disableQuestion}
          showValidationErrors={showValidationErrors}
          validationResult={validationResult}
          subjectUser={subjectUser}
          onValueChange={onValueChange}
          formColor={formColor}
          participants={participants}
        />
      );
    default:
      return null;
  }
}

export default CollabDocQuestionInput;
