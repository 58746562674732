import { createServer } from "miragejs";
import { CreateMockTimelineDataSet } from "./data/MockTimelineData";
import MyDashboardApiResponseDto from "../types/dtos/dashboards/MyDashboardApiResponseDto";
//import MockJourneyData from "./data/MockJourneyData";
import MockInternalDemoJourneyData from "./data/MockInternalDemoJourneyData";
import { CollabDocApiResponseDto } from "../types/dtos/collab-docs";
//import MockCollabDocData from "./data/MockCollabDocData";
import MockInternalDemoCollabDocData from "./data/MockInternalDemoCollabDocData";
import ManagerDashboardApiResponseDto from "../types/dtos/dashboards/ManagerDashboardApiResponseDto";
import MockManagerDashboardData from "./data/MockManagerDashboardData";
import ManagerDashboardTabDataApiResponseDto from "../types/dtos/dashboards/ManagerDashboardTabDataApiResponseDto";
import MockManagerDashboardTabData from "./data/MockManagerDashboardTabData";
import AdminHomeApiResponseDto from "../types/dtos/admin/AdminHomeApiResponseDto";
import MockAdminHomeData from "./data/MockAdminHomeData";
import AdminConfigApiResponseDto from "../types/dtos/admin/AdminConfigApiResponseDto";
import MockAdminConfigData from "./data/MockAdminConfigData";
import { MyDashboardSummaryData } from "./data/MockSummariesData";
import MockAdminConfigWindowedData from "./data/MockAdminConfigWindowedData";
import MockAdminConfigInductionData from "./data/MockAdminConfigInductionData";
import MockAdminConfigExitData from "./data/MockAdminConfigExitData";
import SendNowApiResponseDto from "../types/dtos/admin/SendNowApiResponseDto";
import MockSendNowData from "./data/MockSendNowData";
import SendNowCheckboxTreeApiResponseDto from "../types/dtos/admin/SendNowCheckboxTreeApiResponseDto";
import MockSendNowLocationData from "./data/MockSendNowLocationData";
import MockSendNowAppraisalLevelData from "./data/MockSendNowAppraisalLevelData";
import MockSendNowUserCountData from "./data/MockSendNowUserCountData";
import MockSendNowUsersData from "./data/MockSendNowUsersData";
import SendNowUserApiResponseDto from "../types/dtos/admin/SendNowUserApiResponseDto";
import PeoplePickerUsersApiResponseDataDto from "../types/dtos/generic/PeoplePickerUsersApiResponseDataDto";
import MockUserListData from "./data/MockUserListData";
import demoUserStateHelper from "../helpers/demoUserStateHelper";

const mockDelay = 500;

createServer({
  routes() {
    this.get(
      "/api/dashboards/me",
      () => {
        const isManager = demoUserStateHelper.isManager();
        return {
          journey: MockInternalDemoJourneyData.InitialJourney(isManager),
          summaries: MyDashboardSummaryData,
          timeline: CreateMockTimelineDataSet(5, 10, true),
        } as MyDashboardApiResponseDto;
      },
      { timing: mockDelay }
    );

    this.get(
      "/api/collab-doc/:id",
      (schema, request) => {
        const answerSetUniqueId = request.params.id;
        const isManager = demoUserStateHelper.isManager();

        if (answerSetUniqueId === "77b305b4-5db2-4519-873a-99cda9401cb1") {
          // Internal demo initial journey
          return MockInternalDemoCollabDocData.InitialJourney(
            isManager
          ) as CollabDocApiResponseDto;
        } else if (
          answerSetUniqueId === "956efd2b-b226-45ab-8723-e2ffc9fefddd"
        ) {
          // Internal demo future/aspirations only journey
          return MockInternalDemoCollabDocData.FutureOnlyJourney(
            isManager
          ) as CollabDocApiResponseDto;
        } else {
          // Default (gotta return something)
          return MockInternalDemoCollabDocData.InitialJourney(
            isManager
          ) as CollabDocApiResponseDto;
        }
      },
      { timing: mockDelay }
    );

    this.get(
      "/api/timeline/me-all",
      () => {
        return CreateMockTimelineDataSet(20, 50, true);
      },
      { timing: mockDelay }
    );

    this.get(
      "/api/dashboards/manager",
      () => {
        return MockManagerDashboardData as ManagerDashboardApiResponseDto;
      },
      { timing: mockDelay }
    );

    this.get(
      "/api/dashboards/manager/:id",
      () => {
        return MockManagerDashboardTabData as ManagerDashboardTabDataApiResponseDto;
      },
      { timing: mockDelay }
    );

    this.get(
      "/api/admin/home",
      () => {
        return MockAdminHomeData as AdminHomeApiResponseDto;
      },
      { timing: mockDelay }
    );

    this.get(
      "/api/get-users-for-people-picker",
      () => {
        return MockUserListData as PeoplePickerUsersApiResponseDataDto;
      },
      { timing: mockDelay }
    );

    this.get(
      "/api/admin/config/:id",
      () => {
        return MockAdminConfigData as AdminConfigApiResponseDto;
      },
      { timing: mockDelay }
    );

    // SY-TODO: When this is actually plugged into the API, we need to remove this.
    // The only purpose this has is to test the UI works with specific journeys.
    // --------------- START ------------------------
    this.get(
      "/api/admin/config/windowed/:id",
      () => {
        return MockAdminConfigWindowedData as AdminConfigApiResponseDto;
      },
      { timing: mockDelay }
    );
    this.get(
      "/api/admin/config/induction/:id",
      () => {
        return MockAdminConfigInductionData as AdminConfigApiResponseDto;
      },
      { timing: mockDelay }
    );
    this.get(
      "/api/admin/config/exit/:id",
      () => {
        return MockAdminConfigExitData as AdminConfigApiResponseDto;
      },
      { timing: mockDelay }
    );
    // --------------- END ------------------------

    //SY-TODO: When API gets involved we need to make sure that..
    // - Only automated or manual forms should display here (so not windowed, induction or exit forms)
    // - Pre-filled with the section the user clicked on to reach this page
    this.get(
      "/api/admin/send-now/:id",
      () => {
        return MockSendNowData as SendNowApiResponseDto;
      },
      { timing: mockDelay }
    );

    this.get(
      "/api/admin/get-locations",
      () => {
        return MockSendNowLocationData as SendNowCheckboxTreeApiResponseDto;
      },
      { timing: mockDelay }
    );

    this.get(
      "/api/admin/get-appraisal-levels",
      () => {
        return MockSendNowAppraisalLevelData as SendNowCheckboxTreeApiResponseDto;
      },
      { timing: mockDelay }
    );

    this.get(
      "/api/admin/get-user-count/:audience-tab",
      () => {
        return MockSendNowUserCountData;
      },
      { timing: mockDelay + 500 }
    );

    this.get(
      "/api/admin/get-users/:audience-tab",
      () => {
        return MockSendNowUsersData as SendNowUserApiResponseDto;
      },
      { timing: mockDelay }
    );
  },
});
