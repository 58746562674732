import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouseUser,
  faUserFriends,
  faTools,
  faAnalytics,
  faEllipsisH,
  faTimes,
  faQuestionCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { faThLarge } from "@fortawesome/pro-duotone-svg-icons";
import { useTranslation } from "react-i18next";
import AppRoutes from "../../routes/AppRoutes";
import UserContext from "../../state/UserContext";
import NonPrimaryNavItems from "../../routes/NonPrimaryNavItems";
import demoUserStateHelper from "../../helpers/demoUserStateHelper";

const TouchNav = () => {
  const [displayFullNav, setDisplayFullNav] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userContext = React.useContext(UserContext);
  let displayedNavItems = 2; // Me and Company dashboards always display

  /** Keeps a count of the currently displayed nav items, and
   * returns true or false depending on whether the number
   * of nav items displayed exceeds the maximum number allowed
   */
  const canDisplayNavItem = (boolValueToCheck: boolean | null) => {
    const maxNavItems = 4;
    const anotherNavItemWouldNotExceedMax =
      displayedNavItems + 1 <= maxNavItems;

    if (boolValueToCheck === null) {
      return anotherNavItemWouldNotExceedMax;
    }

    if (boolValueToCheck && anotherNavItemWouldNotExceedMax) {
      displayedNavItems += 1;
      return true;
    }
    return false;
  };

  const navigateAndCloseFullNav = (url: string) => {
    setDisplayFullNav(false);
    navigate(url);
  };

  const switchDemoMode = () => {
    const isManager = userContext.id === 102;
    demoUserStateHelper.setManagerStatus(!isManager);
    navigateAndCloseFullNav(AppRoutes.myDashboard + "?reload=1");
  };

  const otherNavItems = [...NonPrimaryNavItems];

  return (
    <>
      <div className="flex flex-row items-stretch justify-around border-t border-gray-300 pb-2">
        <div className="touch-nav-item">
          <Link to={AppRoutes.myDashboard}>
            <FontAwesomeIcon icon={faHouseUser} size="lg" />
            <span>{t("Nav.Touch.MyDashboard")}</span>
          </Link>
        </div>
        {canDisplayNavItem(userContext.isManager) && (
          <div className="touch-nav-item">
            <Link to={AppRoutes.managerDashboard}>
              <FontAwesomeIcon icon={faUserFriends} size="lg" />
              <span>{t("Nav.Touch.ManagerDashboard")}</span>
            </Link>
          </div>
        )}
        <div className="touch-nav-item">
          <Link to={AppRoutes.missingFromDemo}>
            <FontAwesomeIcon icon={faThLarge} size="lg" />
            <span>{t("Nav.Touch.CompanyDashboard")}</span>
          </Link>
        </div>
        {canDisplayNavItem(userContext.isAdmin) && (
          <div className="touch-nav-item">
            <Link to={AppRoutes.admin.path}>
              <FontAwesomeIcon icon={faTools} size="lg" />
              <span>{t("Nav.Touch.Admin")}</span>
            </Link>
          </div>
        )}
        {canDisplayNavItem(userContext.hasAnalyticsAccess) && (
          <div className="touch-nav-item">
            <Link to={AppRoutes.missingFromDemo}>
              <FontAwesomeIcon icon={faAnalytics} size="lg" />
              <span>{t("Nav.Touch.Analytics")}</span>
            </Link>
          </div>
        )}
        {canDisplayNavItem(null) && (
          <div className="touch-nav-item">
            <Link to={AppRoutes.missingFromDemo}>
              <FontAwesomeIcon icon={faQuestionCircle} size="lg" />
              <span>{t("Nav.Touch.Help")}</span>
            </Link>
          </div>
        )}
        <div className="touch-nav-item">
          <button onClick={() => setDisplayFullNav(true)}>
            <FontAwesomeIcon icon={faEllipsisH} size="lg" />
            <span>{t("Nav.Touch.ExpandNav")}</span>
          </button>
        </div>
      </div>
      {displayFullNav && (
        <div className="h-screen w-screen fixed top-0 left-0 bg-white">
          <div className="flex flex-col h-full">
            <div className="flex-none h-12">
              <div className="relative">
                <button
                  className="absolute top-3 right-3 modal-close-icon"
                  onClick={() => setDisplayFullNav(false)}
                >
                  <FontAwesomeIcon icon={faTimes} size="2x" />
                </button>
              </div>
            </div>
            <div className="flex-grow text-center overflow-y-auto">
              <div className="h-full w-full flex flex-col justify-center items-center">
                {otherNavItems.map((otherNavItem) => (
                  <div>
                    <button
                      onClick={() => navigateAndCloseFullNav(otherNavItem.href)}
                    >
                      <span>{t(otherNavItem.text)}</span>
                    </button>
                  </div>
                ))}
                <div>
                  <button
                    onClick={switchDemoMode}
                    className="mt-4 text-gray-500"
                  >
                    <span>
                      <em>Switch Demo Mode</em>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex-none h-20 bg-white">
              <div className="flex flex-cols h-full items-end">
                <div className="flex-1 text-center bg-gray-100/60 h-16">
                  <div className="h-full w-full flex flex-col justify-center items-center">
                    &copy; Talent Toolbox
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TouchNav;
