import { useTranslation } from "react-i18next"
import { DefaultSettingDto } from "../../../types/dtos/admin/DefaultSettingDto";
import { ColourPicker, HtmlEditor, Label, ModeDropDownList } from "../../common";
import JourneyMode from "../../../types/generic/JourneyMode";
import { useEffect, useState } from "react";
import DurationType from "../../../types/generic/DurationType";
import { colourHelper, dateHelper } from "../../../helpers";
import FrequencyPicker from "../../common/FrequencyPicker";
import { ValidationResult } from "../../../types/forms";
import SuccessAlert from "../../alerts/SuccessAlert";
import WindowPicker from "../../common/WindowPicker";
import { WindowDto } from "../../../types/dtos/admin/WindowDto";
import InductionDateRange from "../../common/InductionDateRange";

interface DefaultSettingsTabProps {
  settings: DefaultSettingDto;
  windows?: WindowDto[];
  onUpdateWindows(newState: WindowDto[] | undefined): void;
}

function DefaultSettingsTab({
  settings,
  windows,
  onUpdateWindows
}: DefaultSettingsTabProps) {
  const { t } = useTranslation();
  const maxWordCount = 500;
  const contrastCheckerFontHexCode = colourHelper.getDefaultTextColour();

  const modeInputId = "mode-select";
  const introMsgInputId = "intro-mesage-editor";
  const themeColourInputId = "theme-colour-selector";
  const frequencyValueInputId = "frequency-value-input";
  const inductionDateRangeInputId = "induction-date-range-input";
  const windowsInputId = "default-windows";

  // States - General
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false); // Whether or not the form has been edited since it was loaded
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false);

  // States - Question Related
  const [selectedMode, setSelectedMode] = useState<JourneyMode>(settings.mode);
  const [introMessage, setIntroMessage] = useState<string>(settings.intro);
  const [introMessageWordCount, setIntroMessageWordCount] = useState<number>(settings.intro.length);
  const [introMessageValidation, setIntroMessageValidation] = useState<ValidationResult | null>(null);
  const [themeColour, setThemeColour] = useState<string>(settings.themeColour);
  const [themeColourValidation, setThemeColourValidation] = useState<ValidationResult | null>(null);
  const [frequencyValue, setFrequencyValue] = useState<number | undefined>(settings.frequencyValue);
  const [selectedFrequencyType, setSelectedFrequencyType] = useState<DurationType | undefined>(settings.frequencyType);
  const [daysTo, setDaysTo] = useState<number | undefined>(settings.daysTo);
  const [daysFrom, setDaysFrom] = useState<number | undefined>(settings.daysFrom);
  const [nextDate, setNextDate] = useState<Date | undefined>();

  useEffect(() => {
    if (showValidationErrors) {
      validateIntroMessage();
    }
  }, [introMessage]);

  useEffect(() => {
    if (showValidationErrors) {
      validateThemeColour();
    }
  }, [themeColour]);

  useEffect(() => {
    if (frequencyValue && selectedFrequencyType) {
      setNextDate(dateHelper.getFromCurrentDateUtcs(frequencyValue, selectedFrequencyType));
    }
  }, [frequencyValue, selectedFrequencyType]);

  useEffect(() => {
    if (daysFrom) {
      setNextDate(dateHelper.getFromCurrentDateUtcs(daysFrom, "DAYS"));
    }
  }, [daysFrom]);

  // Events
  const handleModeChange = (selectedValue: JourneyMode) => {
    if (selectedValue !== "AUTOMATED") {
      setFrequencyValue(undefined);
      setSelectedFrequencyType(undefined);
    } else {
      setFrequencyValue(settings.frequencyValue ? settings.frequencyValue : 1);
      setSelectedFrequencyType(settings.frequencyType ? settings.frequencyType : "WEEKS");
    }

    setFormIsDirty(true);
    setSelectedMode(selectedValue);
  };

  const handleHtmlEditorChange = (selectedValue: string) => {
    setIntroMessage(selectedValue);
    setFormIsDirty(true);
  }

  const handleThemeColourChange = (selectedValue: string) => {
    setFormIsDirty(true);
    setThemeColour(selectedValue);
  }

  const handleFrequencyValueChange = (selectedValue: number) => {
    if (selectedValue !== null) {
      setFormIsDirty(true);
      setFrequencyValue(selectedValue);
    }
  };

  const handleFrequencyChange = (selectedValue: string | null) => {
    const newFrequencyType =
      selectedValue !== null ? (selectedValue as DurationType) : undefined;
    setFormIsDirty(true);
    setSelectedFrequencyType(newFrequencyType);
  };

  const handleDaysToChange = (selectedValue: number) => {
    if (selectedValue !== null) {
      setFormIsDirty(true);
      setDaysTo(selectedValue);
    }
  };

  const handleDaysFromChange = (selectedValue: number) => {
    if (selectedValue !== null) {
      setFormIsDirty(true);
      setDaysFrom(selectedValue);
    }
  };

  const onSaveChanges = () => {
    if (settingsAreValid()) {
      // Save
      console.log("Save - Validation Success");
      // TODO: API call to save the settings
      setShowSuccessAlert(true);
      setShowValidationErrors(false);
    } else {
      // Show errors
      console.log("Save - Validation Failed");
      setShowSuccessAlert(false);
      setShowValidationErrors(true);
    }
  }

  const onResetChanges = () => {
    setSelectedMode(settings.mode);
    setIntroMessage(settings.intro);
    setIntroMessageValidation(null);
    setThemeColour(settings.themeColour);

    if (settings.frequencyValue) {
      setFrequencyValue(settings.frequencyValue);
    }

    if (settings.frequencyType) {
      setSelectedFrequencyType(settings.frequencyType);
    }

    setShowSuccessAlert(false);
    setShowValidationErrors(false);
    setFormIsDirty(false);
  }

  const onAddWindow = (startDate: Date | null, endDate: Date | null) => {
    if (windows && startDate && endDate) {
      const newWindow: WindowDto = {
        id: 0,
        startDate: startDate,
        endDate: endDate
      }

      //SY-TODO: Need API call here to add window to the database.
      onUpdateWindows([...windows, newWindow]);
      setFormIsDirty(true);
    }
  }

  const onEditWindow = (id: number, startDate: Date, endDate: Date) => {
    if (windows) {
      // 'Clone'
      let newState = [...windows];

      // Create window object to represent the edited window
      const editedWindow: WindowDto = {
        id: id,
        startDate: startDate,
        endDate: endDate
      }

      // Find index in the cloned list and replace it, before updating the state.
      let index = newState.findIndex(x => x.id == id);
      newState.splice(index, 1, editedWindow)

      //SY-TODO: Need API call here to edit window in the database.
      onUpdateWindows(newState);
      setFormIsDirty(true);
    }
  }

  const onDeleteWindow = (window: WindowDto) => {
    var newState = windows?.filter(function (w) {
      return w !== window
    });

    //SY-TODO: Need API call here to delete window from the database.

    if (newState && newState?.length > 0) {
      onUpdateWindows(newState);
      //SY-TODO: toggleSuccessMessage(true, "Common.Validation.Successful.DeletedOverride")
    } else {
      onUpdateWindows([]);
    }

    setFormIsDirty(true);
  }

  // Validation 
  const settingsAreValid = (): boolean => {
    const introMessageValidationResult = validateIntroMessage();
    const themeColourValidationResult = validateThemeColour();

    return introMessageValidationResult.isValid &&
      themeColourValidationResult.isValid;
  }

  const validateIntroMessage = (): ValidationResult => {
    let validationResult: ValidationResult;

    if (introMessageWordCount === 0) {
      validationResult = new ValidationResult(false, [{ errorType: "REQUIRED" }]);
    } else if (introMessageWordCount > maxWordCount) {
      validationResult = new ValidationResult(false, [{ errorType: "TOO-LONG", max: maxWordCount, current: introMessageWordCount }]);
    } else {
      validationResult = new ValidationResult(true);
    }

    setIntroMessageValidation(validationResult);
    return validationResult;
  };

  const validateThemeColour = (): ValidationResult => {
    let validationResult: ValidationResult;

    if (themeColour === "#" || themeColour.length === 0) {
      validationResult = new ValidationResult(false, [{ errorType: "REQUIRED" }]);
    } else if (!colourHelper.isValidHex(themeColour)) {
      validationResult = new ValidationResult(false, [{ errorType: "INVALID-HEX" }]);
    } else if (!colourHelper.doesColourPassContrastCheck(contrastCheckerFontHexCode, themeColour)) {
      validationResult = new ValidationResult(false, [{ errorType: "ACCESSIBILITY-ISSUE" }]);
    } else {
      validationResult = new ValidationResult(true);
    }

    setThemeColourValidation(validationResult);
    return validationResult;
  };

  return (
    <>
      <div>
        {showSuccessAlert && (
          <SuccessAlert
            prefix={t("Common.Success")}
            message={t("Common.Validation.Successful.SavedSettings")}
          />
        )}
        {settings.modes && (
          <div className="mb-2">
            <ModeDropDownList
              inputId={modeInputId}
              value={selectedMode}
              onChange={handleModeChange}
              modes={settings.modes}
            />
          </div>
        )}
        <div className="mb-2 pt-4">
          <Label
            htmlFor={introMsgInputId}
            text={t("Pages.Admin.Common.IntroMessage")}
          />
          <HtmlEditor
            inputId={introMsgInputId}
            toolbarMode="ADVANCED"
            currentValue={introMessage}
            onValueChange={handleHtmlEditorChange}
            showValidationErrors={showValidationErrors}
            validationResult={introMessageValidation}
            currentWordCount={introMessageWordCount}
            maxWordCount={maxWordCount}
            onWordCountChange={setIntroMessageWordCount}
          />
        </div>
        <div className="mb-2">
          <Label
            htmlFor={themeColourInputId}
            text={t("Pages.Admin.Common.ThemeColour")}
          />
          <ColourPicker
            inputId={themeColourInputId}
            value={themeColour}
            fontColour={contrastCheckerFontHexCode}
            onChange={handleThemeColourChange}
            showValidationErrors={showValidationErrors}
            validationResult={themeColourValidation} />
        </div>
        {selectedMode === "AUTOMATED" && settings.frequencyTypes && frequencyValue && selectedFrequencyType && (
          <div className="mb-2 mt-6">
            <Label
              htmlFor={frequencyValueInputId}
              text={t("Pages.Admin.Common.SendSectionToUsersEveryX")}
            />
            <FrequencyPicker
              numberInputId={frequencyValueInputId}
              dropdownInputId={frequencyValueInputId}
              onFrequencyValueChange={handleFrequencyValueChange}
              onFrequencyTypeChange={handleFrequencyChange}
              value={frequencyValue}
              selectedFrequencyType={selectedFrequencyType}
              frequencyTypes={settings.frequencyTypes}
              nextDate={nextDate}
            />
          </div>
        )}
        {selectedMode === "WINDOWED" && (
          <WindowPicker
            inputId={windowsInputId}
            onAdd={onAddWindow}
            onEdit={onEditWindow}
            onDelete={onDeleteWindow}
            windows={windows}
          />
        )}
        {selectedMode === "INDUCTION" && daysTo && daysFrom && (
          <InductionDateRange
            inputId={inductionDateRangeInputId}
            onDaysToChange={handleDaysToChange}
            onDaysFromChange={handleDaysFromChange}
            daysTo={daysTo}
            daysFrom={daysFrom}
            nextDate={nextDate}
          />
        )}
        <div className="flex flex-row py-3">
          <div className="grow">
            <button className="btn-primary" disabled={!formIsDirty} onClick={onSaveChanges}>{t("Pages.Admin.Buttons.SaveChanges")}</button>
          </div>
          {formIsDirty && (
            <div>
              <button className="btn-secondary" onClick={onResetChanges}>{t("Pages.Admin.Buttons.ResetChanges")}</button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default DefaultSettingsTab;
