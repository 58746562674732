import React from "react";
import { useTranslation } from "react-i18next";
import UserContext from "../../state/UserContext";
import IndividualComment from "./IndividualComment";
import { CollabDocCommentDto } from "../../types/dtos/collab-docs";
import { UserBasicDetailsDto } from "../../types/dtos/generic";

interface CommentsListProps {
  /** The comments list to render */
  comments: CollabDocCommentDto[] | null;

  /** The form participants, for rendering avatars with */
  participants: UserBasicDetailsDto[];

  /** Whether or not this is readonly - can show "Reply" and "Delete" controls etc if set to false */
  isReadOnly: boolean;

  /** Optional - add css class names to the containing div */
  containerClassNames?: string;

  /** The event to call when the user clicks "Reply" against a comment */
  onTargetCommentForReply(targetCommentId: string): void;

  /** The method to call when the delete button is clicked against a comment */
  onDeleteComment(commentId: string): void;
}

/** A list of comments for the current question */
function CommentsList({
  comments,
  participants,
  isReadOnly,
  onDeleteComment,
  onTargetCommentForReply,
  containerClassNames = "",
}: CommentsListProps) {
  const userContext = React.useContext(UserContext);
  const { t } = useTranslation();

  if (!comments || comments.length === 0) {
    return null;
  }

  const rootComments = comments.filter((x) => x.replyToCommentId === null);

  return (
    <>
      <div className={containerClassNames}>
        <h2 className="hidden">
          {t("Pages.CollaborativeDocument.Common.Comments")}
        </h2>
        {rootComments.map((rootComment, ix) => {
          const replyComments = comments.filter(
            (x) =>
              x.replyToCommentId !== null &&
              x.replyToCommentId === rootComment.id
          );
          const rootCommentAuthor = participants.find(
            (x) => x.userId === rootComment.authorId
          );

          return (
            <IndividualComment
              key={`${ix}_${rootComment.id}`}
              author={rootCommentAuthor}
              comment={rootComment}
              allowReply={replyComments.length === 0 && !isReadOnly}
              allowDelete={
                !isReadOnly && rootCommentAuthor?.userId === userContext.id
              }
              onDeleteClick={() => onDeleteComment(rootComment.id)}
              onTargetCommentForReply={onTargetCommentForReply}
            >
              {replyComments.length > 0 && (
                <div className="pl-4">
                  {replyComments.map((replyComment, replyIx) => {
                    const replyCommentAuthor = participants.find(
                      (x) => x.userId === replyComment.authorId
                    );
                    return (
                      <div
                        className="mt-2"
                        key={`${replyIx}_${replyComment.id}`}
                      >
                        <IndividualComment
                          author={replyCommentAuthor}
                          comment={replyComment}
                          allowReply={
                            !isReadOnly && replyIx === replyComments.length - 1
                          }
                          allowDelete={
                            !isReadOnly &&
                            replyCommentAuthor?.userId === userContext.id
                          }
                          onDeleteClick={() => onDeleteComment(replyComment.id)}
                          onTargetCommentForReply={onTargetCommentForReply}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </IndividualComment>
          );
        })}
      </div>
    </>
  );
}

export default CommentsList;
