import { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Body, TopBar, LeftNav, BottomNav } from "../layout";
import ReactErrorFallback from "../error-handling/ReactErrorFallback";
import { t } from "i18next";

function StandardLayout() {
  const [hasErrored, setHasErrored] = useState(false);

  return (
    <div className="relative min-h-screen flex">
      <div className="bg-gray-100 w-60 flex-none hidden lg:block relative">
        <LeftNav />
        <span className="absolute bottom-6 left-0 right-0 m-auto text-center font-medium text-gray-400">
          {t("Common.PoweredBy")}
        </span>
      </div>

      <div className="flex flex-col w-full">
        <TopBar />
        <ErrorBoundary
          FallbackComponent={ReactErrorFallback}
          resetKeys={[hasErrored]}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
            setHasErrored(false);
            window.console.log("Reset state, error happened");
          }}
        >
          <Body />
        </ErrorBoundary>
      </div>
      <BottomNav />
    </div>
  );
}

export default StandardLayout;
