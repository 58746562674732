import { useTranslation } from "react-i18next";
import DurationType from "../../types/generic/DurationType";

interface FrequencyDisplayProps {
  value: number;
  type: DurationType;
  className?: string;
}

const FrequencyDisplay = ({ value, type, className }: FrequencyDisplayProps) => {
  const { t } = useTranslation();
  const isPlural = value > 1;
  let text = t("Common.Every") + ` ${value}`

  let duration = "";
  switch (type) {
    case "WEEKS":
      duration = isPlural ? "Common.Weeks" : "Common.Week";
      break;
    case "MONTHS":
      duration = isPlural ? "Common.Months" : "Common.Month";
      break;
    case "YEARS":
      duration = isPlural ? "Common.Years" : "Common.Year";
      break;
    case "DAYS":
    default:
      duration = isPlural ? "Common.Days" : "Common.Day";
      break;
  }

  text += " " + t(duration);

  return <span className={className}>{text}</span>;
};

export default FrequencyDisplay;
