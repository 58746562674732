import { useTranslation } from "react-i18next";
import ManagerDashboardOverviewScoreDto from "../../../types/dtos/dashboards/ManagerDashboardOverviewScoreDto";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import {
  faBan,
  faCheck,
  faTrophy,
  faSignal,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StatisticsLoadingCard from "./StatisticsLoadingCard";
import cx from "classnames";

interface StatisticProps {
  statistic: ManagerDashboardOverviewScoreDto | null;
  iconClassName?: string | undefined;
  iconSize?: SizeProp | undefined;
}

function Statistic({
  statistic,
  iconClassName = "text-gray-600",
  iconSize = "2x",
}: StatisticProps) {
  const { t } = useTranslation();
  let text: string;
  let icon: IconProp;
  text = t("Common.Loading");
  icon = faBan;

  if (statistic?.scoreType != null) {
    switch (statistic.scoreType) {
      case "ENPS":
        icon = faCheck;
        iconClassName = "bg-[#C0F1E8] text-[#40695C]";
        text = t("Pages.ManagerDashboard.Statistics.EmployeeNetPromoterScore");
        break;
      case "OPEN-OBJS":
        icon = faTrophy;
        iconClassName = "bg-[#FFEAB4] text-[#FFBB0E]";
        text = t("Pages.ManagerDashboard.Statistics.OpenGoals");
        break;
      case "AVG-PERFORMANCE":
        icon = faSignal;
        iconClassName = "bg-[#CDD8E4] text-[#7090B0]";
        text = t("Pages.ManagerDashboard.Statistics.AveragePerformance");
        break;
      case "AVG-WELLBEING":
        icon = faSignal;
        iconClassName = "bg-[#F3A7A7] text-[#E95151]";
        text = t("Pages.ManagerDashboard.Statistics.AverageWellbeing");
        break;
    }
  }

  return (
    <div className="basis-full md:basis-1/2">
      <div className="flex border-4 border-[#F7F8FA] rounded p-2 bg-white">
        {!statistic && <StatisticsLoadingCard text={text} />}

        {statistic && (
          <>
            <div className="w-1/4">
              <div className={cx(iconClassName, "text-center py-3 rounded-md")}>
                <FontAwesomeIcon
                  icon={icon}
                  size={iconSize}
                  className={iconClassName}
                />
              </div>
            </div>
            <div className="w-3/4">
              <div className="pl-5">
                <p className="text-xs">{text}</p>
                {statistic.scoreValue && (
                  <div className="flex">
                    <h3 className="coloured-heading">{statistic.scoreValue}</h3>
                    <h6 className="coloured-heading pt-2">
                      / {statistic.maxScaleScalue}
                    </h6>
                  </div>
                )}
                {statistic.enpsScore && (
                  <h3 className="coloured-heading">+{statistic.enpsScore}</h3>
                )}
                {statistic.percentageScore && (
                  <h3 className="coloured-heading">
                    {statistic.percentageScore}%
                  </h3>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Statistic;
