import { dateHelper } from "../../helpers";
import AdminConfigApiResponseDto from "../../types/dtos/admin/AdminConfigApiResponseDto";
import { KeyValuePair } from "../../types/generic";
import DurationType from "../../types/generic/DurationType";
import JourneyMode from "../../types/generic/JourneyMode";

const modes: KeyValuePair<JourneyMode, string>[] = [
  {
    key: "AUTOMATED",
    value: "Journey.Mode.Automated"
  },
  {
    key: "MANUAL-ONLY",
    value: "Journey.Mode.ManualOnly"
  },
  {
    key: "HIDDEN-ON-DASHBOARD",
    value: "Journey.Mode.HiddenOnDashboard"
  }
]

const frequencyTypes: KeyValuePair<DurationType, string>[] = [
  {
    key: "DAYS",
    value: "Common.Days"
  },
  {
    key: "WEEKS",
    value: "Common.Weeks"
  },
  {
    key: "MONTHS",
    value: "Common.Months"
  },
  {
    key: "YEARS",
    value: "Common.Years"
  }
]

const appraisalLevels: KeyValuePair<string, string>[] = [
  {
    key: "50",
    value: "Dummy.AppraisalLevel.Leadership"
  },
  {
    key: "51",
    value: "Dummy.AppraisalLevel.Management"
  },
  {
    key: "53",
    value: "Dummy.AppraisalLevel.Team"
  }
]

const MockAdminConfigWindowedData = {
  clientFormId: 200,
  clientFormTitle: "Dummy.FormTitle.GoalReview",
  defaultSettings: {
    mode: "WINDOWED",
    intro: "",
    themeColour: "#f0f0f0",
    windows: [
      {
        id: 1,
        startDate: dateHelper.getDaysFromCurrentDateUtcs(14),
        endDate: dateHelper.getDaysFromCurrentDateUtcs(28)
      },
      {
        id: 2,
        startDate: dateHelper.getDaysFromCurrentDateUtcs(130),
        endDate: dateHelper.getDaysFromCurrentDateUtcs(144)
      }
    ]
  },
  existingOverrides: [
    {
      overrideId: 40,
      appraisalLevelName: "Dummy.AppraisalLevel.Leadership",
      mode: "WINDOWED",
      windows: [
        {
          id: 1011,
          startDate: dateHelper.getDaysFromCurrentDateUtcs(7),
          endDate: dateHelper.getDaysFromCurrentDateUtcs(35)
        }
      ]      
    },
    {
      overrideId: 41,
      appraisalLevelName: "Dummy.AppraisalLevel.Management",
      mode: "WINDOWED",
      windows: [
        {
          id: 1012,
          startDate: dateHelper.getDaysFromCurrentDateUtcs(14),
          endDate: dateHelper.getDaysFromCurrentDateUtcs(28)
        },
        {
          id: 1013,
          startDate: dateHelper.getDaysFromCurrentDateUtcs(70),
          endDate: dateHelper.getDaysFromCurrentDateUtcs(84)
        }
      ]
    }
  ],
  newOverride: {
    appraisalLevels: appraisalLevels,
    modes: modes,
    frequencyTypes: frequencyTypes
  }
} as AdminConfigApiResponseDto;

export default MockAdminConfigWindowedData;
