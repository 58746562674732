import React from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

interface PopoverProps {
  /** This is the trigger element of the tooltip - mousing over this will trigger the popup */
  triggerElement: React.ReactNode;
  /** The content to display in the popover */
  children: React.ReactNode;
  /** Whether or not to show an "x" in the top right of the popover */
  showCloseIcon?: boolean;
  /** Where to position the popover in relation to the element */
  placement?: "top" | "right" | "bottom" | "left";
  /** The heading to display (optional) */
  title?: string;
  /** Class names for the content area - can be used to override width, padding and background-color */
  contentClassNames?: string;
}

/** Pass an element as the `triggerElement` prop as the target for the popover */
const Popover = ({
  triggerElement,
  children,
  showCloseIcon = true,
  title,
  placement = "bottom",
  contentClassNames = "w-48 md:w-56 bg-gray-100 p-4",
}: PopoverProps) => {
  return (
    <PopoverPrimitive.Root>
      <PopoverPrimitive.Trigger asChild>
        {triggerElement}
      </PopoverPrimitive.Trigger>
      <PopoverPrimitive.Content
        align="center"
        side={placement}
        sideOffset={5}
        className={cx(
          "radix-side-top:animate-slide-up radix-side-bottom:animate-slide-down rounded-md shadow-md",
          contentClassNames
        )}
      >
        <PopoverPrimitive.Arrow className="fill-current text-gray-100" />
        {title && (
          <h3 className="text-sm font-medium text-gray-800">{title}</h3>
        )}

        <div className="mt-4 space-y-2">{children}</div>

        {showCloseIcon && (
          <PopoverPrimitive.Close
            className={cx(
              "absolute top-3.5 right-3.5 inline-flex items-center justify-center rounded-full p-1",
              "focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
            )}
          >
            <FontAwesomeIcon
              icon={faTimes}
              className="text-gray-500 hover:text-gray-700 "
            />
          </PopoverPrimitive.Close>
        )}
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Root>
  );
};

export default Popover;
