import { dateHelper } from "../../helpers";
import AdminHomeApiResponseDto from "../../types/dtos/admin/AdminHomeApiResponseDto";

const MockAdminHomeData = {
  clientForms: [
    {
      clientFormId: 10,
      clientFormTitle: "Dummy.FormTitle.Behaviours",
      mode: "AUTOMATED",
      frequencyValue: 6,
      frequencyType: "MONTHS",
      completed: 32
    },
    {
      clientFormId: 11,
      clientFormTitle: "Dummy.FormTitle.AboutYou",
      mode: "AUTOMATED",
      frequencyValue: 3,
      frequencyType: "MONTHS",
      completed: 251
    },
    {
      clientFormId: 12,
      clientFormTitle: "Dummy.FormTitle.GoalReview",
      mode: "WINDOWED",
      completed: 0,
      windowStatus: "SHUT",
      nextWindowStartDate: dateHelper.getDaysFromCurrentDateUtcs(14),
      nextWindowEndDate: dateHelper.getDaysFromCurrentDateUtcs(28)
    },
    {
      clientFormId: 13,
      clientFormTitle: "Dummy.FormTitle.AboutUs",
      mode: "MANUAL-ONLY",
      completed: 7
    },
    {
      clientFormId: 14,
      clientFormTitle: "Dummy.FormTitle.Aspirations",
      mode: "HIDDEN-ON-DASHBOARD",
      completed: 0
    },
    {
      clientFormId: 15,
      clientFormTitle: "Dummy.FormTitle.Induction30",
      mode: "INDUCTION",
      daysFrom: 25,
      daysTo: 30,
      completed: 10
    },
    {
      clientFormId: 16,
      clientFormTitle: "Dummy.FormTitle.ExitQuestionnaire",
      mode: "EXIT",
      completed: 4
    }
  ]
} as AdminHomeApiResponseDto;

export default MockAdminHomeData;
