import { t } from "i18next";
import { useTranslation } from "react-i18next";
import cx from "classnames"
import { useEffect, useState } from "react";
import { OverrideDto } from "../../../types/dtos/admin/OverrideDto";
import JourneyModeDisplay from "../../common/JourneyModeDisplay";
import { AppraisalLevelDropDownList, FormattedDate, FrequencyPicker, IconButton, Label, ModeDropDownList, Switch } from "../../common";
import JourneyMode from "../../../types/generic/JourneyMode";
import DurationType from "../../../types/generic/DurationType";
import { dateHelper } from "../../../helpers";
import FrequencyDisplay from "../../common/FrequencyDisplay";
import SuccessAlert from "../../alerts/SuccessAlert";
import { ValidationResult } from "../../../types/forms";
import { NewOverrideDto } from "../../../types/dtos/admin/NewOverrideDto";
import { WindowDto } from "../../../types/dtos/admin/WindowDto";
import WindowPicker from "../../common/WindowPicker";
import WarningAlert from "../../alerts/WarningAlert";
import overrideHelper from "../../../helpers/overrideHelper";
import InductionDateRange from "../../common/InductionDateRange";

const setOverrideTitle = (
  configMode: ConfigMode,
  appLevelName?: string
) => {
  if (configMode === "EDIT" && appLevelName) {
    return t("Pages.Admin.Tabs.Titles.EditOverrideFor") + " '" + t(appLevelName) + "'";
  } else {
    return t("Pages.Admin.Tabs.Titles.AddNewOverride");
  }
};

const createBlankOverride = (
  mode: JourneyMode,
  appLevelName: string,
  frequencyValue?: number,
  frequencyType?: DurationType,
  daysTo?: number,
  daysFrom?: number,
  send?: boolean
): OverrideDto => {
  return {
    overrideId: 0,
    appraisalLevelName: appLevelName,
    mode: mode,
    frequencyValue: (mode === "WINDOWED" ? undefined : frequencyValue),
    frequencyType: (mode === "WINDOWED" ? undefined : frequencyType),
    windows: (mode === "WINDOWED" ? [] : undefined),
    daysTo: daysTo,
    daysFrom: daysFrom,
    send: send
  }
};

export type ConfigMode = "ADD" | "EDIT";

interface OverridesTabProps {
  newOverrideData: NewOverrideDto;
  existingOverrides?: OverrideDto[];
  onUpdateExistingOverrides(newState: OverrideDto[] | undefined): void;
  defaultAppraisalLevel?: string,
  defaultMode: JourneyMode;
  defaultFrequencyValue?: number;
  defaultFrequencyType?: DurationType;
  defaultDaysTo?: number;
  defaultDaysFrom?: number;
  defaultSend?: boolean;
}

function OverridesTab({
  newOverrideData,
  existingOverrides,
  onUpdateExistingOverrides,
  defaultAppraisalLevel = newOverrideData.appraisalLevels[0].key,
  defaultMode,
  defaultFrequencyValue = 6,
  defaultFrequencyType = "MONTHS",
  defaultDaysTo = 25,
  defaultDaysFrom = 30,
  defaultSend = true
}: OverridesTabProps) {
  const { t } = useTranslation();
  const appraisalLevelInputId = "override-appraisal-level-select";
  const modeInputId = "override-mode-select";
  const frequencyValueInputId = "override-frequency-value-input";
  const windowsInputId = "override-windows";
  const inductionDateRangeInputId = "override-induction-date-range-input";

  // STATE
  const [title, setTitle] = useState<string>(setOverrideTitle("ADD"));
  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false); // Whether or not the form has been edited since it was loaded
  const [overrideObject, setOverrideObject] = useState<OverrideDto>(createBlankOverride(defaultMode, defaultAppraisalLevel, defaultFrequencyValue, defaultFrequencyType, defaultDaysTo, defaultDaysFrom, defaultSend));
  const [nextDate, setNextDate] = useState<Date | undefined>();

  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [showWarningAlert, setShowWarningAlert] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>();
  const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false);

  const [appraisalLevelValidation, setAppraisalLevelValidation] = useState<ValidationResult | null>(null);
  const [inductionDateRangeValidation, setInductionDateRangeValidation] = useState<ValidationResult | null>(null);

  // useEffect(() => {
  //   console.log("overrideObject", overrideObject);
  // }, [overrideObject]);

  useEffect(() => {
    if (showValidationErrors) {
      validateAppraisalLevel();
    }
  }, [overrideObject.appraisalLevelName]);

  useEffect(() => {
    if (overrideObject.frequencyValue && overrideObject.frequencyType) {
      setNextDate(dateHelper.getFromCurrentDateUtcs(overrideObject.frequencyValue, overrideObject.frequencyType));
    }
  }, [overrideObject.frequencyValue, overrideObject.frequencyType]);

  useEffect(() => {
    if (overrideObject.daysFrom) {
      setNextDate(dateHelper.getFromCurrentDateUtcs(overrideObject.daysFrom, "DAYS"));
    }
  }, [overrideObject.daysFrom]);

  // EVENTS
  const handleModeChange = (selectedValue: JourneyMode) => {
    if (selectedValue !== "AUTOMATED") {
      setOverrideObject({ ...overrideObject, mode: selectedValue, frequencyValue: undefined, frequencyType: undefined });
    } else {
      setOverrideObject({ ...overrideObject, mode: selectedValue, frequencyValue: defaultFrequencyValue, frequencyType: defaultFrequencyType });
    }

    setFormIsDirty(true);
  };

  const handleAppraisalLevelChange = (selectedValue: string) => {
    setFormIsDirty(true);
    setOverrideObject({ ...overrideObject, appraisalLevelName: selectedValue });
  }

  const handleFrequencyValueChange = (selectedValue: number) => {
    if (selectedValue !== null) {
      setFormIsDirty(true);
      setOverrideObject({ ...overrideObject, frequencyValue: selectedValue });
    }
  };

  const handleFrequencyChange = (selectedValue: string | null) => {
    const newFrequencyType =
      selectedValue !== null ? (selectedValue as DurationType) : undefined;
    setFormIsDirty(true);
    setOverrideObject({ ...overrideObject, frequencyType: newFrequencyType });
  };

  const onSaveChanges = () => {
    if (settingsAreValid()) {
      var override : OverrideDto;

       // SY-TODO: Below wouldn't stay in place, it's to counter not being hooked up to the real DB yet.
      if (overrideObject.overrideId > 0) {
        override = overrideHelper.createCleanOverrideDto(
          overrideObject.overrideId,
          overrideObject.appraisalLevelName,
          overrideObject.mode,
          overrideObject);
      } else {
        var selectedAppraisalLevelName = newOverrideData.appraisalLevels.find(x => x.key == overrideObject.appraisalLevelName);
        override = overrideHelper.createCleanOverrideDto(
          parseInt(overrideObject.appraisalLevelName),
          selectedAppraisalLevelName != undefined ? selectedAppraisalLevelName?.value : "",
          overrideObject.mode,
          overrideObject);
      }

      if (!inEditMode) {
        // New Override
        if (existingOverrides) {
          onUpdateExistingOverrides([...existingOverrides, override]);
        } else {
          onUpdateExistingOverrides([override]);
        }
        onResetChanges();

        // TODO: API call to save the override
        setShowValidationErrors(false);
        toggleSuccessMessage(true, "Common.Validation.Successful.SavedOverrides");
        setShowValidationErrors(false);
      } else {
        // Updating Override
        if (existingOverrides) {
          // 'Clone'
          let newState = [...existingOverrides];

          // Find index in the cloned list and replace it, before updating the state.
          let index = newState.findIndex(x => x.overrideId == overrideObject.overrideId);
          newState.splice(index, 1, override)

          onUpdateExistingOverrides(newState);
        }

        setInEditMode(false);
        setTitle(setOverrideTitle("ADD"));
        onResetChanges();

        // TODO: API call to save the override
        setShowValidationErrors(false);
        toggleSuccessMessage(true, "Common.Validation.Successful.UpdatedOverrides");
        setShowValidationErrors(false);
      }
    } else {
      // Show errors
      toggleSuccessMessage(false, "");
      setShowValidationErrors(true);
    }
  }

  const onResetChanges = () => {
    setOverrideObject(createBlankOverride(defaultMode, defaultAppraisalLevel, defaultFrequencyValue, defaultFrequencyType, defaultDaysTo, defaultDaysFrom, defaultSend));

    setFormIsDirty(false);
    toggleSuccessMessage(false, "");
    setShowWarningAlert(false);
    setShowValidationErrors(false);
    setInEditMode(false);
    setTitle(setOverrideTitle("ADD"));
  }

  const onEditOverride = (existingOverride: OverrideDto) => {
    setTitle(setOverrideTitle("EDIT", existingOverride.appraisalLevelName));
    setInEditMode(true);
    setOverrideObject(existingOverride);
  }

  const onDeleteOverride = (existingOverride: OverrideDto) => {
    var newState = existingOverrides?.filter(function (o) {
      return o !== existingOverride
    });

    if (newState && newState?.length > 0) {
      onUpdateExistingOverrides(newState);
      toggleSuccessMessage(true, "Common.Validation.Successful.DeletedOverride")
    } else {
      onUpdateExistingOverrides(undefined);
    }
  }

  const toggleSuccessMessage = (show: boolean, message: string) => {
    setShowSuccessAlert(show);
    setSuccessMessage(message);
  }

  const onAddWindow = (startDate: Date | null, endDate: Date | null) => {
    if (overrideObject.windows && startDate && endDate) {
      const newWindow: WindowDto = {
        id: randomInteger(2000, 4000), //SY-TODO: This needs hooking in when API comes into play.
        startDate: startDate,
        endDate: endDate
      }

      const newState = [...overrideObject.windows, newWindow];

      //SY-TODO: Need API call here to add window to the database.
      setOverrideObject({ ...overrideObject, windows: newState });
      setFormIsDirty(true);

      if (inEditMode) {
        setShowWarningAlert(true);
      }
    }
  }

  const onEditWindow = (id: number, startDate: Date, endDate: Date) => {
    if (overrideObject.windows) {
      // 'Clone'
      let newState = [...overrideObject.windows];

      // Create window object to represent the edited window
      const editedWindow: WindowDto = {
        id: id,
        startDate: startDate,
        endDate: endDate
      }

      // Find index in the cloned list and replace it, before updating the state.
      let index = newState.findIndex(x => x.id == id);
      newState.splice(index, 1, editedWindow)

      //SY-TODO: Need API call here to edit window in the database.
      setOverrideObject({ ...overrideObject, windows: newState });
      setFormIsDirty(true);

      if (inEditMode) {
        setShowWarningAlert(true);
      }
    }
  }

  const onDeleteWindow = (window: WindowDto) => {
    var newState = overrideObject.windows?.filter(function (w) {
      return w !== window
    });

    //SY-TODO: Need API call here to delete window from the database.

    if (newState && newState?.length > 0) {
      setOverrideObject({ ...overrideObject, windows: newState });
      //SY-TODO: toggleSuccessMessage(true, "Common.Validation.Successful.DeletedOverride")
    } else {
      setOverrideObject({ ...overrideObject, windows: [] });
    }

    setFormIsDirty(true);

    if (inEditMode) {
      setShowWarningAlert(true);
    }
  }

  const handleDaysToChange = (selectedValue: number) => {
    if (selectedValue !== null) {
      setFormIsDirty(true);
      setOverrideObject({ ...overrideObject, daysTo: selectedValue });
    }
  };

  const handleDaysFromChange = (selectedValue: number) => {
    if (selectedValue !== null) {
      setFormIsDirty(true);
      setOverrideObject({ ...overrideObject, daysFrom: selectedValue });
    }
  };

  const handleSendChange = (value: boolean) => {
    if (value !== null) {
      if (overrideObject.daysTo === undefined && overrideObject.daysFrom === undefined) {
        setOverrideObject({ ...overrideObject, send: value, daysTo: defaultDaysTo, daysFrom:  defaultDaysFrom});
      } else {
        setOverrideObject({ ...overrideObject, send: value });
      }

      setFormIsDirty(true);
    }
  };

  // VALIDATION 
  const settingsAreValid = (): boolean => {
    const appraisalLevelValidationResult = validateAppraisalLevel();
    const inductionDatRangeValidationResult = validateInductionDateRange();

    if (overrideObject.mode === "INDUCTION") {
      return  appraisalLevelValidationResult.isValid &&
              inductionDatRangeValidationResult.isValid;
    } else {
      return appraisalLevelValidationResult.isValid;
    }
  }

  const validateAppraisalLevel = (): ValidationResult => {
    let validationResult: ValidationResult;

    var selectedAppraisalLevelName = newOverrideData.appraisalLevels.find(x => x.key == overrideObject.appraisalLevelName)?.value;
    const doesAppLevelAlreadyHaveOverride = existingOverrides?.some(x => x.appraisalLevelName === selectedAppraisalLevelName);

    if (doesAppLevelAlreadyHaveOverride) {
      validationResult = new ValidationResult(false, [{ errorType: "NON-UNIQUE_APP_LEVEL_OVERRIDE" }]);
    } else {
      validationResult = new ValidationResult(true);
    }

    setAppraisalLevelValidation(validationResult);
    return validationResult;
  };

  const validateInductionDateRange = (): ValidationResult => {
    let validationResult: ValidationResult;

    if (overrideObject.daysTo !== undefined &&
      overrideObject.daysFrom !== undefined && 
      overrideObject.daysTo > overrideObject.daysFrom) {
        validationResult = new ValidationResult(true);
    } else {
      validationResult = new ValidationResult(false, [{ errorType: "NUMBER-MISMATCH" }]);
    }

    setInductionDateRangeValidation(validationResult);
    return validationResult;
  };

  // FUNCTIONS
  function randomInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }


  return (
    <>
      {showSuccessAlert && successMessage && (
        <SuccessAlert
          prefix={t("Common.Success")}
          message={t(successMessage)}
        />
      )}
      {showWarningAlert && (
        <WarningAlert
          prefix=""
          message={t("Common.Validation.Warning.RememberToSaveAfterWindowAlterations")}
        />
      )}
      <div className="grid grid-cols-1 md:grid-cols-12 gap-10">
        {existingOverrides && existingOverrides.length > 0 && (
          <div className="col-span-1 md:col-span-4">
            <h3>
              {t("Pages.Admin.Tabs.Titles.ExistingOverrides")}
              {existingOverrides.map((existing) => {
                return (
                  <div key={`existing_${existing.overrideId}`} className="grid grid-cols-8 border-4 border-[#F7F8FA] rounded mt-4 p-4">
                    <div className="text-sm col-span-6">
                      <div>
                        <label className="font-semibold underline">{t("Common.AppraisalLevel")}</label>
                        <p className="font-normal">- {t(existing.appraisalLevelName)}</p>
                      </div>
                      {existing.mode !== "WINDOWED" &&
                        existing.mode !== "INDUCTION" &&
                        existing.mode !== "EXIT" && (
                          <div className="pt-1">
                            <label className="font-semibold underline">{t("Common.Override")}: </label>
                            <p>
                              - <JourneyModeDisplay className="font-normal" value={existing.mode} />
                              {existing.frequencyValue && existing.frequencyType && (
                                <span> (<FrequencyDisplay value={existing.frequencyValue} type={existing.frequencyType} className="font-normal" />)</span>

                              )}
                            </p>
                          </div>
                        )}
                      {existing.mode === "WINDOWED" && existing.windows && (
                        <div className="pt-1">
                          <label className="font-semibold underline">{t("Common.Override")}: </label>
                          {existing.windows.map((window) => (
                            <p>
                              {window.startDate && window.endDate && (
                                <span className="font-normal">- <FormattedDate displayMode="DATE-ONLY" date={window.startDate} /> - <FormattedDate displayMode="DATE-ONLY" date={window.endDate} />                                </span>
                              )}
                            </p>
                          ))}
                        </div>
                      )}
                      {existing.mode === "INDUCTION" && (
                        <div className="pt-1">
                          <label className="font-semibold underline">{t("Common.Override")}: </label>
                          {existing.send && existing.send === true ? (<div>
                            {existing.daysTo && existing.daysFrom && (
                              <span className="font-normal">- {t("Pages.Admin.Common.Between")} {existing.daysFrom} {t("Common.And_LowerCase")} {existing.daysTo} {t("Common.Days_LowerCase")}</span>
                            )}
                          </div>) : (<div>
                            <span className="font-normal">- {t("Pages.Admin.Common.NotSent")}</span>
                          </div>)}
                        </div>
                      )}
                      {existing.mode === "EXIT" && (
                        <div className="pt-1">
                          <label className="font-semibold underline">{t("Common.Override")}: </label>
                          {existing.send && existing.send === true ? (<div>
                            <span className="font-normal">- {t("Pages.Admin.Common.WillSend")}</span>
                          </div>) : (<div>
                            <span className="font-normal">- {t("Pages.Admin.Common.NotSent")}</span>
                          </div>)}
                        </div>
                      )}
                    </div>
                    <div className="col-span-1 text-right">
                      <IconButton
                        buttonType="EDIT"
                        displayMode="ICON-ONLY"
                        iconClassName="text-yellow-700"
                        onClick={() => { onEditOverride(existing) }}
                      />
                    </div>
                    <div className="col-span-1 text-right">
                      <IconButton
                        buttonType="DELETE"
                        displayMode="ICON-ONLY"
                        iconClassName="text-rose-800"
                        onClick={() => { onDeleteOverride(existing) }}
                      />
                    </div>
                  </div>
                )
              })}
            </h3>
          </div>
        )}
        <div className={cx(
          existingOverrides && existingOverrides.length > 0 ? "col-span-1 md:col-span-8" : "col-span-1 md:col-span-12"
        )}>
          <div className="grid grid-cols-10">
            <h3 className="col-span-9">
              {title}
            </h3>
            {inEditMode && (
              <div className="col-span-1">
                <IconButton
                  buttonType="NEW-OVERRIDE"
                  displayMode="ICON-ONLY"
                  onClick={() => { onResetChanges() }}
                />
              </div>
            )}
          </div>
          {!inEditMode && (
            <div>
              <AppraisalLevelDropDownList
                inputId={appraisalLevelInputId}
                value={overrideObject.appraisalLevelName}
                onChange={handleAppraisalLevelChange}
                options={newOverrideData.appraisalLevels}
                showValidationErrors={showValidationErrors}
                validationResult={appraisalLevelValidation}
              />
            </div>
          )}
          {overrideObject.mode !== "WINDOWED" &&
            overrideObject.mode !== "INDUCTION" &&
            overrideObject.mode !== "EXIT" && (
              <div className={cx(
                inEditMode ? "" : "mb-2 mt-6"
              )}>
                <ModeDropDownList
                  inputId={modeInputId}
                  value={overrideObject.mode}
                  onChange={handleModeChange}
                  modes={newOverrideData.modes}
                />
              </div>
            )}
          {overrideObject.mode === "AUTOMATED" && newOverrideData.frequencyTypes && overrideObject.frequencyValue && overrideObject.frequencyType && (
            <div className="mb-2 mt-6">
              <Label
                htmlFor={frequencyValueInputId}
                text={t("Pages.Admin.Common.SendSectionToUsersEveryX")}
              />
              <FrequencyPicker
                numberInputId={frequencyValueInputId}
                dropdownInputId={frequencyValueInputId}
                onFrequencyValueChange={handleFrequencyValueChange}
                onFrequencyTypeChange={handleFrequencyChange}
                value={overrideObject.frequencyValue}
                selectedFrequencyType={overrideObject.frequencyType}
                frequencyTypes={newOverrideData.frequencyTypes}
                nextDate={nextDate}
              />
            </div>
          )}
          {overrideObject.mode === "WINDOWED" && overrideObject.windows && (
            <WindowPicker
              inputId={windowsInputId}
              onAdd={onAddWindow}
              onEdit={onEditWindow}
              onDelete={onDeleteWindow}
              windows={overrideObject.windows}
            />
          )}
          {overrideObject.mode === "INDUCTION" && overrideObject.send !== undefined && (
            <>
              <div className="mt-4 grid grid-cols-6 gap-4">
                <Label
                  text={t("Pages.Admin.Common.DoYouWantToSend")}
                  className="col-span-5"
                />
                <div className="col-span-1 text-right">
                  <Switch checked={overrideObject.send} onChange={handleSendChange} />
                </div>
              </div>
              {overrideObject.send === true && overrideObject.daysTo && overrideObject.daysFrom && (
                <InductionDateRange
                  inputId={inductionDateRangeInputId}
                  onDaysFromChange={handleDaysFromChange}
                  onDaysToChange={handleDaysToChange}                  
                  daysFrom={overrideObject.daysFrom}
                  daysTo={overrideObject.daysTo}                  
                  nextDate={nextDate}
                  showValidationErrors={showValidationErrors}
                  validationResult={inductionDateRangeValidation}
                />
              )}
            </>
          )}
          {overrideObject.mode === "EXIT" && overrideObject.send !== undefined && (
            <div className="mt-4 grid grid-cols-6 gap-4">
              <Label
                text={t("Pages.Admin.Common.DoYouWantToSend")}
                className="col-span-5"
              />
              <div className="col-span-1 text-right">
                <Switch checked={overrideObject.send} onChange={handleSendChange} />
              </div>
            </div>
          )}
          <div className="flex flex-row py-3">
            <div className="grow">
              <button className="btn-primary" disabled={!formIsDirty} onClick={onSaveChanges}>{t("Pages.Admin.Buttons.SaveChanges")}</button>
            </div>
            {formIsDirty && !inEditMode && (
              <div>
                <button className="btn-secondary" onClick={onResetChanges}>{t("Pages.Admin.Buttons.ResetChanges")}</button>
              </div>
            )}
          </div>
        </div>
      </div >
    </>
  )
}

export default OverridesTab;
