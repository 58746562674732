import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { ScrollPageMainContainerToTop } from "../layout/MainContainer";

interface JourneyFormPagerProps {
  enableNextButton: boolean;
  showPrevBtn: boolean;
  showSubmitBtn: boolean;
  disableSubmitBtn: boolean;
  onNextPageClick(): void;
  onPreviousPageClick(): void;
  onSubmit(): void;
}

function JourneyFormPager({
  enableNextButton,
  showPrevBtn,
  showSubmitBtn,
  disableSubmitBtn,
  onNextPageClick,
  onPreviousPageClick,
  onSubmit,
}: JourneyFormPagerProps) {
  const { t } = useTranslation();

  const scrollToTop = () => {
    // Scroll to the top on question change, as long questions
    // on mobile can leave the user stuck half way down the page
    // when the new question loads
    ScrollPageMainContainerToTop();
  };

  const handlePreviousClick = () => {
    onPreviousPageClick();
    scrollToTop();
  };

  const handleNextPageClick = () => {
    onNextPageClick();
    scrollToTop();
  };

  const handleSubmitClick = () => {
    onSubmit();
    scrollToTop();
  };

  return (
    <div className="text-right">
      {showPrevBtn && (
        <button className="journey-btn-primary" onClick={handlePreviousClick}>
          <FontAwesomeIcon icon={faAngleUp} />
        </button>
      )}
      {!showSubmitBtn && (
        <button
          className="journey-btn-primary"
          onClick={handleNextPageClick}
          disabled={!enableNextButton}
        >
          <FontAwesomeIcon icon={faAngleDown} />
        </button>
      )}
      {showSubmitBtn && (
        <button
          className="journey-btn-primary"
          onClick={handleSubmitClick}
          disabled={disableSubmitBtn}
        >
          {t("Journey.Button.Submit")}
        </button>
      )}
    </div>
  );
}

export default JourneyFormPager;
