import { useTranslation } from "react-i18next";
import { DashboardSummaryTabDto } from "../../../types/dtos/dashboards/DashboardSummaryTabDto";
import { TabDetails } from "../../common/Tabs";
import { IconButton, Tabs } from "../../common";
import SmallLoader from "../../loaders/SmallLoader";
import FormSummary from "../../form-summaries/FormSummary";

interface PeopleContentProps {
  /** Whether or not the PeopleCard component is expanded to view the user's data */
  userIsExpanded: boolean;
  /** Whether or not we're waiting for the API to return data */
  isLoading: boolean;
  tabs: DashboardSummaryTabDto[];
  /** A function to call when the manager chooses to update a form section for one of their people */
  onTriggerSectionUpdate(clientFormId: number): void;
}

function PeopleContent({
  userIsExpanded,
  isLoading,
  tabs,
  onTriggerSectionUpdate,
}: PeopleContentProps) {
  const { t } = useTranslation();

  const getTabContent = (tab: DashboardSummaryTabDto) => {
    if (!userIsExpanded) {
      return null;
    } else if (isLoading) {
      return <SmallLoader />;
    } else {
      const tabDataToDisplay = tabs.find(
        (x) => x.clientFormId === tab.clientFormId
      );
      const emptyTabData = <span>No data!</span>;

      return (
        <>
          {!tabDataToDisplay ? (
            emptyTabData
          ) : (
            <div className="flex flex-col">
              <div className="flex flex-row-reverse gap-3">
                {tabDataToDisplay.tabType !== "CLIENT-FORM-EMPLOYEE-ONLY" && (
                  <button
                    className="btn-primary block"
                    onClick={() =>
                      onTriggerSectionUpdate(tabDataToDisplay.clientFormId!)
                    }
                  >
                    <span>
                      {t("Common.Update") +
                        " " +
                        t(tabDataToDisplay.clientFormTitle)}
                    </span>
                  </button>
                )}
                {tabDataToDisplay.tabType === "GOALS" && (
                  <button className="btn-secondary block">
                    <span>{t("Common.EditGoals")}</span>
                  </button>
                )}
                <IconButton
                  buttonType="HISTORY"
                  displayMode="ICON-ONLY"
                  buttonClassName="px-1 mr-2"
                  iconClassName="text-gray-800"
                  onClick={() => {}}
                />
              </div>
              <FormSummary
                summaryTabData={tabDataToDisplay}
                isManagerDashboard
                onTriggerSectionUpdate={onTriggerSectionUpdate}
              />
            </div>
          )}
        </>
      );
    }
  };

  const displayTabs: Array<TabDetails> = [];
  tabs.map((tab) => {
    const tabName = t(tab.clientFormTitle);
    displayTabs.push({
      title: tabName,
      content: getTabContent(tab),
      displayUpdateIcon: tab.tabHasUpdates,
    });
  });

  return (
    <div>
      <Tabs
        tabs={displayTabs}
        selectFirstTabByDefault={userIsExpanded}
        selectedTabClassNames="radix-state-active: bg-[#EFEFF0] hover:!bg-[#EFEFF0] rounded-md"
        maxTabsOnSmallScreens={2}
      />
    </div>
  );
}

export default PeopleContent;
