import cx from "classnames";

interface BadgeProps {
  text: string;
  classNames?: string;
  backgroundColourClassName?: string;
  textColourClassName?: string;
}

/** A simple badge component which renders text within a small, coloured block */
function Badge({
  text,
  backgroundColourClassName = "bg-gray-200/50",
  textColourClassName = "text-gray-200",
}: BadgeProps) {
  return (
    <span
      className={cx(
        "mx-2 px-2 py-0.5 rounded-sm text-xs leading-tight cursor-default select-none",
        backgroundColourClassName,
        textColourClassName
      )}
    >
      {text}
    </span>
  );
}

export default Badge;
