import { useContext, useState, useEffect } from "react";
import produce from "immer";
import JourneyFormPager from "./JourneyFormPager";
import JourneyQuestion from "./JourneyQuestion";
import JourneySubmitted from "./JourneySubmitted";
import FormQuestion from "../../types/forms/FormQuestion";
import {
  QuestionAnswer,
  QuestionAnswerValue,
  QuestionNewTasks,
} from "../../types/forms";
import { JourneyFormDto, JourneyFormGroupDto } from "../../types/dtos/journeys";
import { FormQuestionDto } from "../../types/dtos/forms";
import { JourneyCommentDto } from "../../types/dtos/journeys/JourneyCommentDto";
import { journeyQuestionSequenceHelper } from "../../helpers/questionSequenceHelpers";
import { dateHelper } from "../../helpers";
import UserContext from "../../state/UserContext";
import JourneyWelcomeMessage from "./JourneyWelcomeMessage";

interface JourneyFormProps {
  formDetails: JourneyFormGroupDto;
  onFormChange(formDto: JourneyFormDto): void;
  showWelcomeMessage: boolean;
  onHideWelcomeMessage(): void;
}

function JourneyForm({
  formDetails,
  onFormChange,
  showWelcomeMessage,
  onHideWelcomeMessage,
}: JourneyFormProps) {
  // Context
  const userContext = useContext(UserContext);
  const formSubjectUserId = userContext.id; // In the future, we might have manager journeys about their people, and this needs to be passed in to validation functions

  // State
  const [answerState, setAnswerState] = useState<QuestionAnswer[]>([]);
  // The state variable for tasks added in this document, but not yet pushed to the user's dashboard
  const [newTasks, setNewTasks] = useState<QuestionNewTasks[]>([]);
  const [comments, setComments] = useState<JourneyCommentDto[]>([]);
  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false);
  const [activeFormIndex, setActiveFormIndex] = useState<number>(0);
  const [activeQuestion, setActiveQuestion] = useState<FormQuestion>();
  const [previousQuestionId, setPreviousQuestionId] = useState<string | null>(
    null
  );
  const [answerSetUniqueId, setAnswerSetUniqueId] = useState<string | null>(
    null
  );
  const [enableNextButton, setEnableNextButton] = useState<boolean>(false);
  const [enablePreviousButton, setEnablePreviousButton] =
    useState<boolean>(false);

  function getAnswerValueForQuestionId(
    questionId: string
  ): QuestionAnswerValue | null {
    const match = answerState.find((x) => x.questionId === questionId)?.answer;
    return match ? match : null;
  }

  /** Check whether or not the Next button can be enabled, depending on whether there
   * are more questions, and whether the current question has a valid answer */
  const canEnableNextButton = () => {
    // Only enable the next button when there's an active question with a valid answer
    if (!activeQuestion) return false;
    const questionAnswer = getAnswerValueForQuestionId(
      activeQuestion.questionId
    );
    const questionTasks = newTasks.find(
      (x) => x.questionId === activeQuestion.questionId
    );
    return activeQuestion.validate(
      questionAnswer,
      questionTasks ? questionTasks.tasks : null,
      userContext.id,
      formSubjectUserId
    ).isValid;
  };

  // Lifecycle events
  useEffect(() => {
    // Select the first question from the first form on mount and
    // convert the DTO to an instance of the `JourneyFormQuestion` class so
    // we can use methods declared on it
    const formQuestion = new FormQuestion(
      formDetails.forms[activeFormIndex].questions[0]
    );
    setActiveQuestion(formQuestion);
    // Reset any answers, tasks etc, as the current journey may have been changed via the summary section buttons
    setAnswerState([]);
    setComments([]);
    setNewTasks([]);
  }, [formDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // On form change, tell the parent component (so we can change bg colour etc)
    const latestForm = formDetails.forms[activeFormIndex];
    onFormChange(latestForm);
  }, [activeFormIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // As the `activeQuestion` changes, calculate which question to show
    // when the user goes backwards in the form
    let previousQuestion: string | null = null;
    if (activeQuestion) {
      previousQuestion = journeyQuestionSequenceHelper.getPreviousQuestionId(
        activeQuestion,
        formDetails.forms[activeFormIndex].questions,
        answerState
      );
    }
    setPreviousQuestionId(previousQuestion ? previousQuestion : null);
    setEnableNextButton(canEnableNextButton());
  }, [activeQuestion]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // When the answer changes, or tasks change for goal setting, conditionally enable the "Next" button
    setEnableNextButton(canEnableNextButton());

    // Show the Previous button except when on the first question of the first form
    const showPreviousPagerButton: boolean =
      activeFormIndex > 0 ||
      (previousQuestionId !== null && previousQuestionId.length > 0);
    setEnablePreviousButton(showPreviousPagerButton);
  }, [activeQuestion, answerState, previousQuestionId, newTasks]); // eslint-disable-line react-hooks/exhaustive-deps

  /** Update the answer state for the current question with the given value */
  const onValueChange = (newValue: QuestionAnswerValue) => {
    if (!activeQuestion) return;

    const answerTimestamp = dateHelper.getCurrentDateUtc();

    // Update the existing state answer if there is one, otherwise add it to the state
    // if it's a new answer
    const nextState = produce(answerState, (draft) => {
      const match = draft.find(
        (x) => x.questionId === activeQuestion.questionId
      );
      if (match !== undefined) {
        match.id = null; // TODO: Is this the right thing to do? Or should we remove the answer, and add a new one instead?
        match.answer = newValue;
        match.timestamp = answerTimestamp;
        match.userId = userContext.id;
      } else {
        draft.push({
          id: null,
          questionId: activeQuestion.questionId,
          answer: newValue,
          timestamp: answerTimestamp,
          userId: userContext.id,
        });
      }
    });
    setAnswerState(nextState);
  };

  /** Update the comment for this question */
  const onCommentChange = (newValue: string | null) => {
    if (!activeQuestion) return;

    // Update the existing state answer if there is one, otherwise add it to the state
    // if it's a new answer
    const nextState = produce(comments, (draft) => {
      const match = draft.find(
        (x) => x.questionId === activeQuestion.questionId
      );
      if (match !== undefined) {
        // A comment exists in state already, update or remove it, depending
        // on whether or not a value has been supplied
        if (newValue && newValue.trim().length > 0) {
          match.comment = newValue;
        } else {
          const indexToRemove = draft.findIndex(
            (x) => x.questionId === activeQuestion.questionId
          );
          draft.splice(indexToRemove, 1);
        }
      } else if (newValue !== null) {
        draft.push({
          questionId: activeQuestion.questionId,
          comment: newValue,
        });
      }
    });

    setComments(nextState);
  };

  /** When a task is added/edited/deleted */
  const onChangeQuestionNewTasks = (questionTasks: QuestionNewTasks) => {
    const nextState = produce(newTasks, (draft) => {
      const match = draft.find(
        (x) => x.questionId === questionTasks.questionId
      );
      if (match !== undefined) {
        // Update the match with a clone of the question tasks array
        match.tasks = [...questionTasks.tasks];
      } else {
        draft.push(questionTasks);
      }
    });
    setNewTasks(nextState);
  };

  const onPagerForwards = () => {
    // Reset the flag to keep validation errors showing,
    // otherwise error warnings appear before the user has
    // had a chance to answer the question
    setShowValidationErrors(false);

    const currentAnswer = getAnswerValueForQuestionId(
      activeQuestion!.questionId
    );

    // Run the validation
    const questionTasks = newTasks.find(
      (x) => x.questionId === activeQuestion!.questionId
    );

    const validationResult = activeQuestion!.validate(
      currentAnswer,
      questionTasks ? questionTasks.tasks : null,
      userContext.id,
      formSubjectUserId
    );
    if (!validationResult.isValid) {
      setShowValidationErrors(true);
      return;
    }

    // Retrieve the next questionId to answer, takes conditional logic into account
    let nextQuestionId: string | null = null;
    if (activeQuestion) {
      nextQuestionId = journeyQuestionSequenceHelper.getNextQuestionId(
        activeQuestion,
        currentAnswer
      );
    }

    // If there is a question in this form left to answer, find it from the list
    // of questions and set it as the active question
    if (nextQuestionId) {
      const nextQuestion = formDetails.forms[activeFormIndex].questions.find(
        (x) => x.questionId === nextQuestionId
      );
      if (nextQuestion) {
        setActiveQuestion(new FormQuestion(nextQuestion));
        return;
      }
    }

    // If no question has been found, and if there is another form
    // to complete, go to the first question in that form

    const isLastForm = activeFormIndex === formDetails.forms.length - 1;
    if (isLastForm) {
      // Submit this form - in theory, this should be handled by the Submit button
      onSubmit();
    } else {
      // Go to the start of the next form
      const newActiveFormIndex = activeFormIndex + 1;
      setActiveFormIndex(newActiveFormIndex);

      const newActiveQuestion =
        formDetails.forms[newActiveFormIndex].questions[0];
      setActiveQuestion(new FormQuestion(newActiveQuestion));
    }
  };

  const onPagerBackwards = () => {
    if (previousQuestionId) {
      const prevQuestion = formDetails.forms[activeFormIndex].questions.find(
        (x) => x.questionId === previousQuestionId
      );
      if (prevQuestion) {
        setActiveQuestion(new FormQuestion(prevQuestion));
        return;
      }
    }

    // If the current question is the first one in a form, and there are previous forms
    // in the set of forms, then we need to go back to the last question the user answered
    // in the previous form. The `previousQuestionId` state value doesn't work here as the
    // activeFormIndex changes too
    const isFirstForm = activeFormIndex === 0;
    if (!isFirstForm) {
      // Go to the previous form
      const newFormIndex = activeFormIndex - 1;
      setActiveFormIndex(newFormIndex);

      // Loop backwards over the questions in this form,
      // and find the last one with an answer
      // Note that if we ever introduce optional questions for journeys, we'll
      // need to ensure we store null answers in the state for such questions
      // to avoid breaking the logic in this pageBackwards function
      let newActiveQuestion: FormQuestionDto | null = null;
      for (
        let iQuestion = formDetails.forms[newFormIndex].questions.length - 1;
        iQuestion >= 0;
        iQuestion--
      ) {
        const loopQuestionId =
          formDetails.forms[newFormIndex].questions[iQuestion].questionId;
        const answerForLoopQuestion = answerState.find(
          (x) => x.questionId === loopQuestionId
        );
        if (answerForLoopQuestion) {
          newActiveQuestion =
            formDetails.forms[newFormIndex].questions[iQuestion];
          break;
        }
      }

      if (!newActiveQuestion) {
        // Shouldn't happen in theory, but as a fallback, go to the first question in the active form
        newActiveQuestion = formDetails.forms[newFormIndex].questions[0];
      }

      setActiveQuestion(new FormQuestion(newActiveQuestion));
    } else {
      // Do nothing - first form, first question, we shouldn't reach here anyway
    }
  };

  const onSubmit = () => {
    // TODO: Implement the saving of the response, return the Guid Id

    // Change the answerSetUniqueId so we can control which data to load in the collab doc
    // for the demo
    let savedResponseId: string;
    switch (formDetails.journeyReference) {
      case "ae2dc563-4fd0-4bd1-bc3c-d981aa6587c6": // Internal demo, initial full journey
        savedResponseId = "77b305b4-5db2-4519-873a-99cda9401cb1";
        break;
      case "4592867e-9305-49a5-b7f4-35ac7b363dc0": // Internal demo, future/aspirations only journey
        savedResponseId = "956efd2b-b226-45ab-8723-e2ffc9fefddd";
        break;
      default: // Realistic demo journey
        savedResponseId = "78f3d6de-3e55-4f45-8a6d-fa67c5f0b77c";
        break;
    }

    // Setting this should trigger the thank you/confirmation screen
    setAnswerSetUniqueId(savedResponseId);
  };

  if (!activeQuestion) return null;

  // Show the Submit button only on the last form, on the last question
  const showSubmitPagerButton =
    activeFormIndex === formDetails.forms.length - 1 &&
    activeQuestion &&
    activeQuestion.isLastQuestionInForm();

  // Disable the submit button if the last question isn't valid
  const disableSubmitPagerButton =
    showSubmitPagerButton && !canEnableNextButton();

  // Get the current answer from the state to pass to form controls as the current value
  const currentAnswer =
    answerState.find((x) => x.questionId === activeQuestion.questionId)
      ?.answer || null;

  const formHasBeenSubmitted: boolean = answerSetUniqueId !== null;

  const questionComment: JourneyCommentDto | undefined = comments.find(
    (x) => x.questionId === activeQuestion.questionId
  );

  const questionNewTasks = newTasks.find(
    (x) => x.questionId === activeQuestion.questionId
  );

  const displayWelcomeMessage =
    showWelcomeMessage &&
    formDetails.welcomeMessage &&
    formDetails.welcomeMessage.trim().length > 0;

  return (
    <>
      <div className="min-h-40 flex content-center">
        <div className="w-full">
          {!formHasBeenSubmitted && displayWelcomeMessage && (
            <JourneyWelcomeMessage
              htmlText={formDetails.welcomeMessage!}
              onHide={onHideWelcomeMessage}
            />
          )}
          {!formHasBeenSubmitted && !displayWelcomeMessage && (
            <>
              <JourneyQuestion
                question={activeQuestion}
                currentValue={currentAnswer}
                onValueChange={onValueChange}
                commentValue={questionComment?.comment}
                onCommentChange={onCommentChange}
                showValidationErrors={showValidationErrors}
                enableNextButton={enableNextButton}
                showSubmitBtn={showSubmitPagerButton}
                onNextPageClick={onPagerForwards}
                subjectUser={formDetails.subjectUser}
                onChangeQuestionNewTasks={onChangeQuestionNewTasks}
                newTasks={questionNewTasks ? questionNewTasks : null}
                formColor={formDetails.backgroundColour}
              />
              <JourneyFormPager
                enableNextButton={enableNextButton}
                showPrevBtn={enablePreviousButton}
                showSubmitBtn={showSubmitPagerButton}
                disableSubmitBtn={disableSubmitPagerButton}
                onNextPageClick={onPagerForwards}
                onPreviousPageClick={onPagerBackwards}
                onSubmit={onSubmit}
              />
            </>
          )}
          {formHasBeenSubmitted && (
            <JourneySubmitted
              journeyType={formDetails.journeyType}
              answerSetUniqueId={answerSetUniqueId!}
              forceRedirectToCollabDoc={formDetails.redirectOnSubmit}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default JourneyForm;
