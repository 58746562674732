import { t } from "i18next";
import { MultipleChoiceOption, QuestionAnswerValue } from "../types/forms";
import { FormQuestion } from "../types/forms";
import { SliderScoreDisplayType } from "../types/generic";

/** Get the collection of options with options marked as "selected" if they're in the currentValue (answer) */
export const initialiseMultipleChoiceOptions = (
  question: FormQuestion,
  currentValue: QuestionAnswerValue,
  translateText: boolean
): MultipleChoiceOption[] => {
  let multipleChoiceQuestionOptions =
    question.isMultiChoiceQuestion() &&
    question.multiChoiceOptions !== null &&
    question.multiChoiceOptions !== undefined
      ? [...question.multiChoiceOptions]
      : [];

  const currentValueIsArray =
    currentValue !== null && Array.isArray(currentValue);

  const itemsWithValuesSelected = multipleChoiceQuestionOptions.map((item) => {
    let itemIsSelected: boolean;
    if (
      currentValue === null ||
      (currentValueIsArray && currentValue.length === 0)
    ) {
      itemIsSelected = false;
    } else if (typeof currentValue === "string") {
      // Don't select... we're expecting one or more optionIds, not strings
      itemIsSelected = false;
    } else if (typeof currentValue === "number") {
      // Value is a single number
      itemIsSelected = item.optionId === currentValue;
    } else if (currentValueIsArray) {
      if (question.questionType === "BEHAVIOUR") {
        // Doesn't need to be handled here...multiple attributes complicate things so this method shouldn't be used
        itemIsSelected = false;
      } else {
        // Value could be multiple numbers, e.g. checklist
        itemIsSelected =
          (currentValue as number[]).indexOf(
            parseInt(item.optionId.toString())
          ) >= 0;
      }
    } else {
      // Unexpected
      itemIsSelected = false;
    }

    // Translate the display text, if necessary
    let displayText = item.text;
    if (translateText && displayText && displayText.length > 0) {
      displayText = t(displayText);
    }

    return { ...item, isSelected: itemIsSelected, text: displayText };
  });
  multipleChoiceQuestionOptions = itemsWithValuesSelected;

  return multipleChoiceQuestionOptions;
};

/** Convert the options array to a valid array of QuestionAnswerValues */
export const getAnswersFromMultiChoiceOptions = (
  updatedMultiChoiceOptions: MultipleChoiceOption[],
  question: FormQuestion
) => {
  // Get the selected optionIds
  const selectedOptions = updatedMultiChoiceOptions
    .filter((x) => x.isSelected)
    .map((x) => x.optionId);

  // If the question only allows one option to be selected,
  // only pick the first option in the array to avoid saving
  // multiple answers for a single choice question
  let returnValue: QuestionAnswerValue = selectedOptions;
  if (!question.multipleValuesAllowed()) {
    if (selectedOptions.length === 0) {
      // No value selected
      returnValue = null;
    } else {
      // One or more values selected. Should only ever be one in theory. Return that, rather than an array of values
      returnValue = selectedOptions[0];
    }
  }
  return returnValue;
};

/** Determine whether to show scores or words next to the slider based on whether every item has text to display or not */
export const getSliderScoreDisplayMode = (
  options: MultipleChoiceOption[]
): SliderScoreDisplayType => {
  if (!options || options.length === 0) {
    return "OFF";
  }

  if (options.every((x) => x.text && x.text.length > 0)) {
    return "WORD";
  }

  return "NUMERIC";
};

const multipleChoiceQuestionHelper = {
  /** Get the collection of options with options marked as "selected" if they're in the currentValue (answer) */
  initialiseMultipleChoiceOptions,
  /** Convert the options array to a valid array of QuestionAnswerValues */
  getAnswersFromMultiChoiceOptions,
  /** Determine whether to show scores or words next to the slider based on whether every item has text to display or not */
  getSliderScoreDisplayMode,
};

export default multipleChoiceQuestionHelper;
