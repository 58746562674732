import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle
} from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ModalPopup from "./ModalPopup";
import GenericDropDownList from "./GenericDropDownList";
import Label from "./Label";
import { KeyValuePair } from "../../types/generic";
import JourneyMode from "../../types/generic/JourneyMode";

interface ModeDropDownListProps {
  /** The Id attribute for the input element, to match the Label */
  inputId?: string;
  /** The current value for the input */
  value: string;
  /** The onChange event, for handling state changes */
  onChange(newValue: string): void;
   /** The options to display for the modes dropdown */
   modes: KeyValuePair<JourneyMode, string>[]
}

const ModeDropDownList = ({
  inputId = "",
  value = "",
  onChange,
  modes
}: ModeDropDownListProps) => {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      <Label
        htmlFor={inputId}
        text={t("Pages.Admin.Common.Mode")}
      />
      <span className="pl-1">
        <FontAwesomeIcon onClick={() => setModalIsOpen(true)} icon={faInfoCircle} size="sm" className="text-gray-600" />
      </span>
      <ModalPopup
        isOpen={modalIsOpen}
        onOpenChange={setModalIsOpen}
        onPrimaryButtonClick={() => setModalIsOpen(false)}
        primaryButtonText={t("Common.Close")}
        title={t("Pages.Admin.Tooltip.ModeInformation")}
        showCloseIcon={false}
      >
        <p className="text-sm"><span className="font-bold">{t("Journey.Mode.Automated")}:</span> {t("Pages.Admin.Tooltip.Automated")}</p>
        <p className="text-sm"><span className="font-bold">{t("Journey.Mode.ManualOnly")}:</span> {t("Pages.Admin.Tooltip.ManualOnly")}</p>
        <p className="text-sm"><span className="font-bold">{t("Journey.Mode.HiddenOnDashboard")}:</span> {t("Pages.Admin.Tooltip.HiddenOnDashboard")}</p>
      </ModalPopup>

      <GenericDropDownList
        currentValue={value ? value : null}
        items={modes}
        onChange={onChange}
        className="block w-full"
        inputId={inputId}
        isReadOnly={false}
        includeSelectOption={false}
        applyBorder={true}
      />
    </>
  );
};

export default ModeDropDownList;
