import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes
} from "@fortawesome/pro-solid-svg-icons";
import { t } from "i18next";
import cx from "classnames";
import { useEffect, useState } from "react";
import PeoplePickerUsersApiResponseDataDto from "../../types/dtos/generic/PeoplePickerUsersApiResponseDataDto";
import { ValidationResult } from "../../types/forms";
import AutoCompleteSuggestionList from "./AutoCompleteSuggestionList";
import ValidationWarning from "./ValidationWarning";
import PeoplePickerUserDto from "../../types/dtos/generic/PeoplePickerUserDto";

interface PeoplePickerProps {
  /** The onChange event, for handling state changes */
  onPeoplePickerChange(newValue: number | PeoplePickerUserDto[] | null): void;
  /** The Id attribute for the input element, to match the Label */
  inputId?: string;
  /** The css class names to apply */
  className?: string;
  /** The Placeholder attribute value for the form element */
  placeholder?: string | undefined;
  /** A ref for calling methods on the input element, e.g. to focus it */
  inputRef?: React.RefObject<HTMLInputElement> | undefined;
  /** Bool that allows multiple people to be selected, if false just one person */
  allowMultipleSelection?: boolean;
  /** Only needed for when multiple selections is enabled */
  selectedUsers?: PeoplePickerUserDto[];
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
  isReadOnly?: boolean;
}

const PeoplePicker = ({
  onPeoplePickerChange,
  inputId = "",
  className = "block w-full border-0 border-none",
  placeholder = undefined,
  inputRef = undefined,
  isReadOnly = false,
  allowMultipleSelection = false,
  selectedUsers = [],
  showValidationErrors = false,
  validationResult = null,
}: PeoplePickerProps) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState<
    PeoplePickerUserDto[]
  >([]);
  const [userList, setUserList] = useState<PeoplePickerUserDto[]>([]);
  const [input, setInput] = useState("");

  useEffect(() => {
    // Call the API to load the necessary state
    fetch("/api/get-users-for-people-picker")
      .then((res) => res.json())
      .then((json: PeoplePickerUsersApiResponseDataDto) => {
        setUserList(json.userList);
      })
      .catch((e) => {
        console.error(e.message);
        // TODO: Show some form of error message
      });
  }, []);

  const onChange = (newValue: string) => {
    const userInput = newValue;

    // Filter out options that don't contain the user's input
    // and users that have already been selected
    const matched = userList.filter(
      (user) =>
        user.fullName.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        && selectedUsers.findIndex(x => x.userId == user.userId) === -1
    );

    setInput(newValue);
    setFilteredSuggestions(matched);
  };

  const onSuggestionClick = (newValue: PeoplePickerUserDto) => {
    if (allowMultipleSelection) {
      const isUserAlreadySelected = selectedUsers.findIndex(x => x.userId == newValue.userId) !== -1;

      // By this point it shouldn't allow a duplicate to be selected as it's filtered out,
      // however for another added level... only add if it's not already been selected
      if (!isUserAlreadySelected) {
        onPeoplePickerChange([...selectedUsers, newValue]);
      }

      setFilteredSuggestions([]);
      setInput("");
    } else {
      setFilteredSuggestions([]);
      setInput(newValue.fullName);
      onPeoplePickerChange(newValue.userId);
    }
  };

  const onRemoveFromSelectedUsers = (person: PeoplePickerUserDto) => {
    let newSelectedUserState = [...selectedUsers];
    let index = newSelectedUserState.findIndex(x => x.userId == person.userId);
    newSelectedUserState.splice(index, 1);
    onPeoplePickerChange(newSelectedUserState);
  }

  return (
    <div>
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      <input
        type="text"
        id={inputId}
        onChange={(e) => onChange(e.target.value)}
        value={input ? input : ""}
        className={cx(
          className,
          "disabled:cursor-not-allowed rounded-md focus:outline-none focus:ring-0"
        )}
        placeholder={placeholder}
        ref={inputRef}
        disabled={isReadOnly}
        autoComplete={"off"}
      />
      {input && (
        <AutoCompleteSuggestionList
          onSuggestionClick={onSuggestionClick}
          filteredSuggestions={filteredSuggestions}
        />
      )}
      {allowMultipleSelection && selectedUsers.length > 0 && (
        <div>
          <div className="mt-2">
            <p>{t("Pages.Admin.Common.YouHaveSelectedToSendTo")}</p>
            {selectedUsers.map((user) => {
              return (
                <div key={user.userId} onClick={() => onRemoveFromSelectedUsers(user)} className="rounded-full border bg-gray-50 cursor-pointer inline-block p-1 px-3 ml-2 mt-2">
                  <p className="inline-block">{user.fullName}</p>
                  <FontAwesomeIcon size="sm" icon={faTimes} className="inline-block pl-2"></FontAwesomeIcon>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default PeoplePicker;
