import { useTranslation } from "react-i18next";
import { ValidationError } from "../../types/forms/ValidationResult";
import DangerAlert from "../alerts/DangerAlert";
import validationMessageHelper from "../../helpers/validationMessageHelper";

interface ValidationWarningProps {
  isValid: boolean;
  errors: Array<ValidationError> | null;
}

function ValidationWarning({ isValid, errors }: ValidationWarningProps) {
  const { t } = useTranslation();
  if (isValid) return null;
  const warningPrefix = t("Common.Validation.Oops");

  return (
    <>
      {(!errors || errors.length === 0) && (
        <DangerAlert
          prefix={warningPrefix}
          message={t("Common.Validation.Errors.Unknown")}
        />
      )}
      {errors && errors.length === 1 && (
        <DangerAlert
          prefix={warningPrefix}
          message={validationMessageHelper.getMessageText(errors[0])}
        />
      )}
      {errors && errors.length > 1 && (
        <>
          <DangerAlert
            prefix={warningPrefix}
            message={t("Common.Validation.Errors.Multiple")}
          />
          <ul>
            {errors.map((err, ix) => (
              <li key={`err_${ix}`}>
                {validationMessageHelper.getMessageText(err)}
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  );
}

export default ValidationWarning;
