import AdminConfigApiResponseDto from "../../types/dtos/admin/AdminConfigApiResponseDto";
import { KeyValuePair } from "../../types/generic";
import DurationType from "../../types/generic/DurationType";
import JourneyMode from "../../types/generic/JourneyMode";

const modes: KeyValuePair<JourneyMode, string>[] = [
  {
    key: "AUTOMATED",
    value: "Journey.Mode.Automated"
  },
  {
    key: "MANUAL-ONLY",
    value: "Journey.Mode.ManualOnly"
  },
  {
    key: "HIDDEN-ON-DASHBOARD",
    value: "Journey.Mode.HiddenOnDashboard"
  }
]

const frequencyTypes: KeyValuePair<DurationType, string>[] = [
  {
    key: "DAYS",
    value: "Common.Days"
  },
  {
    key: "WEEKS",
    value: "Common.Weeks"
  },
  {
    key: "MONTHS",
    value: "Common.Months"
  },
  {
    key: "YEARS",
    value: "Common.Years"
  }
]

const appraisalLevels: KeyValuePair<string, string>[] = [
  {
    key: "50",
    value: "Dummy.AppraisalLevel.Leadership"
  },
  {
    key: "51",
    value: "Dummy.AppraisalLevel.Management"
  },
  {
    key: "53",
    value: "Dummy.AppraisalLevel.Team"
  }
]

const MockAdminConfigData = {
  clientFormId: 100,
  clientFormTitle: "Dummy.FormTitle.Behaviours",
  defaultSettings: {
    mode: "AUTOMATED",
    modes: modes,
    intro: "",
    themeColour: "#f0f0f0",
    frequencyTypes: frequencyTypes,
    frequencyValue: 6,
    frequencyType: "MONTHS"
  },
  existingOverrides: [
    {
      overrideId: 32,
      appraisalLevelName: "Dummy.AppraisalLevel.Leadership",
      mode: "HIDDEN-ON-DASHBOARD"
    },
    {
      overrideId: 57,
      appraisalLevelName: "Dummy.AppraisalLevel.Management",
      mode: "AUTOMATED",
      frequencyValue: 6,
      frequencyType: "MONTHS"
    }
  ],
  newOverride: {
    appraisalLevels: appraisalLevels,
    modes: modes,
    frequencyTypes: frequencyTypes
  }
} as AdminConfigApiResponseDto;

export default MockAdminConfigData;
