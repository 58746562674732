import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export interface DropDownNavMenuProps {}

/** A form field containing a dropdownlist (i.e. a `select` element) */
const DropDownNavMenu = ({}: DropDownNavMenuProps) => {
  const { t } = useTranslation();

  const notifications = [
    {
      id: 1,
      notificationType: 6,
      message: "Jack has requested a Coffee Chat",
      date: "A year ago",
      dateRead: null,
      href: "",
    },
    {
      id: 2,
      notificationType: 6,
      message: "Tom has cancelled a goal",
      date: "A week ago",
      dateRead: null,
      href: "",
    },
    {
      id: 3,
      notificationType: 6,
      message: "You have completed a goal",
      date: "A year ago",
      dateRead: null,
      href: "",
    },
  ];

  return (
    <div>
      <Menu as="div" className="relative inline-block text-left z-1">
        <div>
          <Menu.Button className="inline-flex justify-center w-full bg-white text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-full focus:outline-none">
            <FontAwesomeIcon icon={faBell} className="text-gray-500 m-4" />
          </Menu.Button>
          <span className="h-5 w-5 rounded-full bg-green-500"></span>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-50 origin-top-right absolute right-0 mt-2 w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <div className="flex flex-row justify-between py-1 px-4 border-b border-b-gray-200">
                <span className="text-lg font-medium">
                  {t("Notifications.Heading")}
                </span>
                <button className="justify-end text-xs font-normal underline self-center">
                  {t("Notifications.MarkAsRead")}
                </button>
              </div>

              {notifications.map((notification) => (
                <Menu.Item key={notification.id}>
                  <Link
                    to={notification.href}
                    className="block my-1 hover:bg-gray-100 py-2 px-4"
                  >
                    <div className="flex flex-col">
                      <span className="font-medium text-gray-600">
                        {notification.message}{" "}
                      </span>
                      <span className="text-sm text-gray-400">
                        {" "}
                        {notification.date}
                      </span>
                    </div>
                  </Link>
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default DropDownNavMenu;
