const demoUserStateHelper = {
  hasBeenSet: function () {
    return localStorage.getItem("isManager") !== null;
  },
  isManager: function () {
    const rawValue = localStorage.getItem("isManager");
    return rawValue === "1";
  },
  setManagerStatus: function (isManager: boolean) {
    localStorage.setItem("isManager", isManager ? "1" : "0");
  },
};

export default demoUserStateHelper;
