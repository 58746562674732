import TouchNav from "./TouchNav";

function BottomNav() {
  return (
    <div className="block lg:hidden bg-gray-50 fixed bottom-0 left-0 w-screen">
      <TouchNav />
    </div>
  );
}

export default BottomNav;
