import cx from "classnames";
import { ValidationResult } from "../../types/forms";
import ValidationWarning from "./ValidationWarning";

interface TextInputProps {
  /** The onChange event, for handling state changes */
  onChange(newValue: string): void;
  /** The current value for the input */
  value?: string;
  /** The Id attribute for the input element, to match the Label */
  inputId?: string;
  /** The css class names to apply */
  className?: string;
  /** The Placeholder attribute value for the form element */
  placeholder?: string | undefined;
  /** A ref for calling methods on the input element, e.g. to focus it */
  inputRef?: React.RefObject<HTMLInputElement> | undefined;
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
  isReadOnly?: boolean;
  maxLength?: number;
}

/** A text input field, with common styling already applied
 */
const TextInput = ({
  onChange,
  showValidationErrors = false,
  validationResult = null,
  value = "",
  inputId = "",
  className = "block w-full border-0 border-none",
  placeholder = undefined,
  inputRef = undefined,
  isReadOnly = false,
  maxLength = undefined
}: TextInputProps) => {
  return (
    <div>
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      <input
        type="text"
        id={inputId}
        onChange={(e) => onChange(e.target.value)}
        value={value ? value : ""}
        className={cx(
          className,
          "disabled:cursor-not-allowed rounded-md focus:outline-none focus:ring-0"
        )}
        placeholder={placeholder}
        ref={inputRef}
        disabled={isReadOnly}
        maxLength={maxLength}
      />
    </div>
  );
};

export default TextInput;
