import React, { useEffect } from "react";
import { MainContainer } from "../components/layout";
import AppContext from "../state/AppContext";

type RedirectProps = {
  redirectTo: "ADMIN" | "ANALYTICS" | "COMPANY_DASHBOARD" | "HELP";
};

const OgAppRedirect = ({ redirectTo }: RedirectProps) => {
  const appContext = React.useContext(AppContext);

  useEffect(() => {
    const title = "Redirecting to " + redirectTo;
    appContext.setPageTitle(title);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainContainer>
      <em>You'll be redirected shortly...</em>
    </MainContainer>
  );
};

export default OgAppRedirect;
