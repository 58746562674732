import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import produce from "immer";
import { v4 as uuidv4 } from "uuid";
import { CollaborativeDoc, CommentsSideBar } from "../components/collab-docs";
import { MainContainer } from "../components/layout";
import AppContext from "../state/AppContext";
import UserContext from "../state/UserContext";
import { CollabDocStatus, NewCollabDocComment } from "../types/collab-docs";
import {
  CollabDocApiResponseDto,
  CollabDocFormDto,
  CollabDocCommentDto,
} from "../types/dtos/collab-docs";
import { AlertPopup, ModalPopup } from "../components/common";
import {
  QuestionAnswer,
  QuestionAnswerValue,
  QuestionNewTasks,
} from "../types/forms";
import { dateHelper, styleHelper } from "../helpers";
import AppRoutes from "./AppRoutes";
import { AlertContent } from "../types/generic";
import { BaseUserDetailsDto, UserBasicDetailsDto } from "../types/dtos/generic";
import SuccessIcon from "../components/common/SuccessIcon";

interface CollaborativeDocumentPageProps {
  postJourneyModalEnabled?: boolean;
}

function CollaborativeDocumentPage({
  postJourneyModalEnabled = false,
}: CollaborativeDocumentPageProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { answerSetUniqueId } = useParams();
  const appContext = React.useContext(AppContext);
  const userContext = React.useContext(UserContext);

  // State - initial setup
  const [dateLoadedUtc, setDateLoadedUtc] = useState<Date>(
    dateHelper.getCurrentDateUtc()
  );
  const [showLoadingError, setShowLoadingError] = useState<boolean>(false);
  const [showSaveSuccessfulAlert, setShowSaveSuccessfulAlert] =
    useState<boolean>(false);
  const [saveAlertContent, setSaveAlertContent] = useState<AlertContent>({
    title: "",
    body: "",
  });
  // Whether or not the form has been edited since it was loaded
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [waitingForApiResult, setWaitingForApiResult] = useState<boolean>(true);
  const [docActiveQuestionId, setDocActiveQuestionId] = useState<string | null>(
    null
  );
  const [docSubjectUser, setDocSubjectUser] =
    useState<BaseUserDetailsDto | null>(null);
  const [docStatus, setDocStatus] = useState<CollabDocStatus | null>(null);
  const [docIsReadOnly, setDocIsReadOnly] = useState<boolean>(true);
  const [docLastUpdatedDate, setDocLastUpdatedDate] = useState<Date | null>(
    null
  );
  const [docLastUpdatedByUserId, setDocLastUpdatedByUserId] = useState<
    number | null
  >(null);
  const [docParticipants, setDocParticipants] = useState<UserBasicDetailsDto[]>(
    []
  );
  const [docForms, setDocForms] = useState<CollabDocFormDto[]>([]);
  const [docComments, setDocComments] = useState<CollabDocCommentDto[]>([]);
  const [docBackgroundColour, setDocBackgroundColour] = useState<string>(
    styleHelper.defaultCollabDocHeaderBackgroundColour
  );

  // State - interactive stuff
  const [showPostJourneyModal, setShowPostJourneyModal] =
    useState<boolean>(false);
  const [visibleComments, setVisibleComments] = useState<
    CollabDocCommentDto[] | null
  >(null);
  const [showDeleteCommentModal, setShowDeleteCommentModal] =
    useState<boolean>(false);
  const [commentToDelete, setCommentToDelete] = useState<string | null>(null);
  const [answerState, setAnswerState] = useState<QuestionAnswer[]>([]);
  // The state variable for tasks added in this document, but not yet pushed to the user's dashboard
  const [newTasks, setNewTasks] = useState<QuestionNewTasks[]>([]);

  /** Select either "My Dashboard" or "My People"  on the left nav
   * depending on whoever this collab doc is for
   */
  const setOverrideActiveNavItem = (subjectUser: BaseUserDetailsDto | null) => {
    if (subjectUser) {
      if (subjectUser.userId === userContext.id) {
        appContext.setOverrideNavItem("MyDashboard");
      } else {
        appContext.setOverrideNavItem("ManagerDashboard");
      }
    } else {
      appContext.setOverrideNavItem(null);
    }
  };

  // Initial load of data from the API
  const loadFormData = () => {
    setWaitingForApiResult(true);
    setShowLoadingError(false);

    fetch(`/api/collab-doc/${answerSetUniqueId}`)
      .then((res) => res.json())
      .then((json: CollabDocApiResponseDto) => {
        setDocSubjectUser(json.subject);
        setDocStatus(json.approvalStatus);
        setDocBackgroundColour(json.backgroundColour);
        setDocLastUpdatedDate(json.lastUpdated);
        setDocIsReadOnly(json.isReadOnly);
        setDocLastUpdatedByUserId(json.lastUpdatedByUserId);
        setDocParticipants(json.participants);
        setDocForms(json.forms);
        setAnswerState(json.answers);
        setDocComments(json.comments);
        setPageTitle(json.subject.userId, json.titleSuffix);
        setWaitingForApiResult(false);
        setDateLoadedUtc(dateHelper.getCurrentDateUtc());
        setOverrideActiveNavItem(json.subject);
      })
      .catch((e) => {
        setShowLoadingError(true);
        console.error("Collab doc load error", e);
      });
  };

  // Page load, load the form data
  useEffect(() => {
    // Call the API to load the necessary state
    loadFormData();

    // Show the post journey modal on page load, if specified in the path
    setShowPostJourneyModal(postJourneyModalEnabled);

    return () => {
      setOverrideActiveNavItem(null);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setPageTitle = (
    subjectUserId: number,
    titleSuffix: string | undefined
  ) => {
    // Non-state configuration
    const loggedInUserIsFormSubjectUser = userContext.id === subjectUserId;
    const pageTitlePrefix = loggedInUserIsFormSubjectUser
      ? "Pages.CollaborativeDocument.PageTitle.Employee"
      : "Pages.CollaborativeDocument.PageTitle.Manager";
    const pageTitle = `${t(pageTitlePrefix)}${titleSuffix || ""}`;

    // Set the main page title
    appContext.setPageTitle(pageTitle);
  };

  // Functions/methods

  const filterVisibleComments = (questionId: string) => {
    const filteredComments = docComments.filter(
      (x) => x.questionId === questionId
    );
    setVisibleComments(filteredComments);
  };

  // Update the visible comments when the comments collection changes
  // (to display new comments/deleted comments)
  useEffect(() => {
    if (docActiveQuestionId) {
      filterVisibleComments(docActiveQuestionId);
    }
  }, [docComments]);

  // Event handlers

  const onActiveQuestionChange = (questionId: string) => {
    setDocActiveQuestionId(questionId);
    filterVisibleComments(questionId);
  };

  /** Update the answer state for the matching question with the given value */
  const onValueChange = (questionId: string, newValue: QuestionAnswerValue) => {
    if (!questionId) return;

    // Update the existing state answer if there is one, otherwise add it to the state
    // if it's a new answer
    const nextState = produce(answerState, (draft) => {
      const match = draft.find((x) => x.questionId === questionId);
      const answerTimestamp = dateHelper.getCurrentDateUtc();
      if (match !== undefined) {
        match.id = null; // TODO: Is this the right thing to do? Or should we remove the answer, and add a new one instead?
        match.answer = newValue;
        match.timestamp = answerTimestamp;
        match.userId = userContext.id;
      } else {
        draft.push({
          id: null,
          questionId: questionId,
          answer: newValue,
          timestamp: answerTimestamp,
          userId: userContext.id,
        });
      }
    });
    setAnswerState(nextState);
    setFormIsDirty(true);
  };

  const onCommentsSeen = (questionId: string) => {
    // TODO: API call

    const nextState = produce(docComments, (draft) => {
      const matches = draft.filter((x) => x.questionId === questionId);
      matches.forEach((m) => (m.seen = true));
    });

    setDocComments(nextState);
  };

  const onCommentAdd = (newComment: NewCollabDocComment) => {
    // TODO: API call
    const nextState = [...docComments];
    const newDto: CollabDocCommentDto = {
      authorId: newComment.authorId,
      commentText: newComment.commentText,
      id: uuidv4(), // Mock ID for the sake of the dummy app - need to get this from the server on comment insert
      questionId: newComment.questionId,
      replyToCommentId: newComment.replyToCommentId,
      seen: true,
      timestamp: newComment.timestamp,
    };
    nextState.push(newDto);
    setDocComments(nextState);
    setFormIsDirty(true);
  };

  const onCommentDelete = (commentId: string) => {
    // TODO: API call
    // On the API side, ensure it's the logged in user's comment before allowing the delete
    setShowDeleteCommentModal(true);
    setCommentToDelete(commentId);
  };

  const onCancelCommentDelete = () => {
    setCommentToDelete(null);
    setShowDeleteCommentModal(false);
  };

  const onConfirmCommentDelete = () => {
    // Delete the comment
    const nextState = docComments.filter((x) => x.id !== commentToDelete);
    setDocComments(nextState);

    // Close the modal and reset the commentId to delete
    setCommentToDelete(null);
    setShowDeleteCommentModal(false);
    setFormIsDirty(true);
  };

  /** When a task is added/edited/deleted */
  const onChangeQuestionNewTasks = (questionTasks: QuestionNewTasks) => {
    const nextState = produce(newTasks, (draft) => {
      const match = draft.find(
        (x) => x.questionId === questionTasks.questionId
      );
      if (match !== undefined) {
        match.tasks = questionTasks.tasks;
      } else {
        draft.push(questionTasks);
      }
    });
    setNewTasks(nextState);
    setFormIsDirty(true);
  };

  /** When the user chooses to revert the form to how it was when they loaded it */
  const onResetForm = () => {
    // Reload the form data
    loadFormData();
    // Clear the answers
    setAnswerState([]);
    // Clear any new tasks
    setNewTasks([]);
    // Clear the selected question (in case it was a conditional question which is no longer visible)
    setDocActiveQuestionId(null);
    // Reset the flag
    setFormIsDirty(false);
  };

  const onSubmitDocument = (proposedStatus: CollabDocStatus) => {
    // Validation is handled in the `CollabDoc` component, so this is just handling the actual save
    setSaveAlertContent({
      title: "Save successful",
      body: "The collaborative document has been saved",
    });
    setShowSaveSuccessfulAlert(true);
  };

  // If no AnswerSetUniqueId is supplied, or we are missing key data, don't render anything (yet)
  if (!answerSetUniqueId || docStatus === null || docSubjectUser === null) {
    return null;
  }

  // Right hand column comments control
  const commentsControl = (
    <CommentsSideBar
      activeQuestionId={docActiveQuestionId}
      participants={docParticipants}
      comments={visibleComments}
      onCommentAdd={onCommentAdd}
      onCommentDelete={onCommentDelete}
      onCommentsSeen={onCommentsSeen}
      isReadOnly={docIsReadOnly}
    />
  );

  return (
    <MainContainer
      rightColumnChildren={commentsControl}
      applySidePadding={false}
    >
      {!showLoadingError && (
        <CollaborativeDoc
          isLoading={waitingForApiResult}
          answerSetUniqueId={answerSetUniqueId}
          backgroundColour={docBackgroundColour}
          approvalStatus={docStatus}
          lastUpdated={docLastUpdatedDate}
          loggedInUserId={userContext.id}
          subjectUser={docSubjectUser}
          lastUpdatedByUserId={docLastUpdatedByUserId}
          isReadOnly={docIsReadOnly}
          formIsDirty={formIsDirty}
          comments={docComments}
          participants={docParticipants}
          forms={docForms}
          answers={answerState}
          activeQuestionId={docActiveQuestionId}
          dateLoaded={dateLoadedUtc}
          onValueChange={onValueChange}
          onChangeActiveQuestion={onActiveQuestionChange}
          onCommentAdd={onCommentAdd}
          onCommentDelete={onCommentDelete}
          onCommentsSeen={onCommentsSeen}
          onReloadFormContent={onResetForm}
          onSubmitDocument={onSubmitDocument}
          newTasks={newTasks}
          onChangeQuestionNewTasks={onChangeQuestionNewTasks}
        />
      )}
      {/* Post journey modal */}
      <ModalPopup
        isOpen={showPostJourneyModal}
        onOpenChange={setShowPostJourneyModal}
        onPrimaryButtonClick={() => setShowPostJourneyModal(false)}
        primaryButtonText={t("Common.Ok")}
        title={null}
        showCloseIcon={false}
      >
        <div className="text-center py-4 mt-2">
          <SuccessIcon darkMode />
        </div>
        <div className="text-center mt-2">
          <h2 className="text-xl font-semibold mb-2">
            {t("Pages.CollaborativeDocument.Controls.PostJourneyModalHeading")}
          </h2>
          <p>
            {t("Pages.CollaborativeDocument.Controls.PostJourneyModalBody")}
          </p>
        </div>
      </ModalPopup>
      {/* Save successful modal */}
      <AlertPopup
        isOpen={showSaveSuccessfulAlert}
        onOpenChange={setShowSaveSuccessfulAlert}
        onPrimaryButtonClick={() => navigate(AppRoutes.home)}
        primaryButtonText={t("Common.GoBackHome")}
        bodyText={saveAlertContent.body}
        title={saveAlertContent.title}
      />
      {/* Comment delete modal */}
      <AlertPopup
        isOpen={showDeleteCommentModal}
        onOpenChange={setShowDeleteCommentModal}
        onPrimaryButtonClick={onConfirmCommentDelete}
        onSecondaryButtonClick={onCancelCommentDelete}
        primaryButtonText={t("Common.ConfirmDelete")}
        secondaryButtonText={t("Common.CancelAction")}
        bodyText={t(
          "Pages.CollaborativeDocument.Controls.CommentDeleteBodyText"
        )}
        title={t("Pages.CollaborativeDocument.Controls.CommentDeleteHeading")}
      />
      {/* Error modals */}
      <AlertPopup
        isOpen={showLoadingError}
        onOpenChange={setShowLoadingError}
        onPrimaryButtonClick={loadFormData}
        onSecondaryButtonClick={() => navigate(AppRoutes.home)}
        primaryButtonText={t("Common.TryAgain")}
        secondaryButtonText={t("Common.GoBackHome")}
        bodyText={t("Errors.500.Body")}
        title={t("Errors.500.Title")}
      />
    </MainContainer>
  );
}

export default CollaborativeDocumentPage;
