import { useState } from "react";
import {
  GoalReviewQuestionAnswerValue,
  ValidationResult,
} from "../../../../types/forms";
import { KeyValuePair } from "../../../../types/generic";
import { EditableGoal } from "../../../../types/tasks/EditableTasks";
import { GoalTask } from "../../../../types/tasks/Task";
import { ManageTaskPopup } from "../../../tasks";
import TaskTypeIcon from "../../../tasks/TaskTypeIcon";
import GoalReviewStatusDropDown from "./GoalReviewStatusDropDown";

interface GoalReviewStatusListProps {
  goals: GoalTask[];
  currentValues: GoalReviewQuestionAnswerValue[] | null;
  goalStatusOptions: KeyValuePair<number, string>[];
  isReadOnly: boolean;
  formColor: string;
  onGoalStatusChange(toDoId: number, selectedOptionId: number | null): void;
  /** Whether or not to display the validation warnings */
  showValidationErrors: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
}

/** A list of goals, with a dropdown for setting the status against each goal */
function GoalReviewStatusList({
  goals,
  currentValues,
  goalStatusOptions,
  isReadOnly,
  formColor,
  onGoalStatusChange,
}: GoalReviewStatusListProps) {
  // State
  const [showGoalModal, setShowGoalModal] = useState<boolean>(false);
  const [goalToShowInModal, setGoalToShowInModal] =
    useState<EditableGoal | null>();

  // Events
  const onShowGoalDetails = (goal: GoalTask) => {
    // Convert to an EditableGoal as that's what the modal needs as a prop
    const goalToDisplay = new EditableGoal(
      "ORIGINAL",
      goal.toDoId,
      goal.title,
      goal.targetDate,
      goal.field1,
      goal.field2,
      goal.categoryId
    );
    setGoalToShowInModal(goalToDisplay);
    setShowGoalModal(true);
  };

  const onGoalModalChange = (show: boolean) => {
    if (!show) {
      setGoalToShowInModal(null);
    }
    setShowGoalModal(show);
  };

  const getSelectedOptionId = (goal: GoalTask): number | null => {
    if (currentValues && currentValues.length > 0) {
      const match = currentValues.find((x) => x.toDoId === goal.toDoId);
      if (match) {
        return match.goalStatusOptionId;
      }
    }
    return null;
  };

  const taskTypeIcon = (
    <TaskTypeIcon taskType="GOAL" colourClassName="text-white" iconSize="sm" />
  );

  return (
    <div>
      {goals.map((goal) => {
        // Get the selected option for this goal
        const selectedStatusOptionId = getSelectedOptionId(goal);

        // In case we're ever somehow passed a goal to review which isn't stored in the database, don't render it
        if (!goal.toDoId) {
          return null;
        }

        return (
          <div
            className="px-2 py-1 bg-white/40 text-white rounded-md my-1  lg:flex lg:flex-row"
            key={`goal_${goal.toDoId}`}
          >
            {/* Small screen */}
            <div className="lg:hidden truncate">
              {taskTypeIcon}{" "}
              <button
                onClick={() => onShowGoalDetails(goal)}
                className="truncate"
              >
                {goal.title}
              </button>
            </div>
            <div className="lg:hidden">
              <GoalReviewStatusDropDown
                toDoId={goal.toDoId}
                currentValue={selectedStatusOptionId}
                isReadOnly={isReadOnly}
                dropDownItems={goalStatusOptions}
                onChange={onGoalStatusChange}
                formColor={formColor}
              />
            </div>
            {/* Large screen */}
            <div className="hidden lg:flex flex-initial mr-2 leading-loose">
              {taskTypeIcon}
            </div>
            <div className="hidden lg:flex flex-grow mr-2 leading-loose truncate">
              <button
                onClick={() => onShowGoalDetails(goal)}
                className="truncate"
              >
                {goal.title}
              </button>
            </div>
            <div className="hidden lg:flex flex-initial">
              <GoalReviewStatusDropDown
                toDoId={goal.toDoId}
                currentValue={selectedStatusOptionId}
                isReadOnly={isReadOnly}
                dropDownItems={goalStatusOptions}
                formColor={formColor}
                onChange={onGoalStatusChange}
              />
            </div>
          </div>
        );
      })}
      <ManageTaskPopup
        mode={"EDIT"}
        isOpen={showGoalModal}
        onOpenChange={onGoalModalChange}
        editObject={goalToShowInModal}
        onSaveClick={() => undefined}
        isReadOnly={true}
        taskType={"GOAL"}
      />
    </div>
  );
}

export default GoalReviewStatusList;
