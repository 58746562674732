import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import EmojiPicker, { IEmojiData } from "emoji-picker-react";
import { dateHelper } from "../../helpers";
import UserContext from "../../state/UserContext";
import { NewCollabDocComment } from "../../types/collab-docs";
import { Label, Popover, TextArea } from "../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmilePlus } from "@fortawesome/pro-regular-svg-icons";

interface NewCommentFormProps {
  questionId: string;
  replyToCommentId: string | null;
  containerClassNames?: string;
  onSubmit(newComment: NewCollabDocComment): void;
  /** A ref for calling methods on the textarea element, e.g. to focus it */
  inputRef?: React.RefObject<HTMLTextAreaElement> | undefined;
}

export const newCommentFieldElementId = "new-comment-input";

function NewCommentForm({
  questionId,
  replyToCommentId,
  onSubmit,
  containerClassNames = "",
  inputRef = undefined,
}: NewCommentFormProps) {
  const { t } = useTranslation();
  const userContext = React.useContext(UserContext);

  // State
  const [textValue, setTextValue] = useState<string>("");

  // Events
  const handleSubmitPress = () => {
    const trimmedComment = textValue?.trim();
    if (trimmedComment && trimmedComment.length > 0) {
      // Construct the comment object
      const newComment: NewCollabDocComment = {
        authorId: userContext.id,
        commentText: trimmedComment,
        questionId: questionId,
        timestamp: dateHelper.getCurrentDateUtc(),
        replyToCommentId: replyToCommentId,
      };

      // Submit the comment and update the list of comments
      onSubmit(newComment);

      // Reset the form
      setTextValue("");
    }
  };

  /** Clear the textarea */
  const handleResetPress = () => {
    setTextValue("");
  };

  /** Handle when the user selects an emoji to add to their response */
  const addEmojiToTextArea = (
    event: React.MouseEvent<Element, MouseEvent>,
    emoji: IEmojiData
  ) => {
    // Get the emoji as a string to add to the value
    let sym = emoji.unified.split("-");
    let codesArray: number[] = [];
    sym.forEach((el) => codesArray.push(Number("0x" + el)));
    let emojiString = String.fromCodePoint(...codesArray);

    // Insert the emoji at the carat
    if (inputRef?.current) {
      const selectionStart = inputRef.current.selectionStart;
      const newTextValue =
        textValue.slice(0, selectionStart) +
        emojiString +
        textValue.slice(selectionStart);
      setTextValue(newTextValue);
    }
  };

  const disableSubmitButton = !textValue || textValue.trim().length === 0;
  const showCancelButton = textValue && textValue.length > 0;

  return (
    <div className={containerClassNames}>
      <Label
        className="hidden"
        htmlFor={newCommentFieldElementId}
        text={t("Pages.CollaborativeDocument.Controls.NewCommentLabel")}
      />
      <TextArea
        inputId={newCommentFieldElementId}
        placeholder={t(
          "Pages.CollaborativeDocument.Controls.NewCommentPlaceholder"
        )}
        value={textValue}
        onChange={setTextValue}
        className="block w-full border-0 rounded-0 border-b border-gray-300 bg-transparent"
        minRows={1}
        inputRef={inputRef}
        showValidationErrors={false}
      />
      <div className="mt-2">
        <div className="flex flex-row">
          <div className="flex-initial w-14">
            <Popover
              placement="left"
              contentClassNames="w-fit bg-white"
              showCloseIcon={false}
              triggerElement={
                <button className="hidden md:inline-block">
                  <FontAwesomeIcon
                    icon={faSmilePlus}
                    size="lg"
                    title={t(
                      "Pages.CollaborativeDocument.Common.AddEmojiTooltip"
                    )}
                    className="self-center text-gray-600"
                  />
                </button>
              }
            >
              <EmojiPicker onEmojiClick={addEmojiToTextArea} />
            </Popover>
          </div>
          <div className="grow text-right">
            {showCancelButton && (
              <button onClick={handleResetPress} className="btn-secondary mr-1">
                {t("Common.Cancel")}
              </button>
            )}
            <button
              onClick={handleSubmitPress}
              className="btn-primary"
              disabled={disableSubmitButton}
            >
              {t("Pages.CollaborativeDocument.Common.Reply")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewCommentForm;
