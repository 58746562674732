import {
  BehaviourOptionsDto,
  DevelopmentOptionsDto,
  FormQuestionDto,
  FormQuestionSequenceMapDto,
  GoalReviewOptionsDto,
} from "../dtos/forms";
import {
  QuestionType,
  MultipleChoiceOption,
  QuestionAnswerValue,
  ActorQuestionText,
  ActorRestriction,
} from ".";
import ValidationResult, { ValidationError } from "./ValidationResult";
import ValidationSettings from "./ValidationSettings";
import BehaviourQuestionAnswerValue from "./BehaviourQuestionAnswerValue";
import DevelopmentQuestionAnswerValue from "./DevelopmentQuestionAnswerValue";
import { EditableTask } from "../tasks/EditableTasks";
import GoalReviewQuestionAnswerValue from "./GoalReviewQuestionAnswerValue";

export class FormQuestion implements FormQuestionDto {
  constructor(dto: FormQuestionDto) {
    /** This is a string rather than numeric to facilitate advanced questions
     * like behvaiours and goals having an id like "GOAL_123", so they don't have
     * to have a numeric QuestionId in the FormQuestions TT db table  */
    this.questionId = dto.questionId;

    this.questionText = dto.questionText;
    this.questionType = dto.questionType;
    this.commentsEnabled = dto.commentsEnabled;
    this.sequenceMap = dto.sequenceMap;
    this.multiChoiceOptions = dto.multiChoiceOptions;
    this.behaviourOptions = dto.behaviourOptions;
    this.developmentOptions = dto.developmentOptions;
    this.goalReviewOptions = dto.goalReviewOptions;
    this.validation = dto.validation;
    this.actorRestriction = dto.actorRestriction;
  }

  questionId: string;
  questionText: ActorQuestionText;
  questionType: QuestionType;
  commentsEnabled: boolean;
  sequenceMap: FormQuestionSequenceMapDto;
  multiChoiceOptions?: MultipleChoiceOption[] | null | undefined;
  behaviourOptions?: BehaviourOptionsDto | null | undefined;
  developmentOptions?: DevelopmentOptionsDto | null | undefined;
  goalReviewOptions?: GoalReviewOptionsDto | null | undefined;
  validation: ValidationSettings;
  actorRestriction: ActorRestriction;

  /** Is this a question which we expect to have the `multiChoiceOptions` property filled with a value */
  isMultiChoiceQuestion(): boolean {
    return (
      this.questionType === "RADIOLIST" ||
      this.questionType === "CHECKLIST" ||
      this.questionType === "DROPDOWNLIST" ||
      this.questionType === "SLIDER"
    );
  }

  /** Whether or not the selected value should be a single item, or an array */
  multipleValuesAllowed(): boolean {
    return this.questionType === "CHECKLIST";
  }

  /** Whether or not the next question to be displayed after this one, depends on the answer given to this question */
  nextQuestionIsConditional(): boolean {
    return (
      this.sequenceMap.options !== undefined &&
      this.sequenceMap.options !== null &&
      this.sequenceMap.options.length > 0
    );
  }

  /** Whether or not this is the last question in the form. Used to calculate whether or not to display the Submit button in journeys */
  isLastQuestionInForm(): boolean {
    return (
      !this.sequenceMap.defaultNextQuestionId &&
      (!this.sequenceMap.options || this.sequenceMap.options.length === 0)
    );
  }

  /** Return true if the user is allowed to edit this question, based on the actor restrictions */
  userCanAnswer(loggedInUserId: number, formSubjectUserId: number): boolean {
    if (!this.actorRestriction) return true;
    if (
      this.actorRestriction === "EMPLOYEE" &&
      loggedInUserId === formSubjectUserId
    )
      return true;
    if (
      this.actorRestriction === "MANAGER" &&
      loggedInUserId !== formSubjectUserId
    )
      return true;
    return false;
  }

  /** Whether or not this question passes the validation checks defined in the ValidationSettings */
  validate(
    answer: QuestionAnswerValue | null,
    tasks: EditableTask[] | null,
    loggedInUserId: number,
    formSubjectUserId: number
  ): ValidationResult {
    if (
      !this.validation.required ||
      !this.userCanAnswer(loggedInUserId, formSubjectUserId)
    ) {
      return new ValidationResult(true);
    }

    // Bespoke validation for behaviour question type
    if (this.questionType === "BEHAVIOUR") {
      return this.validateBehaviourQuestion(answer);
    }

    // Bespoke validation for behaviour question type
    if (this.questionType === "LEARNING-AND-DEVELOPMENT") {
      return this.validateDevelopmentQuestion(answer);
    }

    // Bespoke validation for goal setting question type
    if (this.questionType === "GOAL-SETTING") {
      return this.validateGoalSettingQuestion(tasks);
    }

    // Bespoke validation for goal review question type
    if (this.questionType === "GOAL-REVIEW") {
      return this.validateGoalReviewQuestion(
        answer,
        loggedInUserId,
        formSubjectUserId
      );
    }

    // If not multiple choice, as long as there's an answer, pass validation
    let isValid = false;
    if (!this.multipleValuesAllowed()) {
      isValid = answer !== null;
      return new ValidationResult(isValid, [{ errorType: "REQUIRED" }]);
    }

    // Check the min/max validations pass
    const answerCount = Array.isArray(answer) ? (answer as number[]).length : 0;
    if (this.validation.min !== null && this.validation.max !== null) {
      isValid =
        answerCount >= this.validation.min &&
        answerCount <= this.validation.max;
      return new ValidationResult(isValid, [
        {
          errorType: "MIN-AND-MAX",
          min: this.validation.min,
          max: this.validation.max,
        },
      ]);
    } else if (this.validation.min !== null) {
      isValid = answerCount >= this.validation.min;
      return new ValidationResult(isValid, [
        { errorType: "MIN", min: this.validation.min },
      ]);
    } else if (this.validation.max !== null) {
      isValid = answerCount <= this.validation.max;
      return new ValidationResult(isValid, [
        { errorType: "MAX", max: this.validation.max },
      ]);
    }

    // Shouldn't get here in theory
    return new ValidationResult(false);
  }

  private validateBehaviourQuestion = (
    answer: QuestionAnswerValue | null
  ): ValidationResult => {
    // Check an answer exists, which should be an array
    if (answer === null || !Array.isArray(answer) || !this.behaviourOptions) {
      return new ValidationResult(false, [{ errorType: "REQUIRED" }]);
    }

    // Convert the type to the expected array type
    var behaviourAnswers = answer as BehaviourQuestionAnswerValue[];

    const attributeCount = this.behaviourOptions.attributes.length;
    const answerCount = behaviourAnswers.filter(
      (x) => x.selectedScaleValue !== null
    ).length;

    let isValid;
    if (attributeCount === 0) {
      // Single attribute, expect one answer
      isValid = answerCount === 1;
    } else {
      isValid = answerCount === attributeCount;
    }

    return new ValidationResult(isValid, [{ errorType: "REQUIRED" }]);
  };

  private validateDevelopmentQuestion = (
    answer: QuestionAnswerValue | null
  ): ValidationResult => {
    // Check an answer exists, which should be an array
    if (answer === null || !Array.isArray(answer) || !this.developmentOptions) {
      return new ValidationResult(false, [{ errorType: "REQUIRED" }]);
    }

    // Convert the type to the expected array type
    var developmentAnswers = answer as DevelopmentQuestionAnswerValue[];

    // If the question is mandatory, ensure at least one item has been selected
    const selectedItemCount = developmentAnswers
      ? developmentAnswers.length
      : 0;
    if (this.validation.required && selectedItemCount === 0) {
      return new ValidationResult(false, [{ errorType: "REQUIRED" }]);
    }

    // If we get here, it's valid
    return new ValidationResult(true, []);
  };

  private validateGoalSettingQuestion = (
    tasks: EditableTask[] | null
  ): ValidationResult => {
    const taskCount = tasks ? tasks.length : 0;

    let isValid = true;
    let validationErrors: ValidationError[] = [];

    if (this.validation.min && this.validation.max) {
      if (taskCount < this.validation.min || taskCount > this.validation.max) {
        isValid = false;
        validationErrors.push({ errorType: "MIN-AND-MAX" });
      }
    } else if (this.validation.min && taskCount < this.validation.min) {
      isValid = false;
      validationErrors.push({ errorType: "MIN" });
    } else if (this.validation.max && taskCount > this.validation.max) {
      isValid = false;
      validationErrors.push({ errorType: "MAX" });
    } else if (this.validation.required && taskCount === 0) {
      isValid = false;
      validationErrors.push({ errorType: "REQUIRED" });
    }

    return new ValidationResult(isValid, validationErrors);
  };

  private validateGoalReviewQuestion = (
    answer: QuestionAnswerValue | null,
    loggedInUserId: number,
    formSubjectUserId: number
  ): ValidationResult => {
    // Check the necessary config exists, if not, fail validation as the form setup is invalid anyway
    if (!this.goalReviewOptions) {
      return new ValidationResult(false, [{ errorType: "REQUIRED" }]);
    }

    // If there aren't any goals to review, it's valid
    if (
      this.goalReviewOptions.goals === null ||
      this.goalReviewOptions.goals.length === 0
    ) {
      return new ValidationResult(true, []);
    }

    let validationErrors: Array<ValidationError> = [];
    const goalCount = this.goalReviewOptions.goals.length;

    // Convert the type to the expected array type
    var goalAnswers =
      answer && Array.isArray(answer)
        ? (answer as GoalReviewQuestionAnswerValue[])
        : null;

    // Status is always mandatory, so check that each goal question has a status set
    const answerCountWithStatusSelected = goalAnswers
      ? goalAnswers.filter((x) => x.goalStatusOptionId !== null).length
      : 0;

    if (goalCount > answerCountWithStatusSelected) {
      validationErrors.push({ errorType: "REQUIRED" });
    }

    // Next: Validate of other basic sub-questions in goal review
    // If there are sub-questions, we only expect one goal in the collection of goals
    // because that's how collab docs handle them - one goal review question per goal
    // (which is different to journeys, which have status setting for multiple goals as one question)
    if (
      this.goalReviewOptions.additionalQuestions &&
      this.goalReviewOptions.additionalQuestions.length > 0 &&
      this.goalReviewOptions.goals.length === 1
    ) {
      const overallQuestionAnswer =
        goalAnswers && goalAnswers.length === 1 ? goalAnswers[0] : null;

      // Validate each sub question
      this.goalReviewOptions!.additionalQuestions!.forEach((subQ) => {
        const subQuestion = new FormQuestion(subQ);

        // Get the answer to validate against
        const subQuestionAnswer =
          overallQuestionAnswer && overallQuestionAnswer.otherAnswers
            ? overallQuestionAnswer.otherAnswers.find(
                (x) => x.questionId === subQuestion.questionId
              )
            : undefined;
        const subQuestionAnswerValue = subQuestionAnswer
          ? subQuestionAnswer.answer
          : null;

        // Validate the sub question
        const subQuestionValidationResult = subQuestion.validate(
          subQuestionAnswerValue,
          null,
          loggedInUserId,
          formSubjectUserId
        );

        // If the sub question isn't valid, add the errors to the output
        if (
          !subQuestionValidationResult.isValid &&
          subQuestionValidationResult.errors.length > 0
        ) {
          // Populate the `relatesTo` field to tie them to this sub-question
          const relatedErrors = subQuestionValidationResult.errors.map(
            (err) => {
              return { ...err, relatesTo: subQuestion.questionId };
            }
          );
          validationErrors = validationErrors.concat(relatedErrors);
        }
      });
    }

    // If there are any sub-question validation fails, return an overall fail for the validation
    if (validationErrors.length > 0) {
      return new ValidationResult(false, validationErrors);
    }

    // If we get here, it's valid
    return new ValidationResult(true, []);
  };
}

export default FormQuestion;
