import { useEffect, useState } from "react";
import { t } from "i18next";
import cx from "classnames";
import produce from "immer";
import { ManageTaskPopup } from "../../tasks";
import { EditableTask } from "../../../types/tasks/EditableTasks";
import { TaskPopupMode } from "../../tasks/ManageTaskPopup";
import TasksList from "../../tasks/TasksList";
import { AlertPopup } from "../../common";
import { QuestionNewTasks } from "../../../types/forms";
import { TaskType } from "../../../types/tasks";
import { taskTypeHelper } from "../../../helpers";

interface AddTaskFormDefaultValues {
  title: string;
  taskType: TaskType;
}

interface AddTaskControlProps {
  /** The question that the tasks are being added against */
  questionId: string;
  /** The tasks to manage */
  questionTasks: QuestionNewTasks | null;
  /** Whether or not tasks can be added/edit/deleted, or just viewed */
  isReadOnly: boolean;
  classNames?: string | null;
  /** If the add task button text needs to be overridden to, say, "Add Goal", use this prop */
  buttonTextOverride?: string | null;
  /** The function to call when a change is made (add/edit/delete a task) */
  onChange(newState: QuestionNewTasks): void;
  /** If the modal is triggered by a different component, you can hide the "Add Task" button here if you want */
  mode?: "WITH-BUTTON" | "EXTERNALLY-TRIGGERED";
  /** Optionally specify some default values for the add task popup (type and title) */
  newTaskModalDefaultValues?: AddTaskFormDefaultValues | null;
  /** If mode="EXTERNALLY-TRIGGERED", this is the state value for whether or not the add task modal should display */
  modalDisplayedFromExternalTrigger?: boolean;
  /** If mode="EXTERNALLY-TRIGGERED", this is the callback function which fires when the modal closes, so the modal open state in the parent component can be updated */
  onModalClosedCallback?(): void | undefined;
  /** Optionally restrict so the user can only add a certain type of task */
  restrictToTaskType?: TaskType | undefined;
  /** Optionally disable the button, e.g. when a max number of tasks have been added */
  disableAddTaskButton?: boolean;
}

function AddTaskControl({
  questionId,
  questionTasks,
  isReadOnly,
  classNames = "",
  mode = "WITH-BUTTON",
  buttonTextOverride,
  onChange,
  newTaskModalDefaultValues = null,
  modalDisplayedFromExternalTrigger = false,
  onModalClosedCallback = undefined,
  restrictToTaskType = undefined,
  disableAddTaskButton = false,
}: AddTaskControlProps) {
  const [popupMode, setPopupMode] = useState<TaskPopupMode>("ADD");
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [taskToEdit, setTaskToEdit] = useState<EditableTask | null>(null);
  const [taskIndexToEdit, setTaskIndexToEdit] = useState<number | null>(null);
  const [taskIndexToDelete, setTaskIndexToDelete] = useState<number | null>(
    null
  );
  const [showDeletePrompt, setShowDeletePrompt] = useState<boolean>(false);

  // When the user triggers the add task modal from an external component
  useEffect(() => {
    setModalIsOpen(modalDisplayedFromExternalTrigger);
    if (
      modalDisplayedFromExternalTrigger &&
      newTaskModalDefaultValues !== null &&
      newTaskModalDefaultValues.taskType !== null
    ) {
      const newTaskTemplate = taskTypeHelper.getTaskTemplate(
        newTaskModalDefaultValues.taskType,
        newTaskModalDefaultValues.title
      );
      setTaskToEdit(newTaskTemplate);
    }
  }, [modalDisplayedFromExternalTrigger]);

  // When the user clicks to add a new task
  const onOpenModalInAddMode = () => {
    setPopupMode("ADD");
    setModalIsOpen(true);
  };

  // When the user clicks to edit a task
  const onOpenModalInEditMode = (taskIndex: number, editTask: EditableTask) => {
    setPopupMode("EDIT");
    setTaskIndexToEdit(taskIndex);
    setTaskToEdit(editTask);
    setModalIsOpen(true);
  };

  // Reset the modal state
  const onModalOpenChange = (isOpen: boolean) => {
    setPopupMode("ADD");
    setTaskIndexToEdit(null);
    setTaskToEdit(null);
    setModalIsOpen(isOpen);

    // If there's a callback for when the modal closes, execute it
    if (!isOpen && mode === "EXTERNALLY-TRIGGERED" && onModalClosedCallback) {
      onModalClosedCallback();
    }
  };

  // When a user clicks to delete a task
  const promptDeleteConfirmation = (
    taskIndex: number,
    editTask: EditableTask
  ) => {
    setTaskIndexToDelete(taskIndex);
    setShowDeletePrompt(true);
  };

  // When a user backs out from deleting a task
  const onCancelDelete = () => {
    setTaskIndexToDelete(null);
    setShowDeletePrompt(false);
  };

  // When the user adds a new task
  const onAddTask = (task: EditableTask) => {
    const tasksState: QuestionNewTasks = questionTasks
      ? questionTasks
      : { questionId: questionId, tasks: [] };
    const newState = produce(tasksState, (draft) => {
      draft.tasks.push(task);
    });
    onChange(newState);
  };

  // When a user edits an existing task
  const onEditTask = (task: EditableTask) => {
    const tasksState: QuestionNewTasks = questionTasks
      ? questionTasks
      : { questionId: questionId, tasks: [] };
    if (taskIndexToEdit !== null && tasksState.tasks[taskIndexToEdit]) {
      const newState = produce(tasksState, (draft) => {
        draft.tasks[taskIndexToEdit] = task;
      });
      onChange(newState);
    }
  };

  const handlePrimaryButtonClick = (task: EditableTask) => {
    // If readonly, just close the popup and reset it
    if (isReadOnly) {
      onModalOpenChange(false);
      return;
    }
    // Add or edit, as appropriate
    if (popupMode === "ADD") {
      onAddTask(task);
    } else {
      onEditTask(task);
    }
  };

  // When a user deletes a task
  const onDeleteTask = () => {
    const tasksState: QuestionNewTasks = questionTasks
      ? questionTasks
      : { questionId: questionId, tasks: [] };
    if (taskIndexToDelete !== null && tasksState.tasks[taskIndexToDelete]) {
      const newState = produce(tasksState, (draft) => {
        draft.tasks[taskIndexToDelete].modifyStatus = "DELETED";
      });
      onChange(newState);
    }
    setTaskIndexToDelete(null);
    setShowDeletePrompt(false);
  };

  const displayTasks = questionTasks ? questionTasks.tasks : [];

  return (
    <>
      {displayTasks.filter((x) => x.modifyStatus !== "DELETED").length > 0 && (
        <TasksList
          isReadOnly={isReadOnly}
          onDelete={promptDeleteConfirmation}
          onEdit={onOpenModalInEditMode}
          tasks={displayTasks}
        />
      )}
      {!isReadOnly && mode === "WITH-BUTTON" && (
        <div className="text-right m-2">
          <button
            className={cx(classNames, "disabled:cursor-not-allowed")}
            onClick={onOpenModalInAddMode}
            disabled={disableAddTaskButton}
          >
            {buttonTextOverride
              ? buttonTextOverride
              : t("Tasks.Popup.TriggerButtons.Add")}
          </button>
        </div>
      )}
      <ManageTaskPopup
        mode={popupMode}
        isOpen={modalIsOpen}
        onOpenChange={onModalOpenChange}
        editObject={taskToEdit}
        onSaveClick={handlePrimaryButtonClick}
        isReadOnly={isReadOnly}
        taskType={taskToEdit ? taskToEdit.taskType : restrictToTaskType}
      />
      <AlertPopup
        isOpen={showDeletePrompt}
        onOpenChange={setShowDeletePrompt}
        onPrimaryButtonClick={onDeleteTask}
        onSecondaryButtonClick={onCancelDelete}
        primaryButtonText={t("Common.ConfirmDelete")}
        secondaryButtonText={t("Common.CancelAction")}
        bodyText={t("Tasks.DeletePrompt.Body")}
        title={t("Tasks.DeletePrompt.Title")}
      />
    </>
  );
}

export default AddTaskControl;
