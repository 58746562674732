import React, { useEffect, useReducer, Reducer } from "react";
import { useTranslation } from "react-i18next";
import { MainContainer } from "../components/layout";
import UserContext from "../state/UserContext";
import JourneyDashboardWidget from "../components/journeys/JourneyDashboardWidget";
import TimelineWidget from "../components/timeline/TimelineWidget";
import MyFormSummaries from "../components/form-summaries/MyFormSummaries";
import MyDashboardApiResponseDto from "../types/dtos/dashboards/MyDashboardApiResponseDto";
import JourneyFormGroupDto from "../types/dtos/journeys/JourneyFormGroupDto";
import TimelineItemDetailDto from "../types/dtos/timeline/TimelineItemDetailDto";
import AppContext from "../state/AppContext";
import { BaseUserDetailsDto } from "../types/dtos/generic";
import { DashboardSummaryTabDto } from "../types/dtos/dashboards/DashboardSummaryTabDto";
import MockInternalDemoJourneyData from "../mock-api/data/MockInternalDemoJourneyData";
import { ModalPopup } from "../components/common";
import { interactionHelper } from "../helpers";
import demoUserStateHelper from "../helpers/demoUserStateHelper";

interface MyDashboardState {
  waitingForApiResult: boolean;
  userFormSummaries: DashboardSummaryTabDto[];
  journeyFormDetails: JourneyFormGroupDto | undefined | null;
  summariesLoading: boolean;
  initialTimelineItems: TimelineItemDetailDto[];
  allTimelineItems: TimelineItemDetailDto[] | undefined;
  timelineLoading: boolean;
  subjectUser: BaseUserDetailsDto | null;
  /** Used when we need the user to confirm they want to change their current journey */
  proposedNewJourneyClientFormId: number | null;
  showNewJourneyConfirmPrompt: boolean;
}

function MyDashboard() {
  const { t } = useTranslation();
  const userContext = React.useContext(UserContext);
  const appContext = React.useContext(AppContext);

  const [state, setState] = useReducer<
    Reducer<MyDashboardState, Partial<MyDashboardState>>
  >((state, newState) => ({ ...state, ...newState }), {
    waitingForApiResult: true,
    userFormSummaries: [],
    journeyFormDetails: null,
    summariesLoading: true,
    initialTimelineItems: [],
    allTimelineItems: undefined,
    timelineLoading: true,
    subjectUser: null,
    proposedNewJourneyClientFormId: null,
    showNewJourneyConfirmPrompt: false,
  });

  useEffect(() => {
    // Call the API to load the necessary state
    fetch("/api/dashboards/me")
      .then((res) => res.json())
      .then((json: MyDashboardApiResponseDto) => {
        setState({
          userFormSummaries: json.summaries,
          summariesLoading: false,
          initialTimelineItems: json.timeline,
          journeyFormDetails: json.journey,
          waitingForApiResult: false,
          timelineLoading: false,
        });
      })
      .catch((e) => {
        console.error(e.message);
        // TODO: Show some form of error message
      });

    appContext.setPageTitle(t("Pages.MyDashboard.PageTitle"));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /** When the user scrolls to the end of the initial short list, load the full set */
  const loadFullTimeline = (
    callback: (newItems: TimelineItemDetailDto[]) => void
  ) => {
    setState({ timelineLoading: true });
    fetch("/api/timeline/me-all")
      .then((res) => res.json())
      .then((json: TimelineItemDetailDto[]) => {
        setState({ timelineLoading: false, allTimelineItems: json });
        callback(json);
      })
      .catch((e) => {
        console.error(e.message);
        // TODO: Show some form of error message
      });
  };

  /** Call the API and find and assign the relevant journey to the user,
   * and return the new journey details, and refresh the displayed journey
   */
  const assignDifferentJourneyToUser = (clientFormId: number) => {
    switch (clientFormId) {
      case 302: // Future/potential
        // Change the journey
        const isManager = demoUserStateHelper.isManager();
        setState({
          journeyFormDetails:
            MockInternalDemoJourneyData.FutureOnlyJourney(isManager),
        });
        break;
      default:
        alert("Coming soon...");
        break;
    }
    // Scroll to top
    interactionHelper.scrollMainContainerToTop();
  };

  /** When the journey needs to be reloaded, because the user has selected to start a new journey
   * from within a summary section
   */
  const handleManualNewJourneyAssignment = (clientFormId: number) => {
    if (!state.journeyFormDetails) {
      // No journey, can assign a new one straight away
      assignDifferentJourneyToUser(clientFormId);
      return;
    }

    // There is a journey already. Prompt the user to make sure they want to change journeys
    setState({
      showNewJourneyConfirmPrompt: true,
      proposedNewJourneyClientFormId: clientFormId,
    });
  };

  const setJourneyChangePromptVisibility = (isVisible: boolean) => {
    setState({ showNewJourneyConfirmPrompt: isVisible });
    if (!isVisible) {
      // Reset to clear the proposed journey
      setState({ proposedNewJourneyClientFormId: null });
    }
  };

  const confirmJourneyChange = () => {
    if (state.proposedNewJourneyClientFormId) {
      // Load the new form
      assignDifferentJourneyToUser(state.proposedNewJourneyClientFormId);

      // Hide the modal and clear the state variable for the proposed new form
      setJourneyChangePromptVisibility(false);
    }
  };

  const cancelJourneyChange = () => {
    // Hide the modal and clear the state variable for the proposed new form
    setJourneyChangePromptVisibility(false);
  };

  return (
    <MainContainer
      rightColumnChildren={
        <TimelineWidget
          items={
            state.allTimelineItems
              ? state.allTimelineItems
              : state.initialTimelineItems
          }
          onRequestAllItems={loadFullTimeline}
          isLoading={state.timelineLoading}
        />
      }
    >
      <div className="pt-4 lg:pt-8">
        <JourneyDashboardWidget
          formDetails={state.journeyFormDetails}
          isLoading={state.waitingForApiResult}
        />
      </div>

      {userContext.myDashboardMode !== "EXIT" && (
        <MyFormSummaries
          summaries={state.userFormSummaries}
          isLoading={state.summariesLoading}
          onChangeJourney={handleManualNewJourneyAssignment}
        />
      )}
      {/* Confirm journey change prompt */}
      <ModalPopup
        isOpen={state.showNewJourneyConfirmPrompt}
        onOpenChange={setJourneyChangePromptVisibility}
        onPrimaryButtonClick={confirmJourneyChange}
        primaryButtonText={t(
          "Pages.MyDashboard.JourneyChangePrompt.PrimaryButtonText"
        )}
        title={t("Pages.MyDashboard.JourneyChangePrompt.Title")}
        secondaryButtonText={t("Common.CancelAction")}
        onSecondaryButtonClick={cancelJourneyChange}
      >
        <p>{t("Pages.MyDashboard.JourneyChangePrompt.Body")}</p>
      </ModalPopup>
    </MainContainer>
  );
}

export default MyDashboard;
