import PeoplePickerUsersApiResponseDataDto from "../../types/dtos/generic/PeoplePickerUsersApiResponseDataDto";

const MockUserListData = {
  userList: [
  {
    firstName: "Sean",
    fullName: "Sean McSweeney",
    initials: "SM",
    profileImageUrl: null,
    currentStatus: "ACTIVE",
    userId: 1,
    location: "Essex",
    jobTitle: "Dummy.JobTitle.Developer"
  },
  {
    firstName: "Steve",
    fullName: "Steve Kennaird",
    initials: "SK",
    profileImageUrl: null,
    currentStatus: "ACTIVE",
    userId: 2,
    location: "Kent",
    jobTitle: "Dummy.JobTitle.TechnicalDirector"
  },
  {
    firstName: "Sean",
    fullName: "Sean Yearsley",
    initials: "SY",
    profileImageUrl: null,
    currentStatus: "ACTIVE",
    userId: 3,
    location: "Kent",
    jobTitle: "Dummy.JobTitle.SeniorDeveloper"
  },
  {
    firstName: "Tom",
    fullName: "Tom Lake",
    initials: "TL",
    profileImageUrl: null,
    currentStatus: "ACTIVE",
    userId: 4,
    location: "Kent",
    jobTitle: "Dummy.JobTitle.ProductManager"
  },
  {
    firstName: "Joanne",
    fullName: "Joanne Harley",
    initials: "JH",
    profileImageUrl: null,
    currentStatus: "ACTIVE",
    userId: 5,
    location: "Cotswolds",
    jobTitle: "Dummy.JobTitle.MD"
  }
]
} as PeoplePickerUsersApiResponseDataDto;

export default MockUserListData;

