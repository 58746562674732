import { ValidationResult } from "../../../../types/forms";
import ValidationWarning from "../../../common/ValidationWarning";
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { t } from "i18next";
import Tabs, { TabDetails } from "../../../common/Tabs";
import AppraisalLevelSelection from "./AppraisalLevelSelection";
import LocationSelection from "./LocationSelection";
import SpecificUserSelection from "./SpecificUserSelection";
import UserIdsSelection from "./UserIdsSelection";
import { useState } from "react";
import CheckboxTreeNodeDto from "../../../../types/dtos/generic/CheckboxTreeNodeDto";
import SendNowCheckboxTreeApiResponseDto from "../../../../types/dtos/admin/SendNowCheckboxTreeApiResponseDto";
import AudienceTabType from "../../../../types/admin/AudienceTabType";
import PeoplePickerUserDto from "../../../../types/dtos/generic/PeoplePickerUserDto";

interface AudienceSelectionProps {
  onAudienceChange(newState: AudienceTabType): void;
  appraisalLevelTreeChecked: string[];
  appraisalLevelTreeExpanded: string[];
  onAppraisalLevelTreeChecked(newState: string[]): void;
  onAppraisalLevelTreeExpanded(newState: string[]): void;
  locationTreeChecked: string[];
  locationTreeExpanded: string[];
  onLocationTreeChecked(newState: string[]): void;
  onLocationTreeExpanded(newState: string[]): void;
  onSpecificUserChange(newState: PeoplePickerUserDto[]): void;
  specificUserSelectedUsers: PeoplePickerUserDto[];  
  userIdSwitch: boolean;
  userIdsTextAreaValue: string;
  onUserIdsSwitchChange(newState: boolean) : void;
  onUserIdsTextAreaValueChange(newState : string) : void;

  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
}

const AudienceSelection = ({
  onAudienceChange,
  appraisalLevelTreeChecked,
  appraisalLevelTreeExpanded,
  onAppraisalLevelTreeChecked,
  onAppraisalLevelTreeExpanded,
  locationTreeChecked,
  locationTreeExpanded,
  onLocationTreeChecked,
  onLocationTreeExpanded,
  onSpecificUserChange,
  specificUserSelectedUsers,
  userIdSwitch,
  userIdsTextAreaValue,
  onUserIdsSwitchChange,
  onUserIdsTextAreaValueChange,
  showValidationErrors = false,
  validationResult = null,
}: AudienceSelectionProps) => {
  const [appraisalLevels, setappraisalLevels] = useState<CheckboxTreeNodeDto[]>();
  const [isAppraisalLevelsLoading, setIsAppraisalLevelsLoading] = useState<boolean>(false);
  const [locations, setLocations] = useState<CheckboxTreeNodeDto[]>();
  const [isLocationsLoading, setIsLocationsLoading] = useState<boolean>(false);

  const onAppraisalLevelTabClick = (): void => {
    onAudienceChange("APPRAISAL-LEVEL");
    if (appraisalLevels === undefined) {
      setIsAppraisalLevelsLoading(true);

      //SY-TODO: API to hook in.
      fetch(`/api/admin/get-appraisal-levels`)
        .then((res) => res.json())
        .then((json: SendNowCheckboxTreeApiResponseDto) => {
          translateNodes(json.nodes);
          setappraisalLevels(json.nodes);
          setIsAppraisalLevelsLoading(false);
        })
        .catch((e) => {
          console.error(e.message);
          // TODO: Show some form of error message
        });
    }
  }

  const onLocationTabClick = (): void => {
    onAudienceChange("LOCATION");
    if (locations === undefined) {
      setIsLocationsLoading(true);

      //SY-TODO: API to hook in.
      fetch(`/api/admin/get-locations`)
        .then((res) => res.json())
        .then((json: SendNowCheckboxTreeApiResponseDto) => {
          setLocations(json.nodes);
          setIsLocationsLoading(false);
        })
        .catch((e) => {
          console.error(e.message);
          // TODO: Show some form of error message
        });
    }
  }

  const translateNodes = (nodes: CheckboxTreeNodeDto[]) => {
    nodes.forEach((node) => {
      node.label = t(node.label)
      if (node.children !== undefined) {
        translateNodes(node.children);
      }
    });
  }

  const tabs: Array<TabDetails> = [
    {
      title: t("Common.AppraisalLevels"),
      content: <AppraisalLevelSelection
        nodes={appraisalLevels}
        treeChecked={appraisalLevelTreeChecked}
        treeExpanded={appraisalLevelTreeExpanded}
        onTreeChecked={onAppraisalLevelTreeChecked}
        onTreeExpanded={onAppraisalLevelTreeExpanded}
        isLoading={isAppraisalLevelsLoading} />,
      displayUpdateIcon: false,
      onClickEvent: onAppraisalLevelTabClick
    },
    {
      title: t("Common.Locations"),
      content: <LocationSelection
        nodes={locations}
        treeChecked={locationTreeChecked}
        treeExpanded={locationTreeExpanded}
        onTreeChecked={onLocationTreeChecked}
        onTreeExpanded={onLocationTreeExpanded}
        isLoading={isLocationsLoading} />,
      displayUpdateIcon: false,
      onClickEvent: onLocationTabClick
    },
    {
      title: t("Common.SpecificUsers"),
      content: <SpecificUserSelection onPeoplePickerChange={onSpecificUserChange} peoplePickerSelectedUsers={specificUserSelectedUsers} />,
      displayUpdateIcon: false,
      onClickEvent: () => onAudienceChange("SPECIFIC-USER")
    },
    {
      title: t("Common.UserIds"),
      content: <UserIdsSelection 
        checked={userIdSwitch}
        onSwitchChange={onUserIdsSwitchChange} 
        textAreaValue={userIdsTextAreaValue}
        onTextAreaValueChange={onUserIdsTextAreaValueChange}/>,
      displayUpdateIcon: false,
      onClickEvent: () => onAudienceChange("USER-IDS")
    },
    {
      title: t("Common.Everyone"),
      content: (
        <div className="pt-2"><span>{t("Pages.SendNow.Fields.SelectEveryone")}.</span></div>
      ),
      displayUpdateIcon: false,
      onClickEvent: () => onAudienceChange("EVERYONE")
    }
  ];

  return (
    <>
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      <Tabs
        tabs={tabs}
        selectedTabClassNames="radix-state-active: bg-[#EFEFF0] hover:!bg-[#EFEFF0] rounded-md"
        selectFirstTabByDefault={false}
      />
    </>
  );
};

export default AudienceSelection;
