import ManagerDashboardApiResponseDto from "../../types/dtos/dashboards/ManagerDashboardApiResponseDto";
import { v4 as uuidv4 } from "uuid";
import { dateHelper } from "../../helpers";
import { ManagerDashboardSummaryData } from "./MockSummariesData";

const MockManagerDashboardData = {
  statistics: [
    { scoreType: "ENPS", enpsScore: 66 },
    { scoreType: "OPEN-OBJS", scoreValue: 8, maxScaleScalue: 12 },
    { scoreType: "AVG-PERFORMANCE", percentageScore: 80 },
    { scoreType: "AVG-WELLBEING", percentageScore: 55 },
  ],
  users: [
    {
      userId: 1020,
      firstName: "Sarah",
      fullName: "Sarah Marsh",
      initials: "SM",
      profileImageUrl: null,
      currentStatus: "ACTIVE",
      appraisalLevel: "Dummy.AppraisalLevel.FrontOfHouseManager",
      locationName: "London",
      tabs: ManagerDashboardSummaryData,
    },
    {
      userId: 1021,
      firstName: "Oliver",
      fullName: "Oliver Wright",
      initials: "OW",
      profileImageUrl: null,
      currentStatus: "ACTIVE",
      appraisalLevel: "Dummy.AppraisalLevel.RestaurantManager",
      locationName: "London",
      tabs: ManagerDashboardSummaryData,
    },
    {
      userId: 1022,
      firstName: "Holly",
      fullName: "Holly Dean",
      initials: "HD",
      profileImageUrl: null,
      currentStatus: "ACTIVE",
      appraisalLevel: "Dummy.AppraisalLevel.HousekeepingManager",
      locationName: "London",
      tabs: [
        {
          clientFormId: 300,
          clientFormTitle: "Dummy.FormTitle.Goals",
          tabType: "GOALS",
          tabHasUpdates: false,
        },
        {
          clientFormId: 301,
          clientFormTitle: "Dummy.FormTitle.Behaviours",
          tabType: "CLIENT-FORM-COLLABORATIVE",
          tabHasUpdates: false,
        },
        {
          clientFormId: 302,
          clientFormTitle: "Dummy.FormTitle.Future",
          tabType: "CLIENT-FORM-COLLABORATIVE",
          tabHasUpdates: false,
        },
        {
          clientFormId: 303,
          clientFormTitle: "Dummy.FormTitle.Learning",
          tabType: "CLIENT-FORM-COLLABORATIVE",
          tabHasUpdates: false,
        },
        {
          clientFormId: 304,
          clientFormTitle: "Dummy.FormTitle.Opinion",
          tabType: "CLIENT-FORM-EMPLOYEE-ONLY",
          tabHasUpdates: false,
          history: [
            {
              answerSetUniqueId: uuidv4(),
              dateUpdated: dateHelper.getDaysFromCurrentDateUtcs(-2),
            },
          ],
        },
      ],
    },
  ],
  updates: [
    {
      userId: 1020,
      answerSetUniqueId: "956efd2b-b226-45ab-8723-e2ffc9fefddd",
      clientFormTitle: "Dummy.Forms.Future.Title",
      timestamp: dateHelper.getDaysFromCurrentDateUtcs(0),
    },
    {
      userId: 1020,
      answerSetUniqueId: "78f3d6de-3e55-4f45-8a6d-fa67c5f0b77c",
      clientFormTitle: "Dummy.FormTitle.CareerJourney",
      timestamp: dateHelper.getDaysFromCurrentDateUtcs(-7),
    },
  ],
} as ManagerDashboardApiResponseDto;

export default MockManagerDashboardData;
