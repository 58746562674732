import { wcagContrastChecker } from '@mdhnpm/wcag-contrast-checker';

const colourHelper = {
  getDefaultTextColour: function() : string {
    return "#FFFFFF";
  },
  /** Compares the given colour (background) against white text to see if it is accessible */
  doesColourPassContrastCheck: function (foregroundColour : string, backgroundColour : string): boolean {
    try {
      let contrastChecker = wcagContrastChecker(foregroundColour, backgroundColour);
      return contrastChecker.regularText.aa;
    } catch(e) {
      return false;
    }
  },
  /** Checks whether the hex colour provided is a valid hex */
  isValidHex: function (colour : string): boolean {
    if(!colour || typeof colour !== 'string') return false;

    // Validate hex values
    if(colour.substring(0, 1) === '#') colour = colour.substring(1);

    switch(colour.length) {
      case 3: return /^[0-9A-F]{3}$/i.test(colour);
      case 6: return /^[0-9A-F]{6}$/i.test(colour);
      case 8: return /^[0-9A-F]{8}$/i.test(colour);
      default: return false;
    }

    return false;
  }
};

export default colourHelper;
