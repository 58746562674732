import { useTranslation } from "react-i18next";
import { faUser, faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function PeopleHeaderLoadingCard() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex gap-4">
        <div className="pt-1">
          <span className="w-10 h-10 bg-gray-300 rounded-full animate-pulse ring-2 ring-white text-center  flex items-center justify-center font-semibold select-none">L</span>
        </div>
        <div className="flex-col">
          <div>
            <h3 className="coloured-heading !mb-0 !mt-0">{t("Common.Loading")}...</h3>
          </div>
          <div>
            <div className="flex gap-5 text-sm ml-3">
              <p>
                <FontAwesomeIcon icon={faUser} size="1x" className="text-[#C4C4C4] animate-pulse" />
                <span className="pl-1">{t("Common.AppraisalLevel")}</span>
              </p>
              <p>
                <FontAwesomeIcon icon={faMapMarkerAlt} size="1x" className="text-[#C4C4C4] animate-pulse" />
                <span className="pl-1">{t("Common.Location")}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PeopleHeaderLoadingCard;
