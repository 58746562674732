import { useTranslation } from "react-i18next";
import { ValidationResult } from "../../types/forms";
import { KeyValuePair } from "../../types/generic";
import DurationType from "../../types/generic/DurationType";
import NumberInput from "./NumberInput";
import InfoAlert from "../alerts/InfoAlert";
import Label from "./Label";
import ValidationWarning from "./ValidationWarning";

interface InductionDateRangeProps {
  /** The Id attribute for the input element, to match the Label */
  inputId?: string;
  /** The onChange event, for handling state changes */
  onDaysFromChange(newValue: number): void;
  onDaysToChange(newValue: number): void;
  daysFrom: number;
  daysTo: number;
  nextDate?: Date;
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
}

const InductionDateRange = ({
  inputId,
  onDaysFromChange,
  onDaysToChange,
  daysFrom,
  daysTo,
  nextDate,
  showValidationErrors = false,
  validationResult = null,
}: InductionDateRangeProps) => {
  const { t } = useTranslation();

  return (
    <div className="pt-2">
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      <div>
        <Label
          text={t("Pages.Admin.Common.SendSectionToUsersBetween")}
        />
      </div>
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-5">
          <NumberInput
            className="w-full text-base"
            onChange={onDaysFromChange}
            value={daysFrom}
            minimum={1}
            maximum={100}
            step={1}
            inputId={inputId + "_days-from"}
          />
        </div>
        <div className="col-span-2 text-center pt-2">
          {t("Common.And_LowerCase")}
        </div>
        <div className="col-span-5">
          <NumberInput
            className="w-full text-base"
            onChange={onDaysToChange}
            value={daysTo}
            minimum={1}
            maximum={100}
            step={1}
            inputId={inputId + "_days-to"}
          />
        </div>
      </div>
      <div>
        <Label
          text={t("Pages.Admin.Common.DaysAfterTheirHireDate")}
        />
      </div>
      <div className="pt-1">
        <InfoAlert
          prefix={""}
          message={t("Pages.Admin.Common.PrefixTextForNewUserDate")}
          optionalDate={nextDate}
        />
      </div>
    </div>
  );
};

export default InductionDateRange;
