import { useEffect, useRef, useState } from "react";
import { CollabDocCommentDto } from "../../types/dtos/collab-docs";
import CommentsList from "./CommentsList";
import { NewCommentForm } from ".";
import { NewCollabDocComment } from "../../types/collab-docs";
import { UserBasicDetailsDto } from "../../types/dtos/generic";
import { ReactComponent as NoCommentsImage } from "../../images/noComments.svg";

interface CommentsSideBarProps {
  activeQuestionId: string | null;
  comments: CollabDocCommentDto[] | null;
  participants: UserBasicDetailsDto[];
  isReadOnly: boolean;
  /** A method to call to mark the comments as seen */
  onCommentsSeen(questionId: string): void;
  /** A method to call to insert a new comment */
  onCommentAdd(newComment: NewCollabDocComment): void;
  /** A method to call to delete a comment */
  onCommentDelete(commentId: string): void;
}

function CommentsSideBar({
  activeQuestionId,
  comments,
  participants,
  isReadOnly,
  onCommentsSeen,
  onCommentAdd,
  onCommentDelete,
}: CommentsSideBarProps) {
  // State
  const [replyToCommentId, setReplyToCommentId] = useState<string | null>(null);

  // Refs
  const commentFormInputRef = useRef<HTMLTextAreaElement>(null);

  // Determine whether the sidebar is visible so we know whether or not to mark the
  // comments loaded in it as "seen" (as this component loads when the active question changes,
  // but is hidden with responsive css. So we can't just mark them as seen, as this will execute,
  // but the user may not have actually seen it)
  const sidebarElement = document.getElementById("comments-sidebar");
  let sidebarIsVisible = false;
  if (sidebarElement) {
    sidebarIsVisible =
      window.getComputedStyle(sidebarElement).display === "block";
  }

  // Calculated values
  const unseenCommentCount = comments
    ? comments.filter((x) => !x.seen).length
    : 0;

  // Events
  useEffect(() => {
    // Ensure `replyToCommentId` gets reset as the activeQuestion changes
    setReplyToCommentId(null);
    // Mark the comments as "seen" (unless on mobile, in which case mark as seen on comments modal load)
    if (sidebarIsVisible && unseenCommentCount > 0 && activeQuestionId) {
      onCommentsSeen(activeQuestionId);
    }
  }, [activeQuestionId]);

  /** When a user clicks the "Reply" button on another comment */
  const onInitiateReply = (commentId: string) => {
    setReplyToCommentId(commentId);
    // Focus the textarea
    if (commentFormInputRef?.current) {
      commentFormInputRef.current.focus();
    }
  };

  return (
    <div id="comments-sidebar" className="hidden lg:block">
      {!activeQuestionId && (
        <div className="flex justify-center flex-col text-center text-gray-400">
          <NoCommentsImage width={"18rem"} />
          <span>No question selected!</span>
          <span>Select a question to add a comment</span>
        </div>
      )}
      {activeQuestionId && (
        <>
          <CommentsList
            isReadOnly={isReadOnly}
            comments={comments}
            participants={participants}
            onDeleteComment={onCommentDelete}
            onTargetCommentForReply={onInitiateReply}
          />
          {!isReadOnly && (
            <NewCommentForm
              questionId={activeQuestionId}
              replyToCommentId={replyToCommentId}
              onSubmit={onCommentAdd}
              inputRef={commentFormInputRef}
            />
          )}
        </>
      )}
    </div>
  );
}

export default CommentsSideBar;
