import { DashboardSummaryTabDto } from "../../types/dtos/dashboards/DashboardSummaryTabDto";
import FormSummaryLoadingCard from "./FormSummaryLoadingCard";
import CollaborativFormSummaryContent from "./CollaborativeFormSummaryContent";
import GoalFormSummaryContent from "./GoalFormSummaryContent";
import BehaviourFormSummaryContent from "./BehaviourFormSummaryContent";
import FormSummaryButtons from "./FormSummaryButtons";

interface FormSummaryProps {
  isManagerDashboard?: boolean;
  summaryTabData?: DashboardSummaryTabDto | null;
  /** A function to call when the user has selected a different journey to complete from within a summary,
   * or the manager has chosen to update this section for their team member */
  onTriggerSectionUpdate?(clientFormId: number): void | undefined;
}

const FormSummary = ({
  summaryTabData,
  isManagerDashboard,
  onTriggerSectionUpdate,
}: FormSummaryProps) => {
  const switchJourneyForUser = () => {
    // Then reload the journey on the dashboard
    if (onTriggerSectionUpdate && summaryTabData?.clientFormId) {
      onTriggerSectionUpdate(summaryTabData.clientFormId);
    }
  };

  return (
    <div className="basis-full md:basis-1/2">
      <div className="rounded bg-white">
        {/* COLLABORATIVE Form*/}
        <div>
          {summaryTabData &&
            summaryTabData.tabType === "CLIENT-FORM-COLLABORATIVE" &&
            summaryTabData.formData && (
              <CollaborativFormSummaryContent
                formData={summaryTabData.formData}
              />
            )}
        </div>

        {/* GOALS Form */}
        {summaryTabData && summaryTabData.tabType === "GOALS" && (
          <div>
            {summaryTabData.goalData && (
              <GoalFormSummaryContent goalData={summaryTabData.goalData} />
            )}
          </div>
        )}

        {/* BEHAVIOURS Form */}
        {summaryTabData &&
          summaryTabData.tabType === "BEHAVIOURS" &&
          summaryTabData.behaviourData &&
          summaryTabData.behaviourData.behaviours && (
            <BehaviourFormSummaryContent
              behaviourData={summaryTabData.behaviourData}
            />
          )}
        {!isManagerDashboard && (
          <div className="flex flex-col my-2">
            <div className="flex flex-row-reverse gap-3">
              {summaryTabData &&
                summaryTabData.tabType !== "CLIENT-FORM-EMPLOYEE-ONLY" && (
                  <FormSummaryButtons
                    formName={summaryTabData.clientFormTitle}
                    onUpdateButtonClick={switchJourneyForUser}
                  />
                )}
            </div>
          </div>
        )}

        {!summaryTabData && <FormSummaryLoadingCard />}
      </div>
    </div>
  );
};

export default FormSummary;
