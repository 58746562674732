import { ActorQuestionText } from "../../types/forms";

// In order to display the words on sliders, provide a value for the `text` property on EVERY item
// and if you want to display the selected score, e.g. "7 / 10", only supply text for the min and max items
// For behaviours it is slightly different, the question config for behaviours specifies whether to display "WORD" or "NUMERIC"
export const MockNumericSliderOptions = [
  { isSelected: false, optionId: 1, text: "Totally disagree", value: 0 },
  { isSelected: false, optionId: 2, value: 1 },
  { isSelected: false, optionId: 3, value: 2 },
  { isSelected: false, optionId: 4, value: 3 },
  { isSelected: false, optionId: 5, value: 4 },
  { isSelected: false, optionId: 6, value: 5 },
  { isSelected: false, optionId: 7, value: 6 },
  { isSelected: false, optionId: 8, value: 7 },
  { isSelected: false, optionId: 9, value: 8 },
  { isSelected: false, optionId: 10, value: 9 },
  { isSelected: false, optionId: 11, text: "Totally agree", value: 10 },
];

export const MockOpinionSliderOptions = [
  { isSelected: false, optionId: 1, text: "Not at all", value: 0 },
  { isSelected: false, optionId: 2, value: 1 },
  { isSelected: false, optionId: 3, value: 2 },
  { isSelected: false, optionId: 4, value: 3 },
  { isSelected: false, optionId: 5, value: 4 },
  { isSelected: false, optionId: 6, value: 5 },
  { isSelected: false, optionId: 7, value: 6 },
  { isSelected: false, optionId: 8, value: 7 },
  { isSelected: false, optionId: 9, value: 8 },
  { isSelected: false, optionId: 10, value: 9 },
  { isSelected: false, optionId: 11, text: "Extremely", value: 10 },
];

export const MockWordSliderOptions = [
  { isSelected: false, optionId: 10001, text: "Not at all", value: 1 },
  { isSelected: false, optionId: 10002, text: "Not much", value: 2 },
  { isSelected: false, optionId: 10003, text: "Kinda", value: 3 },
  { isSelected: false, optionId: 10004, text: "A bit", value: 4 },
  { isSelected: false, optionId: 10005, text: "Totally", value: 5 },
];

export const MockBehaviourSliderOptions = [
  { isSelected: false, text: "Action required", value: 1 },
  { isSelected: false, text: "", value: 2 },
  { isSelected: false, text: "", value: 3 },
  { isSelected: false, text: "", value: 4 },
  { isSelected: false, text: "Role model", value: 5 },
];

const getActorText = (questionText: string): ActorQuestionText => {
  return {
    employee: questionText,
    manager: questionText,
  };
};

export const FutureFormQuestions = [
  {
    questionId: "F0",
    questionText: {
      employee: "Dummy.Forms.FutureInternalDemo.Questions.F0_Employee",
      manager: "Dummy.Forms.FutureInternalDemo.Questions.F0_Manager",
    },
    questionType: "DROPDOWNLIST",
    commentsEnabled: true,
    sequenceMap: {
      conditionallyTriggered: false,
      defaultNextQuestionId: null,
      options: [
        { optionId: 1, nextQuestionId: "F_COND_NOTSURE_1" },
        { optionId: 2, nextQuestionId: "F_COND_NOTSURE_1" },
        { optionId: 3, nextQuestionId: "F_COND_NOTSURE_1" },
        { optionId: 4, nextQuestionId: "F_COND_NOTSURE_1" },
        { optionId: 5, nextQuestionId: "F_COND_NOTSURE_1" },
        { optionId: 6, nextQuestionId: "F_COND_NOTSURE_1" },
        { optionId: 7, nextQuestionId: "F_COND_PROGRESSNOW_1" },
        { optionId: 8, nextQuestionId: "F_COND_NOTSURE_1" },
      ],
    },
    multiChoiceOptions: [
      {
        optionId: 1,
        isSelected: false,
        text: "Dummy.Forms.FutureInternalDemo.Options.F0.AlmostReady",
        value: "1",
      },
      {
        optionId: 2,
        isSelected: false,
        text: "Dummy.Forms.FutureInternalDemo.Options.F0.CouldDoMore",
        value: "2",
      },
      {
        optionId: 3,
        isSelected: false,
        text: "Dummy.Forms.FutureInternalDemo.Options.F0.PossibleMore",
        value: "3",
      },
      {
        optionId: 4,
        isSelected: false,
        text: "Dummy.Forms.FutureInternalDemo.Options.F0.NotSure",
        value: "4",
      },
      {
        optionId: 5,
        isSelected: false,
        text: "Dummy.Forms.FutureInternalDemo.Options.F0.StayInRole",
        value: "5",
      },
      {
        optionId: 6,
        isSelected: false,
        text: "Dummy.Forms.FutureInternalDemo.Options.F0.MoveOn",
        value: "6",
      },
      {
        optionId: 7,
        isSelected: false,
        text: "Dummy.Forms.FutureInternalDemo.Options.F0.ReadyToProgress",
        value: "7",
      },
      {
        optionId: 8,
        isSelected: false,
        text: "Dummy.Forms.FutureInternalDemo.Options.F0.TooNew",
        value: "8",
      },
    ],
    validation: {
      required: true,
      min: null,
      max: null,
    },
    actorRestriction: null,
  },
  /* Conditionals for "Ready to progress here" */
  {
    questionId: "F_COND_PROGRESSNOW_1", // Note: This isn't important, just a made up question id
    questionText: {
      employee:
        "Dummy.Forms.FutureInternalDemo.Questions.F_COND_PROGRESSNOW_1.Employee",
      manager:
        "Dummy.Forms.FutureInternalDemo.Questions.F_COND_PROGRESSNOW_1.Manager",
    },
    questionType: "SHORTTEXT",
    commentsEnabled: true,
    sequenceMap: {
      conditionallyTriggered: true,
      defaultNextQuestionId: null,
      options: null,
    },
    validation: {
      required: true,
      min: null,
      max: null,
    },
    actorRestriction: null,
  },

  /* Conditionals for "Not sure" */
  {
    questionId: "F_COND_NOTSURE_1", // Note: This isn't important, just a made up question id
    questionText: getActorText(
      "Dummy.Forms.FutureInternalDemo.Questions.F_COND_NOTSURE_1"
    ),
    questionType: "LONGTEXT",
    commentsEnabled: true,
    sequenceMap: {
      conditionallyTriggered: true,
      defaultNextQuestionId: "F_COND_NOTSURE_2",
      options: null,
    },
    validation: {
      required: true,
      min: null,
      max: null,
    },
    actorRestriction: null,
  },
  {
    questionId: "F_COND_NOTSURE_2",
    questionText: getActorText(
      "Dummy.Forms.FutureInternalDemo.Questions.F_COND_NOTSURE_2"
    ),
    questionType: "CHECKLIST",
    commentsEnabled: true,
    sequenceMap: {
      conditionallyTriggered: true,
      defaultNextQuestionId: "F_COND_NOTSURE_3",
      options: [],
    },
    multiChoiceOptions: [
      {
        optionId: 991,
        isSelected: false,
        text: "Dummy.Forms.FutureInternalDemo.Options.F_COND_PROGRESSHERE_3.1",
        value: "1",
      },
      {
        optionId: 992,
        isSelected: false,
        text: "Dummy.Forms.FutureInternalDemo.Options.F_COND_PROGRESSHERE_3.2",
        value: "2",
      },
      {
        optionId: 993,
        isSelected: false,
        text: "Dummy.Forms.FutureInternalDemo.Options.F_COND_PROGRESSHERE_3.3",
        value: "3",
      },
      {
        optionId: 994,
        isSelected: false,
        text: "Dummy.Forms.FutureInternalDemo.Options.F_COND_PROGRESSHERE_3.4",
        value: "4",
      },
    ],
    validation: {
      required: false,
      min: null,
      max: null,
    },
    actorRestriction: null,
  },
  {
    questionId: "F_COND_NOTSURE_3", // Note: This isn't important, just a made up question id
    questionText: getActorText(
      "Dummy.Forms.FutureInternalDemo.Questions.F_COND_NOTSURE_3"
    ),
    questionType: "DROPDOWNLIST",
    commentsEnabled: true,
    sequenceMap: {
      conditionallyTriggered: true,
      defaultNextQuestionId: "F_COND_NOTSURE_4",
      options: null,
    },
    multiChoiceOptions: [
      {
        optionId: 881,
        isSelected: false,
        text: "Dummy.Forms.FutureInternalDemo.Options.Potential.1",
        value: "1",
      },
      {
        optionId: 882,
        isSelected: false,
        text: "Dummy.Forms.FutureInternalDemo.Options.Potential.2",
        value: "2",
      },
      {
        optionId: 883,
        isSelected: false,
        text: "Dummy.Forms.FutureInternalDemo.Options.Potential.3",
        value: "3",
      },
      {
        optionId: 884,
        isSelected: false,
        text: "Dummy.Forms.FutureInternalDemo.Options.Potential.4",
        value: "4",
      },
      {
        optionId: 885,
        isSelected: false,
        text: "Dummy.Forms.FutureInternalDemo.Options.Potential.5",
        value: "5",
      },
      {
        optionId: 886,
        isSelected: false,
        text: "Dummy.Forms.FutureInternalDemo.Options.Potential.6",
        value: "6",
      },
      {
        optionId: 887,
        isSelected: false,
        text: "Dummy.Forms.FutureInternalDemo.Options.Potential.7",
        value: "7",
      },
      {
        optionId: 888,
        isSelected: false,
        text: "Dummy.Forms.FutureInternalDemo.Options.Potential.8",
        value: "8",
      },
    ],
    validation: {
      required: true,
      min: null,
      max: null,
    },
    actorRestriction: null,
  },
  {
    questionId: "F_COND_NOTSURE_4", // Note: This isn't important, just a made up question id
    questionText: getActorText(
      "Dummy.Forms.FutureInternalDemo.Questions.F_COND_NOTSURE_4"
    ),
    questionType: "LONGTEXT",
    commentsEnabled: true,
    sequenceMap: {
      conditionallyTriggered: true,
      defaultNextQuestionId: null,
      options: null,
    },
    validation: {
      required: true,
      min: null,
      max: null,
    },
    actorRestriction: "EMPLOYEE",
  },
  /* end of conditionals */
];

export const BehaviourFormQuestions = [
  {
    questionId: "B1",
    questionText: {
      employee: "Dummy.Forms.BehavioursInternalDemo.Questions.B1_Employee",
      manager: "Dummy.Forms.BehavioursInternalDemo.Questions.B1_Manager",
    },
    questionType: "READONLY",
    commentsEnabled: false,
    sequenceMap: {
      defaultNextQuestionId: "BHV_01",
      options: null,
    },
    validation: {
      required: false,
      min: null,
      max: null,
    },
    actorRestriction: null,
  },
  {
    questionId: "BHV_01",
    questionText: getActorText(""),
    questionType: "BEHAVIOUR",
    commentsEnabled: true,
    sequenceMap: {
      defaultNextQuestionId: "B3",
      options: null,
    },
    behaviourOptions: {
      behaviour: { key: 1, value: "Dummy.BehavioursInternalDemoAttrHackTitle" },
      scale: MockBehaviourSliderOptions,
      singleScaleLabel: null,
      infoTooltipContent: "Dummy.Behaviour1Tooltip",
      attributes: [
        { key: 1, value: getActorText("Dummy.Behaviour2") },
        { key: 2, value: getActorText("Dummy.Behaviour3") },
        { key: 3, value: getActorText("Dummy.Behaviour4") },
      ],
      showAddTaskButton: true,
      sliderScoreDisplayType: "NUMERIC",
    },
    validation: {
      required: true,
      min: null,
      max: null,
    },
  },
  /*
  {
    questionId: "BHV_01",
    questionText: getActorText(""),
    questionType: "BEHAVIOUR",
    commentsEnabled: true,
    sequenceMap: {
      defaultNextQuestionId: "BHV_02",
      options: null,
    },
    behaviourOptions: {
      behaviour: { key: 1, value: "Dummy.Behaviour2" },
      scale: MockBehaviourSliderOptions,
      singleScaleLabel: {
        employee: "Dummy.Behaviour2SingleScaleLabelEmployee",
        manager: "Dummy.Behaviour2SingleScaleLabelManager",
      },
      infoTooltipContent: "Dummy.Behaviour2Tooltip",
      attributes: [],
      showAddTaskButton: true,
      sliderScoreDisplayType: "NUMERIC",
    },
    validation: {
      required: true,
      min: null,
      max: null,
    },
    actorRestriction: null,
  },
  {
    questionId: "BHV_02",
    questionText: getActorText(""),
    questionType: "BEHAVIOUR",
    commentsEnabled: true,
    sequenceMap: {
      defaultNextQuestionId: "BHV_03",
      options: null,
    },
    behaviourOptions: {
      behaviour: { key: 1, value: "Dummy.Behaviour3" },
      scale: MockBehaviourSliderOptions,
      singleScaleLabel: {
        employee: "Dummy.Behaviour2SingleScaleLabelEmployee",
        manager: "Dummy.Behaviour2SingleScaleLabelManager",
      },
      infoTooltipContent: "Dummy.Behaviour2Tooltip",
      attributes: [],
      showAddTaskButton: true,
      sliderScoreDisplayType: "NUMERIC",
    },
    validation: {
      required: true,
      min: null,
      max: null,
    },
    actorRestriction: null,
  },
  {
    questionId: "BHV_03",
    questionText: getActorText(""),
    questionType: "BEHAVIOUR",
    commentsEnabled: true,
    sequenceMap: {
      defaultNextQuestionId: "B3",
      options: null,
    },
    behaviourOptions: {
      behaviour: { key: 1, value: "Dummy.Behaviour4" },
      scale: MockBehaviourSliderOptions,
      singleScaleLabel: {
        employee: "Dummy.Behaviour2SingleScaleLabelEmployee",
        manager: "Dummy.Behaviour2SingleScaleLabelManager",
      },
      infoTooltipContent: "Dummy.Behaviour2Tooltip",
      attributes: [],
      showAddTaskButton: true,
      sliderScoreDisplayType: "NUMERIC",
    },
    validation: {
      required: true,
      min: null,
      max: null,
    },
    actorRestriction: null,
  },*/
  {
    questionId: "B3",
    questionText: getActorText(
      "Dummy.Forms.BehavioursInternalDemo.Questions.B3"
    ),
    questionType: "LONGTEXT",
    commentsEnabled: true,
    sequenceMap: {
      defaultNextQuestionId: null,
      options: null,
    },
    validation: {
      required: false,
      min: null,
      max: null,
    },
    actorRestriction: null,
  },
];

export const LearningAndDevelopmentFormQuestions = [
  {
    questionId: "LD1",
    questionText: getActorText("Dummy.Forms.LearnDev.Questions.LD1"),
    questionType: "LEARNING-AND-DEVELOPMENT",
    commentsEnabled: true,
    sequenceMap: {
      defaultNextQuestionId: null,
      options: null,
    },
    developmentOptions: {
      allowCustomItems: true,
      listGroups: [
        {
          id: 1000,
          name: "Dummy.LearnDev.Item1",
          items: [
            { text: "Dummy.LearnDev.Item1_1", value: 1001, isSelected: false },
            { text: "Dummy.LearnDev.Item1_2", value: 1002, isSelected: false },
            { text: "Dummy.LearnDev.Item1_3", value: 1003, isSelected: false },
            { text: "Dummy.LearnDev.Item1_4", value: 1004, isSelected: false },
            { text: "Dummy.LearnDev.Item1_5", value: 1005, isSelected: false },
          ],
        },
        {
          id: 2000,
          name: "Dummy.LearnDev.Item2",
          items: [
            { text: "Dummy.LearnDev.Item2_1", value: 2001, isSelected: false },
            { text: "Dummy.LearnDev.Item2_2", value: 2002, isSelected: false },
            { text: "Dummy.LearnDev.Item2_3", value: 2003, isSelected: false },
          ],
        },
        {
          id: 3000,
          name: "Dummy.LearnDev.Item3",
          items: [
            { text: "Dummy.LearnDev.Item3_1", value: 3001, isSelected: false },
            { text: "Dummy.LearnDev.Item3_2", value: 3002, isSelected: false },
            { text: "Dummy.LearnDev.Item3_3", value: 3003, isSelected: false },
          ],
        },
        {
          id: 4000,
          name: "Dummy.LearnDev.Item4",
          items: [
            { text: "Dummy.LearnDev.Item4_1", value: 4001, isSelected: false },
            { text: "Dummy.LearnDev.Item4_2", value: 4002, isSelected: false },
            { text: "Dummy.LearnDev.Item4_3", value: 4003, isSelected: false },
            { text: "Dummy.LearnDev.Item4_4", value: 4004, isSelected: false },
            { text: "Dummy.LearnDev.Item4_5", value: 4005, isSelected: false },
            { text: "Dummy.LearnDev.Item4_6", value: 4006, isSelected: false },
            { text: "Dummy.LearnDev.Item4_7", value: 4007, isSelected: false },
            { text: "Dummy.LearnDev.Item4_8", value: 4008, isSelected: false },
            { text: "Dummy.LearnDev.Item4_9", value: 4009, isSelected: false },
            { text: "Dummy.LearnDev.Item4_10", value: 4010, isSelected: false },
            { text: "Dummy.LearnDev.Item4_11", value: 4011, isSelected: false },
          ],
        },
      ],
    },
    validation: {
      required: false,
      min: null,
      max: null,
    },
    actorRestriction: null,
  },
];

export const OpinionYouFormQuestions = [
  {
    questionId: "OY1",
    questionText: getActorText(
      "Dummy.Forms.OpinionYouInternalDemo.Questions.OY1"
    ),
    questionType: "SLIDER",
    commentsEnabled: true,
    sequenceMap: {
      defaultNextQuestionId: "OY2",
      options: null,
    },
    multiChoiceOptions: MockOpinionSliderOptions,
    validation: {
      required: true,
      min: null,
      max: null,
    },
    actorRestriction: "EMPLOYEE",
  },
  {
    questionId: "OY2",
    questionText: getActorText(
      "Dummy.Forms.OpinionYouInternalDemo.Questions.OY2"
    ),
    questionType: "SLIDER",
    commentsEnabled: true,
    sequenceMap: {
      defaultNextQuestionId: "OY3",
      options: null,
    },
    multiChoiceOptions: MockOpinionSliderOptions,
    validation: {
      required: true,
      min: null,
      max: null,
    },
    actorRestriction: "EMPLOYEE",
  },
  {
    questionId: "OY3",
    questionText: getActorText(
      "Dummy.Forms.OpinionYouInternalDemo.Questions.OY3"
    ),
    questionType: "SLIDER",
    commentsEnabled: true,
    sequenceMap: {
      defaultNextQuestionId: "OY4",
      options: null,
    },
    multiChoiceOptions: MockOpinionSliderOptions,
    validation: {
      required: true,
      min: null,
      max: null,
    },
    actorRestriction: "EMPLOYEE",
  },
  {
    questionId: "OY4",
    questionText: getActorText(
      "Dummy.Forms.OpinionYouInternalDemo.Questions.OY4"
    ),
    questionType: "LONGTEXT",
    commentsEnabled: true,
    sequenceMap: {
      defaultNextQuestionId: null,
      options: null,
    },
    validation: {
      required: false,
      min: null,
      max: null,
    },
    actorRestriction: "EMPLOYEE",
  },
];

export const OpinionCompanyQuestions = [
  {
    questionId: "OC1",
    questionText: getActorText(
      "Dummy.Forms.OpinionCoInternalDemo.Questions.OC1"
    ),
    questionType: "SLIDER",
    commentsEnabled: true,
    sequenceMap: {
      defaultNextQuestionId: "OC2",
      options: null,
    },
    multiChoiceOptions: MockOpinionSliderOptions,
    validation: {
      required: true,
      min: null,
      max: null,
    },
    actorRestriction: "EMPLOYEE",
  },
  {
    questionId: "OC2",
    questionText: getActorText(
      "Dummy.Forms.OpinionCoInternalDemo.Questions.OC2"
    ),
    questionType: "SLIDER",
    commentsEnabled: true,
    sequenceMap: {
      defaultNextQuestionId: "OC3",
      options: null,
    },
    multiChoiceOptions: MockOpinionSliderOptions,
    validation: {
      required: true,
      min: null,
      max: null,
    },
    actorRestriction: "EMPLOYEE",
  },
  {
    questionId: "OC3",
    questionText: getActorText("Dummy.Forms.OpinionCo.Questions.OC3"),
    questionType: "LONGTEXT",
    commentsEnabled: true,
    sequenceMap: {
      defaultNextQuestionId: null,
      options: null,
    },
    validation: {
      required: false,
      min: null,
      max: null,
    },
    actorRestriction: "EMPLOYEE",
  },
];

export const GoalReviewJourneyQuestions = [
  {
    // The journey goal review question displays a list of goals, with a control to change the status for each goal
    // This then feeds that status into the collab doc, which can (optionally) have more questions
    // So the format of the mock data below here, for journeys, has one question object with multiple goals in goalReviewOptions.goals
    // This is different to collab docs, which needs a question per goal in order to facilitate the different UI there, plus allowing
    // for different comments per goal, and only highlighting one goal at a time as the active question
    questionId: "GRVW_01",
    questionText: getActorText("Dummy.Forms.GoalReview.Questions.GRVW_01"),
    questionType: "GOAL-REVIEW",
    commentsEnabled: true,
    sequenceMap: {
      defaultNextQuestionId: null,
      options: null,
    },
    validation: {
      required: true,
    },
    actorRestriction: null,
    goalReviewOptions: {
      additionalQuestions: null,
      goals: [
        {
          title: "Convert 20 leads this year",
          categoryId: 2,
          field1: "Field 1 text",
          field2: "Field 2 text",
          targetDate: new Date(),
          taskType: "GOAL",
          toDoId: 123,
          dateCompleted: new Date(),
          dateCancelled: null,
        },
        {
          title: "Improve initial engagement",
          categoryId: 1,
          field1: "Field 1 text",
          field2: "Field 2 text",
          targetDate: new Date(),
          taskType: "GOAL",
          toDoId: 456,
          dateCompleted: null,
          dateCancelled: null,
        },
      ],
      goalStatusOptions: [
        { key: 1, value: "Dummy.GoalStatus.Achieved" },
        { key: 2, value: "Dummy.GoalStatus.Cancelled" },
        { key: 3, value: "Dummy.GoalStatus.Rollover" },
      ],
    },
  },
];

export const GoalSettingQuestions = [
  {
    questionId: "GSET_01",
    questionText: getActorText("Please set some goals"),
    questionType: "GOAL-SETTING",
    commentsEnabled: true,
    sequenceMap: {
      defaultNextQuestionId: null,
      options: null,
    },
    validation: {
      required: true,
      min: null,
      max: 3,
    },
    actorRestriction: null,
  },
];

export const MockCollabDocGoalReviewQuestions = [
  {
    // The collab doc goal review question needs a question per goal in order to facilitate the UI there, allowing
    // for different comments per goal, and only highlighting one goal at a time as the active question
    // So goalReviewOptions.goals should contain one goal per question, and the id of the question should be different for each goal
    questionId: "GRVW_01_Goal_1",
    questionText: getActorText(
      "Dummy.Forms.GoalReview.Questions.GRVW_01_Singular"
    ), // This is the question text for the status dropdown
    questionType: "GOAL-REVIEW",
    commentsEnabled: true,
    sequenceMap: {
      defaultNextQuestionId: "GRVW_01_Goal_2",
      options: null,
    },
    validation: {
      required: true,
    },
    actorRestriction: null,
    goalReviewOptions: {
      additionalQuestions: [
        {
          questionId: "GRVW_01_Goal_1_3000", // This changes per goal & additional question
          questionText: getActorText(
            "Dummy.Forms.GoalReview.Questions.GiveExamples"
          ),
          questionType: "LONGTEXT",
          commentsEnabled: true,
          sequenceMap: {
            // Conditionals aren't supported in goal review - so no need to worry about that on the server side
            conditionallyTriggered: false,
            defaultNextQuestionId: null,
            options: null,
          },
          validation: {
            required: true,
            min: null,
            max: null,
          },
        },
        /*{
          questionId: "GRVW_01_Goal_1_3001",
          questionText: getActorText(
            "How well do you feel you performed with this goal?"
          ),
          questionType: "SLIDER",
          commentsEnabled: true,
          sequenceMap: {
            conditionallyTriggered: false,
            defaultNextQuestionId: null,
            options: null,
          },
          multiChoiceOptions: MockWordSliderOptions,
          validation: {
            required: true,
            min: null,
            max: null,
          },
        },*/
      ],
      goals: [
        {
          title: "Convert 20 leads this year",
          categoryId: 2,
          field1: "Field 1 text",
          field2: "Field 2 text",
          targetDate: new Date(),
          taskType: "GOAL",
          toDoId: 123,
          dateCompleted: new Date(),
          dateCancelled: null,
        },
      ],
      goalStatusOptions: [
        { key: 1, value: "Dummy.GoalStatus.Achieved" },
        { key: 2, value: "Dummy.GoalStatus.Cancelled" },
        { key: 3, value: "Dummy.GoalStatus.Rollover" },
      ],
    },
  },
  {
    questionId: "GRVW_01_Goal_2",
    questionText: getActorText(
      "Dummy.Forms.GoalReview.Questions.GRVW_01_Singular"
    ), // This is the question text for the status dropdown
    questionType: "GOAL-REVIEW",
    commentsEnabled: true,
    sequenceMap: {
      defaultNextQuestionId: "GSET_01",
      options: null,
    },
    validation: {
      required: true,
    },
    actorRestriction: null,
    goalReviewOptions: {
      additionalQuestions: [
        {
          questionId: "GRVW_01_Goal_2_3000", // This changes per goal & additional question
          questionText: getActorText(
            "Dummy.Forms.GoalReview.Questions.GiveExamples"
          ),
          questionType: "LONGTEXT",
          commentsEnabled: true,
          sequenceMap: {
            // Conditionals aren't supported in goal review - so no need to worry about that on the server side
            conditionallyTriggered: false,
            defaultNextQuestionId: null,
            options: null,
          },
          validation: {
            required: true,
            min: null,
            max: null,
          },
        },
        /*{
          questionId: "GRVW_01_Goal_2_3001",
          questionText: getActorText(
            "How well do you feel you performed with this goal?"
          ),
          questionType: "SLIDER",
          commentsEnabled: true,
          sequenceMap: {
            conditionallyTriggered: false,
            defaultNextQuestionId: null,
            options: null,
          },
          multiChoiceOptions: MockWordSliderOptions,
          validation: {
            required: true,
            min: null,
            max: null,
          },
        },*/
      ],
      goals: [
        {
          title: "Improve initial engagement",
          categoryId: 1,
          field1: "Field 1 text",
          field2: "Field 2 text",
          targetDate: new Date(),
          taskType: "GOAL",
          toDoId: 456,
          dateCompleted: null,
          dateCancelled: null,
        },
      ],
      goalStatusOptions: [
        { key: 1, value: "Dummy.GoalStatus.Achieved" },
        { key: 2, value: "Dummy.GoalStatus.Cancelled" },
        { key: 3, value: "Dummy.GoalStatus.Rollover" },
      ],
    },
  },
];
