import { useTranslation } from "react-i18next";
import { IconButton } from "../common";

interface FormSummaryButtonProps {
  formName: string;
  onUpdateButtonClick(): void;
}

const FormSummaryButtons = ({
  formName,
  onUpdateButtonClick,
}: FormSummaryButtonProps) => {
  const { t } = useTranslation();
  return (
    <>
      <button className="btn-primary block" onClick={onUpdateButtonClick}>
        <span>{t("Common.Update") + " " + t(formName)}</span>
      </button>
      <IconButton
        buttonType="HISTORY"
        displayMode="ICON-ONLY"
        buttonClassName="mr-1 border-0 rounded-md px-3 hover:bg-gray-100"
        iconClassName="text-gray-600"
        onClick={() => {}}
      />
    </>
  );
};

export default FormSummaryButtons;
