import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { debounce } from "debounce";
import TimelineItem from "./TimelineItem";
import TimelineItemLoadingCard from "./TimelineItemLoadingCard";
import TimelineItemDetailDto, {
  TimelineItemType,
} from "../../types/dtos/timeline/TimelineItemDetailDto";
import { ReactComponent as NoTasksImage } from "../../images/noTasks.svg";
import { useTranslation } from "react-i18next";
import ManageCatchUpPopup from "../catch-ups/ManageCatchUpPopup";

interface MyIndividualTimelineItemProps {
  /** The array of objects to show in the timeline */
  items: TimelineItemDetailDto[];
  /** A method which calls the API and provides a full set of timeline events for the user */
  onRequestAllItems(
    callback: (newItems: TimelineItemDetailDto[]) => void
  ): void;
  /** Enable us to show a spinner whilst the data is loading */
  isLoading: boolean;
}

const defaultFilterOption = "ALL";

type TimelineFilterDropdownOptions = TimelineItemType | "ALL";

/** Initially, the active index needs to be the first event in the future */
const getInitialIndexForTimeline = (itemsToFilter: TimelineItemDetailDto[]) => {
  let suggestedIndex = 0;
  const today = dayjs().startOf("day");

  // Relies on the filteredItems array being in date order ascending
  for (var i = 0; i < itemsToFilter.length; i++) {
    const thisItem = itemsToFilter[i];
    const thisItemDate = dayjs(thisItem.dueDate);
    if (thisItemDate >= today) {
      suggestedIndex = i;
      break;
    }
  }

  return suggestedIndex;
};

/** Filter the displayed items based on the value selected in the dropdown */
const filterTimelineDetails = (
  filteredOption: TimelineFilterDropdownOptions,
  itemsToFilter: TimelineItemDetailDto[]
): TimelineItemDetailDto[] => {
  const newItemList = itemsToFilter.filter(
    (td) => filteredOption === defaultFilterOption || td.type === filteredOption
  );
  return newItemList;
};

/** The scrollable timeline shown on the user's own dashboard with meetings, goals etc in */
function TimelineWidget({
  items,
  onRequestAllItems,
  isLoading,
}: MyIndividualTimelineItemProps) {
  const { t } = useTranslation();
  const [hasCalledLoadAll, setHasCalledLoadAll] = useState<boolean>(false);
  const [listVisibleStartingPoint, setListVisibleStartingPoint] = useState<
    number | undefined
  >();
  const [activeTimelineIndex, setActiveTimelineIndex] = useState(0);
  const [filterCategory, setFilterCategory] =
    useState<TimelineFilterDropdownOptions>(defaultFilterOption);

  // Use the collection of items supplied via props as the default set to show
  const [filteredItems, setFilteredItems] =
    useState<TimelineItemDetailDto[]>(items);

  //Modal States
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  /** When the user changes the timeline filter dropdown */
  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFilterValue = e.target.value as TimelineFilterDropdownOptions;
    setFilterCategory(newFilterValue);
  };

  /** Load all items if we haven't already requested them */
  const loadAllTimelineItems = (currentScrollIndex: number) => {
    if (items.length > 0 && !hasCalledLoadAll) {
      setHasCalledLoadAll(true);
      onRequestAllItems((newItems: TimelineItemDetailDto[]) => {
        const newStartIndex = getInitialIndexForTimeline(newItems);
        scrollToItemByIndex(newStartIndex + currentScrollIndex);
      });
    }
  };

  const scrollToItemByIndex = (newActiveIndex: number) => {
    const elementToScrollTo = document.getElementById(
      `timeline-item-${newActiveIndex}`
    );
    if (elementToScrollTo) {
      const container = document.getElementById(
        "scrollable-timeline"
      ) as HTMLDivElement;
      container.scrollTop =
        elementToScrollTo.offsetTop - elementToScrollTo.offsetHeight;
    }
  };

  /** Trigger the call to load the rest of the items if the user
   * reaches the start/end of the list
   */
  const onTimelineScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const scrollAreaElement = event.target as HTMLDivElement;
    const top = scrollAreaElement.scrollTop < 200;
    const bottom =
      scrollAreaElement.scrollHeight - scrollAreaElement.scrollTop ===
      scrollAreaElement.clientHeight;
    if (top || bottom) {
      const visibleItemOffset = getCurrentTopMostVisibleItemOffset();
      loadAllTimelineItems(visibleItemOffset);
    }
  };

  /** Get the offset in terms of array position against the first visible item
   * e.g. if the first future item in the array is at index 4, and the first
   * visible item in the scrolled container is index 10, then the offset is 6
   */
  const getCurrentTopMostVisibleItemOffset = (): number => {
    const container = document.getElementById(
      "scrollable-timeline"
    ) as HTMLDivElement;
    for (var i = 0; i < container.children.length; i++) {
      const element = container.children[i] as HTMLDivElement;
      const yPosition =
        element.offsetTop - element.scrollTop + element.clientTop;

      if (yPosition >= container.scrollTop) {
        return i - listVisibleStartingPoint!; // TODO: Comment properly. Returns a value relative to the start index
      }
    }

    return 0;
  };

  // Debounce the scroll handler so it doesn't fire a crazy amount of times
  // as the user scrolls
  const debouncedScrollHandler = debounce(onTimelineScroll, 250);

  /** When new items are supplied, or the filter changes, update the filtered list */
  useEffect(() => {
    const filterResult = filterTimelineDetails(filterCategory, items);
    const newActiveIndex = getInitialIndexForTimeline(filterResult);
    setFilteredItems(filterResult);
    setActiveTimelineIndex(newActiveIndex);
    setListVisibleStartingPoint(newActiveIndex);
  }, [items, filterCategory]);

  /** Set the scroll start to be the first timeline item with a date of today or onwards
   * so we can scroll back in time
   */
  useEffect(() => {
    if (listVisibleStartingPoint !== undefined && !hasCalledLoadAll) {
      scrollToItemByIndex(listVisibleStartingPoint);
    }
  }, [listVisibleStartingPoint, hasCalledLoadAll]);

  // When the user clicks to add a new Catch Up
  const onModalOpen = () => {
    setModalIsOpen(true);
  };
  const onModalOpenChange = (isOpen: boolean) => {
    setModalIsOpen(isOpen);
  };

  const handlePrimaryButtonClick = () => {
    // If readonly, just close the popup and reset it
    setModalIsOpen(false);
    return;
  };

  return (
    <>
      <div className="m-3 flex flex-col">
        <div className="flex my-3">
          <select
            className="header-select grow text-left"
            value={filterCategory}
            onChange={handleCategoryChange}
          >
            <option value={defaultFilterOption}>
              {t("Timeline.Filters.All")}
            </option>
            <option value="TASK">{t("Timeline.Filters.Tasks")}</option>
            <option value="JOURNEY">{t("Timeline.Filters.Journeys")}</option>
            <option value="CATCH-UP">{t("Timeline.Filters.CatchUps")}</option>
          </select>
        </div>
        {isLoading && !hasCalledLoadAll && (
          <>
            <TimelineItemLoadingCard />
            <TimelineItemLoadingCard />
          </>
        )}
        {!isLoading && filteredItems.length === 0 && (
          <div className="flex justify-center flex-col text-center text-gray-400 mb-4">
            <NoTasksImage width={"18rem"} />
            <span>No upcoming tasks!</span>
            <span>Change the filter option or add a task</span>
          </div>
        )}
        {filteredItems.length > 0 && (
          <div
            id="scrollable-timeline"
            className="snap-y overflow-scroll overflow-x-hidden mb-4"
            style={{ maxHeight: "340px" }}
            onScroll={debouncedScrollHandler}
          >
            {filteredItems.map((td, ix) => (
              <div
                className="snap-start"
                key={`timeline-item-${ix}`}
                id={`timeline-item-${ix}`}
              >
                <TimelineItem
                  key={td.titleTranslationKey}
                  titleTranslationKey={td.titleTranslationKey}
                  dueDate={td.dueDate}
                  type={td.type}
                  isActive={ix === activeTimelineIndex}
                />
              </div>
            ))}
          </div>
        )}
        <div className="block">
          <span className="mr-1">
            <button className="btn-primary block w-full ">
              <span>{t("Timeline.Button.AddTask")}</span>
            </button>
          </span>
          <span className="ml-1">
            <button className="btn-primary block w-full" onClick={onModalOpen}>
              <span>{t("Timeline.Button.AddCatchUp")}</span>
            </button>
          </span>
        </div>
        <ManageCatchUpPopup
          isOpen={modalIsOpen}
          onOpenChange={onModalOpenChange}
        />
      </div>
    </>
  );
}

export default TimelineWidget;
