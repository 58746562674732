import SendNowCheckboxTreeApiResponseDto from "../../types/dtos/admin/SendNowCheckboxTreeApiResponseDto";

const MockSendNowLocationData = {
  nodes: [
    {
      value: "1",
      label: "Africa"
    },
    {
      value: "2",
      label: "America"
    },
    {
      value: "3",
      label: "Asia"
    },
    {
      value: "4",
      label: "Europe",
      children: [
        { value: "5", label: "France" },
        { value: "6", label: "Germany" },
        {
          value: "7", label: "United Kingdom", children: [
            {
              value: "8", label: "England", children: [
                { value: "12", label: "Birmingham" },
                { value: "13", label: "London" },
                { value: "14", label: "Manchester" },
              ]
            },
            { value: "9", label: "Scotland" },
            { value: "10", label: "Northern Ireland" },
            { value: "11", label: "Wales" },
          ]
        }
      ],
    }
  ]
} as SendNowCheckboxTreeApiResponseDto;

export default MockSendNowLocationData;
