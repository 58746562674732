import { dateHelper } from "../../helpers";
import AdminConfigApiResponseDto from "../../types/dtos/admin/AdminConfigApiResponseDto";
import { KeyValuePair } from "../../types/generic";
import DurationType from "../../types/generic/DurationType";
import JourneyMode from "../../types/generic/JourneyMode";

const modes: KeyValuePair<JourneyMode, string>[] = [
  {
    key: "AUTOMATED",
    value: "Journey.Mode.Automated"
  },
  {
    key: "MANUAL-ONLY",
    value: "Journey.Mode.ManualOnly"
  },
  {
    key: "HIDDEN-ON-DASHBOARD",
    value: "Journey.Mode.HiddenOnDashboard"
  }
]

const frequencyTypes: KeyValuePair<DurationType, string>[] = [
  {
    key: "DAYS",
    value: "Common.Days"
  },
  {
    key: "WEEKS",
    value: "Common.Weeks"
  },
  {
    key: "MONTHS",
    value: "Common.Months"
  },
  {
    key: "YEARS",
    value: "Common.Years"
  }
]

const appraisalLevels: KeyValuePair<string, string>[] = [
  {
    key: "50",
    value: "Dummy.AppraisalLevel.Leadership"
  },
  {
    key: "51",
    value: "Dummy.AppraisalLevel.Management"
  },
  {
    key: "53",
    value: "Dummy.AppraisalLevel.Team"
  }
]

const MockAdminConfigExitData = {
  clientFormId: 200,
  clientFormTitle: "Dummy.FormTitle.ExitQuestionnaire",
  defaultSettings: {
    mode: "EXIT",
    intro: "",
    themeColour: "#f0f0f0"
  },
  existingOverrides: [
    {
      overrideId: 40,
      appraisalLevelName: "Dummy.AppraisalLevel.Leadership",
      mode: "EXIT",
      send: true    
    },
    {
      overrideId: 41,
      appraisalLevelName: "Dummy.AppraisalLevel.Management",
      mode: "EXIT",
      send: false
    }
  ],
  newOverride: {
    appraisalLevels: appraisalLevels,
    modes: modes,
    frequencyTypes: frequencyTypes
  }
} as AdminConfigApiResponseDto;

export default MockAdminConfigExitData;
