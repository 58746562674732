import React, { useEffect, useReducer, Reducer } from "react";
import { useTranslation } from "react-i18next";
import { MainContainer } from "../components/layout";
import UpdatesWidget from "../components/manager-dashboard/NotificationWidget";
import OverallStatisticsWidget from "../components/manager-dashboard/OverallStatisticsWidget";
import PeopleWidget from "../components/manager-dashboard/PeopleWidget";
import AppContext from "../state/AppContext";
import ManagerDashboardApiResponseDto from "../types/dtos/dashboards/ManagerDashboardApiResponseDto";
import ManagerDashboardOverviewScoreDto from "../types/dtos/dashboards/ManagerDashboardOverviewScoreDto";
import ManagerDashboardPersonDto from "../types/dtos/dashboards/ManagerDashboardPersonDto";
import ManagerDashboardUpdateDto from "../types/dtos/dashboards/ManagerDashboardUpdateDto";

interface ManagerDashboardState {
  waitingForApiResult: boolean;
  statistics: ManagerDashboardOverviewScoreDto[];
  users: ManagerDashboardPersonDto[];
  updates: ManagerDashboardUpdateDto[];
}

function ManagerDashboard() {
  const { t } = useTranslation();
  const appContext = React.useContext(AppContext);

  const [state, setState] = useReducer<
    Reducer<ManagerDashboardState, Partial<ManagerDashboardState>>
  >((state, newState) => ({ ...state, ...newState }), {
    waitingForApiResult: true,
    statistics: [],
    users: [],
    updates: [],
  });

  useEffect(() => {
    // Call the API to load the necessary state
    fetch("/api/dashboards/manager")
      .then((res) => res.json())
      .then((json: ManagerDashboardApiResponseDto) => {
        setState({
          waitingForApiResult: false,
          statistics: json.statistics,
          users: json.users,
          updates: json.updates,
        });
      })
      .catch((e) => {
        console.error(e.message);
        // TODO: Show some form of error message
      });

    appContext.setPageTitle(t("Pages.ManagerDashboard.PageTitle"));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateFormSummarySection = (clientFormId: number) => {
    alert("navigate to collab doc: " + clientFormId);
  };

  return (
    <MainContainer>
      <OverallStatisticsWidget
        statistics={state.statistics}
        isLoading={state.waitingForApiResult}
      />

      <UpdatesWidget
        users={state.users}
        updates={state.updates}
        isLoading={state.waitingForApiResult}
      />

      <PeopleWidget
        users={state.users}
        isLoading={state.waitingForApiResult}
        onTriggerSectionUpdate={updateFormSummarySection}
      />
    </MainContainer>
  );
}

export default ManagerDashboard;
