import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faInfo } from "@fortawesome/pro-solid-svg-icons";

interface InfoBannerProps {
  text?: string;
  children?: React.ReactNode;
}

/** Renders the children, if supplied, otherwise falls back to displaying the text prop */
function InfoBanner({ text, children }: InfoBannerProps) {
  return (
    <div className="mb-2 py-1 text-sm px-4 bg-blue-50 text-blue-500 rounded-y-md rounded-r-md border-l-2 border-l-blue-500">
      <div className="fa-layers fa-fw mr-1">
        <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon>
        <FontAwesomeIcon icon={faInfo} size="xs" className="text-white" />
      </div>
      {children ? children : text}
    </div>
  );
}

export default InfoBanner;
