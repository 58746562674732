import * as SliderPrimitive from "@radix-ui/react-slider";
import cx from "classnames";
import produce from "immer";
import { useEffect } from "react";
import { BaseSelectableItem, ValidationResult } from "../../types/forms";
import { SliderScoreDisplayType } from "../../types/generic";
import ValidationWarning from "./ValidationWarning";

interface SliderProps {
  /** The options to build the scale up with. Should have linear `value` properties, all numeric */
  scaleOptions: BaseSelectableItem[];
  onChange(newState: BaseSelectableItem[]): void;
  /** The colour of the sliding draggable handle */
  mainColourClassName?: string;
  /** If you want a different colour track to the left hand side of the drag handle, specify a CSS class name */
  selectedTrackBgColourClassName?: string;
  /**The hex of the form colour used in collaborative docs */
  formBackgroundColorStyle?: string | null;
  /** The colour of the track to the right hand side of the drag handle */
  emptyTrackBgColourClassName?: string;
  /** Optional. To customise the top margin on the slider */
  topMarginClassName?: string;
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  showNumericValue?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
  isReadOnly?: boolean;
  /** Whether or not to show the numeric scale, or the selected items word, for the item selected in the slider */
  selectedValueDisplayMode: SliderScoreDisplayType;
}

const Slider = ({
  scaleOptions,
  onChange,
  selectedValueDisplayMode,
  showValidationErrors = false,
  validationResult = null,
  isReadOnly = false,
  mainColourClassName = "bg-white shadow-sm h-2 m-auto top-0 bottom-0",
  selectedTrackBgColourClassName = mainColourClassName,
  formBackgroundColorStyle = null,
  emptyTrackBgColourClassName: trackBgColourClassName = "bg-gray-300",
  topMarginClassName = "mt-5",
}: SliderProps) => {
  useEffect(() => {}, [scaleOptions]);

  /** Deselect everything but the selected value */
  const handleSliderChange = (newValues: number[]) => {
    // The slider control gives us an array of numbers, as it supports multiple thumbs,
    // but we only use one, so can access the first in the array
    const selectedNumericValue = newValues[0];
    const nextState = produce(scaleOptions, (draft) => {
      draft.forEach((x) => {
        x.isSelected = parseInt(x.value.toString()) === selectedNumericValue;
      });
    });
    onChange(nextState);
  };

  // Don't render if no options are supplied
  if (!scaleOptions || scaleOptions.length === 0) return null;

  const sliderMinOption = scaleOptions.reduce(function (prev, curr) {
    return prev.value < curr.value ? prev : curr;
  });

  const sliderMaxOption = scaleOptions.reduce(function (prev, curr) {
    return prev.value > curr.value ? prev : curr;
  });

  let selectedValue: number[] | undefined;
  let selectedValueText: string = "";
  const selectedScaleOption = scaleOptions.find((x) => x.isSelected);
  if (selectedScaleOption) {
    selectedValue = [parseInt(selectedScaleOption.value.toString())];
    selectedValueText = selectedScaleOption.text;
  }

  const defaultValue = parseInt(
    scaleOptions[Math.floor(scaleOptions.length / 2)].value.toString()
  );
  const sliderMin = parseInt(sliderMinOption.value.toString());
  const sliderMax = parseInt(sliderMaxOption.value.toString());

  // Use the default value if there isn't already a selected value
  const inputValue = selectedValue ? selectedValue : [defaultValue];

  // Set the value to display to the user, if the component is set to display numeric values
  const displayValue =
    selectedValue && selectedValue.length > 0
      ? selectedValue[0].toString()
      : "";
  return (
    <>
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      <SliderPrimitive.Root
        min={sliderMin}
        value={inputValue}
        max={sliderMax}
        disabled={isReadOnly}
        step={1}
        onValueChange={handleSliderChange}
        aria-label="value"
        className={cx(
          topMarginClassName,
          "relative flex w-full h-5 touch-none items-center"
        )}
      >
        <SliderPrimitive.Track
          className={cx(
            "relative w-full h-1 grow rounded-full",
            trackBgColourClassName
          )}
        >
          <SliderPrimitive.Range
            className={cx(
              "absolute rounded-full",
              `${
                validationResult && validationResult.isValid
                  ? mainColourClassName
                  : "bg-transparent"
              }`
            )}
            style={{ backgroundColor: `#${formBackgroundColorStyle}` }}
          />
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb
          className={cx(
            "block h-6 w-6 rounded-full cursor-pointer shadow-xl hover:drop-shadow-[1px_1px_5px_rgba(255,255,255,0.40)]",
            mainColourClassName,
            "focus:outline-none",
            isReadOnly ? "cursor-not-allowed" : ""
          )}
          style={{ backgroundColor: `#${formBackgroundColorStyle}` }}
        />
      </SliderPrimitive.Root>
      <div className="w-full flex mb-3 text-xs">
        {selectedValueDisplayMode === "NUMERIC" && (
          <>
            <span className="flex-auto w-1/3">{sliderMinOption.text}</span>
            <span className="flex-auto w-1/3 text-center opacity-80">
              {`${displayValue} / ${sliderMaxOption.value}`}
            </span>
            <span className="flex-auto w-1/3 text-right opacity-80">
              {sliderMaxOption.text}
            </span>
          </>
        )}
        {selectedValueDisplayMode === "WORD" && (
          <>
            <span className="flex-auto w-1/3 text-xs italic opacity-80">
              {sliderMinOption.text}
            </span>
            <span className="flex-auto w-1/3 text-center font-medium opacity-80">
              {selectedValueText}
            </span>
            <span className="flex-auto w-1/3 text-right text-xs italic opacity-80">
              {sliderMaxOption.text}
            </span>
          </>
        )}
        {selectedValueDisplayMode === "OFF" && (
          <>
            <span className="flex-auto w-half text-xs opacity-80">
              {sliderMinOption.text}
            </span>
            <span className="flex-auto w-half text-right opacity-80">
              {sliderMaxOption.text}
            </span>
          </>
        )}
      </div>
    </>
  );
};

export default Slider;
