import { KeyValuePair } from "../../../../types/generic";
import { GenericDropDownList } from "../../../common";

interface GoalReviewStatusDropDownProps {
  /** The Id of the goal being reviewed */
  toDoId: number;
  /** The selected option id for the goal status, if there is one */
  currentValue: number | null;
  isReadOnly: boolean;
  dropDownItems: KeyValuePair<number, string>[];
  formColor?: string;
  onChange(toDoId: number, selectedOptionId: number | null): void;
  /** If you want to tie the dropdown to a label, supply an id value for the <select> */
  inputId?: string | undefined;
}

function GoalReviewStatusDropDown({
  toDoId,
  currentValue,
  isReadOnly,
  dropDownItems,
  inputId,
  formColor,
  onChange,
}: GoalReviewStatusDropDownProps) {
  const handleDropDownChange = (selectedValue: string | null) => {
    if (selectedValue && !isNaN(Number(selectedValue))) {
      onChange(toDoId, Number(selectedValue));
    } else {
      onChange(toDoId, null);
    }
  };

  const bgColorClassName = "bg-transparent border border-white";

  const listItems = dropDownItems.map((gso) => ({
    key: gso.key.toString(),
    value: gso.value,
  }));
  return (
    <GenericDropDownList
      items={listItems}
      currentValue={currentValue ? currentValue.toString() : null}
      isReadOnly={isReadOnly}
      onChange={handleDropDownChange}
      className={`py-1`}
      bgColorClassName={bgColorClassName}
      inputId={inputId}
    />
  );
}

export default GoalReviewStatusDropDown;
