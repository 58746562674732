import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../state/UserContext";
import { isDate } from "lodash";
import { ValidationResult } from "../../types/forms";
import {
  AutoComplete,
  DateInput,
  Label,
  ModalPopup,
  TextArea,
} from "../common";
import PeoplePicker from "../common/PeoplePicker";
import { AutoCompleteSelectedValue, KeyValuePair } from "../../types/generic";

const modalTitle = "CatchUps.Popup.RequestCatchUp";
const participantInputId = "catch-up-participant";
const datePickerInputId = "catch-up-date";
const subjectFieldId = "catch-up-subject";

interface ManageCatchUpPopupProps {
  /** Whether or not the modal popup is open */
  isOpen: boolean;
  /** Handle modal closing in the parent state */
  onOpenChange(open: boolean): void;
}

function ManageCatchUpPopup({ isOpen, onOpenChange }: ManageCatchUpPopupProps) {
  // Context
  const userContext = useContext(UserContext);

  // State
  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false);
  const [participantId, setParticipantId] = useState<number>();
  const [targetDate, setTargetDate] = useState<Date | null>(null);
  const [subject, setSubject] = useState<string>("");
  const [autoCompleteValue, setAutoCompleteValue] = useState<
    string | undefined
  >();

  const autoCompleteSuggestions: KeyValuePair<string, string>[] = [
    { key: "B", value: "Behaviours" },
    { key: "F", value: "Future" },
    { key: "AU", value: "About Us" },
    { key: "G", value: "Goals" },
    { key: "L", value: "Learning" },
  ];

  // Events
  const handleAutoCompleteValueSelected = (
    newValue: AutoCompleteSelectedValue | null
  ) => {
    if (newValue && newValue.isCustomValue) {
      setAutoCompleteValue(newValue.displayText);
    }
  };

  const handleParticipantChange = (newValue: number) => {
    setParticipantId(newValue);
  };

  const handleTargetDateChange = (newValue: Date | null) => {
    setTargetDate(newValue);
  };
  const handleSubjectChange = (newValue: string) => {
    setSubject(newValue);
  };

  const handlePrimaryButtonClick = () => {
    {
      // const catchUpIsValid = taskObject && taskObject.isValid();

      // if (selectedTaskType === undefined || !taskIsValid) {
      //   setShowValidationErrors(true);
      //   return;
      // }
      // Reset to not display any validation issues
      setShowValidationErrors(false);
      // Close the modal
      onOpenChange(false);
    }
  };

  // Validation
  const participantFieldIsValid = participantId && !isNaN(participantId);
  const targetDateIsValid = targetDate && isDate(targetDate);
  const subjectFieldIsValid = subject && subject.trim().length > 0;
  const validationResults = {
    valid: new ValidationResult(true, []),
    invalid: new ValidationResult(false, [{ errorType: "REQUIRED" }]),
  };

  return (
    <ModalPopup
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      onPrimaryButtonClick={handlePrimaryButtonClick}
      primaryButtonText={t("Common.Save")}
      title={t(modalTitle)}
      onSecondaryButtonClick={() => onOpenChange(false)}
      secondaryButtonText={t("Common.Cancel")}
    >
      <>
        <div className="mb-2">
          <Label
            htmlFor={participantInputId}
            text={t("CatchUps.Popup.WhoWith")}
          />
          <PeoplePicker
            onPeoplePickerChange={handleParticipantChange}
            inputId={participantInputId}
            className="block mt-2 p-2 w-full bg-gray-100 border-0"
            showValidationErrors={showValidationErrors}
            validationResult={
              participantFieldIsValid
                ? validationResults.valid
                : validationResults.invalid
            }
          />
        </div>
        <div className="mb-2">
          <Label
            htmlFor={datePickerInputId}
            text={t("CatchUps.Popup.TargetDate")}
          />
          <DateInput
            onChange={handleTargetDateChange}
            inputId={datePickerInputId}
            showValidationErrors={showValidationErrors}
            validationResult={
              targetDateIsValid
                ? validationResults.valid
                : validationResults.invalid
            }
            value={targetDate}
          />
        </div>
        <div>
          <Label htmlFor={subjectFieldId} text={t("CatchUps.Popup.Subject")} />

          <AutoComplete
            selectedValue={autoCompleteValue}
            onValueSelected={handleAutoCompleteValueSelected}
            valuesList={autoCompleteSuggestions}
            placeholder=""
            className="block mt-2 p-2 w-full bg-gray-100 border-0 rounded-md outline-none focus:ring-0"
            inputId="autocomplete-input"
            suggestionPropertyToDisplay="VALUE"
            allowCustomTypedValues={true}
          />
        </div>
      </>
    </ModalPopup>
  );
}

export default ManageCatchUpPopup;
