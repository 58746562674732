import { ReactComponent as NoJourneyImage } from "../../images/noJourney.svg";
function JourneyEmptyState() {
  return (
    <div>
      <div className="flex flex-col text-center text-white/60">
        <NoJourneyImage width={"12rem"} height={"10rem"} className="m-auto" />
        <span>There are no question to answer at this time</span>
        <span>Click on one of the tabs below to update your journeys</span>
      </div>
    </div>
  );
}

export default JourneyEmptyState;
