import dayjs from "dayjs";
import { isDate } from "lodash";
import { dateHelper } from "../../helpers";
import { KeyValuePair, ModifyStatus } from "../generic";
import TaskType from "./TaskType";

class BaseEditableTask {
  constructor(
    modifyStatus: ModifyStatus,
    toDoId: number | null,
    title: string | null,
    targetDate: Date | null,
    field1: string | null,
    taskType: TaskType
  ) {
    this.modifyStatus = modifyStatus;
    this.toDoId = toDoId;
    this.title = title;

    // If the target date comes in as a string, it's a JSON date from the API,
    // so needs to be converted into a date, then from UTC to local
    if (typeof targetDate === "string") {
      const utcDate = dayjs(targetDate).toDate();
      this.targetDate = dateHelper.convertUtcDateToLocal(utcDate);
    } else {
      this.targetDate = targetDate;
    }
    this.field1 = field1;
    this.taskType = taskType;
  }

  modifyStatus: ModifyStatus;
  toDoId: number | null;
  title: string | null;
  targetDate: Date | null;
  field1: string | null;
  taskType: TaskType;

  isValid(): boolean {
    return (
      this.title !== null &&
      this.title.length > 0 &&
      this.targetDate !== null &&
      isDate(this.targetDate) &&
      this.field1 !== null &&
      this.field1.length > 0
    );
  }
}

export type EditableTask =
  | EditableAction
  | EditableLearningAction
  | EditableGoal;

export class EditableAction extends BaseEditableTask {
  constructor(
    modifyStatus: ModifyStatus,
    toDoId: number | null,
    title: string | null,
    targetDate: Date | null,
    field1: string | null
  ) {
    super(modifyStatus, toDoId, title, targetDate, field1, "ACTION");
  }
}

export class EditableLearningAction extends BaseEditableTask {
  constructor(
    modifyStatus: ModifyStatus,
    toDoId: number | null,
    title: string | null,
    targetDate: Date | null,
    field1: string | null,
    field2: string | null,
    field3: string | null
  ) {
    super(modifyStatus, toDoId, title, targetDate, field1, "LEARNING");
    this.field2 = field2;
    this.field3 = field3;
  }

  field2: string | null;
  field3: string | null;

  isValid(): boolean {
    return (
      super.isValid() &&
      this.field2 !== null &&
      this.field2.trim().length > 0 &&
      this.field3 !== null &&
      this.field3.trim().length > 0
    );
  }
}

export class EditableGoal extends BaseEditableTask {
  constructor(
    modifyStatus: ModifyStatus,
    toDoId: number | null,
    title: string | null,
    targetDate: Date | null,
    field1: string | null,
    field2: string | null,
    categoryId: number | null
  ) {
    super(modifyStatus, toDoId, title, targetDate, field1, "GOAL");
    this.field2 = field2;
    this.categoryId = categoryId;
  }

  field2: string | null;
  categoryId: number | null;

  /** Validation of goal category needs to be handled separately, depending on whether there are goal categories available */
  isValid(): boolean {
    return (
      super.isValid() && this.field2 !== null && this.field2.trim().length > 0
    );
  }

  /** If this user has goal categories, ensure they have selected a valid one from the set */
  goalCategoryIsValid(
    availableGoalCategories: KeyValuePair<number, string>[] | null
  ): boolean {
    // If this user doesn't have goal categories, the field isn't displayed, so is valid
    if (!availableGoalCategories || availableGoalCategories.length === 0)
      return true;
    // User has goal categories, check a selection has been made
    if (!this.categoryId) return false;
    // Return true if a valid category is selected
    return (
      availableGoalCategories.find((x) => x.key === this.categoryId) !==
      undefined
    );
  }
}
