import * as DialogPrimitive from "@radix-ui/react-dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import cx from "classnames";

interface ModalPopupProps {
  children: React.ReactNode;
  isOpen: boolean;
  onOpenChange(open: boolean): void;
  onPrimaryButtonClick(): void;
  onSecondaryButtonClick?(): void;
  primaryButtonText: string;
  /** Optional. If you only want a single button, don't supply a value for this */
  secondaryButtonText?: string;
  /** Pass a null title to not show the title bar */
  title: string | null;
  titleDescription?: string;
  /** Whether or not to display the `x` button to close the modal */
  showCloseIcon?: boolean;
  allowYOverflow?: boolean;
  isPrimaryButtonDisabled?: boolean;
}

const ModalPopup = ({
  children,
  isOpen,
  onOpenChange,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonText,
  secondaryButtonText,
  title,
  titleDescription,
  showCloseIcon = true,
  allowYOverflow = true,
  isPrimaryButtonDisabled = false
}: ModalPopupProps) => {
  return (
    <DialogPrimitive.Root open={isOpen} onOpenChange={onOpenChange}>
      <DialogPrimitive.Portal container={document.body}>
        <DialogPrimitive.Overlay
          forceMount
          className="fixed inset-0 z-20 bg-black/50"
        />
        <DialogPrimitive.Content
          forceMount
          className={cx(
            "fixed z-50",
            "w-[95vw] rounded-md p-4 px-10 md:w-1/2",
            "top-[40%] left-[50%] -translate-x-[50%] -translate-y-[50%]",
            "bg-white",
            "focus:outline-none"
          )}
        >
          {title !== null && title.length > 0 && (
            <>
              <DialogPrimitive.Title className="text-2xl font-semibold text-gray-700 border-b border-gray-100 text-center pb-2">
                {title}
              </DialogPrimitive.Title>
              {titleDescription && (
                <DialogPrimitive.Description className="mt-2 text-sm font-normal text-gray-700 ">
                  {titleDescription}
                </DialogPrimitive.Description>
              )}
            </>
          )}

          <div
            className={cx(
              "mt-2 space-y-2 text-gray-500 max-h-96",
              allowYOverflow ? "overflow-y-auto" : ""
            )}
          >
            {children}
          </div>

          <div className="mt-4 pt-4 text-right border-t border-gray-100">
            {secondaryButtonText && onSecondaryButtonClick && (
              <button
                className="inline-block btn-secondary m-2"
                onClick={onSecondaryButtonClick}
              >
                {secondaryButtonText}
              </button>
            )}
            <button
              className="inline-block btn-primary m-2"
              onClick={onPrimaryButtonClick}
              disabled={isPrimaryButtonDisabled}
            >
              {primaryButtonText}
            </button>
          </div>

          {showCloseIcon && (
            <DialogPrimitive.Close
              className={cx(
                "absolute top-3.5 right-3.5 inline-flex items-center justify-center rounded-full p-1",
                "focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
              )}
            >
              <FontAwesomeIcon
                icon={faTimes}
                size="lg"
                className="text-gray-500 hover:text-gray-700 "
              />
            </DialogPrimitive.Close>
          )}
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.Root>
  );
};

export default ModalPopup;
