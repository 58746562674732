import { memo, useContext, useEffect } from "react";
import { isEqual } from "lodash";
import {
  multipleChoiceQuestionHelper,
  questionTextHelper,
  questionTypeHelper,
  userDetailsHelper,
} from "../../../helpers";
import UserContext from "../../../state/UserContext";
import {
  BaseUserDetailsDto,
  UserBasicDetailsDto,
} from "../../../types/dtos/generic";
import {
  FormQuestion,
  MultipleChoiceOption,
  QuestionAnswer,
  QuestionAnswerValue,
  ValidationResult,
} from "../../../types/forms";
import { BasicQuestionAnswerValue } from "../../../types/forms/QuestionAnswerValue";
import { CollabDocQuestionInput } from "../../collab-docs";
import { Label } from "../../common";
import QuestionReadonlyBadge from "../QuestionReadonlyBadge";

interface BasicSubQuestionProps {
  question: FormQuestion;
  currentAnswer: QuestionAnswer | null;
  isReadOnly: boolean;
  subjectUser: BaseUserDetailsDto;
  showValidationErrors: boolean;
  validationResult: ValidationResult | null;
  formColor: string;
  participants: Array<UserBasicDetailsDto>;
  /** Handle the value changing for this question (in the state in parent components) */
  onValueChange(questionId: string, newValue: BasicQuestionAnswerValue): void;
}

/** We need to use React.memo as questions are rendered as a list,
 * and with lists, when the parent state updates, every child component re-renders
 * But in complex forms, that can lead to a slow UI where lots of components re-render unncessarily.
 * This is equivalent to the old "shouldComponentUpdate" effectively.
 * https://staleclosures.dev/preventing-list-rerenders/
 */
const subQuestionPropsAreEqual = (
  prevProps: Readonly<BasicSubQuestionProps>,
  nextProps: Readonly<BasicSubQuestionProps>
): boolean => {
  if (prevProps.showValidationErrors !== nextProps.showValidationErrors)
    return false;

  if (!isEqual(prevProps.currentAnswer, nextProps.currentAnswer)) return false;

  if (!isEqual(prevProps.validationResult, nextProps.validationResult))
    return false;

  return true;
};

/** Supports simple question types, like long text, multi choice, sliders, short text, as part of a wider question
 * (e.g. as part of a goal being reviewed - with the overall question being the goal, and child questions like this component).
 * Currently only used for GoalReview questions, only in collab docs. So if this gets used by another scenario, this might need some adapting */
const BasicSubQuestion = memo(
  ({
    question,
    currentAnswer,
    isReadOnly,
    subjectUser,
    showValidationErrors,
    validationResult,
    formColor,
    participants,
    onValueChange,
  }: BasicSubQuestionProps) => {
    // Context
    const userContext = useContext(UserContext);

    // State / local variables
    let multipleChoiceQuestionOptions: MultipleChoiceOption[] = [];
    const inputId = `question_input_${question.questionId}`;
    const questionDisplayText = questionTextHelper.getQuestionText(
      question,
      subjectUser,
      userContext,
      "COLLAB-DOC"
    );

    // Update the multiple choice options when the question changes
    useEffect(() => {
      multipleChoiceQuestionOptions =
        multipleChoiceQuestionHelper.initialiseMultipleChoiceOptions(
          question,
          currentAnswer ? currentAnswer.answer : null,
          true
        );
    }, [question]); // eslint-disable-line react-hooks/exhaustive-deps

    // Events

    /** Get the selected option id(s) and update the state on the parent */
    const onMultipleChoiceQuestionValueChange = (
      updatedMultiChoiceOptions: MultipleChoiceOption[]
    ) => {
      const returnValue =
        multipleChoiceQuestionHelper.getAnswersFromMultiChoiceOptions(
          updatedMultiChoiceOptions,
          question
        );
      onValueChange(question.questionId, returnValue);
    };

    // If this is an advanced question, it can't be a "BasicSubQuestion"
    if (questionTypeHelper.isAdvancedQuestionType(question.questionType)) {
      return null;
    }

    // Set the multiple choice options to be selected where appropriate (based on the current answer)
    multipleChoiceQuestionOptions =
      multipleChoiceQuestionHelper.initialiseMultipleChoiceOptions(
        question,
        currentAnswer ? currentAnswer.answer : null,
        true
      );

    // Check the actor restrictions, and lock the question if it can't be answered by the current user
    const questionIsLockedForCurrentUser = !question.userCanAnswer(
      userContext.id,
      subjectUser.userId
    );

    // Get the name to display in the read-only/locked question badge
    const otherUserDisplayName = userDetailsHelper.getDisplayName(
      subjectUser.userId,
      participants
    );

    const questionComponent = (
      <CollabDocQuestionInput
        question={question}
        isReadOnly={isReadOnly}
        isLockedForCurrentUser={questionIsLockedForCurrentUser}
        inputId={inputId}
        currentAnswer={currentAnswer}
        multipleChoiceQuestionOptions={multipleChoiceQuestionOptions}
        showValidationErrors={showValidationErrors}
        subjectUser={subjectUser}
        validationResult={validationResult}
        formColor={formColor}
        participants={participants}
        newTasks={null /* Not supported in basic questions */}
        onChangeQuestionNewTasks={
          () => undefined /* Not supported in basic questions */
        }
        onMultipleChoiceQuestionValueChange={
          onMultipleChoiceQuestionValueChange
        }
        onValueChange={
          (newValue: QuestionAnswerValue) =>
            onValueChange(
              question.questionId,
              newValue as BasicQuestionAnswerValue
            ) // Conversion required as only basic (non-advanced question) answers are valid as sub question answers
        }
      />
    );

    return (
      <div>
        <Label text={questionDisplayText} htmlFor={inputId} />
        {questionIsLockedForCurrentUser && (
          <QuestionReadonlyBadge otherUserName={otherUserDisplayName} />
        )}
        <div>{questionComponent}</div>
      </div>
    );
  },
  subQuestionPropsAreEqual
);

export default BasicSubQuestion;
