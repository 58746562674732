/** A set of helper functions for changing the UI - shouldn't be any business logic in here */
const interactionHelper = {
  scrollMainContainerToTop: function () {
    const container = document.getElementById("main-container-inner");
    if (container) {
      container.scroll({
        left: 0,
        top: 0,
        behavior: "smooth",
      });
    }
  },
};

export default interactionHelper;
