import { BaseUserDetailsDto } from "../../types/dtos/generic";
import JourneyFormGroupDto from "../../types/dtos/journeys/JourneyFormGroupDto";
import {
  BehaviourFormQuestions,
  FutureFormQuestions,
  LearningAndDevelopmentFormQuestions,
  OpinionYouFormQuestions,
  OpinionCompanyQuestions,
} from "./MockInternalDemoFormData";

const EmployeeSubject: BaseUserDetailsDto = {
  userId: 101,
  firstName: "Sarah",
  fullName: "Marsh",
};

const ManagerSubject: BaseUserDetailsDto = {
  userId: 102,
  firstName: "Jack",
  fullName: "Smith",
};

const MockInternalDemoJourneyData = {
  InitialJourney: function (isManager: boolean) {
    return {
      subjectUser: isManager ? ManagerSubject : EmployeeSubject,
      journeyType: "COLLAB-DOC",
      backgroundColour: "281F51",
      redirectOnSubmit: false,
      welcomeMessage:
        "<h1>Welcome to your career journey</h1><p>There are a few questions to work through to help us understand your aspirations. Click on the button below to get started. Your manager will be notified when you're done.</p>",
      forms: [
        {
          title: "Dummy.Forms.Future.Title",
          questions: FutureFormQuestions,
        },
        {
          title: "Dummy.Forms.Behaviours.Title",
          questions: BehaviourFormQuestions,
        },
        {
          title: "Dummy.Forms.LearnDev.Title",
          questions: LearningAndDevelopmentFormQuestions,
        },
        {
          title: "Dummy.Forms.OpinionYou.Title",
          questions: OpinionYouFormQuestions,
        },
        {
          title: "Dummy.Forms.OpinionCo.Title",
          questions: OpinionCompanyQuestions,
        },
      ],
      journeyReference: "ae2dc563-4fd0-4bd1-bc3c-d981aa6587c6",
    } as JourneyFormGroupDto;
  },
  FutureOnlyJourney: function (isManager: boolean) {
    return {
      subjectUser: isManager ? ManagerSubject : EmployeeSubject,
      journeyType: "COLLAB-DOC",
      backgroundColour: "281F51",
      redirectOnSubmit: false,
      welcomeMessage:
        "<h1>Your future and potential</h1><p>There are a few questions to work through to help us understand your aspirations. Click on the button below to get started. Your manager will be notified when you're done.</p>",
      forms: [
        {
          title: "Dummy.Forms.Future.Title",
          questions: FutureFormQuestions,
        },
      ],
      journeyReference: "4592867e-9305-49a5-b7f4-35ac7b363dc0",
    } as JourneyFormGroupDto;
  },
};

export default MockInternalDemoJourneyData;
