import React from "react";
import cx from "classnames";

interface MainContainerType {
  children: React.ReactNode;
  rightColumnChildren?: React.ReactNode;
  applySidePadding?: boolean;
}

const InnerContainerId = "main-container-inner";

export const ScrollPageMainContainerToTop = () => {
  const container = document.getElementById(InnerContainerId);
  if (container) {
    container.scrollTo({ top: 0, behavior: "smooth" });
  }
};

const MainContainer = ({
  children,
  rightColumnChildren,
  applySidePadding = true,
}: MainContainerType) => {
  return (
    <div
      id="main-container"
      className={cx("flex flex-col", rightColumnChildren ? "lg:flex-row" : "")}
    >
      <div
        id={InnerContainerId}
        className={cx(
          "overflow-auto overflow-x-hidden",
          rightColumnChildren ? "lg:w-3/4" : "lg:w-full",
          applySidePadding ? "px-6" : ""
        )}
        style={{ maxHeight: "calc(100vh - 3.5rem)" }}
      >
        {children}
      </div>
      {rightColumnChildren && (
        <div
          id="right-bar-container"
          className="lg:w-1/3 p-6 bg-gray-100/60"
          style={{ height: "calc(100vh - 3.5rem)" }}
        >
          {/* Using CSS calc for height to use 100% of the viewport height minus the height of the top bar */}
          {rightColumnChildren}
        </div>
      )}
    </div>
  );
};

export default MainContainer;
