import { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as Logo } from "../../images/ttNavLogo.svg";
import {
  faHouseUser,
  faUserFriends,
  faTools,
  faAnalytics,
  faQuestionCircle,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faHouseUser as fasHouseUser,
  faUserFriends as fasUserFriends,
  faTools as fasTools,
  faAnalytics as fasAnalytics,
  faQuestionCircle as fasQuestionCircle,
  faThLarge as fasThLarge,
} from "@fortawesome/pro-solid-svg-icons";
import { faThLarge } from "@fortawesome/pro-duotone-svg-icons";
// import { useTranslation } from "react-i18next";
import AppRoutes from "../../routes/AppRoutes";
import UserContext from "../../state/UserContext";
import { useTranslation } from "react-i18next";
import AppContext from "../../state/AppContext";
import OverrideNavItem from "../../types/nav/OverrideNavItem";

function LeftNav() {
  let displayedNavItems = 2;
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const [overrideNavItem, setOverrideNavItem] =
    useState<OverrideNavItem | null>(appContext.overrideNavItem);
  const { t } = useTranslation();

  useEffect(() => {
    setOverrideNavItem(appContext.overrideNavItem);
  }, [appContext.overrideNavItem]);

  const canDisplayNavItem = (boolValueToCheck: boolean | null) => {
    const maxNavItems = 5;
    const anotherNavItemWouldNotExceedMax =
      displayedNavItems + 1 <= maxNavItems;

    if (boolValueToCheck === null) {
      return anotherNavItemWouldNotExceedMax;
    }

    if (boolValueToCheck && anotherNavItemWouldNotExceedMax) {
      displayedNavItems += 1;
      return true;
    }
    return false;
  };

  const shouldShowAnalyticsNavItems =
    userContext.hasAnalyticsAccess || userContext.isManager;

  return (
    <div>
      <Link
        className="block px-4 py-2 m-0 text-center"
        to={AppRoutes.myDashboard}
      >
        <Logo className="px-2 pb-2 m-auto" width={"12rem"} />
      </Link>

      <nav className="flex flex-col">
        <NavLink
          className={({ isActive }) =>
            isActive || overrideNavItem === "MyDashboard"
              ? "active-nav-link"
              : "inactive-nav-link"
          }
          to={AppRoutes.myDashboard}
        >
          <FontAwesomeIcon
            icon={fasHouseUser}
            size="lg"
            className="nav-icon-active fa-fw"
          />
          <FontAwesomeIcon
            icon={faHouseUser}
            size="lg"
            className="nav-icon-inactive fa-fw"
          />

          <span className="px-4">{t("Nav.LargeScreen.MyDashboard")}</span>
        </NavLink>
        {canDisplayNavItem(userContext.isManager) && (
          <NavLink
            className={({ isActive }) =>
              isActive || overrideNavItem === "ManagerDashboard"
                ? "active-nav-link"
                : "inactive-nav-link"
            }
            to={AppRoutes.managerDashboard}
          >
            <FontAwesomeIcon
              icon={fasUserFriends}
              size="lg"
              className="nav-icon-active fa-fw"
            />
            <FontAwesomeIcon
              icon={faUserFriends}
              size="lg"
              className="nav-icon-inactive fa-fw"
            />

            <span className="px-4">
              {t("Nav.LargeScreen.ManagerDashboard")}
            </span>
          </NavLink>
        )}
        <NavLink
          className={({ isActive }) =>
            isActive ? "active-nav-link" : "inactive-nav-link"
          }
          to={`${AppRoutes.missingFromDemo}${AppRoutes.companyDashboard}`}
        >
          <FontAwesomeIcon
            icon={fasThLarge}
            size="lg"
            className="nav-icon-active fa-fw"
          />
          <FontAwesomeIcon
            icon={faThLarge}
            size="lg"
            className="nav-icon-inactive fa-fw"
          />

          <span className="px-4">{t("Nav.LargeScreen.CompanyDashboard")}</span>
        </NavLink>
        {canDisplayNavItem(userContext.isAdmin) && (
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-nav-link" : "inactive-nav-link"
            }
            to={AppRoutes.admin.path}
          >
            <FontAwesomeIcon
              icon={fasTools}
              size="lg"
              className="nav-icon-active fa-fw"
            />
            <FontAwesomeIcon
              icon={faTools}
              size="lg"
              className="nav-icon-inactive fa-fw"
            />

            <span className="px-4">{t("Nav.LargeScreen.Admin")}</span>
          </NavLink>
        )}
        {canDisplayNavItem(shouldShowAnalyticsNavItems) && (
          <NavLink
            className={({ isActive }) =>
              isActive ? "active-nav-link" : "inactive-nav-link"
            }
            to={`${AppRoutes.missingFromDemo}${AppRoutes.analytics}`}
          >
            <FontAwesomeIcon
              icon={fasAnalytics}
              size="lg"
              className="nav-icon-active fa-fw"
            />
            <FontAwesomeIcon
              icon={faAnalytics}
              size="lg"
              className="nav-icon-inactive fa-fw"
            />

            <span className="px-4">{t("Nav.LargeScreen.Analytics")}</span>
          </NavLink>
        )}

        <NavLink
          className={({ isActive }) =>
            isActive ? "active-nav-link" : "inactive-nav-link"
          }
          to={`${AppRoutes.missingFromDemo}${AppRoutes.help}`}
        >
          <FontAwesomeIcon
            icon={fasQuestionCircle}
            size="lg"
            className="nav-icon-active fa-fw"
          />
          <FontAwesomeIcon
            icon={faQuestionCircle}
            size="lg"
            className="nav-icon-inactive fa-fw"
          />

          <span className="px-4">{t("Nav.LargeScreen.Help")}</span>
        </NavLink>
      </nav>
    </div>
  );
}

export default LeftNav;
