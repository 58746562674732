import { useContext, useEffect, useState } from "react";
import { t } from "i18next";
import {
  Label,
  Slider,
  RadioButtonGroup,
  TextArea,
  CheckBoxList,
  FormDropDownList,
  Badge,
  SafeRenderHtml,
  TextInput,
} from "../common";
import {
  CheckBoxListDisplayMode,
  GoalReviewQuestionAnswerValue,
  MultipleChoiceOption,
  QuestionAnswerValue,
  QuestionNewTasks,
  ValidationResult,
} from "../../types/forms";
import FormQuestion from "../../types/forms/FormQuestion";
import JourneyCommentControl from "./JourneyCommentControl";
import multipleChoiceQuestionHelper from "../../helpers/multipleChoiceQuestionHelper";
import JourneyKeyboardPager from "./JourneyKeyboardPager";
import BehaviourQuestion from "../forms/advanced/BehaviourQuestion";
import BehaviourQuestionAnswerValue from "../../types/forms/BehaviourQuestionAnswerValue";
import { questionTextHelper } from "../../helpers";
import {
  BaseUserDetailsDto,
  UserBasicDetailsDto,
} from "../../types/dtos/generic";
import UserContext from "../../state/UserContext";
import React from "react";
import DevelopmentQuestion from "../forms/advanced/DevelopmentQuestion";
import DevelopmentQuestionAnswerValue from "../../types/forms/DevelopmentQuestionAnswerValue";
import GoalSettingQuestion from "../forms/advanced/GoalSettingQuestion";
import GoalReviewQuestion from "../forms/advanced/GoalReviewQuestion";
import BehaviourMoreInfo from "../forms/advanced/behaviours/BehaviourMoreInfo";

interface JourneyQuestionProps {
  question: FormQuestion;
  currentValue: QuestionAnswerValue;
  subjectUser: BaseUserDetailsDto;
  newTasks: QuestionNewTasks | null;
  onValueChange(newValue: QuestionAnswerValue): void;
  commentValue: string | undefined;
  onCommentChange(newValue: string | null): void;
  showValidationErrors: boolean;
  enableNextButton: boolean;
  showSubmitBtn: boolean;
  formColor: string;
  onNextPageClick(): void;
  /** When a use adds/edits/deletes a new task */
  onChangeQuestionNewTasks(questionTasks: QuestionNewTasks): void;
}

/** This is the component which renders the correct question type based on the details passed in.
 * State is managed at the JourneyForm level.
 */
function JourneyQuestion({
  question,
  currentValue,
  subjectUser,
  onValueChange,
  commentValue,
  onCommentChange,
  showValidationErrors,
  enableNextButton,
  showSubmitBtn,
  onNextPageClick,
  newTasks,
  formColor,
  onChangeQuestionNewTasks,
}: JourneyQuestionProps) {
  // Context
  const userContext = useContext(UserContext);

  // Multiple choice question setup
  const getMultipleChoiceOptions = (): MultipleChoiceOption[] => {
    return multipleChoiceQuestionHelper.initialiseMultipleChoiceOptions(
      question,
      currentValue,
      true
    );
  };

  // State and constants
  const inputIdAttributeValue = "journey_current_question";
  const [validationResult, setValidationResult] =
    useState<ValidationResult | null>(null);

  const [multipleChoiceQuestionOptions, setMultipleChoiceQuestionOptions] =
    useState<MultipleChoiceOption[]>(getMultipleChoiceOptions());

  // Update the local multiple choice option state when the question or answer changes
  useEffect(() => {
    const newOptionState = getMultipleChoiceOptions();
    setMultipleChoiceQuestionOptions(newOptionState);
  }, [question, currentValue]);

  // Update the validation errors when the question, answer or tasks change
  useEffect(() => {
    const tasksToValidate = newTasks !== null ? newTasks.tasks : null;
    setValidationResult(
      question.validate(
        currentValue,
        tasksToValidate,
        userContext.id,
        subjectUser.userId
      )
    );
  }, [question, currentValue, newTasks]);

  // Events

  /** Get the selected option id(s) and update the state on the parent */
  const onMultipleChoiceQuestionValueChange = (
    updatedMultiChoiceOptions: MultipleChoiceOption[]
  ) => {
    const returnValue =
      multipleChoiceQuestionHelper.getAnswersFromMultiChoiceOptions(
        updatedMultiChoiceOptions,
        question
      );
    onValueChange(returnValue);
  };

  let questionComponent = null;
  switch (question.questionType) {
    case "SLIDER":
      const sliderScoreDisplayMode =
        multipleChoiceQuestionHelper.getSliderScoreDisplayMode(
          multipleChoiceQuestionOptions
        );
      questionComponent = (
        <Slider
          scaleOptions={multipleChoiceQuestionOptions}
          onChange={onMultipleChoiceQuestionValueChange}
          showValidationErrors={showValidationErrors}
          validationResult={validationResult}
          selectedTrackBgColourClassName="bg-white"
          emptyTrackBgColourClassName="bg-white/40"
          selectedValueDisplayMode={sliderScoreDisplayMode}
        />
      );
      break;
    case "RADIOLIST":
      questionComponent = (
        <RadioButtonGroup
          uniqueFieldName={`radio_q_${question.questionId}`}
          values={multipleChoiceQuestionOptions}
          onChange={onMultipleChoiceQuestionValueChange}
          showValidationErrors={showValidationErrors}
          validationResult={validationResult}
        />
      );
      break;
    case "CHECKLIST":
      const checkboxCount = question.multiChoiceOptions!.length;
      // Change the display mode based on how many checkboxes there are to show
      let displayMode: CheckBoxListDisplayMode = "horizontal";
      if (checkboxCount > 10) {
        displayMode = "grid";
      } else if (checkboxCount > 2) {
        displayMode = "vertical";
      }

      questionComponent = (
        <CheckBoxList
          uniqueFieldName={`checklist_q_${question.questionId}`}
          values={multipleChoiceQuestionOptions}
          onChange={onMultipleChoiceQuestionValueChange}
          displayMode={displayMode}
          showValidationErrors={showValidationErrors}
          validationResult={validationResult}
          textColourClassName="text-white"
          iconColourClassName="text-white"
          checkboxBorderClassName="border border-white/40"
          selectMinCount={question.validation.min}
          selectMaxCount={question.validation.max}
        />
      );
      break;
    case "LONGTEXT":
      questionComponent = (
        <TextArea
          inputId={inputIdAttributeValue}
          value={currentValue as string}
          onChange={onValueChange}
          minRows={3}
          maxRows={6}
          placeholder={t("Common.TextboxDefaultPlaceholder")}
          className="mt-2 p-2 w-full rounded-md bg-white/20 border-0 placeholder:text-white/40 placeholder:text-sm focus:outline-none focus:ring-0"
          showValidationErrors={showValidationErrors}
          validationResult={validationResult}
        />
      );
      break;
    case "SHORTTEXT":
      questionComponent = (
        <TextInput
          inputId={inputIdAttributeValue}
          value={currentValue as string}
          onChange={onValueChange}
          placeholder={t("Common.TextboxDefaultPlaceholder")}
          className="mt-2 p-2 w-full rounded-md bg-white/20 border-0 placeholder:text-white/40 placeholder:text-sm focus:outline-none focus:ring-0"
          showValidationErrors={showValidationErrors}
          validationResult={validationResult}
        />
      );
      break;
    case "DROPDOWNLIST":
      questionComponent = (
        <FormDropDownList
          inputId={inputIdAttributeValue}
          values={multipleChoiceQuestionOptions}
          onChange={onMultipleChoiceQuestionValueChange}
          className="block my-3 w-full rounded-md bg-white/20 border-0 focus:outline-none focus:ring-0"
          showValidationErrors={showValidationErrors}
          validationResult={validationResult}
        />
      );
      break;
    case "BEHAVIOUR":
      questionComponent = (
        <BehaviourQuestion
          questionId={question.questionId}
          attributes={question.behaviourOptions!.attributes}
          behaviour={question.behaviourOptions!.behaviour}
          scalePoints={question.behaviourOptions!.scale}
          singleScaleLabel={question.behaviourOptions!.singleScaleLabel}
          infoTooltipContent={question.behaviourOptions!.infoTooltipContent}
          showAddTaskButton={question.behaviourOptions!.showAddTaskButton}
          showValidationErrors={showValidationErrors}
          onValueChange={onValueChange}
          currentValues={currentValue as BehaviourQuestionAnswerValue[]}
          selectedTrackBgColourClassName="bg-white"
          emptyTrackBgColourClassName="bg-white/40"
          subjectUser={subjectUser}
          addTaskButtonClassName="journey-btn-primary"
          sliderScoreDisplayType={
            question.behaviourOptions!.sliderScoreDisplayType
          }
          isReadOnly={false}
          newTasks={newTasks}
          onChangeQuestionNewTasks={onChangeQuestionNewTasks}
        />
      );
      break;
    case "LEARNING-AND-DEVELOPMENT":
      questionComponent = (
        <DevelopmentQuestion
          formType="JOURNEY"
          question={question}
          isReadOnly={false}
          newTasks={newTasks}
          onChangeQuestionNewTasks={onChangeQuestionNewTasks}
          onValueChange={onValueChange}
          currentValues={currentValue as DevelopmentQuestionAnswerValue[]}
          showValidationErrors={showValidationErrors}
          validationResult={validationResult}
        />
      );
      break;
    case "GOAL-SETTING":
      questionComponent = (
        <GoalSettingQuestion
          question={question}
          isReadOnly={false}
          addTaskButtonClassName="journey-btn-primary"
          showValidationErrors={showValidationErrors}
          newTasks={newTasks}
          onChangeQuestionNewTasks={onChangeQuestionNewTasks}
        />
      );
      break;
    case "GOAL-REVIEW":
      questionComponent = (
        <GoalReviewQuestion
          formType="JOURNEY"
          question={question}
          currentValues={currentValue as GoalReviewQuestionAnswerValue[]}
          isReadOnly={false}
          showValidationErrors={false}
          onValueChange={onValueChange}
          subjectUser={subjectUser}
          formColor={formColor}
          participants={
            [] as Array<UserBasicDetailsDto> /* A required prop, but there's only ever one particpant in journeys */
          }
        />
      );
      break;
    default:
      questionComponent = null;
      break;
  }

  const handleEnterPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && enableNextButton) {
      onNextPageClick();
    }
  };

  const questionDisplayText = questionTextHelper.getQuestionText(
    question,
    subjectUser,
    userContext,
    "JOURNEY"
  );

  return (
    <div className="w-full text-white">
      <div className="question" onKeyPress={handleEnterPress}>
        {question.questionType === "READONLY" && (
          <SafeRenderHtml
            htmlText={questionDisplayText}
            containerClassName="my-1 px-2 journey-custom-content"
          />
        )}
        {question.questionType !== "READONLY" && (
          <>
            <div className="mb-2 flex flex-row font-medium items-start">
              <Label
                text={questionDisplayText}
                htmlFor={inputIdAttributeValue}
                className="text-lg"
              />
              {question.behaviourOptions?.behaviour.key && (
                <BehaviourMoreInfo
                  behaviour={question.behaviourOptions!.behaviour}
                  infoTooltipContent={
                    question.behaviourOptions!.infoTooltipContent
                  }
                  classNames="text-white/60"
                />
              )}
              {!question.validation.required && (
                <Badge text={t("Common.Optional")} />
              )}
            </div>
            {questionComponent}
          </>
        )}
      </div>

      <div className="flex flex-row justify-end space-x-4 w-full">
        {question.commentsEnabled && question.questionType !== "LONGTEXT" && (
          <JourneyCommentControl
            currentValue={commentValue}
            onChange={onCommentChange}
            questionId={question.questionId}
          />
        )}
        <div className="hidden lg:block">
          <JourneyKeyboardPager
            enableNextButton={enableNextButton}
            onNextPageClick={onNextPageClick}
            showSubmitBtn={showSubmitBtn}
          />
        </div>
      </div>
    </div>
  );
}

export default JourneyQuestion;
