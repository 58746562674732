import React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import cx from "classnames";

interface TooltipProps {
  /** This is the trigger element of the tooltip - mousing over this will trigger the popup */
  triggerElement: React.ReactNode;
  /** The content to display in the tooltip */
  content: string;
}

/** Pass an element as the `triggerElement` prop as the target for the tooltip */
const Tooltip = ({ triggerElement, content }: TooltipProps) => {
  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root>
        <TooltipPrimitive.Trigger asChild>
          {triggerElement}
        </TooltipPrimitive.Trigger>
        <TooltipPrimitive.Content
          sideOffset={4}
          className={cx(
            "radix-side-top:animate-slide-down-fade",
            "radix-side-right:animate-slide-left-fade",
            "radix-side-bottom:animate-slide-up-fade",
            "radix-side-left:animate-slide-right-fade",
            "inline-flex items-center rounded-md px-4 py-2.5",
            "bg-gray-200 border-gray-400"
          )}
        >
          <TooltipPrimitive.Arrow className="fill-current text-gray-200" />
          <span className="block text-xs leading-none text-gray-700">
            {content}
          </span>
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};

export default Tooltip;
