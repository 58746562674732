import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../state/AppContext";
import { MainContainer } from "../components/layout";
import AppRoutes from "./AppRoutes";

const MissingFromDemo = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    appContext.setPageTitle("Not available");
  }, []);

  const onLinkClick = () => {
    navigate(AppRoutes.myDashboard);
  };

  return (
    <MainContainer>
      <div>
        <p className="mt-2 mb-4">
          <em>
            We aren't quite ready to show this feature yet, check back soon
          </em>
        </p>
        <button className="btn-primary" onClick={onLinkClick}>
          Back to My Journey
        </button>
      </div>
    </MainContainer>
  );
};

export default MissingFromDemo;
