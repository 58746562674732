import produce from "immer";
import goalReviewHelper from "../../../helpers/goalReviewHelper";
import {
  BaseUserDetailsDto,
  UserBasicDetailsDto,
} from "../../../types/dtos/generic";
import {
  FormQuestion,
  FormType,
  GoalReviewQuestionAnswerValue,
  ValidationResult,
} from "../../../types/forms";
import GoalReviewStatusList from "./goal-review/GoalReviewStatusList";
import ReviewableGoalWithQuestions from "./goal-review/ReviewableGoalWithQuestions";

interface GoalReviewQuestionProps {
  formType: FormType;
  question: FormQuestion;
  currentValues: GoalReviewQuestionAnswerValue[] | null;
  isReadOnly: boolean;
  formColor: string;
  participants: Array<UserBasicDetailsDto>;
  /** Who the form is regarding. Used to determine question text for sub questions */
  subjectUser: BaseUserDetailsDto;
  /** Whether or not to display the validation warnings */
  showValidationErrors: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
  /** Notify the parent component(s) about a changed answer within this goal */
  onValueChange(newState: GoalReviewQuestionAnswerValue[]): void;
}

function GoalReviewQuestion({
  formType,
  question,
  currentValues,
  isReadOnly,
  subjectUser,
  showValidationErrors,
  validationResult = null,
  formColor,
  participants,
  onValueChange,
}: GoalReviewQuestionProps) {
  // Events

  /** When the goal status dropdown is changed */
  const onGoalStatusChange = (
    toDoId: number,
    selectedOptionId: number | null
  ) => {
    const originalState = currentValues ? currentValues : [];
    const nextState = produce(originalState, (draft) => {
      // Search for a match, and if one is found, update the selected status id
      const match = draft.find((x) => x.toDoId === toDoId);
      if (match) {
        match.goalStatusOptionId = selectedOptionId;
      } else {
        // No answer for this goal yet, create one with the selected status
        draft.push({
          toDoId: toDoId,
          goalStatusOptionId: selectedOptionId,
          otherAnswers: [],
        });
      }
    });
    onValueChange(nextState);
  };

  /** When a non-goal-status answer is changed (e.g. a textbox against a goal) */
  const onGoalQuestionAnswerChange = (
    newAnswer: GoalReviewQuestionAnswerValue
  ) => {
    const oldState = currentValues ? currentValues : [];
    const newState = produce(oldState, (draft) => {
      const match = draft.find((x) => x.toDoId === newAnswer.toDoId);
      // Update the answer if it exists already, otherwise add it to the collection of goal answers
      if (match) {
        if (newAnswer.otherAnswers && newAnswer.otherAnswers.length > 0) {
          match.otherAnswers = [...newAnswer.otherAnswers];
        } else {
          match.otherAnswers = [];
        }
      } else {
        draft.push(newAnswer);
      }
    });

    onValueChange(newState);
  };

  const hasGoalStatusOptions =
    question.goalReviewOptions?.goalStatusOptions &&
    question.goalReviewOptions?.goalStatusOptions.length > 0;

  const hasGoalsToReview =
    question.goalReviewOptions?.goals &&
    question.goalReviewOptions?.goals.length > 0;

  // Ensure we have the necessary config/goals
  if (!hasGoalStatusOptions || !hasGoalsToReview) {
    return null;
  }

  // If there aren't any additional questions, render the goals as a table, with a status dropdown
  // (This is usually what is seen in "journey mode", but can theoretically be used in collab docs as well, if there are no additional questions)
  if (goalReviewHelper.getReviewFormat(formType, question) === "STATUS-ONLY") {
    return (
      <GoalReviewStatusList
        goals={question.goalReviewOptions!.goals}
        currentValues={currentValues}
        goalStatusOptions={question.goalReviewOptions!.goalStatusOptions}
        isReadOnly={isReadOnly}
        formColor={formColor}
        onGoalStatusChange={onGoalStatusChange}
        validationResult={validationResult}
        showValidationErrors={showValidationErrors}
      />
    );
  }

  // If there are additional questions, then it's a goal per (root level) question,
  // to facilitate comments per question id, and the active question highlighting

  const singleGoalToReview = question.goalReviewOptions!.goals[0];
  const singleGoalAnswer =
    currentValues && currentValues.length > 0
      ? currentValues.find((x) => x.toDoId === singleGoalToReview.toDoId)
      : null;

  return (
    <ReviewableGoalWithQuestions
      question={question}
      goal={singleGoalToReview}
      currentValue={singleGoalAnswer !== undefined ? singleGoalAnswer : null}
      goalStatusOptions={question.goalReviewOptions!.goalStatusOptions}
      isReadOnly={isReadOnly}
      validationResult={validationResult}
      showValidationErrors={showValidationErrors}
      onGoalStatusChange={onGoalStatusChange}
      subjectUser={subjectUser}
      formColor={formColor}
      onValueChange={onGoalQuestionAnswerChange}
      participants={participants}
    />
  );
}

export default GoalReviewQuestion;
