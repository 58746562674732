import React, { useEffect, useReducer, Reducer } from "react";
import { useTranslation } from "react-i18next";
import { MainContainer } from "../components/layout";
import AppContext from "../state/AppContext";
import { Link, useParams } from "react-router-dom";
import AdminConfigApiResponseDto from "../types/dtos/admin/AdminConfigApiResponseDto";
import { DefaultSettingDto } from "../types/dtos/admin/DefaultSettingDto";
import { OverrideDto } from "../types/dtos/admin/OverrideDto";
import AppRoutes from "./AppRoutes";
import { TabDetails } from "../components/common/Tabs";
import { Tabs } from "../components/common";
import DefaultSettingsTab from "../components/admin/Configuration/DefaultSettingsTab";
import OverridesTab from "../components/admin/Configuration/OverridesTab";
import { NewOverrideDto } from "../types/dtos/admin/NewOverrideDto";
import { WindowDto } from "../types/dtos/admin/WindowDto";

interface AdminConfigState {
  waitingForApiResult: boolean;
  clientFormId: number;
  displayTabs: TabDetails[];
  defaultSettings: DefaultSettingDto | undefined,
  defaultSettingsWindows?: WindowDto[];
  existingOverrides: OverrideDto[],
  newOverrideData: NewOverrideDto | undefined
}

function AdminConfiguration() {
  const { t } = useTranslation();
  const { clientFormId } = useParams();
  const appContext = React.useContext(AppContext);

  const [state, setState] = useReducer<
    Reducer<AdminConfigState, Partial<AdminConfigState>>
  >((state, newState) => ({ ...state, ...newState }), {
    waitingForApiResult: true,
    clientFormId: 0,
    displayTabs: [],
    defaultSettings: undefined,
    defaultSettingsWindows: undefined,
    existingOverrides: [],
    newOverrideData: undefined
  });


  useEffect(() => {
    // Call the API to load the necessary state
    // TODO: When this is actually plugged into the API, we need to remove this 'if' statement and keep the code from the else section. 
    // The only purpose this has is to test the UI works with specifically windowed journeys.
    let fetchApiUrl;
    if (clientFormId === "12") {
      // WINDOWED
      fetchApiUrl = `/api/admin/config/windowed/${clientFormId}`;
    } else if (clientFormId === "15") {
      // INDUCTION
      fetchApiUrl = `/api/admin/config/induction/${clientFormId}`;
    } else if (clientFormId === "16") {
      // EXIT
      fetchApiUrl = `/api/admin/config/exit/${clientFormId}`;
    } else {
      // EVERYTHING ELSE
      fetchApiUrl = `/api/admin/config/${clientFormId}`;
    }

    fetch(fetchApiUrl)
      .then((res) => res.json())
      .then((json: AdminConfigApiResponseDto) => {
        //  console.log("Json", json);
        setState({
          waitingForApiResult: false,
          clientFormId: json.clientFormId,
          displayTabs: [],
          defaultSettings: json.defaultSettings,
          defaultSettingsWindows: json.defaultSettings.windows,
          existingOverrides: json.existingOverrides,
          newOverrideData: json.newOverride
        });

        setPageTitle(json.clientFormTitle);
      })
      .catch((e) => {
        console.error(e.message);
        // TODO: Show some form of error message
      });

    setPageTitle("");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    var tabs = loadDisplayTabs();
    setState({ displayTabs: tabs });
  }, [state.defaultSettings, state.defaultSettingsWindows, state.existingOverrides, state.newOverrideData]);

  const setPageTitle = (
    titleSuffix: string,
  ) => {
    const pageTitlePrefix = "Pages.Admin.PageTitle.Configuration";
    const pageTitle = `${t(pageTitlePrefix)}: ${t(titleSuffix) || ""}`;

    // Set the main page title
    appContext.setPageTitle(pageTitle);
  };

  const onUpdateExistingOverrides = (overrides: any) => {
    setState({ existingOverrides: overrides });
  }

  const onUpdateDefaultSettingWindows = (windows: any) => {
    setState({ defaultSettingsWindows: windows });
  }

  const loadDisplayTabs = () => {
    const tabs = Array<TabDetails>();

    if (state.defaultSettings != undefined
      && state.newOverrideData != undefined) {
      tabs.push({
        title: t("Pages.Admin.Tabs.Headings.DefaultSettings"),
        content: (<DefaultSettingsTab
          settings={state.defaultSettings}
          windows={state.defaultSettingsWindows}
          onUpdateWindows={onUpdateDefaultSettingWindows} />),
        displayUpdateIcon: false
      });

      tabs.push({
        title: t("Pages.Admin.Tabs.Headings.Overrides"),
        content: (<OverridesTab newOverrideData={state.newOverrideData}
          existingOverrides={state.existingOverrides}
          onUpdateExistingOverrides={onUpdateExistingOverrides}
          defaultMode={state.defaultSettings.mode}
          defaultFrequencyValue={state.defaultSettings.frequencyValue}
          defaultFrequencyType={state.defaultSettings.frequencyType}
          defaultDaysTo={state.defaultSettings.daysTo}
          defaultDaysFrom={state.defaultSettings.daysFrom} />),
        displayUpdateIcon: false
      });
    }

    return tabs;
  };

  return (
    <MainContainer>
      <div className="pt-2">
        <Link to={AppRoutes.admin.path} className="btn-primary">
          {t("Common.Back")}
        </Link>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-0.5">
        <div className="mt-4 col-span-1 md:col-span-10 md:col-start-2">
          <Tabs
            tabs={state.displayTabs}
            selectedTabClassNames="radix-state-active: bg-[#EFEFF0] hover:!bg-[#EFEFF0] rounded-md" />
        </div>
      </div>
    </MainContainer>
  );
}

export default AdminConfiguration;
