import { Fragment, ReactNode } from "react";
import { Menu, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export interface DropDownMenuItem {
  text: string;
  /** Set a value if the menu's EventType is "NAVIGATE" */
  href?: string | undefined;
  /** Used for custom click events on items */
  customArg?: any | undefined;
  customIcon?: ReactNode | undefined;
}

export interface DropDownNavMenuProps {
  menuButtonClassName?: string;
  eventType: "NAVIGATE" | "EVENT";
  items: DropDownMenuItem[];
  customButtonContents?: ReactNode | undefined;
  onItemClick?(itemIndex: number, customArg: any | undefined): void | undefined;
}

/** A form field containing a dropdownlist (i.e. a `select` element) */
const DropDownMenu = ({
  items,
  eventType,
  onItemClick,
  customButtonContents = undefined,
  menuButtonClassName = "inline-flex justify-center w-full bg-white text-sm font-medium text-gray-700 rounded-full hover:bg-gray-100 focus:outline-none",
}: DropDownNavMenuProps) => {
  const { t } = useTranslation();

  const handleItemClick = (index: number, clickedItem: DropDownMenuItem) => {
    if (onItemClick) {
      onItemClick(index, clickedItem.customArg);
    }
  };

  const menuButtonContents =
    customButtonContents === undefined ? (
      <FontAwesomeIcon icon={faChevronDown} className="text-gray-500 m-4" />
    ) : (
      customButtonContents
    );

  return (
    <Menu as="div" className="relative inline-block text-left z-1">
      <Menu.Button className={menuButtonClassName}>
        {menuButtonContents}
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {items.map((item, itemIndex) => (
              <Menu.Item key={item.text}>
                <div>
                  {eventType === "NAVIGATE" && (
                    <Link
                      to={item.href || ""}
                      className="block my-1 text-gray-600 font-medium hover:bg-gray-100 py-2 px-4"
                    >
                      {t(item.text)}
                      {item.customIcon ? item.customIcon : null}
                    </Link>
                  )}
                  {eventType === "EVENT" && (
                    <span
                      className="block my-1 text-gray-600 font-medium hover:bg-gray-100 py-2 px-4"
                      onClick={() => handleItemClick(itemIndex, item)}
                    >
                      {t(item.text)}
                      {item.customIcon ? item.customIcon : null}
                    </span>
                  )}
                </div>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DropDownMenu;
