import { Markup } from "interweave";
import { t } from "i18next";

interface JourneyWelcomeMessageProps {
  htmlText: string;
  onHide(): void;
}

function JourneyWelcomeMessage({
  htmlText,
  onHide,
}: JourneyWelcomeMessageProps) {
  return (
    <div className="flex flex-col justify-between h-full">
      <div className="journey-custom-content">
        <Markup content={htmlText} />
      </div>
      <div className="mt-4 text-center md:text-right flex-end">
        <button
          className="bg-white font-medium text-gray-700 rounded-md px-2 py-1 hover:shadow-md hover:shadow-white/20"
          onClick={onHide}
        >
          {t("Journey.Button.StartJourney")}
        </button>
      </div>
    </div>
  );
}

export default JourneyWelcomeMessage;
