import SendNowUserApiResponseDto from "../../types/dtos/admin/SendNowUserApiResponseDto";

const MockSendNowUsersData = {
  users: [
    {
      name: "Sean McSweeney",
      jobTitle: "Dummy.JobTitle.Developer"
    },
    {
      name: "Sean Yearsley",
      jobTitle: "Dummy.JobTitle.SeniorDeveloper"
    },
    {
      name: "Steve Kenniard",
      jobTitle: "Dummy.JobTitle.TechnicalDirector"
    },
    {
      name: "Sarah Marsh",
      jobTitle: "Dummy.JobTitle.FrontOfHouseManager"
    },
    {
      name: "Oliver Wright",
      jobTitle: "Dummy.JobTitle.RestaurantManager"
    },
    {
      name: "Holly Dean",
      jobTitle: "Dummy.JobTitle.HousekeepingManager"
    },
    {
      name: "Nathaniel Melina",
      jobTitle: "Dummy.JobTitle.QATester"
    },
    {
      name: "Darrell Kinslee",
      jobTitle: "Dummy.JobTitle.QATester"
    },
    {
      name: "Rolph Clem",
      jobTitle: "Dummy.JobTitle.Marketing"
    },
    {
      name: "Hilary McKinley",
      jobTitle: "Dummy.JobTitle.CEO"
    }
  ]
} as SendNowUserApiResponseDto;

export default MockSendNowUsersData;
