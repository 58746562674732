const AppRoutes = {
  admin: {
    path: "/admin",
    pathAfterSendNowComplete: "/admin/send-now-complete",
  },
  adminConfiguration: {
    path: "/admin/configuration/:clientFormId",
    generateRoute: (clientFormId: string): string => {
      return AppRoutes.adminConfiguration.path.replace(
        ":clientFormId",
        clientFormId
      );
    },
  },
  adminPeople: "/og-app/admin",
  analytics: "/og-app/analytics",
  companyDashboard: "/company",
  collaborativeDocument: {
    path: "/collab-doc/:answerSetUniqueId",
    generateRoute: (
      answerSetUniqueId: string,
      showPostJourneyModal: boolean
    ): string => {
      let output = AppRoutes.collaborativeDocument.path.replace(
        ":answerSetUniqueId",
        answerSetUniqueId
      );

      if (showPostJourneyModal) {
        output += "/post-journey";
      }

      return output;
    },
  },
  componentsDemo: "/components-demo",
  help: "/og-app/help",
  home: "/",
  myDashboard: "/me",
  managerDashboard: "/team",
  missingFromDemo: "/not-available-yet",
  profile: "/og-app/profile",
  sendNow: {
    path: "/admin/send-now/:clientFormId",
    generateRoute: (clientFormId: string): string => {
      return AppRoutes.sendNow.path.replace(":clientFormId", clientFormId);
    },
  },
};

export default AppRoutes;
