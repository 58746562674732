import { t } from "i18next";
import { UserContextInterface } from "../state/UserContext";
import { BaseUserDetailsDto } from "../types/dtos/generic";
import { ActorQuestionText, FormQuestion, FormType } from "../types/forms";
import goalReviewHelper from "./goalReviewHelper";

const employeeNameToken: string = "{{subject}}";
const clientNameToken: string = "{{client}}";

export const questionTextHelper = {
  /** Get the translated question text for the logged in user, with the subject name and client name tokens replaced for the appropriate values */
  getQuestionText: function (
    question: FormQuestion,
    subject: BaseUserDetailsDto,
    loggedInUser: UserContextInterface,
    formType: FormType
  ): string {
    if (question.questionType === "BEHAVIOUR") {
      return t(question.behaviourOptions!.behaviour.value);
    } else if (question.questionType === "GOAL-REVIEW") {
      if (
        goalReviewHelper.getReviewFormat(formType, question) ===
        "STATUS-AND-ADDITIONAL-QUESTIONS"
      ) {
        const goalName = question.goalReviewOptions?.goals[0].title;
        return goalName ? goalName : "";
      }
    }

    return this.getActorText(question.questionText, subject, loggedInUser);
  },
  /** Get the correct, translated text for the logged in user, with the subject name and client name tokens replaced for the appropriate values */
  getActorText: function (
    actorText: ActorQuestionText,
    subject: BaseUserDetailsDto,
    loggedInUser: UserContextInterface
  ) {
    const untranslatedText =
      subject.userId === loggedInUser.id
        ? actorText.employee
        : actorText.manager;

    let output = t(untranslatedText);
    output = output.replace(employeeNameToken, subject.firstName);
    output = output.replace(clientNameToken, loggedInUser.client.name);
    return output;
  },
};

export default questionTextHelper;
