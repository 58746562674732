import { useEffect, useState, ReactNode } from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropDownMenu, { DropDownMenuItem } from "./DropDownMenu";

/** The type for Tabs, specifying the props required */
export interface TabDetails {
  /** The title for this tab */
  title: string;
  /** The content to render when this tab is selected */
  content: ReactNode;
  /** The toggle whether or not the tab should display it has an update */
  displayUpdateIcon: boolean;
  onClickEvent?(): void | undefined;
}

interface TabsProps {
  tabs: TabDetails[];
  selectFirstTabByDefault?: boolean;
  selectedTabClassNames?: string;
  /** To enable responsive tabs with a drop down for further tabs, set a number here */
  maxTabsOnSmallScreens?: number | null;
}

/** A tab component - just supply an array of Tabs (`title`, `content` as an element to render) */
const Tabs = ({
  tabs,
  selectFirstTabByDefault = true,
  selectedTabClassNames = "",
  maxTabsOnSmallScreens = null,
}: TabsProps) => {
  const { t } = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = useState<number | undefined>(
    selectFirstTabByDefault ? 0 : undefined
  );
  // If the user selects tabs from the "More" dropdown, change the displayed tabs to the left of that "More" tab
  const [mobileVisibleTabs, setMobileVisibleTabs] = useState<null | number[]>(
    null
  );
  const [showAlertIconOnMoreTab, setShowAlertIconOnMoreTab] =
    useState<boolean>(true);

  const condenseTabsForMobile: boolean =
    maxTabsOnSmallScreens !== null &&
    maxTabsOnSmallScreens > 0 &&
    tabs.length > maxTabsOnSmallScreens;

  const alertIcon = (
    <FontAwesomeIcon
      icon={faExclamationCircle}
      size="1x"
      className="text-[#62DBC6] ml-1"
    />
  );

  useEffect(() => {
    // On load, set the initial displayed mobile/condensed tabs
    if (maxTabsOnSmallScreens !== null && maxTabsOnSmallScreens > 0) {
      const displayedTabs = Array.from(Array(maxTabsOnSmallScreens).keys());
      setMobileVisibleTabs(displayedTabs);
    }
  }, []);

  /** Get the css class name for condensed tabs */
  const getCondensedTabClassName = (tabIndex: number) => {
    if (maxTabsOnSmallScreens !== null && maxTabsOnSmallScreens > 0) {
      const noDropDownMoreTabSelected =
        mobileVisibleTabs === null && tabIndex + 1 > maxTabsOnSmallScreens;
      const moreTabSelectedAndTabIndexLowerThanSelectedMoreTabIndex =
        mobileVisibleTabs && mobileVisibleTabs.indexOf(tabIndex) === -1;
      if (
        noDropDownMoreTabSelected ||
        moreTabSelectedAndTabIndexLowerThanSelectedMoreTabIndex
      ) {
        return "hidden md:block";
      }
    }
    return "";
  };

  /** Handle the tab change, with complex behaviour for mobile condensed tabs */
  const onActiveTabChange = (tabId: string) => {
    const newActiveTab = parseInt(tabId.replace("tab", ""));
    const mobileMoreTabId = "tab" + tabs.length;

    // If the "More" tab is clicked, don't change the value
    if (condenseTabsForMobile && tabId === mobileMoreTabId) {
      // Do nothing
    } else if (
      condenseTabsForMobile &&
      maxTabsOnSmallScreens &&
      maxTabsOnSmallScreens > 0 &&
      (mobileVisibleTabs === null ||
        mobileVisibleTabs.indexOf(newActiveTab) === -1)
    ) {
      // Display the selected tab, plus up to n more, when n is `maxTabsOnSmallScreens`
      const newMobileVisibleTabs = [newActiveTab];
      for (var i = 1; i < maxTabsOnSmallScreens!; i++) {
        if (tabs.length >= newActiveTab + i) {
          newMobileVisibleTabs.push(newActiveTab + i);
        }
      }

      // If any tab not displayed has an alert icon, make the "More" tab show that alert
      const anyNotVisibleTabHasAlertIcon =
        tabs.filter(
          (tab, tabIndex) =>
            newMobileVisibleTabs.indexOf(tabIndex) === -1 &&
            tab.displayUpdateIcon
        ).length > 0;

      setMobileVisibleTabs(newMobileVisibleTabs);
      setShowAlertIconOnMoreTab(anyNotVisibleTabHasAlertIcon);
      setActiveTabIndex(newActiveTab);
    } else {
      // Non-condensed tabs, no complication
      setActiveTabIndex(newActiveTab);
    }
  };

  /** Get the tabs that aren't visible to display in the drop down menu on the More tab */
  const getMoreDropDownMenuItems = (): DropDownMenuItem[] => {
    const defaultDisplayedTabs = Array.from(
      Array(maxTabsOnSmallScreens).keys()
    );
    const currentVisibleTabs =
      mobileVisibleTabs === null ? defaultDisplayedTabs : mobileVisibleTabs;

    const notVisibleTabs = tabs.filter(
      (tab, tabIndex) => currentVisibleTabs.indexOf(tabIndex) === -1
    );
    return notVisibleTabs.map((tab) => {
      const tabIndexInFullSet = tabs.findIndex((t) => t.title === tab.title);
      return {
        text: tab.title,
        customArg: tabIndexInFullSet,
        customIcon: tab.displayUpdateIcon ? alertIcon : undefined,
      };
    });
  };

  return (
    <TabsPrimitive.Root
      defaultValue={
        activeTabIndex !== undefined ? `tab${activeTabIndex}` : undefined
      }
      value={activeTabIndex !== undefined ? `tab${activeTabIndex}` : undefined}
      onValueChange={onActiveTabChange}
    >
      <TabsPrimitive.List className={"flex w-full bg-white"}>
        {tabs.map(({ title, displayUpdateIcon, onClickEvent }, i) => (
          <TabsPrimitive.Trigger
            key={`tab-trigger-tab${i}`}
            value={`tab${i}`}
            className={cx(
              selectedTabClassNames,
              "group",
              "border-gray-300 text-[#878D97]",
              "radix-state-inactive:bg-white",
              "flex-1 px-3 py-2.5",
              "focus:radix-state-active:border-b-red",
              "focus:z-10 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75",
              getCondensedTabClassName(i)
            )}
            onClick={(onClickEvent ? () => onClickEvent() : undefined)}
          >
            <span className="text-sm font-medium">
              {title}
              {displayUpdateIcon && alertIcon}
            </span>
          </TabsPrimitive.Trigger>
        ))}
        {condenseTabsForMobile && (
          <TabsPrimitive.Trigger
            value={`tab${tabs.length}`}
            className={cx(
              "md:hidden",
              selectedTabClassNames,
              "group",
              "border-gray-300 text-[#878D97]",
              "radix-state-inactive:bg-white",
              "flex-1 px-3 py-2.5",
              "focus:radix-state-active:border-b-red",
              "focus:z-10 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
            )}
          >
            <DropDownMenu
              eventType="EVENT"
              menuButtonClassName=""
              onItemClick={(itemIndex: number, tabIndex: any | undefined) => {
                onActiveTabChange(("tab" + tabIndex) as string);
              }}
              items={getMoreDropDownMenuItems()}
              customButtonContents={
                <span className="text-sm font-medium">
                  {t("Common.More")}
                  {showAlertIconOnMoreTab && alertIcon}
                </span>
              }
            />
          </TabsPrimitive.Trigger>
        )}
      </TabsPrimitive.List>
      {tabs.map(({ content }, i) => (
        <TabsPrimitive.Content
          key={`tab-content-tab${i}`}
          value={`tab${i}`}
          className="rounded-b-lg bg-white py-2"
        >
          {content}
        </TabsPrimitive.Content>
      ))}
    </TabsPrimitive.Root>
  );
};

export default Tabs;
