import SendNowApiResponseDto from "../../types/dtos/admin/SendNowApiResponseDto";

const MockSendNowData = {
  details: {
    introMessage: "[Intro Message for the Behaviour Section]",
    themeColour: "#1d6f6d",
  },
  availableSections: [
    {
      id: 11,
      translationKey: "Dummy.FormTitle.AboutYou",
      overrides: [
        {
          overrideId: 30,
          appraisalLevelName: "Dummy.AppraisalLevel.Causals",
          mode: "HIDDEN-ON-DASHBOARD"
        }
      ]
    },
    {
      id: 13,
      translationKey: "Dummy.FormTitle.AboutUs",
      overrides: [
        {
          overrideId: 31,
          appraisalLevelName: "Dummy.AppraisalLevel.Leadership",
          mode: "HIDDEN-ON-DASHBOARD"
        },
        {
          overrideId: 32,
          appraisalLevelName: "Dummy.AppraisalLevel.Management",
          mode: "HIDDEN-ON-DASHBOARD"
        },
        {
          overrideId: 33,
          appraisalLevelName: "Dummy.AppraisalLevel.Causals",
          mode: "MANUAL-ONLY"
        }
      ]
    },
    {
      id: 14,
      translationKey: "Dummy.FormTitle.Aspirations",
      overrides: []
    }
  ],
  selectedSections: [
    {
      id: 10,
      translationKey: "Dummy.FormTitle.Behaviours",
      overrides: [
        {
          overrideId: 34,
          appraisalLevelName: "Dummy.AppraisalLevel.Leadership",
          mode: "HIDDEN-ON-DASHBOARD"
        },
        {
          overrideId: 35,
          appraisalLevelName: "Dummy.AppraisalLevel.Management",
          mode: "AUTOMATED",
          frequencyValue: 6,
          frequencyType: "MONTHS"
        }
      ],
    }
  ]
} as SendNowApiResponseDto;

export default MockSendNowData;
