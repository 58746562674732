import { useContext, useEffect, useState } from "react";
import { t } from "i18next";
import { EditableGoal } from "../../types/tasks/EditableTasks";
import {
  DateInput,
  GenericDropDownList,
  Label,
  TextArea,
  TextInput,
} from "../common";
import { isDate } from "lodash";
import { ValidationResult } from "../../types/forms";
import UserContext from "../../state/UserContext";

const titleInputId = "new-action-title";
const categoryInputId = "new-action-category";
const datePickerInputId = "new-action-target-date";
const field1InputId = "new-action-field1";
const field2InputId = "new-action-field2";

interface EditGoalFormProps {
  editObject: EditableGoal | null;
  showValidationErrors: boolean;
  onChange(newState: EditableGoal): void;
  isReadOnly?: boolean;
}

function EditGoalForm({
  editObject,
  showValidationErrors,
  onChange,
  isReadOnly = false,
}: EditGoalFormProps) {
  // Context
  const userContext = useContext(UserContext);
  const showCategoryField =
    userContext.appLvl.goalCategories !== null &&
    userContext.appLvl.goalCategories.length > 0;

  // State
  const [taskGoalCategoryId, setTaskGoalCategoryId] = useState<string | null>(
    null
  );
  const [taskTitle, setTaskTitle] = useState<string>("");
  const [taskTargetDate, setTaskTargetDate] = useState<Date | null>(null);
  const [taskField1, setTaskField1] = useState<string>("");
  const [taskField2, setTaskField2] = useState<string>("");

  // Lifecycle

  const loadEditDetails = () => {
    setTaskTitle(editObject && editObject.title ? editObject.title : "");
    setTaskTargetDate(editObject ? editObject.targetDate : null);
    setTaskField1(editObject && editObject.field1 ? editObject.field1 : "");
    setTaskField2(editObject && editObject.field2 ? editObject.field2 : "");
    setTaskGoalCategoryId(
      editObject && editObject.categoryId
        ? editObject.categoryId.toString()
        : null
    );
  };

  // Initial mount
  useEffect(() => {
    loadEditDetails();
  }, []);

  // When the task to edit changes
  useEffect(() => {
    loadEditDetails();
  }, [editObject]);

  // Private functions
  const updateParentEditObjectState = (
    title = taskTitle,
    targetDate = taskTargetDate,
    field1 = taskField1,
    field2 = taskField2,
    goalCategoryId = taskGoalCategoryId
  ) => {
    // State for the individual fields is managed locally. But we need to sync the changes to the edit
    // object passed from the parent state
    const updatedObject = new EditableGoal(
      editObject && editObject.toDoId ? "UPDATED" : "NEW",
      editObject ? editObject.toDoId : null,
      title,
      targetDate,
      field1,
      field2,
      goalCategoryId ? parseInt(goalCategoryId) : null
    );

    onChange(updatedObject);
  };

  // Events
  const handleTitleChange = (newValue: string) => {
    setTaskTitle(newValue);
    updateParentEditObjectState(newValue, undefined, undefined);
  };

  const handleCategoryChange = (newValue: string) => {
    setTaskGoalCategoryId(newValue);
    updateParentEditObjectState(
      undefined,
      undefined,
      undefined,
      undefined,
      newValue
    );
  };

  const handleTargetDateChange = (newValue: Date | null) => {
    setTaskTargetDate(newValue);
    updateParentEditObjectState(undefined, newValue);
  };

  const handleField1Change = (newValue: string) => {
    setTaskField1(newValue);
    updateParentEditObjectState(undefined, undefined, newValue);
  };

  const handleField2Change = (newValue: string) => {
    setTaskField2(newValue);
    updateParentEditObjectState(undefined, undefined, undefined, newValue);
  };

  // Goal category dropdown items
  const goalCategoryDropDownItems = userContext?.appLvl?.goalCategories?.map(
    (cat) => {
      return { key: cat.key.toString(), value: cat.value };
    }
  );

  // Validation
  const titleIsValid = taskTitle && taskTitle.trim().length > 0;
  const categoryIsValid =
    taskGoalCategoryId &&
    taskGoalCategoryId.length > 0 &&
    !isNaN(Number(taskGoalCategoryId));
  const targetDateIsValid = taskTargetDate && isDate(taskTargetDate);
  const field1IsValid = taskField1 && taskField1.trim().length > 0;
  const field2IsValid = taskField2 && taskField2.trim().length > 0;
  const validationResults = {
    valid: new ValidationResult(true, []),
    invalid: new ValidationResult(false, [{ errorType: "REQUIRED" }]),
  };

  return (
    <>
      <div className="mb-2">
        <Label htmlFor={titleInputId} text={t("Tasks.Forms.Goal.Title")} />
        <TextInput
          onChange={handleTitleChange}
          inputId={field1InputId}
          className="block mt-2 p-2 w-full bg-gray-100 border-0"
          value={taskTitle}
          showValidationErrors={showValidationErrors}
          validationResult={
            titleIsValid ? validationResults.valid : validationResults.invalid
          }
          isReadOnly={isReadOnly}
        />
      </div>
      {showCategoryField && goalCategoryDropDownItems && (
        <div className="mb-2">
          <Label
            htmlFor={categoryInputId}
            text={t("Tasks.Forms.Goal.Category")}
          />
          <GenericDropDownList
            currentValue={taskGoalCategoryId}
            items={goalCategoryDropDownItems}
            onChange={handleCategoryChange}
            className="block w-full"
            inputId={categoryInputId}
            bgColorClassName="bg-gray-100"
            showValidationErrors={showValidationErrors}
            validationResult={
              categoryIsValid
                ? validationResults.valid
                : validationResults.invalid
            }
            isReadOnly={isReadOnly}
          />
        </div>
      )}
      <div className="mb-2">
        <Label
          htmlFor={datePickerInputId}
          text={t("Tasks.Common.TargetDate")}
        />
        <DateInput
          onChange={handleTargetDateChange}
          inputId={datePickerInputId}
          showValidationErrors={showValidationErrors}
          validationResult={
            targetDateIsValid
              ? validationResults.valid
              : validationResults.invalid
          }
          value={taskTargetDate}
          isReadOnly={isReadOnly}
        />
      </div>
      <div className="mb-2">
        <Label htmlFor={field1InputId} text={t("Tasks.Forms.Goal.Field1")} />
        <TextArea
          onChange={handleField1Change}
          inputId={field1InputId}
          className="block mt-2 p-2 w-full bg-gray-100 border-0"
          minRows={3}
          maxRows={6}
          value={taskField1}
          showValidationErrors={showValidationErrors}
          validationResult={
            field1IsValid ? validationResults.valid : validationResults.invalid
          }
          isReadOnly={isReadOnly}
        />
      </div>
      <div>
        <Label htmlFor={field1InputId} text={t("Tasks.Forms.Goal.Field2")} />
        <TextArea
          onChange={handleField2Change}
          inputId={field2InputId}
          className="block mt-2 p-2 w-full bg-gray-100 border-0"
          minRows={3}
          maxRows={6}
          value={taskField2}
          showValidationErrors={showValidationErrors}
          validationResult={
            field2IsValid ? validationResults.valid : validationResults.invalid
          }
          isReadOnly={isReadOnly}
        />
      </div>
    </>
  );
}

export default EditGoalForm;
