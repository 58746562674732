type StatisticsLoadingCardProps = {
  text: string;
};

function StatisticsLoadingCard({ text }: StatisticsLoadingCardProps) {
  return (
    <>
    <div className="w-1/4">
      <div>
        <div className="h-14 bg-gray-200 rounded animate-pulse"></div>
      </div>
    </div>
    <div className="w-3/4">
      <div className="pl-2">
        <p className="text-xs font-bold">{text}...</p>
        <div className="h-7 col-span-4 mt-3 bg-gray-200 rounded animate-pulse"></div>
      </div>
    </div>
  </>
  );
}

export default StatisticsLoadingCard;
