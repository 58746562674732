import produce from "immer";
import { t } from "i18next";
import cx from "classnames";
import { MultipleChoiceOption, ValidationResult } from "../../types/forms";
import ValidationWarning from "./ValidationWarning";

export interface FormDropDownListProps {
  /** The Id attribute for the select element, to match the Label */
  inputId?: string;
  /** The css class names to apply */
  className?: string;
  /** The options to show in the list */
  values: MultipleChoiceOption[];
  /** The onChange event, returning the new state of the options */
  onChange(updatedValues: MultipleChoiceOption[]): void;
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
  isReadOnly?: boolean;
}

/** A form field containing a dropdownlist (i.e. a `select` element) */
const FormDropDownList = ({
  values,
  onChange,
  showValidationErrors = false,
  validationResult = null,
  inputId = "",
  className = "",
  isReadOnly = false,
}: FormDropDownListProps) => {
  /** Ensure that only one selected value exists in the state */
  const handleValueChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const optionId = parseInt(event.target.value);
    const nextState = produce(values, (draft) => {
      draft.forEach((option) => {
        option.isSelected = option.optionId === optionId;
      });
    });
    onChange(nextState);
  };

  const selectedOptionId: number | undefined = values.find(
    (x) => x.isSelected
  )?.optionId;

  /* The plan is to use the RadixUI select component once that is released */
  /* so the current `select` element is just temporary */
  /* https://twitter.com/radix_ui/status/1494784126645329922 */

  return (
    <div>
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      <select
        id={inputId}
        className={cx(className, "disabled:cursor-not-allowed")}
        value={selectedOptionId}
        onChange={handleValueChange}
        disabled={isReadOnly}
      >
        <option className="text-gray-500">
          {t("Common.DropdownDefaultItem")}
        </option>
        {values.map(({ value, text, isSelected, optionId }) => (
          <option
            className="text-gray-500 hover:bg-gray-100"
            key={optionId}
            value={optionId}
          >
            {text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FormDropDownList;
