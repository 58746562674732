import ManagerDashboardPersonDto from "../../types/dtos/dashboards/ManagerDashboardPersonDto";
import PeopleCard from "./People/PeopleCard";

interface PeopleWidgetProps {
  users: ManagerDashboardPersonDto[];
  isLoading: boolean;
  /** A function to call when the manager has selected to update a section for one of their people */
  onTriggerSectionUpdate(clientFormId: number): void;
}

function PeopleWidget({
  users,
  isLoading,
  onTriggerSectionUpdate,
}: PeopleWidgetProps) {
  return (
    <div className="pt-6">
      <div className="bg-[#F7F8FA] rounded-md">
        <div className="px-5 py-3">
          {isLoading && (
            <>
              <PeopleCard
                user={null}
                onTriggerSectionUpdate={onTriggerSectionUpdate}
              />
              <PeopleCard
                user={null}
                onTriggerSectionUpdate={onTriggerSectionUpdate}
              />
              <PeopleCard
                user={null}
                onTriggerSectionUpdate={onTriggerSectionUpdate}
              />
            </>
          )}
          {!isLoading && (
            <>
              {users.map((u) => {
                return (
                  <PeopleCard
                    key={`user_${u.userId}`}
                    user={u}
                    onTriggerSectionUpdate={onTriggerSectionUpdate}
                  />
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PeopleWidget;
