import { t } from "i18next";
import { ValidationError } from "../types/forms/ValidationResult";

const replaceToken = (
  input: string,
  replaceValue: number,
  token: string
): string => {
  let output = input;
  if (input && input.length > 0) {
    output = output.replace(token, replaceValue.toString());
  }
  return output;
};

const replaceMinToken = (input: string, replaceValue: number): string => {
  return replaceToken(input, replaceValue, "#MIN#");
};

const replaceMaxToken = (input: string, replaceValue: number): string => {
  return replaceToken(input, replaceValue, "#MAX#");
};

const replaceCurrentToken = (input: string, replaceValue: number): string => {
  return replaceToken(input, replaceValue, "#CURRENT#");
};

export const validationMessageHelper = {
  /** Get the validation message with numeric values (e.g. for min and max) substituted in where necessary */
  getMessageText: (error: ValidationError): string => {
    let output = "";
    switch (error.errorType) {
      case "MAX":
        output = t("Common.Validation.Errors.Min");
        break;
      case "MIN":
        output = t("Common.Validation.Errors.Max");
        break;
      case "MIN-AND-MAX":
        output = t("Common.Validation.Errors.MinAndMax");
        break;
      case "REQUIRED":
        output = t("Common.Validation.Errors.Required");
        break;
      case "TOO-LONG":
        output = t("Common.Validation.Errors.TooLong");
        break;
      case "INVALID-HEX":
        output = t("Common.Validation.Errors.InvalidHex");
        break;
      case "ACCESSIBILITY-ISSUE":
        output = t("Common.Validation.Errors.NotAvailableDueToAccessibility");
        break;
      case "NON-UNIQUE_APP_LEVEL_OVERRIDE":
        output = t("Common.Validation.Errors.NonUniqueAppLevelOverride");
        break;
      case "NUMBER-MISMATCH":
        output = t("Common.Validation.Errors.NumberMismatch");
        break;
      case "ATLEAST-ONE-SECTION":
        output = t("Common.Validation.Errors.AtleastOneSection");
        break;
      case "ILLEGAL-CHARACTER":
        output = t("Common.Validation.Errors.IllegalCharacters");
        break;
    }

    // Replace tokens
    if (error.min) {
      output = replaceMinToken(output, error.min);
    }

    if (error.max) {
      output = replaceMaxToken(output, error.max);
    }

    if (error.max && error.current) {
      output = replaceMaxToken(output, error.max);
      output = replaceCurrentToken(output, (error.current - error.max));
    }

    return output;
  },
  /** Get instructions for the user on how many items they should select */
  getMultiChoiceMinMaxSelectionMessage: (
    min: number | null,
    max: number | null
  ): string | null => {
    if (min === null && max === null) return null;

    let output = "";
    if (min === null && max !== null) {
      output = t("Common.Validation.Errors.Min");
    } else if (min !== null && max === null) {
      output = t("Common.Validation.Errors.Max");
    } else {
      // Both numbers not null
      output = t("Common.Validation.Errors.MinAndMax");
    }

    // Replace tokens
    if (min) {
      output = replaceMinToken(output, min);
    }

    if (max) {
      output = replaceMaxToken(output, max);
    }

    return output;
  },
};

export default validationMessageHelper;
