import React from "react";
import { KeyValuePair } from "../types/generic";

export interface ClientContextInterface {
  name: string;
  /** Without the # at the start */
  accentHexColour: string;
}

export interface AppraisalLevelSettings {
  goalCategories: KeyValuePair<number, string>[] | null;
}

export interface InternationalisationSettings {
  cultureCode: string;
  dateFormat: string;
}

export type MyDashboardMode = "STANDARD" | "INDUCTION" | "EXIT";

export interface UserContextInterface {
  /** The Id should never be passed to the server. We'll know who they are via their authenticated user token on the server */
  id: number;
  client: ClientContextInterface;
  firstName: string;
  initials: string;
  profileImgSrc: string | null;
  hasAnalyticsAccess: boolean;
  isAdmin: boolean;
  isManager: boolean;
  myDashboardMode: MyDashboardMode;
  i18n: InternationalisationSettings;
  appLvl: AppraisalLevelSettings;
}

const UserContext = React.createContext<UserContextInterface>({
  id: 0,
  client: {
    name: "Client",
    accentHexColour: "000000",
  },
  firstName: "User",
  initials: "US",
  profileImgSrc: null,
  hasAnalyticsAccess: false,
  isAdmin: false,
  isManager: false,
  myDashboardMode: "STANDARD",
  i18n: {
    cultureCode: "en-GB",
    dateFormat: "dd/MM/yyyy",
  },
  appLvl: {
    goalCategories: [
      { key: 1, value: "Dummy.GoalCategory1" },
      { key: 2, value: "Dummy.GoalCategory2" },
      { key: 3, value: "Dummy.GoalCategory3" },
    ],
  },
});

export default UserContext;
