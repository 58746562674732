import SendNowCheckboxTreeApiResponseDto from "../../types/dtos/admin/SendNowCheckboxTreeApiResponseDto";

const MockSendNowAppraisalLevelData = {
  nodes: [
    {
      value: "1",
      label: "Dummy.AppraisalLevel.Causals"
    },
    {
      value: "2",
      label: "Dummy.AppraisalLevel.Leadership"
    },
    {
      value: "3",
      label: "Dummy.AppraisalLevel.Management"
    },
    {
      value: "4",
      label: "Dummy.AppraisalLevel.Team"
    }
  ]
} as SendNowCheckboxTreeApiResponseDto;

export default MockSendNowAppraisalLevelData;
