import { Markup } from "interweave";

interface SafeRenderHtmlProps {
  htmlText: string;
  containerClassName?: string | undefined;
}

/** Renders custom HTML, with content parsed so to avoid XSS attacks (in case it was entered by frontend users) */
function SafeRenderHtml({
  htmlText,
  containerClassName = undefined,
}: SafeRenderHtmlProps) {
  return (
    <div className={containerClassName}>
      <Markup content={htmlText} />
    </div>
  );
}

export default SafeRenderHtml;
