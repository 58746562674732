import TextareaAutosize from "react-textarea-autosize";
import cx from "classnames";
import { ValidationResult } from "../../types/forms";
import ValidationWarning from "./ValidationWarning";

interface TextAreaProps {
  /** The onChange event, for handling state changes */
  onChange(newValue: string): void;
  /** The current value for the textarea */
  value?: string;
  /** The Id attribute for the input element, to match the Label */
  inputId?: string;
  /** The css class names to apply */
  className?: string;
  /** The minimum number of rows to have at all times */
  minRows?: number | undefined;
  /** The maximum number of rows to have before the scrollbar appears */
  maxRows?: number | undefined;
  /** The Placeholder attribute value for the form element */
  placeholder?: string | undefined;
  /** A ref for calling methods on the textarea element, e.g. to focus it */
  inputRef?: React.RefObject<HTMLTextAreaElement> | undefined;
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
  isReadOnly?: boolean;
}

/** A TextArea element which can auto-grow up to a maximum number of rows
 * to avoid the user having to scroll as soon as the text gets quite long
 */
const TextArea = ({
  onChange,
  minRows,
  maxRows,
  showValidationErrors = false,
  validationResult = null,
  value = "",
  inputId = "",
  className = "border-0 border-none",
  placeholder = undefined,
  inputRef = undefined,
  isReadOnly = false,
}: TextAreaProps) => {
  return (
    <div>
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      <TextareaAutosize
        id={inputId}
        onChange={(e) => onChange(e.target.value)}
        value={value ? value : ""}
        className={cx(
          className,
          "rounded-md focus:outline-none focus:ring-0",
          isReadOnly ? "cursor-not-allowed" : ""
        )}
        minRows={minRows}
        maxRows={maxRows}
        placeholder={placeholder}
        ref={inputRef}
        disabled={isReadOnly}
      />
    </div>
  );
};

export default TextArea;
