import { useEffect, useState } from "react";
import { t } from "i18next";
import JourneyEmptyState from "./JourneyEmptyState";
import JourneyForm from "./JourneyForm";
import JourneyFormGroupDto from "../../types/dtos/journeys/JourneyFormGroupDto";
import { JourneyFormDto } from "../../types/dtos/journeys";
import SmallLoader from "../loaders/SmallLoader";
import { styleHelper } from "../../helpers";
import { ScrollPageMainContainerToTop } from "../layout/MainContainer";

interface JourneyWidgetProps {
  isLoading: boolean;
  formDetails: JourneyFormGroupDto | undefined | null;
}

function JourneyDashboardWidget({
  formDetails,
  isLoading,
}: JourneyWidgetProps) {
  const [showWelcomeMessage, setShowWelcomeMessage] = useState<boolean>(true);
  const [formTitle, setFormTitle] = useState<string | undefined>(
    formDetails?.forms[0]?.title
  );

  // When the form details load for the first time, show the welcome message (if there is one)
  useEffect(() => {
    const shouldShowMessage =
      formDetails !== undefined &&
      formDetails !== null &&
      formDetails.welcomeMessage !== null &&
      formDetails.welcomeMessage.length > 0;
    setShowWelcomeMessage(shouldShowMessage);
  }, [formDetails]);

  const onFormChange = (formDto: JourneyFormDto) => {
    setFormTitle(formDto.title);
  };

  const onHideWelcomeMessage = () => {
    setShowWelcomeMessage(false);
    ScrollPageMainContainerToTop();
  };

  const backgroundColour = formDetails?.backgroundColour
    ? formDetails?.backgroundColour
    : styleHelper.defaultJourneyBackgroundColour;

  const displayHeader =
    !showWelcomeMessage && formTitle && formTitle.length > 0;

  return (
    <div
      id="journey-widget"
      className="rounded-lg drop-shadow-2xl"
      style={{
        backgroundColor: `#${backgroundColour}`,
      }}
    >
      {!displayHeader && <div className="rounded-t-lg px-2 py-1 lg:px-6"></div>}
      {displayHeader && (
        <div className="rounded-t-lg px-2 py-2 lg:px-6 text-white  text-sm font-normal bg-white/20">
          {formTitle ? t(formTitle) : ""}
        </div>
      )}

      <div
        className="mb-4 rounded-b-lg border-solid p-8 lg:py-8 lg:px-12 drop-shadow-[0_4px_20px_rgba(33,71,57,0.25)]"
        style={{
          background:
            "linear-gradient(to bottom right, #fff,#" +
            `${backgroundColour} 0%` +
            ",#fff 700%)",
        }}
      >
        {isLoading && <SmallLoader />}
        {!isLoading && !formDetails && <JourneyEmptyState />}
        {!isLoading && formDetails && (
          <JourneyForm
            formDetails={formDetails}
            onFormChange={onFormChange}
            showWelcomeMessage={showWelcomeMessage}
            onHideWelcomeMessage={onHideWelcomeMessage}
          />
        )}
      </div>
    </div>
  );
}

export default JourneyDashboardWidget;
