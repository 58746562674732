import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
import Avatar from "./Avatar";
import PeoplePickerUserDto from "../../types/dtos/generic/PeoplePickerUserDto";
import { t } from "i18next";

interface AutoCompleteSuggestionListProps {
  filteredSuggestions: PeoplePickerUserDto[];
  onSuggestionClick(newValue: PeoplePickerUserDto): void;
}

const AutoCompleteSuggestionList = ({
  filteredSuggestions,
  onSuggestionClick,
}: AutoCompleteSuggestionListProps) => {
  return filteredSuggestions.length ? (
    <div className="relative">
      <div className="z-50 origin-top-right text-gray-400 absolute m-2 mt-2 w-7/8  md:w-2/3 bg-white rounded-md shadow-lg focus:outline-none">
        <ul className="divide-y">
          <span className="text-xs text-gray-400 p-2">
            {t("Common.Suggestions")}:
          </span>
          {filteredSuggestions.map((suggestion) => {
            return (
              <li
                className="p-2 hover:bg-gray-50 cusor-pointer"
                key={suggestion.userId}
                onClick={() => onSuggestionClick(suggestion)}
              >
                <div className="flex gap-3 ml-3">
                  <div className="pt-1">
                    <Avatar
                      userInitials={suggestion.initials}
                      userFullName={suggestion.fullName}
                      activeStatus="DO-NOT-SHOW"
                      imageUrl={suggestion.profileImageUrl}
                      size={8}
                    />
                  </div>
                  <div className="flex-col">
                    <div>
                      <h5 className="coloured-heading !mb-0 !mt-0">
                        {suggestion.fullName}
                      </h5>
                    </div>
                    <div>
                      <div className="flex gap-1 text-sm ml-3">
                        <p>
                          <FontAwesomeIcon
                            icon={faUser}
                            size="1x"
                            className="text-[#C4C4C4]"
                          />
                          <span className="pl-1">
                            {t(suggestion.jobTitle)} |
                          </span>
                        </p>
                        <p>
                          <FontAwesomeIcon
                            icon={faMapMarkerAlt}
                            size="1x"
                            className="text-[#C4C4C4]"
                          />
                          <span className="pl-1">{suggestion.location}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default AutoCompleteSuggestionList;
