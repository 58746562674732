export interface ValidationError {
  errorType: "REQUIRED" | "MIN" | "MAX" | "MIN-AND-MAX" | "TOO-LONG" | "INVALID-HEX" | "ACCESSIBILITY-ISSUE" | "NON-UNIQUE_APP_LEVEL_OVERRIDE" | "NUMBER-MISMATCH" | "ATLEAST-ONE-SECTION" | "ILLEGAL-CHARACTER";
  min?: number | undefined;
  max?: number | undefined;
  current?: number | undefined;

  /** Can be used for complex scenarios, like sub-questions in goal review, where validation is ran at the question level, but sub-questions need validating as well */
  relatesTo?: string | undefined;
}

export class ValidationResult {
  constructor(isValid: boolean, errors: Array<ValidationError> = []) {
    this.isValid = isValid;
    this.errors = errors;
  }

  isValid: boolean;
  errors: Array<ValidationError>;
}

export default ValidationResult;
