import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { userDetailsHelper } from "../../helpers";
import UserContext from "../../state/UserContext";
import { CollabDocStatus } from "../../types/collab-docs";
import { UserBasicDetailsDto } from "../../types/dtos/generic";
import { AlertPopup } from "../common";

const replaceTokensInButtonText = (
  translatedText: string,
  otherUserName: string
) => {
  return translatedText.replace("#OTHER_USER_NAME#", otherUserName);
};

interface CollabDocFooterProps {
  /** Whether or not the form has been modified since it was loaded */
  formIsDirty: boolean;
  /** The current status of the collab doc */
  approvalStatus: CollabDocStatus;
  participants: Array<UserBasicDetailsDto>;
  onValidate(): void;
  /** When the user wants to reset the form to how it was before they made changes in this session */
  onRevertFormChanges(): void;
  /** When the user clicks the button to submit the document for approval etc */
  onSubmitDocument(proposedStatus: CollabDocStatus): void;
}

function CollabDocFooter({
  formIsDirty,
  approvalStatus,
  participants,
  onValidate,
  onRevertFormChanges,
  onSubmitDocument,
}: CollabDocFooterProps) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);

  // State
  const [showResetConfirmPrompt, setShowResetConfirmPrompt] =
    useState<boolean>(false);

  // Events
  const handleSaveButtonClick = (proposedStatus: CollabDocStatus) => {
    onValidate();
    onSubmitDocument(proposedStatus);
  };

  const handleResetButtonClick = () => {
    setShowResetConfirmPrompt(true);
  };

  /** When the user confirms they want to revert their changes to the form (answers, not comments) */
  const onConfirmReset = () => {
    onRevertFormChanges();
    setShowResetConfirmPrompt(false);
  };

  let primaryButtonText: string | null = null;
  let primaryButtonProposedStatus: CollabDocStatus | null = null;
  const otherUserId = participants.find(
    (x) => x.userId !== userContext.id
  )?.userId;
  const otherParticipantName = userDetailsHelper.getDisplayName(
    otherUserId!,
    participants
  );

  switch (approvalStatus) {
    case "APPROVED-BY-OTHER":
      if (formIsDirty) {
        // Changes have been made, mode changes to get approval from other party
        primaryButtonText = replaceTokensInButtonText(
          t(
            "Pages.CollaborativeDocument.Controls.SubmitButton.SendForApproval"
          ),
          otherParticipantName
        ); //"Send for approval";
        primaryButtonProposedStatus = "RETURNED-BY-YOU";
      } else {
        // No changes since the form was loaded, allow sign-off
        primaryButtonText = replaceTokensInButtonText(
          t("Pages.CollaborativeDocument.Controls.SubmitButton.SignOff"),
          otherParticipantName
        ); // "Sign off form";
        primaryButtonProposedStatus = "CLOSED";
      }
      break;
    case "APPROVED-BY-YOU":
    case "NEW":
    case "RETURNED-BY-OTHER":
    case "RETURNED-BY-YOU":
    case "SAVED-UNAPPROVED":
      primaryButtonText = replaceTokensInButtonText(
        t("Pages.CollaborativeDocument.Controls.SubmitButton.SendForApproval"),
        otherParticipantName
      ); //"Send for approval";
      primaryButtonProposedStatus = "APPROVED-BY-YOU";
      break;
    case "CLOSED":
      // Nothing - form should be readonly, and the footer should be hidden as a result
      break;
  }

  return (
    <>
      <footer className="flex flex-col lg:flex-row-reverse mb-14 lg:mb-0 px-10 pb-16">
        {primaryButtonText !== null && primaryButtonProposedStatus !== null && (
          <div className="flex-grow lg:flex-initial mb-4 lg:mb-0">
            <button
              className="btn-primary block w-full lg:inline-block lg:w-auto"
              onClick={() =>
                handleSaveButtonClick(primaryButtonProposedStatus!)
              }
            >
              {primaryButtonText}
            </button>
          </div>
        )}

        {formIsDirty && (
          <div className="flex-grow lg:flex-initial mb-3 lg:mb-0 mr-0 lg:mr-4">
            <button
              className="btn-secondary block w-full lg:inline-block lg:w-auto"
              onClick={() => handleSaveButtonClick("SAVED-UNAPPROVED")}
            >
              Save changes{" "}
              {/* TODO: Get confirmed wording and put into t("") */}
            </button>
          </div>
        )}
        {formIsDirty && (
          <div className="flex-grow">
            <button
              className="block w-full lg:inline-block lg:w-auto underline"
              onClick={handleResetButtonClick}
            >
              Revert changes{" "}
              {/* TODO: Get confirmed wording and put into t("") */}
            </button>
          </div>
        )}
      </footer>
      <AlertPopup
        isOpen={showResetConfirmPrompt}
        onOpenChange={setShowResetConfirmPrompt}
        onPrimaryButtonClick={onConfirmReset}
        onSecondaryButtonClick={() => setShowResetConfirmPrompt(false)}
        primaryButtonText={t(
          "Pages.CollaborativeDocument.Controls.RevertChangesModalConfirmButtonText"
        )}
        secondaryButtonText={t("Common.CancelAction")}
        bodyText={t(
          "Pages.CollaborativeDocument.Controls.RevertChangesModalBodyText"
        )}
        title={t(
          "Pages.CollaborativeDocument.Controls.RevertChangesModalTitle"
        )}
      />
    </>
  );
}

export default CollabDocFooter;
