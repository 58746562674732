import { KeyValuePair } from "../types/generic";
import { TaskType } from "../types/tasks";
import {
  EditableAction,
  EditableGoal,
  EditableLearningAction,
  EditableTask,
} from "../types/tasks/EditableTasks";

export const taskTypeHelper = {
  /** Get a new, blank task of the specified task type, optionally with the title pre-filled.
   * Useful when loading an add task modal but wanted to pre-fill some of the details
   */
  getTaskTemplate: function (
    taskType: TaskType,
    title: string | undefined = undefined
  ): EditableTask {
    switch (taskType) {
      case "ACTION":
        return new EditableAction(
          "NEW",
          null,
          title ? title : null,
          null,
          null
        );
      case "GOAL":
        return new EditableGoal(
          "NEW",
          null,
          title ? title : null,
          null,
          null,
          null,
          null
        );
      case "LEARNING":
        return new EditableLearningAction(
          "NEW",
          null,
          title ? title : null,
          null,
          null,
          null,
          null
        );
    }
  },
  getTaskTypesAsKeyValuePairs: function (): KeyValuePair<TaskType, string>[] {
    const types: TaskType[] = ["ACTION", "GOAL", "LEARNING"];
    return types.map((type) => {
      return { key: type, value: this.getTranslationKeyForTaskType(type) };
    });
  },
  getTranslationKeyForTaskType: function (taskType: TaskType) {
    switch (taskType) {
      case "ACTION":
        return "Tasks.Types.Action";
      case "GOAL":
        return "Tasks.Types.Goal";
      case "LEARNING":
        return "Tasks.Types.Learning";
    }
  },
};

export default taskTypeHelper;
