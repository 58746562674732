import React, { useEffect, useState } from "react";
import AppContext from "../../state/AppContext";
import ProfileNav from "../navigation/ProfileNav";

function TopBar() {
  const appContext = React.useContext(AppContext);
  const [pageTitle, setPageTitle] = useState<string>("");

  useEffect(() => {
    setPageTitle(appContext.pageTitle);
  }, [appContext.pageTitle]);

  return (
    <div
      id="top-bar"
      className="pl-6 border-b flex flex-row justify-center lg:justify-between"
    >
      <div>
        <h3 className="text-xl">
          {pageTitle}
          <span className="accent-text text-2xl">.</span>
        </h3>
      </div>
      <div className="hidden lg:block pr-8">
        <ProfileNav />
      </div>
    </div>
  );
}

export default TopBar;
