import { OverrideDto } from "../types/dtos/admin/OverrideDto";
import JourneyMode from "../types/generic/JourneyMode";

const overrideHelper = {
  createCleanOverrideDto: function (overrideId: number,
    appraisalLevelName: string,
    mode: JourneyMode,
    dto : OverrideDto
  ): OverrideDto {
    const newOverride: OverrideDto = {
      overrideId: overrideId,
      appraisalLevelName: appraisalLevelName != undefined ? appraisalLevelName : "",
      mode: mode
    }

    switch (mode) {
      case "AUTOMATED":
        newOverride.frequencyValue = dto.frequencyValue;
        newOverride.frequencyType = dto.frequencyType;
        break;
      case "WINDOWED":
        newOverride.windows = dto.windows;
        break;
      case "INDUCTION":
        newOverride.send = dto.send
        // If true then we save the daysTo and daysFrom
        if (newOverride.send === true) {
          newOverride.daysTo = dto.daysTo;
          newOverride.daysFrom = dto.daysFrom
        } 
        break;
      case "EXIT":
        newOverride.send = dto.send
        break;
    }

    return newOverride;
  }
};

export default overrideHelper;
