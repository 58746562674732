import { dateHelper } from "../../helpers";
import { CollabDocApiResponseDto } from "../../types/dtos/collab-docs";
import {
  BehaviourFormQuestions,
  FutureFormQuestions,
  LearningAndDevelopmentFormQuestions,
  OpinionYouFormQuestions,
  OpinionCompanyQuestions,
} from "./MockInternalDemoFormData";
import { v4 as uuidv4 } from "uuid";

const MockInternalDemoCollabDocData = {
  InitialJourney: function (isManager: boolean) {
    return {
      answerSetUniqueId: "78f3d6de-3e55-4f45-8a6d-fa67c5f0b77c",
      titleSuffix: isManager ? " - Sarah Marsh" : undefined,
      backgroundColour: "281F51",
      approvalStatus: "APPROVED-BY-OTHER",
      lastUpdated: dateHelper.getCurrentDateUtc(),
      subject: {
        userId: 101,
        firstName: "Sarah",
        fullName: "Sarah Marsh",
      },
      lastUpdatedByUserId: 101,
      isReadOnly: false,
      comments: [
        {
          id: "64877e96-b9d7-4be0-9c25-9d7f06aab9fa",
          replyToCommentId: null,
          questionId: "F0",
          authorId: 101,
          commentText:
            "I've been feeling great in my role recently, I think it's time",
          timestamp: dateHelper.getCurrentDateUtc(),
          seen: true,
        },
      ],
      forms: [
        {
          title: "Dummy.Forms.Future.Title",
          questions: FutureFormQuestions,
        },
        {
          title: "Dummy.Forms.Behaviours.Title",
          questions: BehaviourFormQuestions,
          flaggedChanges: [
            {
              questionId: "F0", // TODO:
              authorId: 101,
              previousValue: "Option 1",
              timestamp: dateHelper.getCurrentDateUtc(),
            },
          ],
        },
        {
          title: "Dummy.Forms.LearnDev.Title",
          questions: LearningAndDevelopmentFormQuestions,
        },
        {
          title: "Dummy.Forms.OpinionYou.Title",
          questions: OpinionYouFormQuestions,
          flaggedChanges: [],
        },
        {
          title: "Dummy.Forms.OpinionCo.Title",
          questions: OpinionCompanyQuestions,
          flaggedChanges: [],
        },
      ],
      participants: [
        {
          userId: 101,
          firstName: "Sarah",
          fullName: "Sarah Marsh",
          initials: "SM",
          profileImageUrl:
            "https://faces-img.xcdn.link/image-lorem-face-806.jpg",
          currentStatus: "ACTIVE",
        },
        {
          userId: 102,
          firstName: "Jack",
          fullName: "Jack Smith",
          initials: "JS",
          profileImageUrl:
            "https://faces-img.xcdn.link/image-lorem-face-3180.jpg",
          currentStatus: "INACTIVE",
        },
      ],
      answers: [
        {
          id: uuidv4(), // Mock ID for the sake of the dummy app
          answer: 7,
          questionId: "F0",
          timestamp: dateHelper.getCurrentDateUtc(),
          userId: 101,
        },
        {
          id: uuidv4(), // Mock ID for the sake of the dummy app
          answer: "Restaurant manager",
          questionId: "F_COND_PROGRESSNOW_1",
          timestamp: dateHelper.getCurrentDateUtc(),
          userId: 101,
        },
        {
          id: uuidv4(), // Mock ID for the sake of the dummy app
          answer: [
            {
              behaviourId: 1,
              attributeId: 1,
              selectedScaleValue: 5,
            },
            {
              behaviourId: 1,
              attributeId: 2,
              selectedScaleValue: 3,
            },
            {
              behaviourId: 1,
              attributeId: 3,
              selectedScaleValue: 4,
            },
          ],
          questionId: "BHV_01",
          timestamp: dateHelper.getCurrentDateUtc(),
          userId: 101,
        },
        {
          id: uuidv4(), // Mock ID for the sake of the dummy app
          answer:
            "I've had some issues when trying to help other people in the team, which I'd like to discuss",
          questionId: "B3",
          timestamp: dateHelper.getCurrentDateUtc(),
          userId: 101,
        },
        {
          id: uuidv4(), // Mock ID for the sake of the dummy app
          answer: 9,
          questionId: "OY1",
          timestamp: dateHelper.getCurrentDateUtc(),
          userId: 101,
        },
        {
          id: uuidv4(), // Mock ID for the sake of the dummy app
          answer: 6,
          questionId: "OY2",
          timestamp: dateHelper.getCurrentDateUtc(),
          userId: 101,
        },
        {
          id: uuidv4(), // Mock ID for the sake of the dummy app
          answer: 8,
          questionId: "OY3",
          timestamp: dateHelper.getCurrentDateUtc(),
          userId: 101,
        },
        {
          id: uuidv4(), // Mock ID for the sake of the dummy app
          answer:
            "There are some changes which I seem to hear about very last-minute, and I'd like to understand why",
          questionId: "OY4",
          timestamp: dateHelper.getCurrentDateUtc(),
          userId: 101,
        },
        {
          id: uuidv4(), // Mock ID for the sake of the dummy app
          answer: 8,
          questionId: "OC1",
          timestamp: dateHelper.getCurrentDateUtc(),
          userId: 101,
        },
        {
          id: uuidv4(), // Mock ID for the sake of the dummy app
          answer: 7,
          questionId: "OC2",
          timestamp: dateHelper.getCurrentDateUtc(),
          userId: 101,
        },
        {
          id: uuidv4(), // Mock ID for the sake of the dummy app
          answer:
            "There's got to be a way to improve communications between teams, have we thought about digitising certain aspects of our comms with a mobile app of some sort?",
          questionId: "OC3",
          timestamp: dateHelper.getCurrentDateUtc(),
          userId: 101,
        },
        {
          id: uuidv4(), // Mock ID for the sake of the dummy app
          answer: [
            {
              isCustom: false,
              itemId: 1002,
              name: "", // Name will be populated from the L&D list for non custom items
            },
          ],
          questionId: "LD1",
          timestamp: dateHelper.getCurrentDateUtc(),
          userId: 101,
        },
      ],
    } as CollabDocApiResponseDto;
  },
  FutureOnlyJourney: function (isManager: boolean) {
    return {
      answerSetUniqueId: "956efd2b-b226-45ab-8723-e2ffc9fefddd",
      titleSuffix: isManager ? " - Sarah Marsh" : undefined,
      backgroundColour: "281F51",
      approvalStatus: "APPROVED-BY-OTHER",
      lastUpdated: dateHelper.getCurrentDateUtc(),
      subject: {
        userId: 101,
        firstName: "Sarah",
        fullName: "Sarah Marsh",
      },
      lastUpdatedByUserId: 101,
      isReadOnly: false,
      comments: [
        {
          id: "64877e96-b9d7-4be0-9c25-9d7f06aab9fa",
          replyToCommentId: null,
          questionId: "F0",
          authorId: 101,
          commentText:
            "I've been feeling great in my role recently, I think it's time",
          timestamp: dateHelper.getCurrentDateUtc(),
          seen: true,
        },
      ],
      forms: [
        {
          title: "Dummy.Forms.Future.Title",
          questions: FutureFormQuestions,
        },
      ],
      participants: [
        {
          userId: 101,
          firstName: "Sarah",
          fullName: "Sarah Marsh",
          initials: "SM",
          profileImageUrl:
            "https://faces-img.xcdn.link/image-lorem-face-806.jpg",
          currentStatus: "ACTIVE",
        },
        {
          userId: 102,
          firstName: "Jack",
          fullName: "Jack Smith",
          initials: "JS",
          profileImageUrl:
            "https://faces-img.xcdn.link/image-lorem-face-3180.jpg",
          currentStatus: "INACTIVE",
        },
      ],
      answers: [
        {
          id: uuidv4(), // Mock ID for the sake of the dummy app
          answer: 7,
          questionId: "F0",
          timestamp: dateHelper.getCurrentDateUtc(),
          userId: 101,
        },
        {
          id: uuidv4(), // Mock ID for the sake of the dummy app
          answer: "Restaurant manager",
          questionId: "F_COND_PROGRESSNOW_1",
          timestamp: dateHelper.getCurrentDateUtc(),
          userId: 101,
        },
      ],
    } as CollabDocApiResponseDto;
  },
};

export default MockInternalDemoCollabDocData;
