// import logo from './logo.svg';
import { useState } from "react";
import { Routes, Route, Navigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./config/content/i18n";
import "./mock-api/init-mock-api";
import { useTranslation } from "react-i18next";
import { StandardLayout, ScrollTopOnPageChange } from "./components/layout";
import {
  CollaborativeDocumentPage,
  MyDashboard,
  ManagerDashboard,
  OgAppRedirect,
  ErrorPage,
  ComponentsDemo,
  AdminHome,
  AdminConfiguration,
  SendNow,
  MissingFromDemo,
} from "./routes";
import AppRoutes from "./routes/AppRoutes";
import UserContext, { UserContextInterface } from "./state/UserContext";
import AppContext from "./state/AppContext";
import OverrideNavItem from "./types/nav/OverrideNavItem";
import demoUserStateHelper from "./helpers/demoUserStateHelper";

function App() {
  // Page title is set via Context due to the structure of the components
  // and child components needing to update the title in TopBar.tsx
  const [pageTitle, setPageTitle] = useState<string>("");
  const [overrideNavItem, setOverrideNavItem] =
    useState<OverrideNavItem | null>(null);
  const { t } = useTranslation();

  // Conditionally set the context based on local storage setting, which
  // gets changed by toggles in the navs
  const searchParams = useSearchParams(); // `useSearchParams` required to trigger the context switching for the demo
  let isManagerDemo: boolean;
  if (!demoUserStateHelper.hasBeenSet()) {
    isManagerDemo = false;
    demoUserStateHelper.setManagerStatus(isManagerDemo);
  } else {
    isManagerDemo = demoUserStateHelper.isManager();
  }

  // Set up the user state (later: come from auth token etc)
  const userState: UserContextInterface = {
    id: isManagerDemo ? 102 : 101, // 101 for Sarah, 102 for Jack
    client: {
      name: "Purple Hotel",
      accentHexColour: "da3a8c",
    },
    firstName: isManagerDemo ? "Jack" : "Sarah",
    initials: isManagerDemo ? "JS" : "SM",
    profileImgSrc: isManagerDemo
      ? "https://faces-img.xcdn.link/image-lorem-face-3180.jpg"
      : "https://faces-img.xcdn.link/image-lorem-face-806.jpg",
    hasAnalyticsAccess: false,
    isAdmin: isManagerDemo,
    isManager: isManagerDemo,
    myDashboardMode: "STANDARD", // INDUCTION/EXIT/STANDARD
    i18n: {
      cultureCode: "en-GB",
      dateFormat: "dd/MM/yyyy",
    },
    appLvl: {
      goalCategories: [
        { key: 1, value: "Dummy.GoalCategory1" },
        { key: 2, value: "Dummy.GoalCategory2" },
        { key: 3, value: "Dummy.GoalCategory3" },
      ],
    },
  };

  return (
    <>
      {/* Set the client accent colour via a CSS variable in the head tag */}
      <Helmet>
        <style type="text/css">
          {`:root {
            --color-accent: #${userState.client.accentHexColour};
          }`}
        </style>
      </Helmet>
      <UserContext.Provider value={userState}>
        <AppContext.Provider
          value={{
            pageTitle,
            setPageTitle,
            overrideNavItem,
            setOverrideNavItem,
          }}
        >
          <ScrollTopOnPageChange>
            <Routes>
              <Route path="/" element={<StandardLayout />}>
                <Route
                  index
                  element={<Navigate to={AppRoutes.myDashboard} replace />}
                />
                <Route path={AppRoutes.myDashboard} element={<MyDashboard />} />
                <Route
                  path={AppRoutes.managerDashboard}
                  element={<ManagerDashboard />}
                />
                <Route
                  path={AppRoutes.companyDashboard}
                  element={<OgAppRedirect redirectTo="COMPANY_DASHBOARD" />}
                />
                <Route path={AppRoutes.collaborativeDocument.path}>
                  <Route
                    path="post-journey"
                    element={
                      <CollaborativeDocumentPage
                        postJourneyModalEnabled={true}
                      />
                    }
                  />
                  <Route path="" element={<CollaborativeDocumentPage />} />
                </Route>
                <Route path={AppRoutes.admin.path} element={<AdminHome />} />
                <Route
                  path={AppRoutes.admin.pathAfterSendNowComplete}
                  element={<AdminHome />}
                />
                <Route
                  path={AppRoutes.analytics}
                  element={<OgAppRedirect redirectTo="ANALYTICS" />}
                />
                <Route
                  path={AppRoutes.help}
                  element={<OgAppRedirect redirectTo="HELP" />}
                />
                <Route
                  path={AppRoutes.componentsDemo}
                  element={<ComponentsDemo />}
                />
                <Route
                  path={AppRoutes.adminConfiguration.path}
                  element={<AdminConfiguration />}
                />
                <Route path={AppRoutes.sendNow.path} element={<SendNow />} />
                <Route path={AppRoutes.missingFromDemo}>
                  <Route path="" element={<MissingFromDemo />} />
                  <Route path="*" element={<MissingFromDemo />} />
                </Route>
                <Route
                  path="*"
                  element={
                    <ErrorPage
                      errorTitle={t("Errors.404.Title")}
                      shortDescription={t("Errors.404.Body")}
                    />
                  }
                />
              </Route>
            </Routes>
          </ScrollTopOnPageChange>
        </AppContext.Provider>
      </UserContext.Provider>
    </>
  );
}

export default App;
