import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import NotificationMenu from "../common/NotificationMenu";
import Avatar from "../common/Avatar";
import UserContext from "../../state/UserContext";
import { DropDownMenu } from "../common";
import NonPrimaryNavItems from "../../routes/NonPrimaryNavItems";
import AppRoutes from "../../routes/AppRoutes";
import demoUserStateHelper from "../../helpers/demoUserStateHelper";

function ProfileNav() {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const switchDemoMode = () => {
    const isManager = userContext.id === 102;
    demoUserStateHelper.setManagerStatus(!isManager);
    navigate(AppRoutes.myDashboard + "?reload=1");
  };

  return (
    <div className="flex flex-row items-center">
      <NotificationMenu />
      <span
        onClick={switchDemoMode}
        title="Switch demo mode"
        className="cursor-pointer"
      >
        <Avatar
          userFullName={userContext.firstName}
          userInitials={userContext.initials}
          size={8}
          imageUrl={userContext.profileImgSrc}
        />
      </span>

      <DropDownMenu eventType="NAVIGATE" items={NonPrimaryNavItems} />
    </div>
  );
}

export default ProfileNav;
