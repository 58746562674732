import { UserBasicDetailsDto } from "../types/dtos/generic";

export const userDetailsHelper = {
  /** Takes a collection of users, and a target user, and pulls out the first name if that first name is unique in the collection.
   * E.g. if the target user is the only "Jane", return "Jane".
   * If there are multiple "Jane"s, return the full name instead.
   */
  getDisplayName(
    targetUserId: number,
    allUsers: UserBasicDetailsDto[]
  ): string {
    let output = "";
    const matchedUser = allUsers.find((x) => x.userId === targetUserId);
    if (matchedUser) {
      const usersWithMatchingFirstName = allUsers.filter(
        (x) => x.firstName === matchedUser.firstName
      );
      if (usersWithMatchingFirstName.length > 1) {
        output = matchedUser.fullName;
      } else {
        output = matchedUser.firstName;
      }
    }
    return output;
  },
};

export default userDetailsHelper;
