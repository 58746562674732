import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    backend: {
      // Config options docs: https://github.com/i18next/i18next-http-backend
      loadPath: `${process.env.PUBLIC_URL}/temporary-translations/{{lng}}/{{ns}}.json`, // TODO: Load these from file storage/cdn after a TT process to generate the file
      crossDomain: true,
    },

    fallbackLng: "en",

    ns: ["all"],

    debug: false, // Set to true to enable logging of missing translations

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
