import { Fragment, useEffect, useMemo, useState } from "react";
import cx from "classnames";
import produce from "immer";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import * as LabelPrimitive from "@radix-ui/react-label";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import {
  CheckBoxListDisplayMode,
  MultipleChoiceOption,
  ValidationResult,
} from "../../types/forms";
import ValidationWarning from "./ValidationWarning";
import validationMessageHelper from "../../helpers/validationMessageHelper";

/** Determine whether the count of selected items is valid within the min/max bounds */
const selectedCountIsInRange = (
  currentCount: number,
  min: number | null,
  max: number | null
): boolean => {
  if (min === null && max === null) return true;
  if (min === null && max !== null && currentCount > max) return false;
  if (min !== null && max === null && currentCount < min) return false;
  if (
    min !== null &&
    max !== null &&
    (currentCount < min || currentCount > max)
  )
    return false;
  return true;
};

export interface CheckBoxListProps {
  displayMode: CheckBoxListDisplayMode;
  /** Used as a prefix for `id` attributes, so make this unique for the page */
  uniqueFieldName: string;
  /** The question text for this check box list */
  fieldLabel?: string;
  values: MultipleChoiceOption[];
  /** Whether or not to display the validation warnings */
  showValidationErrors?: boolean;
  /** If validation has been run, this is the validity plus any errors */
  validationResult?: ValidationResult | null;
  /** The method to run when the value changes */
  onChange(newValues: MultipleChoiceOption[]): void;
  /** The colour of the labels for the checkboxes */
  textColourClassName?: string;
  /** The icon colour */
  iconColourClassName?: string;
  /** The border colour/style */
  checkboxBorderClassName?: string;
  /** The background colour class name for checkboxes when checked */
  checkboxCheckedBgColourClassName?: string;
  /** The background colour class name for checkboxes when unchecked */
  checkboxUncheckedBgColourClassName?: string;
  isReadOnly?: boolean;

  /** Used to display a message to the user, e.g. "Select at least 3" */
  selectMinCount: number | null;

  /** Used to display a message to the user, e.g. "Select no more than 3" */
  selectMaxCount: number | null;
}

/** A form field containing multiple radio buttons */
const CheckBoxList = ({
  displayMode,
  uniqueFieldName,
  fieldLabel,
  showValidationErrors = false,
  validationResult = null,
  values,
  onChange,
  selectMinCount,
  selectMaxCount,
  textColourClassName = "text-gray-800",
  iconColourClassName = "text-gray-800",
  checkboxBorderClassName = "border border-gray-700",
  checkboxCheckedBgColourClassName = "bg-white/30",
  checkboxUncheckedBgColourClassName = "bg-gray-100",
  isReadOnly = false,
}: CheckBoxListProps) => {
  // State
  const [countSelected, setCountSelected] = useState<number>(0);
  const [userHasInteracted, setUserHasInteracted] = useState<boolean>(false);

  useEffect(() => {
    // Reset `userHasInteracted` so we only show the validation warning
    // after the user has interacted with the form
    setUserHasInteracted(false);
  }, [uniqueFieldName]);

  useEffect(() => {
    // Set the count selected as the question changes, or as the selected values change
    const selectedCount = values.filter((x) => x.isSelected).length;
    setCountSelected(selectedCount);
  }, [uniqueFieldName, values]);

  // When the user ticks/unticks a checkbox
  const toggleCheckbox = (
    checked: CheckboxPrimitive.CheckedState,
    optionId: number
  ) => {
    const isChecked = checked !== "indeterminate" && checked === true;
    const nextState = produce(values, (draft) => {
      const match = draft.find((x) => x.optionId === optionId);
      if (match !== undefined) {
        match.isSelected = isChecked;
      }
    });
    setUserHasInteracted(true);
    onChange(nextState);
  };

  let containerClassNames = "";
  let checkboxInputClassNames = "";
  switch (displayMode) {
    case "grid":
      containerClassNames =
        "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4";
      break;
    case "horizontal":
      containerClassNames = "flex items-center";
      checkboxInputClassNames = "flex";
      break;
    case "vertical":
      containerClassNames = "";
      break;
  }

  const instructionMessage = useMemo(
    () =>
      validationMessageHelper.getMultiChoiceMinMaxSelectionMessage(
        selectMinCount,
        selectMaxCount
      ),
    [selectMinCount, selectMaxCount]
  );

  // Apply a subtle animation to the instruction text if the user has selected an invalid number of items
  const warnUserAboutInvalidAnswer =
    !showValidationErrors &&
    userHasInteracted &&
    !selectedCountIsInRange(countSelected, selectMinCount, selectMaxCount);

  return (
    <>
      {!showValidationErrors && (
        <span
          className={`block mb-1 italic font-light text-sm text-opacity-50 ${
            warnUserAboutInvalidAnswer ? "animate-shake" : ""
          }`}
        >
          {instructionMessage}
        </span>
      )}
      {showValidationErrors && validationResult && (
        <ValidationWarning
          isValid={validationResult.isValid}
          errors={validationResult.errors}
        />
      )}
      <div
        className={cx(
          textColourClassName,
          "text-sm font-regular leading-4 mb-5"
        )}
      >
        {fieldLabel}
      </div>
      <div className={containerClassNames}>
        {values.map(({ value, text, optionId, isSelected }) => {
          const itemKey = `item_${optionId}`;
          const checkbox = (
            <>
              <div className="flex flex-row m-2">
                <CheckboxPrimitive.Root
                  id={`chk${uniqueFieldName}_${optionId}`}
                  value={value}
                  checked={isSelected}
                  disabled={isReadOnly}
                  onCheckedChange={(checked: CheckboxPrimitive.CheckedState) =>
                    toggleCheckbox(checked, optionId)
                  }
                  className={cx(
                    checkboxInputClassNames,
                    checkboxBorderClassName,
                    "h-5 w-5 flex-shrink-0 items-center justify-center rounded leading-tight",
                    `radix-state-checked:${checkboxCheckedBgColourClassName} radix-state-unchecked:${checkboxUncheckedBgColourClassName}`,
                    "focus:outline-none disabled:cursor-not-allowed"
                  )}
                >
                  <CheckboxPrimitive.Indicator>
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={cx(iconColourClassName, "self-center")}
                    />
                  </CheckboxPrimitive.Indicator>
                </CheckboxPrimitive.Root>

                <LabelPrimitive.Label
                  htmlFor={`chk${uniqueFieldName}_${optionId}`}
                  className={cx(
                    textColourClassName,
                    "ml-3 select-none text-sm font-normal opacity-80"
                  )}
                >
                  {text}
                </LabelPrimitive.Label>
              </div>
            </>
          );

          switch (displayMode) {
            case "grid":
              return (
                <div key={itemKey} className="basis-full md:basis-1/2">
                  {checkbox}
                </div>
              );
            case "horizontal":
              return <Fragment key={itemKey}>{checkbox}</Fragment>;
            case "vertical":
              return <div key={itemKey}>{checkbox}</div>;
            default:
              return null;
          }
        })}
      </div>
    </>
  );
};

export default CheckBoxList;
