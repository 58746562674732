import { useEffect, useState } from "react";
import ManagerDashboardPersonDto from "../../../types/dtos/dashboards/ManagerDashboardPersonDto";
import { DashboardSummaryTabDto } from "../../../types/dtos/dashboards/DashboardSummaryTabDto";
import ManagerDashboardTabDataApiResponseDto from "../../../types/dtos/dashboards/ManagerDashboardTabDataApiResponseDto";
import PeopleContent from "./PeopleContent";
import PeopleHeader from "./PeopleHeader";

interface PeopleCardProps {
  user: ManagerDashboardPersonDto | null;
  /** A function to call when the manager has clicked an "Update [Section]" button */
  onTriggerSectionUpdate(clientFormId: number): void;
}

function PeopleCard({ user, onTriggerSectionUpdate }: PeopleCardProps) {
  // State
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userTabs, setUserTabs] = useState<DashboardSummaryTabDto[]>([]);

  // Data loading
  const loadPersonData = () => {
    setIsLoading(true);

    // Call the API to get the data for this user
    fetch(`/api/dashboards/manager/${user!.userId}`)
      .then((res) => res.json())
      .then((json: ManagerDashboardTabDataApiResponseDto) => {
        setUserTabs(json.tabs);
        setIsLoading(false);
      })
      .catch((e) => {
        console.error(e.message);
        // TODO: Show some form of error message
      });
  };

  // Lifecycle
  // Initial load
  useEffect(() => {
    if (user) {
      setUserTabs(user.tabs);
    }
  }, []);

  // When the person card gets expanded
  useEffect(() => {
    if (isExpanded) {
      loadPersonData();
    }
  }, [isExpanded]);

  return (
    <div
      className="p-5 !pb-2 bg-white rounded-md mt-3"
      onClick={() => setIsExpanded(true)}
    >
      <PeopleHeader user={user} />
      {user && (
        <>
          <hr className="mt-3" />
          <PeopleContent
            isLoading={isLoading}
            userIsExpanded={isExpanded}
            tabs={userTabs}
            onTriggerSectionUpdate={onTriggerSectionUpdate}
          />
        </>
      )}
    </div>
  );
}

export default PeopleCard;
