import { useTranslation } from "react-i18next";
import ManagerDashboardOverviewScoreDto from "../../types/dtos/dashboards/ManagerDashboardOverviewScoreDto";
import OverallStatisticsGrid from "./Statistics/OverallStatisticsGrid";
import Statistic from "./Statistics/Statistic";

interface OverallStatisticsWidgetProps {
  statistics: ManagerDashboardOverviewScoreDto[];
  isLoading: boolean;
}

function OverallStatisticsWidget({
  statistics,
  isLoading,
}: OverallStatisticsWidgetProps) {
  const { t } = useTranslation();
  return (
    <>
      <div className="py-6">
        {isLoading && (
          <OverallStatisticsGrid>
            <Statistic statistic={null} />
            <Statistic statistic={null} />
            <Statistic statistic={null} />
            <Statistic statistic={null} />
          </OverallStatisticsGrid>
        )}
        {!isLoading && statistics && statistics.length > 0 && (
          <OverallStatisticsGrid>
            {statistics.map((s) => {
              return (
                <Statistic key={`stat_type_${s.scoreType}`} statistic={s} />
              );
            })}
          </OverallStatisticsGrid>
        )}
      </div>
    </>
  );
}

export default OverallStatisticsWidget;
