import { useEffect, useState } from "react";
import smartTruncate from "smart-truncate";
import { ModalPopup, TextArea } from "../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

interface JourneyCommentControlProps {
  currentValue: string | null | undefined;
  onChange(newValue: string | null): void;
  questionId: string;
}

function JourneyCommentControl({
  questionId,
  currentValue,
  onChange,
}: JourneyCommentControlProps) {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [editableCommentValue, setEditableCommentValue] = useState(
    currentValue || ""
  );

  // Update the editable value state when a new comment is supplied,
  // e.g. when the question changes
  useEffect(() => {
    setEditableCommentValue(currentValue || "");
  }, [currentValue]);

  const openEditModal = () => {
    setModalIsOpen(true);
  };

  const handleCommentChange = (newValue: string | null) => {
    setEditableCommentValue(newValue || "");
    onChange(newValue);
    setModalIsOpen(false);
  };

  const { t } = useTranslation();

  const hasComment = currentValue && currentValue.trim().length > 0;
  const maxLengthToDisplay: number = 40;

  let displayValue = null;
  if (hasComment) {
    displayValue = smartTruncate(currentValue, maxLengthToDisplay);
  }

  const commentFieldId = `question-comment-${questionId}`;

  return (
    <div className="flex justify-end mt-2 mb-4">
      {hasComment && (
        <>
          <button
            className="text-sm text-white/40 border-b border-b-white/30 hover:text-white hover:border-b-white flex flex-row"
            onClick={openEditModal}
          >
            <p className="italic pr-2">{displayValue}</p>
            <FontAwesomeIcon icon={faPencil} />
          </button>
        </>
      )}
      {!hasComment && (
        <button
          className="text-sm text-white/40 border-b border-b-white/30 hover:text-white hover:border-b-white"
          onClick={openEditModal}
        >
          {t("Journey.Button.AddComment")}{" "}
          <span className="text-xs">{t("Journey.Button.Optional")}</span>
        </button>
      )}
      <ModalPopup
        isOpen={modalIsOpen}
        onOpenChange={setModalIsOpen}
        onPrimaryButtonClick={() => handleCommentChange(editableCommentValue)}
        onSecondaryButtonClick={() => handleCommentChange(null)}
        primaryButtonText="Save"
        secondaryButtonText={
          editableCommentValue.trim().length > 0 ? "Clear comment" : undefined
        }
        title="Edit Comment"
        titleDescription="You can use this to add some context to your answer"
      >
        <TextArea
          inputId={commentFieldId}
          value={editableCommentValue}
          onChange={setEditableCommentValue}
          minRows={3}
          maxRows={6}
          className="mt-2 p-2 w-full lg:max-w-2xl bg-gray-100 text-gray-600 rounded-lg border-0"
          showValidationErrors={false}
        />
      </ModalPopup>
    </div>
  );
}

export default JourneyCommentControl;
