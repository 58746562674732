import { v4 as uuidv4 } from "uuid";
import { dateHelper } from "../../helpers";
import ManagerDashboardTabDataApiResponseDto from "../../types/dtos/dashboards/ManagerDashboardTabDataApiResponseDto";
import { FormSummaryBehaviourDataDto } from "../../types/dtos/form-summaries/FormSummaryBehaviourDataDto";

const MockManagerDashboardTabData = {
  tabs: [
    {
      clientFormId: 300,
      clientFormTitle: "Dummy.FormTitle.Goals",
      tabType: "GOALS",
      tabHasUpdates: false,
      history: [],
      goalData: {
        windowOpen: true,
        goals: [{
          titleTranslationKey: "Dummy.GoalTitle1",
          targetDate: dateHelper.getDaysFromCurrentDateUtcs(30),
          dateCompleted: null
        },
        {
          titleTranslationKey: "Dummy.GoalTitle2",
          targetDate: dateHelper.getDaysFromCurrentDateUtcs(20),
          dateCompleted: dateHelper.getDaysFromCurrentDateUtcs(-5),
        },
        {
          titleTranslationKey: "Dummy.GoalTitle3",
          targetDate: dateHelper.getDaysFromCurrentDateUtcs(-5),
          dateCompleted: null
        },
        
        ],
      },
    },
    {
      clientFormId: 301,
      clientFormTitle: "Dummy.FormTitle.Behaviours",
      tabType: "BEHAVIOURS",
      tabHasUpdates: false,
      behaviourData: {
       averageBehaviourScore: "80%",
       behaviours: [{
         score: 3,
         scoreDescriptor: "Very Good",
         titleTranslationKey: "Dummy.Behaviour1"
  
       },
       {
        score: 4,
        scoreDescriptor: "Excellent",
        titleTranslationKey: "Dummy.Behaviour2"
  
      },{
        score: 5,
        scoreDescriptor: "Very Good",
        titleTranslationKey: "Dummy.Behaviour3"
  
      },{
        score: 3,
        scoreDescriptor: "Very Good",
        titleTranslationKey: "Dummy.Behaviour4"
  
      }
    ],
      highestScoringBehaviour: "Dummy.Behaviour3",
      lowestScoringBehaviour: "Dummy.Behaviour1",
      scaleMaxValue: 5
      } as FormSummaryBehaviourDataDto,
      
      history: [
        {
          answerSetUniqueId: uuidv4(),
          dateUpdated: dateHelper.getDaysFromCurrentDateUtcs(-2),
        },
      ],
    },
  
    {
      clientFormId: 302,
      clientFormTitle: "Dummy.FormTitle.Future",
      tabType: "CLIENT-FORM-COLLABORATIVE",
      tabHasUpdates: true,
      formData: [
        {
          displayType: "TEXT",
          value: "Ready to progress career here",
          selectedOption: null,
          titleTranslationKey: "YOUR POTENTIAL",
        },
        {
          displayType: "TEXT",
          value: "Would like to progress here",
          selectedOption: null,
          titleTranslationKey: "WHAT’S NEXT?",
        },
        {
          displayType: "TEXT",
          value: "Operations Manager",
          selectedOption: null,
          titleTranslationKey: "WHICH ROLE ARE YOU WORKING TOWARDS?",
        },
      ],
      history: [
        {
          answerSetUniqueId: uuidv4(),
          dateUpdated: dateHelper.getDaysFromCurrentDateUtcs(-2),
        },
      ],
    },
    {
      clientFormId: 303,
      clientFormTitle: "Dummy.FormTitle.Learning",
      tabType: "CLIENT-FORM-COLLABORATIVE",
      tabHasUpdates: false,
      history: [],
      formData: [
        {
          displayType: "MULTIPLE-CHOICE",
          value: null,
          selectedOption: ["Event/Conference","Team Activity","Job Swap"],
          titleTranslationKey: "WHAT LEARNING AND DEVELOPMENT WILL IMPROVE YOUR ROLE AND PERFORMANCE",
        },
      ],
    },
    {
      clientFormId: 304,
      clientFormTitle: "Dummy.FormTitle.Opinion",
      tabType: "CLIENT-FORM-EMPLOYEE-ONLY",
      tabHasUpdates: false,
      history: [],
    },
  ]
} as ManagerDashboardTabDataApiResponseDto;

export default MockManagerDashboardTabData;
