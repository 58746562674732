import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { CollabDocCommentDto } from "../../types/dtos/collab-docs";
import { Avatar, FormattedDate } from "../common";
import { UserBasicDetailsDto } from "../../types/dtos/generic";

interface IndividualCommentProps {
  comment: CollabDocCommentDto;
  author: UserBasicDetailsDto | undefined;
  allowDelete: boolean;
  allowReply: boolean;
  onDeleteClick(commentId: string): void;
  onTargetCommentForReply(commentId: string): void;
  /** Replies, if there are any */
  children?: React.ReactNode | null;
}

function IndividualComment({
  comment,
  author,
  allowDelete,
  allowReply,
  onDeleteClick,
  onTargetCommentForReply,
  children = null,
}: IndividualCommentProps) {
  const { t } = useTranslation();

  return (
    <div className="my-2" key={`comment_${comment.id}`}>
      <div className="flex flex-row">
        {author && (
          <div className="inline-block mr-2 pt-1">
            <Avatar
              imageUrl={author.profileImageUrl}
              userFullName={author.fullName}
              userInitials={author.initials}
              size={8}
              initialsTextClassName="text-xs"
            />
          </div>
        )}
        <div className="rounded-md inline-block text-sm text-gray-500 bg-white p-2 shadow-md flex-grow">
          {comment.commentText}
        </div>
      </div>
      <div className="flex flex-row">
        <div className="basis-2/3 text-gray-400">
          <small className="pl-2">
            <FormattedDate date={comment.timestamp} displayMode="FROM-NOW" />
          </small>
        </div>
        {(allowReply || allowDelete) && (
          <div className="basis-1/3 text-right">
            {allowReply && (
              <button
                className="text-xs text-gray-400"
                onClick={() => onTargetCommentForReply(comment.id)}
              >
                {t("Pages.CollaborativeDocument.Common.Reply")}
              </button>
            )}

            {allowDelete && (
              <button
                title={t("Common.Delete")}
                className="ml-2"
                onClick={() => onDeleteClick(comment.id)}
              >
                <FontAwesomeIcon size="xs" icon={faTrashAlt} />
              </button>
            )}
          </div>
        )}
      </div>

      {children}
    </div>
  );
}

export default IndividualComment;
