import { useContext, useState } from "react";
import { t } from "i18next";
import { CollabDocStatus, NewCollabDocComment } from "../../types/collab-docs";
import {
  CollabDocCommentDto,
  CollabDocFormDto,
} from "../../types/dtos/collab-docs";
import CollabDocHeader from "./CollabDocHeader";
import SmallLoader from "../loaders/SmallLoader";
import CollabDocFooter from "./CollabDocFooter";
import {
  QuestionAnswer,
  QuestionAnswerValue,
  QuestionNewTasks,
} from "../../types/forms";
import CollabDocForm from "./CollabDocForm";
import collabDocValidator from "../../helpers/collabDocValidator";
import { AlertPopup } from "../common";
import {
  BaseUserDetailsDto,
  UserBasicDetailsDto,
} from "../../types/dtos/generic";
import UserContext from "../../state/UserContext";

interface CollaborativeDocProps {
  isLoading: boolean;
  answerSetUniqueId: string;
  approvalStatus: CollabDocStatus;
  lastUpdated: Date | null;
  loggedInUserId: number;
  subjectUser: BaseUserDetailsDto;
  lastUpdatedByUserId: number | null;
  isReadOnly: boolean;
  comments: Array<CollabDocCommentDto>;
  participants: Array<UserBasicDetailsDto>;
  forms: Array<CollabDocFormDto>;
  answers: Array<QuestionAnswer>;
  newTasks: Array<QuestionNewTasks>;
  activeQuestionId: string | null;
  /** A hex background colour, without the hash */
  backgroundColour: string;
  /** Whether or not the form has been edited by the user since it was loaded. Controls the "Revert changes" button */
  formIsDirty: boolean;
  /** The date/time the form data was loaded (must be UTC) */
  dateLoaded: Date;
  /** A method to call to update the answer state */
  onValueChange(questionId: string, newValue: QuestionAnswerValue): void;
  /** The active question determines the state of the comments sidebar */
  onChangeActiveQuestion(activeQuestionId: string): void;
  /** A method to call to mark the comments as seen */
  onCommentsSeen(questionId: string): void;
  /** A method to call to insert a new comment */
  onCommentAdd(newComment: NewCollabDocComment): void;
  /** A method to call to delete a comment */
  onCommentDelete(commentId: string): void;
  /** A method for reloading the form content */
  onReloadFormContent(): void;
  /** When the user clicks the button to submit the document for approval etc */
  onSubmitDocument(proposedStatus: CollabDocStatus): void;
  /** When a use adds/edits/deletes a new task */
  onChangeQuestionNewTasks(questionTasks: QuestionNewTasks): void;
}

const CollaborativeDoc = ({
  isLoading,
  isReadOnly,
  answerSetUniqueId,
  comments,
  newTasks,
  forms,
  answers,
  backgroundColour,
  participants,
  lastUpdated,
  lastUpdatedByUserId,
  activeQuestionId,
  approvalStatus,
  dateLoaded,
  subjectUser,
  formIsDirty,
  onValueChange,
  onChangeActiveQuestion,
  onCommentsSeen,
  onCommentAdd,
  onCommentDelete,
  onReloadFormContent,
  onSubmitDocument,
  onChangeQuestionNewTasks,
}: CollaborativeDocProps) => {
  // Context
  const userContext = useContext(UserContext);

  // State
  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false);
  const [showValidationFailedAlert, setShowValidationFailedAlert] =
    useState<boolean>(false);

  const [showChangeDetails, setShowChangeDetails] = useState<boolean>(false);

  // Events
  const handleSubmitClick = (proposedStatus: CollabDocStatus) => {
    // Handle validation failure - don't allow to continue, alert user about any validation failures
    const valid = collabDocValidator.isValid(
      forms,
      answers,
      newTasks,
      userContext.id,
      subjectUser.userId
    );
    if (valid) {
      onSubmitDocument(proposedStatus);
    } else {
      setShowValidationFailedAlert(true);
    }
  };

  const toggleShowChangeDetails = (showChangeDetails: boolean) => {
    showChangeDetails
      ? setShowChangeDetails(false)
      : setShowChangeDetails(true);
  };

  // Show the loading spinner whilst the page is loading
  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <>
      {forms.map((form, formIndex) => {
        const questionIds = form.questions.map((x) => x.questionId);
        const formComments = comments.filter(
          (x) => questionIds.indexOf(x.questionId) >= 0
        );
        const formAnswers = answers.filter(
          (x) => questionIds.indexOf(x.questionId) >= 0
        );

        return (
          <>
            <div className="flex flex-col -space-y-24 lg:-space-y-28">
              {formIndex === 0 && (
                <div className="-z-1 h-50">
                  <CollabDocHeader
                    formBackgroundColour={backgroundColour}
                    participants={participants}
                    lastUpdated={lastUpdated!}
                    lastUpdatedByUserId={lastUpdatedByUserId!}
                    isReadOnly={false}
                  />
                </div>
              )}

              <div>
                <CollabDocForm
                  form={form}
                  approvalStatus={approvalStatus}
                  activeQuestionId={activeQuestionId}
                  answers={formAnswers}
                  comments={formComments}
                  key={`form_${formIndex}`}
                  isReadOnly={isReadOnly}
                  onChangeActiveQuestion={onChangeActiveQuestion}
                  onCommentAdd={onCommentAdd}
                  onCommentDelete={onCommentDelete}
                  onCommentsSeen={onCommentsSeen}
                  onValueChange={onValueChange}
                  toggleShowChangeDetails={toggleShowChangeDetails}
                  participants={participants}
                  showValidationErrors={showValidationErrors}
                  dateLoaded={dateLoaded}
                  showChangeDetails={showChangeDetails}
                  subjectUser={subjectUser}
                  newTasks={newTasks}
                  onChangeQuestionNewTasks={onChangeQuestionNewTasks}
                  backgroundColour={backgroundColour}
                />
              </div>
            </div>
          </>
        );
      })}
      {!isReadOnly && (
        <CollabDocFooter
          formIsDirty={formIsDirty}
          approvalStatus={approvalStatus}
          participants={participants}
          onRevertFormChanges={onReloadFormContent}
          onSubmitDocument={handleSubmitClick}
          onValidate={() => setShowValidationErrors(true)}
        />
      )}
      {/* Validation failed alert */}
      <AlertPopup
        isOpen={showValidationFailedAlert}
        onOpenChange={setShowValidationFailedAlert}
        onPrimaryButtonClick={() => setShowValidationFailedAlert(false)}
        primaryButtonText={t("Common.Ok")}
        bodyText={t(
          "Pages.CollaborativeDocument.Controls.ValidationFailedAlertBody"
        )}
        title={t(
          "Pages.CollaborativeDocument.Controls.ValidationFailedAlertTitle"
        )}
      />
    </>
  );
};

export default CollaborativeDoc;
