import { useContext, useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { t } from "i18next";
import UserContext from "../../state/UserContext";

dayjs.extend(relativeTime);

interface FormattedDateProps {
  date: Date;
  displayMode: "DATE-ONLY" | "DATE-AND-TIME" | "FROM-NOW";
}

/** A component for consistent date formatting, including the ability for a "2 days from now" style value */
const FormattedDate = ({ date, displayMode }: FormattedDateProps) => {
  // Context
  const userContext = useContext(UserContext);

  // State
  const [showUnderlyingDate, setShowUnderlyingDate] = useState<boolean>(false);

  // Local variables
  const now = dayjs();
  const dateObject = dayjs(date);

  // The "from now" span allows clicking to toggle displaying the underlying date
  const toggleDisplay = () => {
    setShowUnderlyingDate(!showUnderlyingDate);
  };

  if (!dateObject.isValid()) {
    return <span>{t("Common.InvalidDate")}</span>;
  } else {
    const formattedDate = dateObject.format(
      // Format for dayjs is different to most other things, e.g. the date picker component
      // e.g. what is normally dd/MM/yyyy is DD/MM/YYYY in dayJs
      // https://day.js.org/docs/en/display/format
      userContext.i18n.dateFormat.toUpperCase()
    );

    switch (displayMode) {
      case "FROM-NOW":
        const valueToDisplay = showUnderlyingDate
          ? formattedDate
          : dateObject.from(now);
        return (
          <span className="cursor-pointer select-none" onClick={toggleDisplay}>
            {valueToDisplay}
          </span>
        );
      case "DATE-AND-TIME":
        return (
          <span>
            {formattedDate} {dateObject.format("HH:mm")}
          </span>
        );
      case "DATE-ONLY":
      default:
        return <span>{formattedDate}</span>;
    }
  }
};

export default FormattedDate;
