import { useTranslation } from "react-i18next";
import { AdminJourneyFormDto } from "../../../types/dtos/admin/AdminJourneyFormDto";
import ClientFormCard from "./ClientFormCard";

interface ClientFormsGridProps {
  clientForms: AdminJourneyFormDto[];
  isLoading: boolean;
}

function ClientFormsGrid({
  clientForms,
  isLoading
}: ClientFormsGridProps){
  const { t } = useTranslation();
  return (
    <>
      {isLoading && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">          
          <ClientFormCard clientForm={null} />
          <ClientFormCard clientForm={null} />
          <ClientFormCard clientForm={null} />
          <ClientFormCard clientForm={null} />
          <ClientFormCard clientForm={null} />
        </div>
      )}
      {!isLoading && clientForms && clientForms.length > 0 && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
          {clientForms.map((cf) => {
            return (
              <ClientFormCard
                key={`cfc_${cf.clientFormId}`}
                clientForm={cf}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default ClientFormsGrid;
